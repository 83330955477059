import { __assign } from "tslib";
import { defineComponent } from "vue";
import "suus-api";
import AvatarCard from "components/UI/AvatarCard.vue";
import { mdiAccountMultipleOutline } from "@mdi/js";
import useTranslate from "features/common/translate/concerns/useTranslate";
import AddEditAttendeeDialog from "features/events/attendees/add-edit-attendee/AddEditAttendeeDialog.vue";
var EventAttendee = /** @class */ (function () {
    function EventAttendee() {
    }
    return EventAttendee;
}());
export default defineComponent({
    name: "EventAvatarCard",
    components: { AddEditAttendeeDialog: AddEditAttendeeDialog, AvatarCard: AvatarCard },
    props: {
        attendee: {
            type: Object,
            required: true,
        },
        reply: String,
        isEditable: Boolean,
        guestsAllowed: Boolean,
    },
    computed: {
        attendeeWithFullname: function () {
            return __assign(__assign({}, this.attendee), { full_name: this.attendee.name });
        },
    },
    methods: {
        attendeeGuestsText: function (attendeeCount) {
            return this.tc("guest", attendeeCount);
        },
        handleEdit: function (saveObject) {
            this.$emit("edit", saveObject);
        },
    },
    setup: function () {
        var tc = useTranslate("components.attendee_list").tc;
        return {
            guestsIcon: mdiAccountMultipleOutline,
            tc: tc,
        };
    },
});
