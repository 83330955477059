import { defineComponent } from "vue";
import HourglassCircle from "./assets/HourglassCircle.svg";
import CheckCircle from "./assets/CheckCircle.svg";
import CloseCircle from "./assets/CloseCircle.svg";
import TextBody from "components/UI/TextBody.vue";
export default defineComponent({
    name: "ActivationWizardStep",
    components: { TextBody: TextBody, HourglassCircle: HourglassCircle, CheckCircle: CheckCircle, CloseCircle: CloseCircle },
    props: {
        text: {
            type: String,
            required: true,
        },
        state: {
            type: String,
            required: true,
        },
    },
    computed: {
        textClasses: function () {
            return {
                "suus-activation-success": this.state === "success",
                "suus-activation-failure": this.state === "error",
                "suus-activation-waiting": this.state === "waiting",
                "suus-activation-active": this.state === "active",
            };
        },
    },
    setup: function () {
        return {};
    },
});
