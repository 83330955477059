<template lang="pug">
v-app.portal-app
    router-view
</template>

<script>
import { Portal } from "@linusborg/vue-simple-portal"
import { defineComponent } from "vue"

export default defineComponent({
  name: "PortalAppPlain",
  components: {
    Portal,
  },
})
</script>

<style lang="sass">
.portal-app
  position: static
</style>
