import { defineComponent } from "vue";
export default defineComponent({
    name: "ConnectIcon",
    props: {
        size: {
            type: Number,
            default: 64,
        },
    },
});
