import "features/media/media.types";
import "suus-api";
export function attachmentToAttachmentMediaUpload(attachment) {
    var id = attachment.id, frontend_id = attachment.frontend_id, name = attachment.name, file_type_extension = attachment.file_type_extension, links = attachment.links, type = attachment.type, content_type = attachment.content_type;
    return {
        attachmentId: id.toString(),
        frontendId: frontend_id,
        name: name,
        type: file_type_extension,
        thumbDataUrl: links.thumb_url,
        fullImageUrl: links.original_url,
        attachmentType: type,
        mimeContentType: content_type,
    };
}
