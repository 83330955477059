import { computed, defineComponent } from "vue";
import "suus-api";
import GalleryViewSkeletonLoader from "features/gallery/view/GalleryViewSkeletonLoader.vue";
export default defineComponent({
    name: "EventHighlightsFeaturedImage",
    components: { GalleryViewSkeletonLoader: GalleryViewSkeletonLoader },
    props: {
        image: {
            type: Object,
            required: false,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    setup: function (props) {
        var isLoading = computed(function () {
            return props.loading || !props.image;
        });
        console.log("image", props.image);
        return {
            isLoading: isLoading,
        };
    },
});
