import { __assign, __awaiter, __generator } from "tslib";
import { EVENT_STATUS } from "constants/constants";
import { createNamespacedHelpers } from "vuex";
var mapGetters = createNamespacedHelpers("event").mapGetters;
var mapMemberGetters = createNamespacedHelpers("member").mapGetters;
import { mdiAccountMultipleOutline } from "@mdi/js";
import StatusIcon from "../UI/StatusIcon.vue";
import Header from "../UI/Header.vue";
import TextButton from "../UI/TextButton.vue";
import TextBody from "../UI/TextBody.vue";
import AvatarCard from "../UI/AvatarCard.vue";
import Subtitle from "../UI/Subtitle.vue";
import GuestModal from "./GuestModal.vue";
import { computed, defineComponent } from "vue";
import EventAttendAlert from "features/events/attendees/event-attend-alert/EventAttendAlert.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import AddEditAttendeeDialog from "features/events/attendees/add-edit-attendee/AddEditAttendeeDialog.vue";
import Button from "components/UI/Button.vue";
import { membersApi } from "store/api";
import { RsvpReply } from "suus-api";
import { useRSVPContext } from "app/modules/events/rsvp-context/use-rsvp-context.concern";
import { useOwnUserAttendanceContext } from "app/modules/events/use-own-user-attendance-context.concern";
import "concerns/useContextName";
import EventAttendeeListSection from "features/events/attendees/event-attendee-list/EventAttendeeListSection.vue";
import { GroupAccess } from "components/TopicForm/general-fields/group-access";
export default defineComponent({
    name: "EventAttendeeList",
    components: {
        Button: Button,
        AddEditAttendeeDialog: AddEditAttendeeDialog,
        EventAttendAlert: EventAttendAlert,
        StatusIcon: StatusIcon,
        Header: Header,
        TextButton: TextButton,
        TextBody: TextBody,
        AvatarCard: AvatarCard,
        Subtitle: Subtitle,
        GuestModal: GuestModal,
        EventAttendeeListSection: EventAttendeeListSection,
    },
    data: function () { return ({
        EVENT_STATUS: EVENT_STATUS,
        guestsIcon: mdiAccountMultipleOutline,
        tab: null,
    }); },
    computed: __assign(__assign({ eventId: function () {
            return this.$route.params.event_id;
        }, AttendingCoetanenSubtitle: function () {
            if (this.coetanenCount < 1)
                return null;
            var component = ".coetanen_subtitle";
            return this.currentUserAttends
                ? this.tc$(component + ".attending", this.coetanenCount)
                : this.t$(component + ".other", [this.coetanenCount]);
        }, DeclinedMemberSubtitle: function () {
            if (this.declinesCount < 1)
                return null;
            return this.t$(".declined_subtitle", [this.declinesCount]);
        }, AttendingMembersSubtitle: function () {
            if (this.nonCoetans.length < 1)
                return null;
            var component = ".members_subtitle";
            var other = this.coetanenCount > 0
                ? this.tc$(component + ".other", this.nonCoetansCount)
                : "";
            return this.tc$(component + ".members", this.nonCoetansCount, {
                other: other,
            });
        }, noAttendeesText: function () {
            return this.t("no_attendees");
        }, isCurrentMemberOrganizerOrAllowed: function () {
            var _a;
            return ((_a = this.currentEvent) === null || _a === void 0 ? void 0 : _a.links.update_url) != null;
        }, getSearchFunction: function () {
            var _a, _b, _c, _d, _e;
            // TODO: Remove store; Doesnt belong to RSVP directly so ignored in [SN-1265]
            if (((_a = this.currentEvent) === null || _a === void 0 ? void 0 : _a.nonmember_access) === GroupAccess.CONFIDENTIAL ||
                ((_b = this.currentEvent) === null || _b === void 0 ? void 0 : _b.nonmember_access) === GroupAccess.INTERNAL) {
                return this.buildQueryFunction(undefined, undefined, (_c = this.currentEvent) === null || _c === void 0 ? void 0 : _c.group.id);
            }
            else {
                return this.buildQueryFunction((_d = this.currentEvent) === null || _d === void 0 ? void 0 : _d.group.client_id, (_e = this.currentEvent) === null || _e === void 0 ? void 0 : _e.group.level_id);
            }
        }, viewContext: function () {
            var _a, _b, _c;
            // TODO: Remove store; Doesnt belong to RSVP directly so ignored in [SN-1265]
            return {
                level_id: (_a = this.currentEvent) === null || _a === void 0 ? void 0 : _a.level_id,
                group: (_b = this.currentEvent) === null || _b === void 0 ? void 0 : _b.group,
                client_id: (_c = this.currentEvent) === null || _c === void 0 ? void 0 : _c.client_id,
            };
        } }, mapGetters([
        // TODO: Remove store; guestsAllowed doesn't belong to RSVP directly so ignored in [SN-1265]
        "guestsAllowed",
        // TODO: Remove store; currentEvent doesn't belong to RSVP directly so ignored in [SN-1265]
        "currentEvent",
    ])), mapMemberGetters(["currentMember"])),
    methods: {
        handleCreateAttendee: function (saveAttendeeObject) {
            var attendee = saveAttendeeObject.attendee, response = saveAttendeeObject.response, guest_count = saveAttendeeObject.guest_count;
            var personal_record_id = attendee.personal_record_id;
            this.createRSVP(personal_record_id, response, guest_count);
        },
        handleAttendeeEdit: function (saveAttendeeObject) {
            var attendee = saveAttendeeObject.attendee, response = saveAttendeeObject.response, guest_count = saveAttendeeObject.guest_count;
            var personal_record_id = attendee.personal_record_id;
            this.updateRSVP(personal_record_id, response, guest_count);
        },
        toggleAttending: function (isAttend) {
            if (isAttend) {
                this.attend(this.currentUserGuestsCount);
            }
            else {
                this.decline();
            }
        },
    },
    setup: function () {
        var _this = this;
        var _a = useTranslate("components.attendee_list"), t = _a.t, t$ = _a.t$, tc$ = _a.tc$;
        var buildQueryFunction = function (clientId, levelId, groupId) { return function (query) {
            return membersApi
                .fuzzySearch(query, levelId ? levelId : undefined, clientId ? [clientId] : undefined, groupId ? [groupId] : undefined, true)
                .then(function (response) { return response.data.results; });
        }; };
        var RsvpReplyAttend = RsvpReply.Attend;
        var RsvpReplyDecline = RsvpReply.Decline;
        var rsvpConcern = useRSVPContext();
        var loading = rsvpConcern.loading, createRSVP = rsvpConcern.createRSVP, updateRSVP = rsvpConcern.updateRSVP, reloadRSVPs = rsvpConcern.reloadRSVPs, attendsConcern = rsvpConcern.attends, declinesConcern = rsvpConcern.declines;
        var attendsCount = attendsConcern.attendsCount;
        var nonCoetans = attendsConcern.nonCoetans;
        var coetanenCount = attendsConcern.coetansCount;
        var _b = useOwnUserAttendanceContext(), ownUserAttends = _b.ownUserAttends, ownUserGuestsCount = _b.ownUserGuestsCount, eventAttendStatus = _b.eventAttendStatus, _attend = _b.attend, _decline = _b.decline;
        var attend = function (guestsCount) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _attend(guestsCount)];
                    case 1:
                        _a.sent();
                        reloadRSVPs();
                        return [2 /*return*/];
                }
            });
        }); };
        var decline = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _decline()];
                    case 1:
                        _a.sent();
                        reloadRSVPs();
                        return [2 /*return*/];
                }
            });
        }); };
        var areMoreAttendsAvailable = computed(function () { return !attendsConcern.allAttendsLoaded.value; });
        var areMoreDeclinesAvailable = computed(function () { return !declinesConcern.allDeclinesLoaded.value; });
        return {
            t: t,
            t$: t$,
            tc$: tc$,
            buildQueryFunction: buildQueryFunction,
            loadMoreRSVP: attendsConcern.loadMoreAttends,
            isMoreRSVPAvailable: areMoreAttendsAvailable,
            loadMoreDeclinedRSVP: declinesConcern.loadMoreDeclines,
            isMoreDeclinedRSVPAvailable: areMoreDeclinesAvailable,
            RsvpReplyAttend: RsvpReplyAttend,
            RsvpReplyDecline: RsvpReplyDecline,
            // New Concerns
            attendsCount: attendsCount,
            coetanenCount: coetanenCount,
            currentUserAttends: ownUserAttends,
            declinesCount: declinesConcern.totalDeclinesCount,
            nonCoetans: nonCoetans,
            currentUserGuestsCount: ownUserGuestsCount,
            loading: loading,
            coetanAttendees: attendsConcern.coetans,
            declinedAttendees: declinesConcern.declines,
            createRSVP: createRSVP,
            updateRSVP: updateRSVP,
            eventAttendStatus: eventAttendStatus,
            attend: attend,
            decline: decline,
            nonCoetansCount: attendsConcern.nonCoetansCount,
        };
    },
});
