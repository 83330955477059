import Vue from "vue";
import THEME from "../../constants/theme";
export default Vue.extend({
    name: "ReactionTextButton",
    props: {
        selected: { type: Object, required: true },
    },
    data: function () {
        return {
            labelStyles: THEME.typography.button,
        };
    },
});
