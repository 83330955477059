import { __assign } from "tslib";
import { defineComponent } from "vue";
import THEME from "constants/theme";
import AttachmentThumbnailProgress from "./AttachmentThumbnailProgress.vue";
import { mdiCloseThick } from "@mdi/js";
import ErrorPopup from "features/common/ui/ErrorPopup.vue";
var primaryColor = THEME.colors.light.primary;
var textColor = THEME.colors.light.textColor;
var successColor = THEME.colors.light.highlight;
var errorColor = THEME.colors.light.error;
var textFont = THEME.typography.text;
var boldFontWeight = THEME.typography.fontWeightBold;
export default defineComponent({
    name: "AttachmentThumbnail",
    components: { ErrorPopup: ErrorPopup, AttachmentThumbnailProgress: AttachmentThumbnailProgress },
    props: {
        fileTypeExtension: {
            type: String,
        },
        thumbnailUrl: {
            type: String,
        },
        progress: {
            type: Number, // between 0 and 1!
        },
        error: {
            type: String,
        },
    },
    computed: {
        isError: function () {
            return this.error != null;
        },
        isProgress: function () {
            return (this.progress != null &&
                this.progress < 1 &&
                this.progress >= 0 &&
                !this.isError);
        },
        isSuccess: function () {
            return this.progress != null && this.progress >= 1 && !this.isError;
        },
        isThumb: function () {
            return this.thumbnailUrl != null;
        },
        isShowMode: function () {
            return !this.progress && !this.isError;
        },
        getErrorIcon: function () {
            return mdiCloseThick;
        },
        getBorderColor: function () {
            if (this.isShowMode) {
                return textColor;
            }
            if (this.isError) {
                return errorColor;
            }
            if (this.isProgress) {
                return primaryColor;
            }
            if (this.isSuccess) {
                return successColor;
            }
            return textColor;
        },
        styleObject: function () {
            return {
                border: "2px solid ".concat(this.getBorderColor),
                borderRadius: "4px",
                height: "50px",
                width: "50px",
            };
        },
        fileExtensionStyle: function () {
            return __assign(__assign({ color: this.isShowMode ? textColor : successColor }, textFont), { fontWeight: boldFontWeight, height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" });
        },
        getErrorStyle: function () {
            return {
                color: "white",
                backgroundColor: errorColor,
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "help",
            };
        },
    },
});
