import Vue from "vue"

/**
 * Vue filter to convert the given value to percent.
 * http://jsfiddle.net/bryan_k/qauf3qyh/
 *
 * @param {String} value    The value string.
 * @param {Number} decimals The number of decimal places.
 */
Vue.filter("percentage", function (value, decimals = 0) {
  if (!value) value = 0

  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
  value = value + "%"
  return value
})
