<template lang="pug">
div(@click="handleScroll", :style="containerStyles")
  slot 
    Hyperlink(:text="label", small, :clickHandler="handleScroll")
</template>

<script>
import Hyperlink from "./Hyperlink.vue"

export default {
  name: "ScrollTo",
  components: {
    Hyperlink,
  },
  props: {
    label: String,
    targetId: String,
    callBefore: Function,
    inline: { type: Boolean, default: true },
  },
  computed: {
    containerStyles() {
      return {
        display: this.inline ? "inline" : "block",
      }
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      }
    },
  },
  methods: {
    handleScroll() {
      if (this.callBefore) this.callBefore()
      const target = document.getElementById(this.targetId)
      //can't use Vuetify GoTo as the background will scroll instead of the form
      if (target) target.scrollIntoView({ behavior: "smooth" })
    },
  },
}
</script>
