import { defineComponent } from "vue";
import WysiwygAddon from "features/wysiwyg/addon/WysiwygAddon.vue";
import "features/wysiwyg/addon/wysiwyg-addon.types";
import "features/wysiwyg/addon/wysiwyg-addon.types";
import "features/posts/form/addon/post-form-addon.types";
export default defineComponent({
    name: "PostFormAddon",
    components: { WysiwygAddon: WysiwygAddon },
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        initialActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        registerWysiwygAddon: {
            type: Function,
            required: true,
        },
        badgeCount: {
            type: Number,
            required: true,
        },
        registerPostFormAddon: {
            type: Function,
            required: true,
        },
        resetAddon: {
            type: Function,
            required: false,
        },
        createAddonData: {
            type: Function,
            required: true,
        },
        updateAddonData: {
            type: Function,
            required: true,
        },
        addAddonData: {
            type: Function,
            required: false,
        },
    },
    setup: function (props) {
        var registerAddon = function (wysiwygAddon) {
            props.registerWysiwygAddon(wysiwygAddon);
            var resetFunction = function () {
                wysiwygAddon.resetAddon();
                if (props.resetAddon)
                    props.resetAddon();
            };
            props.registerPostFormAddon({
                createAddonData: props.createAddonData,
                updateAddonData: props.updateAddonData,
                addAddonData: props.addAddonData,
                resetAddon: resetFunction,
            });
        };
        return {
            registerAddon: registerAddon,
        };
    },
});
