import { __awaiter, __generator } from "tslib";
import VueI18n from "vue-i18n";
import { clientApi } from "store/api";
import "axios";
require("../initializers/i18n.js");
var recheckNow = function (store) {
    var ts_key = "suus_i18n_de_timestamp";
    var last_checked_at_val = store.getItem(ts_key);
    if (!last_checked_at_val) {
        return true;
    }
    var last_checked_at = Number.parseInt(last_checked_at_val);
    var delayInMin = function (min) { return 1000 * 60 * min; };
    var mins = process.env.NODE_ENV == "development" ? 1 : 10;
    var recheckNow = last_checked_at + delayInMin(mins) < Date.now();
    // console.log(`Recheck in ${(last_checked_at + delayInMin(mins) - Date.now()) / 1000} sec`)
    return recheckNow;
};
var i18nLoading = false;
var loadI18n = function (i18n, store) {
    return __awaiter(this, void 0, void 0, function () {
        var messages, ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (i18nLoading)
                        return [2 /*return*/];
                    i18nLoading = true;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, clientApi.getLocales()];
                case 2:
                    messages = (_a.sent()).data;
                    store.setItem("suus_i18n_de_timestamp", Date.now());
                    i18n.setLocaleMessage(i18n.locale, messages);
                    store.setItem("suus_i18n_de", JSON.stringify(messages));
                    return [3 /*break*/, 5];
                case 3:
                    ex_1 = _a.sent();
                    if (ex_1.isAxiosError && ex_1.code === "ERR_NETWORK") {
                        console.warn("Could not load locales, probably the backend is not running.");
                    }
                    else {
                        throw ex_1;
                    }
                    return [3 /*break*/, 5];
                case 4:
                    i18nLoading = true;
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    });
};
var restoreI18n = function (i18n, store) {
    //console.info("restoring i18n.")
    var messagesStr = store.getItem("suus_i18n_de");
    if (messagesStr) {
        var messages = JSON.parse(messagesStr);
        i18n.setLocaleMessage("de", messages);
        if (i18n.locale !== "de")
            i18n.locale = "de";
        //console.info("I18n restored.")
    }
    else {
        loadI18n(i18n, store);
    }
};
var initI18n = function (i18n, store) {
    restoreI18n(i18n, store);
    if (recheckNow(store))
        loadI18n(i18n, store);
};
var dateTimeFormats = {
    de: {
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
    },
};
export var i18n = new VueI18n({
    locale: "de",
    dateTimeFormats: dateTimeFormats,
    missing: function (locale, key, vm) {
        if (recheckNow(window.localStorage)) {
            console.log("I18n key '" + key + "' missing, reloading locales.");
            loadI18n(i18n, window.localStorage);
        }
    },
});
initI18n(i18n, window.localStorage);
