<template>
  <div>
    <div class="ui divided items vuejs" v-if="relations.length > 0">
      <ListItem v-for="relation in relations" :key="relation.id">
        <template slot="title">
          <a
            :href="'/personal_records/' + relation.personal_record.id"
            class="title"
          >
            <span class="particle">{{
              relation.personal_record.particle
            }}</span>
            <strong class="plain-name">{{
              relation.personal_record.name_without_particle
            }}</strong>
            <template v-if="relation.personal_record.first_name"
              >, {{ relation.personal_record.first_name }}</template
            >
            <template v-if="relation.personal_record.alive">
              <span
                class="age"
                v-if="relation.personal_record.birthdate"
                :title="relation.personal_record.birthdate"
                >({{ relation.personal_record.age }})</span
              >
            </template>
            <template v-else>
              <span class="age"
                >(&dagger;<template
                  v-if="relation.personal_record.date_of_death"
                >
                  {{ relation.personal_record.date_of_death }}</template
                >)</span
              >
            </template>
          </a>
        </template>
        <template slot="meta-columns">
          <div class="column">
            <span class="ui label" v-for="tag in getTags(relation)">
              <i class="ui icon" v-if="tag.icon" :class="tag.icon"></i>
              {{ tag.name }}
            </span>
          </div>
        </template>
        <template slot="actions">
          <div
            class="ui icon button"
            @click="remove(relation.id)"
            v-if="!relation.via"
          >
            Löschen
          </div>
          <div
            class="ui icon button disabled"
            v-else
            title="Dieser Eintrag wurde automatisch auf Basis der Familienbeziehungen berechnet."
          >
            <i class="ui icon suus-wand-11"></i>
            {{ "Via " + relation.via }}
          </div>
        </template>
      </ListItem>
    </div>
    <p v-else>Keine Familie</p>

    <div
      class="ui button primary small"
      v-if="!showSearch"
      @click.prevent="showSearch = true"
    >
      Hinzufügen
    </div>
    <div class="ui message" v-else>
      <h5 class="ui dividing header">Person hinzufügen</h5>
      <br />
      <div class="ui grid align-bottom stackable">
        <div class="column four wide">
          <div class="field">
            <label>Vorname</label>
            <input
              v-model="first_name"
              class="string required prompt"
              placeholder="Vorname"
              @keypress.13.prevent="getResults"
              type="text"
            />
          </div>
        </div>
        <div class="column four wide">
          <div class="field">
            <label>Nachname</label>
            <input
              v-model="last_name"
              class="string required prompt"
              placeholder="Nachname"
              @keypress.13.prevent="getResults"
              type="text"
            />
          </div>
        </div>
        <div class="column seven wide field">
          <div class="ui button primary" @click="getResults">Suchen</div>
          <div
            class="ui button"
            @click="
              showSearch = false
              first_name = ''
              last_name = ''
              results = []
            "
          >
            Abbrechen
          </div>
        </div>
      </div>

      <div class="ui divided items vuejs" v-if="results.length > 0">
        <ListItem v-for="person in results" :key="person.id">
          <template slot="title">
            <a
              :href="'/personal_records/' + person.id"
              class="title"
              @click.prevent
            >
              <span class="particle">{{ person.particle }}</span>
              <strong class="plain-name">{{
                person.name_without_particle
              }}</strong>
              <template v-if="person.first_name"
                >, {{ person.first_name }}</template
              >
              <template v-if="person.alive">
                <span
                  class="age"
                  v-if="person.birthdate"
                  :title="person.birthdate"
                  >({{ person.age }})</span
                >
              </template>
              <template v-else>
                <span class="age"
                  >(&dagger;<template v-if="person.date_of_death">
                    {{ person.date_of_death }}</template
                  >)</span
                >
              </template>
            </a>
          </template>
          <template slot="actions">
            <strong class="item" style="padding-right: 2rem">
              Hinzufügen als:
            </strong>
            <a
              class="item ui icon button unpadded"
              v-for="rel in relationTypes"
              @click="addRelation(person.id, rel.value)"
            >
              {{ rel.text }}
            </a>
          </template>
        </ListItem>
      </div>
    </div>
    <div v-if="notFound">
      <p>{{ first_name }} {{ last_name }} nicht gefunden.</p>
      <div class="ui compact menu">
        <div class="ui simple dropdown item">
          {{ first_name }} {{ last_name }} anlegen als...
          <i class="dropdown icon"></i>
          <div class="menu">
            <a
              class="item"
              v-for="rel in relationTypes"
              @click.prevent="addRelation(undefined, rel.value)"
            >
              {{ rel.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <p v-if="error">Fehler: {{ error }}</p>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue"
import Vue from "vue"

export default Vue.extend({
  props: ["personal_record_id"],
  data() {
    return {
      first_name: "",
      last_name: "",
      error: null,
      notFound: false,
      results: [],
      showSearch: false,
      relationTypes: [
        { value: "spouse_of", text: "Partner/in" },
        { value: "parent_of", text: "Kind" },
        { value: "child_of", text: "Elternteil" },
        { value: "sibling_of", text: "Geschwister" },
        // { value: 'grandparent_of', text: 'Enkel' },
        // { value: 'grandchild_of', text: 'Großeltern' }
      ],
      relations: [],
    }
  },
  components: {
    ListItem,
  },
  mounted: function () {
    this.getFamily()
  },
  methods: {
    getTags: function (relation) {
      return [{ name: relation.name, icon: relation.icon }]
    },
    remove: function (id) {
      this.error = null
      this.loading = true
      fetch(this.relationUrl() + "?relation_id=" + id, {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.loading = false
          this.relations = data.results
          if (this.showSearch) {
            this.getResults()
          }
        })
        .catch((err) => {
          this.loading = false
          this.error = err.toString()
          console.error(err)
        })
    },
    queryUrl() {
      return (
        "/api/v1/personal_records/search/?first_name=" +
        this.first_name +
        "&last_name=" +
        this.last_name +
        "&personal_record_id=" +
        this.personal_record_id
      )
    },
    relationUrl() {
      return "/api/v1/personal_record/" + this.personal_record_id + "/relations"
    },
    addRelation: function (target_id, rel) {
      this.error = null
      this.loading = true
      let data = null
      let idx = this.results.filter(function (rel) {
        rel.id === target_id
      })[0]
      this.results.splice(idx, 1)
      if (target_id === undefined) {
        data = {
          target_id: target_id,
          rel: rel,
          first_name: this.first_name,
          last_name: this.last_name,
        }
        this.first_name = ""
        this.last_name = ""
        this.notFound = false
      } else {
        data = { target_id: target_id, rel: rel }
      }
      fetch(this.relationUrl(), {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        credentials: "same-origin",
        body: JSON.stringify(data),
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.loading = false
          this.relations = data.results
          this.getResults()
        })
        .catch((err) => {
          this.loading = false
          this.error = err.toString()
          console.error(err)
        })
    },
    getResults() {
      this.error = null
      this.loading = true
      fetch(this.queryUrl(), {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.loading = false
          this.results = data.results
          this.notFound = data.count == 0
        })
        .catch((err) => {
          this.loading = false
          this.error = err.toString()
          console.error(err)
        })
    },
    getFamily() {
      this.error = null
      this.loading = true
      fetch(this.relationUrl(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        },
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.loading = false
          this.relations = data.results
        })
        .catch((err) => {
          this.loading = false
          this.error = err.toString()
          console.error(err)
        })
    },
  },
})
</script>
