const state = {
  loading: 0,
  totalLoad: 0,
  fromRoute: null,
  error: undefined,
}

const getters = {
  loading: (state) => state.loading,
}

const mutations = {
  START_LOADING: (state) => {
    state.totalLoad++
    return state.loading++
  },
  FINISH_LOADING: (state) => {
    return state.loading--
  },
  ALL_LOADED: (state) => {
    return (state.totalLoad = 0)
  },
  SET_ERROR: (state, error) => {
    return (state.error = error)
  },
  SET_FROM_ROUTE: (state, r) => {
    return (state.fromRoute = r)
  },
}

const namespaced = true

const loader = {
  namespaced,
  state,
  getters,
  mutations,
}

export default loader
