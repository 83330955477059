import { __awaiter, __generator } from "tslib";
// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseError } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage, } from "firebase/messaging";
import { pushDeviceApi } from "store/api";
import { SW_VERSION, firebaseConfig, vapidKey, } from "initializers/firebase-config";
import { deleteToken } from "@firebase/messaging";
import "axios";
import log from "features/common/logging/logging";
// Initialize Firebase
var firebaseApp = initializeApp(firebaseConfig);
// Save the timestamp when the device registration was last confirmed to the API
var cookieName = "suus_last_registration_pinged_at";
export function enablePush(repairPush) {
    return __awaiter(this, void 0, void 0, function () {
        var hasFirebaseMessagingSupport, firebaseMessaging, serviceWorkerRegistration, token, e_1, e_2, registrationId;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    log(SW_VERSION + " Enabling Push Notfications...");
                    return [4 /*yield*/, isSupported()];
                case 1:
                    hasFirebaseMessagingSupport = _a.sent();
                    if (!hasFirebaseMessagingSupport) {
                        alert("Firebase Cloud Messaging is not supported by this browser.");
                        throw new Error("Firebase Cloud Messaging is not supported.");
                    }
                    firebaseMessaging = getMessaging(firebaseApp);
                    return [4 /*yield*/, navigator.serviceWorker.getRegistration()];
                case 2:
                    serviceWorkerRegistration = _a.sent();
                    if (!serviceWorkerRegistration) {
                        throw new Error("Service Worker not found!");
                    }
                    if (!repairPush) return [3 /*break*/, 13];
                    log("Deleting old token...");
                    token = null;
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 6, , 13]);
                    return [4 /*yield*/, getToken(firebaseMessaging, {
                            vapidKey: vapidKey,
                            serviceWorkerRegistration: serviceWorkerRegistration,
                        })];
                case 4:
                    token = _a.sent();
                    return [4 /*yield*/, deleteToken(firebaseMessaging)];
                case 5:
                    _a.sent();
                    return [3 /*break*/, 13];
                case 6:
                    e_1 = _a.sent();
                    if (!(e_1 instanceof FirebaseError)) return [3 /*break*/, 11];
                    log("FCM error during deletion of device registration: " + e_1.code, e_1);
                    if (!token) return [3 /*break*/, 10];
                    _a.label = 7;
                case 7:
                    _a.trys.push([7, 9, , 10]);
                    return [4 /*yield*/, pushDeviceApi.deletePushRegistration(token)];
                case 8:
                    _a.sent();
                    return [3 /*break*/, 10];
                case 9:
                    e_2 = _a.sent();
                    // Ignore 404 errors
                    if (e_2.isAxiosError &&
                        e_2.response.status === 404) {
                        log("Token was not present in database already, continuing...");
                    }
                    else {
                        throw e_2;
                    }
                    return [3 /*break*/, 10];
                case 10: return [3 /*break*/, 12];
                case 11:
                    log("Some error while deleting token!", e_1);
                    _a.label = 12;
                case 12: return [3 /*break*/, 13];
                case 13: return [4 /*yield*/, getToken(firebaseMessaging, {
                        vapidKey: vapidKey,
                        serviceWorkerRegistration: serviceWorkerRegistration,
                    })];
                case 14:
                    registrationId = _a.sent();
                    log("Token available, sending to backend: " + registrationId);
                    return [4 /*yield*/, pushDeviceApi.createPushRegistration({
                            registration_id: registrationId,
                        })];
                case 15: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function sendTestPush() {
    return __awaiter(this, void 0, void 0, function () {
        var serviceWorkerRegistration, token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, navigator.serviceWorker.getRegistration()];
                case 1:
                    serviceWorkerRegistration = _a.sent();
                    if (!serviceWorkerRegistration) return [3 /*break*/, 3];
                    return [4 /*yield*/, getToken(getMessaging(firebaseApp), {
                            vapidKey: vapidKey,
                            serviceWorkerRegistration: serviceWorkerRegistration,
                        })];
                case 2:
                    token = _a.sent();
                    return [2 /*return*/, pushDeviceApi.sendTestNotification(token)];
                case 3: return [2 /*return*/, Promise.reject("Service Worker not found!")];
            }
        });
    });
}
export function waitForTestMessage(messageId, timeout) {
    return __awaiter(this, void 0, Promise, function () {
        var messaging;
        return __generator(this, function (_a) {
            messaging = getMessaging(firebaseApp);
            return [2 /*return*/, new Promise(function (resolve) {
                    function handleMessage(payload) {
                        log("Awaited message received!", payload);
                        if (payload.messageId) {
                            clearTimeout(timeoutId);
                            if (unsubscribe) {
                                unsubscribe();
                            }
                            resolve(true);
                        }
                    }
                    log("Waiting for message");
                    var unsubscribe = onMessage(messaging, handleMessage);
                    var timeoutId = setTimeout(function () {
                        unsubscribe();
                        resolve(false);
                    }, timeout); // timeout after 10 seconds
                })];
        });
    });
}
function getLastPingTimestamp() {
    var v = document.cookie.match("(^|;) ?" + cookieName + "=([^;]*)(;|$)");
    return v ? Number.parseInt(v[2]) : 0;
}
function isTimeForNextPing() {
    var lastUpdated = getLastPingTimestamp();
    var oneHour = 60 * 60 * 1000;
    var nextUpdate = new Date(lastUpdated + oneHour);
    return nextUpdate < new Date();
}
function updatePingCookie() {
    document.cookie = cookieName + "=" + Date.now().toString() + ";path=/;";
}
function refreshDeviceRegistration(firebaseMessaging, serviceWorkerRegistration) {
    return __awaiter(this, void 0, void 0, function () {
        var currentToken, response, e_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(isTimeForNextPing() && Notification.permission === "granted")) return [3 /*break*/, 10];
                    return [4 /*yield*/, getToken(firebaseMessaging, {
                            vapidKey: vapidKey,
                            serviceWorkerRegistration: serviceWorkerRegistration,
                        })];
                case 1:
                    currentToken = _a.sent();
                    if (!currentToken) return [3 /*break*/, 9];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 8]);
                    return [4 /*yield*/, pushDeviceApi.updatePushRegistration({
                            registration_id: currentToken,
                        })];
                case 3:
                    response = _a.sent();
                    console.info("PushRegistration updated.");
                    updatePingCookie();
                    return [3 /*break*/, 8];
                case 4:
                    e_3 = _a.sent();
                    if (!(e_3.isAxiosError &&
                        e_3.response.status === 404)) return [3 /*break*/, 6];
                    // Show permission request UI
                    console.warn("Token has ceased, repairing registration.");
                    return [4 /*yield*/, enablePush(true)];
                case 5:
                    _a.sent();
                    log("Ready!");
                    return [3 /*break*/, 7];
                case 6: throw e_3;
                case 7: return [3 /*break*/, 8];
                case 8: return [3 /*break*/, 10];
                case 9:
                    console.warn("No token yet available");
                    _a.label = 10;
                case 10: return [2 /*return*/];
            }
        });
    });
}
function initRegistrationRefresh(sw) {
    return __awaiter(this, void 0, void 0, function () {
        var firebaseMessaging, serviceWorkerRegistration;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    log("The site is now controlled by a SW!");
                    return [4 /*yield*/, isSupported()];
                case 1:
                    if (!_a.sent()) return [3 /*break*/, 4];
                    firebaseMessaging = getMessaging(firebaseApp);
                    // Export the function to the window object, so it can be called from the UI
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.enablePush = enablePush;
                    return [4 /*yield*/, navigator.serviceWorker.getRegistration()];
                case 2:
                    serviceWorkerRegistration = _a.sent();
                    if (!serviceWorkerRegistration) {
                        throw new Error("This client is not controlled by a service worker!");
                    }
                    /* Disable because handler is not set
                        handleForegroundNotifications(
                          firebaseMessaging,
                          serviceWorkerRegistration
                        )*/
                    return [4 /*yield*/, refreshDeviceRegistration(firebaseMessaging, serviceWorkerRegistration)];
                case 3:
                    /* Disable because handler is not set
                        handleForegroundNotifications(
                          firebaseMessaging,
                          serviceWorkerRegistration
                        )*/
                    _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    console.warn("Push notifications are not supported by this browser.");
                    _a.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    });
}
export { firebaseApp, initRegistrationRefresh };
