import { usePermissionStore } from "features/common/permissions/usePermissionStore";
import "suus-api";
import { ref, watchEffect } from "vue";
export function usePermissionCheck() {
    var _a = usePermissionStore(), levelPermissions = _a.levelPermissions, clientPermissions = _a.clientPermissions;
    var hasLevelPermission = function (levelId, ability) {
        var hasLevelPermissionRef = ref(false);
        watchEffect(function () {
            hasLevelPermissionRef.value = levelPermissions.value.includes("".concat(ability, "@").concat(levelId));
        });
        return hasLevelPermissionRef;
    };
    var hasClientPermission = function (permissionName) {
        var hasClientPermissionRef = ref(false);
        watchEffect(function () {
            hasClientPermissionRef.value =
                clientPermissions.value.includes(permissionName);
        });
        return hasClientPermissionRef;
    };
    var hasLevelPermissionOrClientFallback = function (levelId, levelPermissionName, clientPermissionName) {
        if (levelId && levelPermissionName) {
            return hasLevelPermission(levelId, levelPermissionName);
        }
        else {
            return hasClientPermission(clientPermissionName);
        }
    };
    return {
        hasLevelPermission: hasLevelPermission,
        hasClientPermission: hasClientPermission,
        hasLevelPermissionOrClientFallback: hasLevelPermissionOrClientFallback,
    };
}
