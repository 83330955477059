import axios from "../../initializers/axios"
import { handleErrorResponse } from "helpers/errorHandling"
import { TOPIC_API } from "constants/constants"
import { topicsApi } from "store/api"
export default {
  async getTopic({ state, commit }, topicId) {
    if (state?.topics[topicId]) {
      let currentTopic = state.topics[topicId]
      commit("SET_TOPIC_DATA", currentTopic)
      return
    }
    try {
      commit("SET_TOPIC_ERROR", false)
      commit("SET_TOPIC_LOADING", true)
      let url = `${TOPIC_API}/${topicId}`
      const { data } = await axios.get(url)
      commit("SET_TOPIC_DATA", data)
    } catch (error) {
      commit("SET_TOPIC_ERROR", error)
      handleErrorResponse(error)
    } finally {
      commit("SET_TOPIC_LOADING", false)
    }
  },
  async createTopic({ state, commit }, requestBody) {
    try {
      commit("SET_TOPIC_ERROR", false)
      commit("SET_TOPIC_LOADING", true)
      let url = TOPIC_API
      const { data } = await axios.post(url, requestBody)
      commit("SET_TOPIC_DATA", data)
      commit("UPDATE_TOPICSLIST", data)
      return true
    } catch (error) {
      commit("SET_TOPIC_ERROR", error)
      handleErrorResponse(error)
      return false
    } finally {
      commit("SET_TOPIC_LOADING", false)
    }
  },
  async updateTopic({ state, commit }, { topicId, requestBody }) {
    try {
      commit("SET_TOPIC_ERROR", false)
      commit("SET_TOPIC_LOADING", true)
      let url = `${TOPIC_API}/${topicId}`
      const { data } = await axios.patch(url, requestBody)
      commit("SET_TOPIC_DATA", data)
      commit("UPDATE_TOPICSLIST", data)
    } catch (error) {
      commit("SET_TOPIC_ERROR", error)
      handleErrorResponse(error)
    } finally {
      commit("SET_TOPIC_LOADING", false)
    }
  },
  async deleteTopic({ state, commit }, topicId) {
    try {
      commit("SET_TOPIC_ERROR", false)
      let url = `${TOPIC_API}/${topicId}`
      await axios.delete(url)
      commit("SET_TOPIC_DATA", null)
      commit("REMOVE_TOPIC_FROM_TOPICSLIST", topicId)
    } catch (error) {
      commit("SET_TOPIC_ERROR", error)
      handleErrorResponse(error)
    }
  },
  async getTopicReactions({ state, commit }, topicId) {
    try {
      const { data } = await topicsApi.getTopicReactions(topicId)
      commit("SET_TOPIC_REACTIONS", data)
    } catch (error) {
      handleErrorResponse(error)
    }
  },
}
