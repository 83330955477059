import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
  inodes: undefined,
  currentInodeId: undefined,
  createCategoryUrl: undefined,
  uploaderOpen: false,
  loadingProgress: {},
  error: false,
  success: false,
  loading: false,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
