import Vue from "vue";
import ConfettiSearch from "../components/graphics/ConfettiSearch.vue";
import Button from "../components/UI/Button.vue";
import { mdiMagnify } from "@mdi/js";
import { mdiClose } from "@mdi/js";
import { mdiAccountSearchOutline } from "@mdi/js";
import { mdiChevronDoubleRight } from "@mdi/js";
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiHelpCircleOutline } from "@mdi/js";
import { membersApi } from "store/api";
import _ from "lodash";
export default Vue.extend({
    name: "SearchPerson",
    components: { ConfettiSearch: ConfettiSearch, Button: Button },
    data: function () {
        return {
            hideNoData: true,
            search: "",
            select: null,
            error: false,
            loading: false,
            focused: false,
            disabled: false,
            showModal: false,
            showBadge: true,
            isMobile: this.$vuetify.breakpoint.mobile,
            expand: !this.$vuetify.breakpoint.mobile,
            searchIcon: mdiMagnify,
            closeIcon: mdiClose,
            collapseIcon: mdiChevronDoubleRight,
            personSearchIcon: mdiAccountSearchOutline,
            helpIcon: mdiHelpCircleOutline,
            userIcon: mdiAccountCircleOutline,
            searchData: [],
            debouncedGetResults: function () { },
        };
    },
    created: function () {
        this.debouncedGetResults = _.debounce(this.getResults, 500);
    },
    mounted: function () {
        var searchBox = document.getElementById("search");
        if (!searchBox)
            return;
        searchBox.onclick = function () {
            var isExpanded, scrollAmount, slideTimer, slide_el;
            isExpanded = document.getElementsByClassName("suus__search-box container--expand").length;
            slide_el = document.getElementById("suus__sub-nav");
            scrollAmount = 0;
            if (isExpanded) {
                slideTimer = setInterval(function () {
                    slide_el.scrollLeft += 25;
                    scrollAmount += 25;
                    if (scrollAmount >= 320) {
                        window.clearInterval(slideTimer);
                    }
                }, 25);
            }
        };
        return;
    },
    methods: {
        toUrl: function (url) {
            window.location = url;
        },
        handleExpand: function () {
            if (this.isMobile) {
                this.expand && this.resetData();
                this.expand = !this.expand;
            }
        },
        handleSelect: function (item) {
            this.toUrl(item.link);
            this.select = item.name;
        },
        handleBadgeClick: function () {
            if (this.disabled) {
                return;
            }
            this.showBadge = false;
            this.disabled = false;
        },
        resetData: function () {
            this.searchData = [];
            this.hideNoData = true;
            this.search = "";
        },
        disableModal: function () {
            this.showModal = false;
            this.disabled = true;
        },
        activateModal: function () {
            this.showBadge && this.disabled
                ? (this.disabled = false)
                : (this.disabled = true);
        },
        sanitizeResults: function (data) {
            var _this = this;
            return data.map(function (record) {
                var alive = record.alive, _a = record.avatar_url, avatar_url = _a === void 0 ? "" : _a, list_name = record.list_name, id = record.id;
                var strippedListName = list_name.replace(/(<([^>]+)>)/gi, "");
                var description = alive
                    ? _this.recordDescriptionAlive(record)
                    : _this.recordDescriptionDead(record);
                var link = "".concat(window.location.origin, "/member/").concat(id);
                return {
                    name: strippedListName,
                    description: description,
                    avatar: avatar_url,
                    link: link,
                };
            });
        },
        recordDescriptionAlive: function (record) {
            var _a = record.postal_code, postal_code = _a === void 0 ? "" : _a, _b = record.region, region = _b === void 0 ? "" : _b, _c = record.age, age = _c === void 0 ? "" : _c;
            var age_description = "".concat(age ? "".concat(age, " Jahre") : "");
            var comma = age && (postal_code || region) ? ", " : "";
            var region_description = "".concat(postal_code || region
                ? "aus ".concat(postal_code ? postal_code + " " : "").concat(region)
                : "");
            return age_description + comma + region_description;
        },
        recordDescriptionDead: function (record) {
            var date_of_death = record.date_of_death;
            return "\u271D verstorben ".concat(date_of_death || "");
        },
        getResults: function () {
            var _this = this;
            this.searchData = [];
            this.error = false;
            this.hideNoData = true;
            if (this.search.length > 0) {
                this.loading = true;
                this.hideNoData = false;
                membersApi
                    .fuzzySearch(this.search, undefined, undefined, undefined, true)
                    .then(function (res) {
                    var membersFound = res.data.results;
                    if (membersFound) {
                        _this.searchData = _this.sanitizeResults(membersFound);
                    }
                })
                    .catch(function (error) {
                    console.log(error);
                    _this.error = true;
                })
                    .finally(function () { return (_this.loading = false); });
            }
        },
    },
    watch: {
        search: function (val) {
            //console.log(val)
            if (val && val.length > 0) {
                this.disableModal();
            }
            if (!val || val.length === 0) {
                this.activateModal();
                this.hideNoData = false;
            }
            if (val && val !== this.select) {
                this.debouncedGetResults();
            }
        },
    },
});
