import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref } from "vue";
import { mdiGift } from "@mdi/js";
import ChangelogCard from "features/changelog/ChangelogCard.vue";
import { changelogItems } from "changelog-items";
import { currentMemberApi } from "store/api";
import CloseableDialog from "components/UI/CloseableDialog.vue";
import "features/changelog/changelog.types";
export default defineComponent({
    name: "ChangelogEntry",
    components: { CloseableDialog: CloseableDialog, ChangelogCard: ChangelogCard },
    props: {
        lastSeen: {
            type: String,
            required: true,
        },
    },
    setup: function (props) {
        var _this = this;
        var hideChangelogBadge = ref(false);
        var lastSeenDate = computed(function () { return new Date(parseInt(props.lastSeen) * 1000); });
        var handleDialogOpening = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, currentMemberApi.setChangelogViewedNow()];
                    case 1:
                        _a.sent();
                        hideChangelogBadge.value = true;
                        return [2 /*return*/];
                }
            });
        }); };
        var hasUnseenUpdates = computed(function () {
            return changelogItems.some(function (item) { return item.date > lastSeenDate.value; });
        });
        var getChangelogItemKey = function (item) {
            return item.title + item.date.toString();
        };
        return {
            mdiGift: mdiGift,
            changelogItems: changelogItems,
            handleDialogOpening: handleDialogOpening,
            hideChangelogBadge: hideChangelogBadge,
            lastSeenDate: lastSeenDate,
            hasUnseenUpdates: hasUnseenUpdates,
            getChangelogItemKey: getChangelogItemKey,
        };
    },
});
