import { __assign, __extends } from "tslib";
import { ThumbnailService, } from "features/gallery/service/thumbnail.service";
import "features/gallery/service/gallery.service";
import { buildThumbnailRepresentation } from "features/gallery/service/thumbnail.functions";
import { mdiStar } from "@mdi/js";
var PostThumbnailService = /** @class */ (function (_super) {
    __extends(PostThumbnailService, _super);
    function PostThumbnailService(galleryService) {
        return _super.call(this, galleryService, buildPostThumbnailRepresentation(galleryService)) || this;
    }
    return PostThumbnailService;
}(ThumbnailService));
export { PostThumbnailService };
var buildPostThumbnailRepresentation = function (galleryService) {
    return function (thumbnailRepresentation, canEdit) {
        var thumbnailRep = buildThumbnailRepresentation(galleryService)(thumbnailRepresentation, canEdit);
        if (thumbnailRep.isHighlight.value && !galleryService.canEdit.value) {
            thumbnailRep.topLeftAction.value = __assign(__assign({}, thumbnailRep.topLeftAction.value), { showAlways: true, icon: mdiStar, tooltip: "Dieses Bild ist ein Highlight!" });
        }
        return thumbnailRep;
    };
};
