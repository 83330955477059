import { __assign } from "tslib";
import { defineComponent } from "vue";
import THEME from "constants/theme";
export default defineComponent({
    name: "PostFormError",
    props: {
        error: {
            type: String,
            default: null,
        },
    },
    setup: function () {
        var errorMessageStyles = __assign({}, THEME.typography.inputMessage);
        return {
            errorMessageStyles: errorMessageStyles,
        };
    },
});
