import { Calendar } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import { toMoment } from "@fullcalendar/moment"
import momentPlugin from "@fullcalendar/moment"
import deLocale from "@fullcalendar/core/locales/de"
import listPlugin from "@fullcalendar/list"

import "@fullcalendar/core/main.css"
import "@fullcalendar/daygrid/main.css"

window.initCalendar = function (scope) {
  let url
  window.copyIcalUrl = function () {
    const input = $("#ical-url").select()
    document.execCommand("Copy")
    const icon = $("#ical-form .icon.suus-ungroup")
    return icon.removeClass("suus-ungroup").addClass("suus-check-2")
  }

  if ($("body.user_calendar").length > 0) {
    url = "user_calendar.json"
  } else if ($("body.calendar.level").length > 0) {
    url = "calendar.json"
  } else {
    url = "global_calendar.json"
  }

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches

  const mobileHeader = {
    left: "prev,today",
    center: "title",
    right: "next",
  }
  const desktopHeader = {
    left: "prev,next today",
    center: "title",
    right: "month listMonth dayGridMonth",
  }

  // Default case are local events, mark level events with icon
  const localRenderFunc = function (info) {
    var el = info.el
    console.log(el)
    var event = info.event.extendedProps
    if (!event.group) return

    var title = (
      info.view.viewSpec.type == "dayGridMonth"
        ? el.getElementsByClassName("fc-title")
        : el.getElementsByClassName("fc-list-item-title")
    )[0]

    if (event.level) {
      title.insertAdjacentHTML(
        info.view.viewSpec.type == "dayGridMonth"
          ? "beforebegin"
          : "afterbegin",
        '<i class="ui icon suus-connect has-popup" title="' +
          event.level.name +
          '"></i>'
      )
      title.insertAdjacentText("beforeend", " (" + event.group.name + ")")
    } else {
      title.insertAdjacentText("beforeend", " (" + event.group.name + ")")
    }
  }

  // Default case are level events, mark published client events with icon
  const levelRenderFunc = function (info) {
    var el = info.el
    var event = info.event.extendedProps
    if (!event.group) return

    var title
    var content

    if (info.view.viewSpec.type == "dayGridMonth") {
      title = el.getElementsByClassName("fc-title")[0]
      content = el.getElementsByClassName("fc-content")[0]

      if (event.client) {
        content.insertAdjacentHTML(
          "afterbegin",
          '<i class="ui icon avatar corps has-popup" title="' +
            event.client.name +
            '" style="display: inline-block; margin-right: 5px; ' +
            event.client.ribbon_css +
            '"></i>'
        )
        title.insertAdjacentText(
          "beforeend",
          " (" + event.client.shortname + ")"
        )
      } else {
        title.insertAdjacentHTML(
          "beforebegin",
          '<i class="ui icon suus-connect has-popup" title="' +
            event.level.name +
            '"></i>'
        )
        title.insertAdjacentText("beforeend", " (" + event.group.name + ")")
      }
    } else {
      title = el.getElementsByClassName("fc-list-item-title")[0]
      content = el.getElementsByClassName("fc-list-item-title")[0]

      if (event.client) {
        content.insertAdjacentHTML(
          "afterbegin",
          '<i class="ui icon avatar corps has-popup" title="' +
            event.client.name +
            '" style="display: inline-block; margin-right: 5px; ' +
            event.client.ribbon_css +
            '"></i>'
        )
        title.insertAdjacentText(
          "beforeend",
          " (" + event.client.shortname + ")"
        )
      } else {
        title.insertAdjacentHTML(
          "afterbegin",
          '<i class="ui icon suus-connect has-popup" title="' +
            event.level.name +
            '"></i>'
        )
        title.insertAdjacentText("beforeend", " (" + event.group.name + ")")
      }
    }
  }

  const renderFunc =
    $("body.calendar.level").length > 0 ? levelRenderFunc : localRenderFunc

  let calendarEl = document.getElementById("calendar")

  let calendar = new Calendar(calendarEl, {
    plugins: [dayGridPlugin, momentPlugin, listPlugin],
    header: isMobile ? mobileHeader : desktopHeader,
    editable: false,
    defaultView: isMobile ? "listMonth" : "dayGridMonth",
    eventLimit: false,
    firstDay: 1,
    fixedWeekCount: false,
    //height: 'auto'
    timeZone: "UTC",
    aspectRatio: isMobile ? "auto" : 2,
    locale: deLocale,
    lazyFetching: true,
    timeFormat: "H:mm",
    eventDataTransform(event) {
      event.start = toMoment(event.start, calendar).toDate()

      if (event.end !== null && event.allDay) {
        // fullcalendar understands end = 24.3.19 00:00 as an event ending at 23.03.19 at 23:59
        event.end = toMoment(event.end, calendar).add(1, "days").toDate()
      } else {
        event.end = toMoment(event.end, calendar).toDate()
      }
      return event
    },
    events: url,
    eventRender: renderFunc,
  })

  calendar.render()
}
