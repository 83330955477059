import { computed, defineComponent, inject } from "vue";
import GalleryThumbnail from "features/gallery/thumbnail/GalleryThumbnail.vue";
import "features/gallery/gallery.types";
import "features/gallery/service/thumbnail.service";
export default defineComponent({
    name: "ServicedGalleryThumbnail",
    components: { GalleryThumbnail: GalleryThumbnail },
    props: {
        size: {
            type: Number,
            default: 128,
        },
        medium: {
            type: Object,
        },
        bottomLeftAction: {
            type: Object,
            default: null,
        },
    },
    setup: function (props) {
        var _a;
        var thumbnailService = inject("ThumbnailService");
        var thumbnailRepresentation = thumbnailService.registerThumbnail(props.medium);
        var topLeftAction = computed(function () { var _a; return (_a = thumbnailRepresentation.topLeftAction) === null || _a === void 0 ? void 0 : _a.value; });
        var topRightAction = computed(function () { var _a; return (_a = thumbnailRepresentation.topRightAction) === null || _a === void 0 ? void 0 : _a.value; });
        var image = (_a = thumbnailRepresentation.galleryMedium.uploaded_file.thumb.url) !== null && _a !== void 0 ? _a : thumbnailRepresentation.galleryMedium.uploaded_file.preview.url;
        return {
            topLeftAction: topLeftAction,
            topRightAction: topRightAction,
            image: image,
        };
    },
});
