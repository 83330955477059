import Vue from "vue";
export default Vue.extend({
    name: "UnionIcon",
    props: {
        color: String,
        width: { type: Number, default: 26 },
        height: { type: Number, default: 26 },
    },
    computed: {
        iconColor: function () {
            var _a, _b;
            switch (this.color) {
                case "primary":
                    return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
                case "textColor":
                    return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
                default:
                    return this.color;
            }
        },
    },
});
