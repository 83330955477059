window.initPageLayout = ->
  $('.overlay-label.account').click (e) ->
    o = $(e.target).closest('.overlay')
    unless $(o).hasClass('open')
      $(o).addClass 'open' # Open news
      e.stopPropagation() # dont close on initial click
      $(document).one 'click', ->
        $(o).removeClass 'open'
      $(o).find('.menu').click (e) ->
        e.stopPropagation()  # dont close when clicked inside menu


  $('.overlay-label.clients').click (e) ->
    o = $(e.target).closest('.overlay')
    unless $(o).hasClass('open')
      $(o).addClass 'open' # Open news
      e.stopPropagation() # dont close on initial click
      $(document).one 'click', ->
        $(o).removeClass 'open'
      $(o).find('.menu').click (e) ->
        e.stopPropagation()  # dont close when clicked inside menu


  $('.sub.menu .search').click (e) ->
    unless $('.sub.menu .search').hasClass('open')
      $('.sub.menu .search').addClass 'open' # Open news
      e.stopPropagation() # dont close on initial click
      $(document).one 'click', ->
        $('.sub.menu .search').removeClass 'open'
      $('.sub.menu .search').click (e) ->
        e.stopPropagation()  # dont close when clicked inside menu
