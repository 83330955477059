<template lang="pug">
LoaderWrapper
  h1.ui.header
    .content Dateiablage
    .sub.header
      | In der Dateiablage sind sämtliche Dokumente und Dateien abgespeichert.
      | Auf dieser Seite findest du neben Schnellzugriffen zu besonders wichtigen
      | Dokumenten eine Übersicht über die jüngst hochgeladenen Dateien.

  .ui.divider.horizontal.left
    i.ui.icon.suus-square-right-03
    | Angepinnte Dokumente

  lazy-list(ref="lazyItems"
    endpoint="/api/v1/drive/pinned?"
    :placeholders="{items: 3, slots: ['image', 'title']}"
    :order="[{name: 'asc'}]"
  )
    template(#item="{item: inode}")
      template(v-if="inode.type === 'Container'")
        PinnedContainer(:inode="inode" :show="{actions: true, path: false}")
      template(v-else)
        component(:is="inode.type" :inode="inode" :show="{actions: true, path: false}")

    template(#empty)
      p.no-elements
        | Es ist nichts angepinnt. Du kannst Dateien, Ordner und Serien anpinnen.
        | Bei angepinnten Serien, z.B. Protokolle, ist automatisch immer das jüngste Protokoll angepinnt.

  .ui.divider.horizontal.left
    i.ui.icon.suus-square-right-03
    | Neueste Dokumente

  lazy-list(ref="lazyItems"
    endpoint="/api/v1/drive/news?"
    :placeholders="{items: 10, slots: ['image', 'title']}"
    :order="latestOrdering"
    :initialLoad="10"
  )
    template(#item="{item: inode}")
      component(:is="inode.type" :inode="inode" :show="{actions: false, path: true, category: true, created_at: true}")
    template(#empty)
      p.no-elements Es wurden noch keine Dateien hochgeladen.
</template>

<script>
import LoaderWrapper from "../LoaderWrapper.vue"
import InodeIcon from "../InodeIcon.vue"
import LazyList from "../../LazyList.vue"
import ListItem from "../../ListItem.vue"

import DriveFile from "../ListView/DriveFile.vue"
import Folder from "../ListView/Folder.vue"
import Photo from "../ListView/Photo.vue"
import ContainerFile from "../ListView/ContainerFile.vue"
import Gallery from "../ListView/Gallery.vue"
import Container from "../ListView/Container.vue"
import PinnedContainer from "../ListView/PinnedContainer.vue"
import moment from "moment"

import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")

export default {
  name: "LatestFiles",
  methods: {
    inodeLink(inode) {
      if (inode.type === "Container" || inode.type === "Folder") {
        return { name: "drive/folder", params: { inode_id: inode.id } }
      } else {
        return {
          name: "drive/file",
          params: { drive_file_id: inode.id },
        }
      }
    },
  },
  computed: {
    latestOrdering() {
      return [
        {
          created_at: {
            order: "desc",
            transform(x) {
              return moment(x).valueOf()
            },
          },
        },
      ]
    },
  },
  components: {
    LazyList,
    ListItem,
    InodeIcon,
    DriveFile,
    Folder,
    Photo,
    ContainerFile,
    Container,
    Gallery,
    PinnedContainer,
    LoaderWrapper,
  },
}
</script>

<style scoped lang="sass">
.ui.divider.horizontal.left
  margin-bottom: 1.5rem
</style>
