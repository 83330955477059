import { defineComponent } from "vue";
import AndroidPermissionImage from "./assets/AndroidPermission.svg";
import iOSPermissionImage from "./assets/iOSPermissions.svg";
import PointingGuy from "./assets/PointingGuy.svg";
import TextBody from "components/UI/TextBody.vue";
import Subtitle from "components/UI/Subtitle.vue";
import Button from "components/UI/Button.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "NotificationPermissionWizard",
    components: {
        Button: Button,
        Subtitle: Subtitle,
        TextBody: TextBody,
        AndroidPermissionImage: AndroidPermissionImage,
        PointingGuy: PointingGuy,
        iOSPermissionImage: iOSPermissionImage,
    },
    props: { isIOS: { type: Boolean, required: true } },
    setup: function (props, context) {
        var t$ = useTranslate("components.push_wizard.notification_wizard").t$;
        return {
            t$: t$,
        };
    },
});
