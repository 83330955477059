import Vue from "vue";
export default Vue.extend({
    name: "Digest",
    props: {
        width: { type: String, default: "81" },
    },
    data: function () {
        return {
            primaryColor: this.$vuetify.theme.themes.light.primary,
        };
    },
});
