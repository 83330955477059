export function parseAxiosError(response) {
    var message = response.data.message;
    if (typeof message === "string") {
        if (message.includes("Not authorized.")) {
            return "Dir fehlen hierfür die nötigen Rechte.";
        }
        else {
            return "Ein unbekannter Fehler ist aufgetreten.";
        }
    }
    var keys = Object.keys(message);
    var errors = keys.map(function (key) {
        return "".concat(key, ": ").concat(message[key].join(", "));
    });
    return errors.join("\n");
}
