import { defineComponent } from "vue";
import GalleryDashboardCardUI from "features/gallery/dashboard/GalleryDashboardCardUI.vue";
import "suus-api";
import { getContextNameLong, getViewContextOfGroup, } from "concerns/useContextName";
export default defineComponent({
    name: "GalleryDashboardCard",
    components: {
        GalleryDashboardCardUI: GalleryDashboardCardUI,
    },
    props: {
        eventGallery: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        var eventGallery = props.eventGallery;
        var group = eventGallery.event.group;
        var viewContext = getViewContextOfGroup(group);
        var eventName = eventGallery.event.title;
        var eventDate = new Date(eventGallery.event.start_date);
        var images = eventGallery.event_media_preview.map(function (media) { return ({
            src: media.uploaded_file.thumb.url,
        }); });
        var allImagesCount = eventGallery.event_media_count;
        var userImageSrc = eventGallery.creator.avatar_url;
        var userName = getContextNameLong(viewContext, eventGallery.creator);
        var postDate = new Date(eventGallery.created_at);
        var showLevelIcon = !!group.level_id;
        var galleryUrl = eventGallery.links.gallery_url;
        return {
            eventName: eventName,
            eventDate: eventDate,
            images: images,
            allImagesCount: allImagesCount,
            userImageSrc: userImageSrc,
            userName: userName,
            postDate: postDate,
            showLevelIcon: showLevelIcon,
            galleryUrl: galleryUrl,
        };
    },
});
