import Vue from "vue";
import TextButton from "./TextButton.vue";
import Hyperlink from "./Hyperlink.vue";
export default Vue.extend({
    name: "SamePageLink",
    components: {
        TextButton: TextButton,
        Hyperlink: Hyperlink,
    },
    props: {
        type: {
            type: String,
            default: "text-button",
            validator: function (val) { return ["text-button", "hyperlink"].includes(val); },
        },
        small: { type: Boolean, default: false },
        label: { type: String, required: true },
        target: { type: String, required: true },
        color: { type: String, default: "primary" },
        callback: { type: Function },
        default: function () { },
    },
    methods: {
        handleScroll: function () {
            if (!document.querySelector(this.target)) {
                console.error("Broken reference in component SamePageLink: scroll target \"".concat(this.target, "\" was not found on page!"));
                return;
            }
            this.$vuetify.goTo(this.target, this.options);
            if (this.callback)
                this.callback();
        },
    },
    computed: {
        options: function () {
            return {
                duration: 300,
                offset: 0,
                easing: "easeInOutCubic",
            };
        },
    },
});
