import { computed, defineComponent } from "vue";
import { formatDateToDDMonthYYYY } from "app/util/date.helper";
import Avatar from "components/UI/Avatar.vue";
import RelativeTime from "components/UI/RelativeTime.vue";
import Subtitle from "components/UI/Subtitle.vue";
import { mdiGoogleCirclesCommunities } from "@mdi/js";
import ConnectIcon from "components/icons/ConnectIcon.vue";
export default defineComponent({
    name: "GalleryDashboardCardUI",
    components: { ConnectIcon: ConnectIcon, Subtitle: Subtitle, RelativeTime: RelativeTime, Avatar: Avatar },
    props: {
        eventName: { type: String, required: true },
        eventDate: { type: Date, required: true },
        images: { type: Array, required: true },
        allImagesCount: { type: Number, required: true },
        userImageSrc: { type: String, required: false },
        userName: { type: String, required: true },
        postDate: { type: Date, required: true },
        showLevelIcon: { type: Boolean, required: false, default: false },
        galleryUrl: { type: String, required: false },
    },
    setup: function (props) {
        console.log(props);
        var eventDateDisplayString = computed(function () { return "am ".concat(formatDateToDDMonthYYYY(props.eventDate)); });
        var imageCountString = computed(function () {
            if (props.allImagesCount === 1) {
                return "Ein Foto";
            }
            return "".concat(props.allImagesCount, " Fotos");
        });
        var additionalGridClasses = computed(function () {
            if (props.images.length === 1) {
                return "one-image";
            }
            if (props.images.length === 2) {
                return "two-images";
            }
            if (props.images.length === 3) {
                return "three-images";
            }
            return "";
        });
        return {
            eventDateDisplayString: eventDateDisplayString,
            imageCountString: imageCountString,
            additionalGridClasses: additionalGridClasses,
            mdiGoogleCirclesCommunities: mdiGoogleCirclesCommunities,
        };
    },
});
