import "vue";
import { SuusMention } from "components/Wysiwyg/extensions/suus-mention/SuusMention";
import { VueRenderer } from "@tiptap/vue-2";
import MentionList from "components/Wysiwyg/extensions/suus-mention/MentionList.vue";
import tippy from "tippy.js";
export function ConfiguredSuusMentionExtension(suggestionFunction, parentComponent) {
    return SuusMention.configure({
        suggestion: {
            decorationTag: "a",
            allowSpaces: true,
            items: suggestionFunction,
            render: function () {
                var component;
                var popup;
                return {
                    onBeforeStart: function (props) {
                        component = new VueRenderer(MentionList, {
                            // using vue 2:
                            parent: parentComponent,
                            propsData: props,
                            // using vue 3:
                            // props,
                            // editor: props.editor,
                        });
                        if (!props.clientRect) {
                            return;
                        }
                        popup = tippy("body", {
                            getReferenceClientRect: props.clientRect,
                            appendTo: function () { return document.body; },
                            content: component.element,
                            showOnCreate: true,
                            interactive: true,
                            trigger: "manual",
                            placement: "bottom-start",
                        });
                    },
                    onUpdate: function (props) {
                        component.updateProps(props);
                        if (!props.clientRect) {
                            return;
                        }
                        popup[0].setProps({
                            getReferenceClientRect: props.clientRect,
                        });
                    },
                    onKeyDown: function (props) {
                        var _a;
                        if (props.event.key === "Escape") {
                            popup[0].hide();
                            return true;
                        }
                        return (_a = component === null || component === void 0 ? void 0 : component.ref) === null || _a === void 0 ? void 0 : _a.onKeyDown(props);
                    },
                    onExit: function () {
                        popup[0].destroy();
                        component.destroy();
                    },
                };
            },
        },
    });
}
