import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, inject } from "vue";
import "suus-api";
import GalleryView from "features/gallery/view/GalleryView.vue";
import Header from "components/UI/Header.vue";
import { getHighlightsGalleryMedia } from "features/events/highlights/highlights.functions";
import "features/gallery/service/gallery.service";
import "core/event-bus/event-bus";
import IconButton from "components/UI/IconButton.vue";
import { mdiDownload } from "@mdi/js";
import { highlightsApi } from "store/api";
export default defineComponent({
    name: "EventHighlightsView",
    components: { IconButton: IconButton, GalleryView: GalleryView, Header: Header },
    props: {
        event: {
            type: Object,
            required: true,
        },
        openEvent: {
            type: Object,
            default: null,
        },
    },
    setup: function (props) {
        var _this = this;
        var loadMoreMedia = function (offset, limit) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, getHighlightsGalleryMedia(props.event.id, offset, limit)];
            });
        }); };
        var isSortable = computed(function () {
            return !!props.event.links.update_url;
        });
        var galleryService = inject("GalleryService");
        var totalEventMedia = galleryService.highlightsCount;
        var handleReorder = function (_a) {
            var id = _a.id, index = _a.index;
            galleryService.reorderHighlights({ id: id, index: index });
        };
        var onDownloadClick = function () {
            // Set the responseType to allow our axios interceptor to handle the file download
            highlightsApi.downloadHighlights(props.event.id.toString(), {
                responseType: "blob",
            });
        };
        return {
            loadMoreMedia: loadMoreMedia,
            isSortable: isSortable,
            totalEventMedia: totalEventMedia,
            handleReorder: handleReorder,
            mdiDownload: mdiDownload,
            onDownloadClick: onDownloadClick,
        };
    },
});
