import { defineComponent } from "vue";
import { mdiMoonFull } from "@mdi/js";
export default defineComponent({
    name: "ChangelogCard",
    methods: {
        mdiMoonFull: function () {
            return mdiMoonFull;
        },
    },
    props: {
        image: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: false,
        },
        date: {
            type: Date,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
        tags: {
            type: Array,
            required: false,
            default: function () { return []; },
        },
    },
    setup: function (props) {
        var formattedMonth = props.date.toLocaleString("de-DE", { month: "long" });
        var formattedYes = props.date.getFullYear();
        var formattedDate = "".concat(formattedMonth, " ").concat(formattedYes);
        return {
            formattedDate: formattedDate,
        };
    },
});
