import { defineComponent } from "vue";
import "suus-api";
import Post from "../../components/Post/Post.vue";
import TimeDelta from "../../components/UI/TimeDelta.vue";
import "concerns/useContextName";
import "features/posts/form/post-form.types";
export default defineComponent({
    name: "PostListItem",
    components: {
        Post: Post,
        TimeDelta: TimeDelta,
    },
    props: {
        post: { type: Object, default: function () { return null; } },
        viewContext: { type: Object },
        previousPostCreatedAt: { type: String, required: false },
        updatePost: {
            type: Function,
        },
    },
});
