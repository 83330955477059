export default {
  SET_GROUP_DATA(state, data) {
    state.currentGroup = { ...state.currentGroup, data }
  },
  SET_GROUP_ERROR(state, error) {
    state.currentGroup = { ...state.currentGroup, error }
  },
  SET_GROUP_LOADING(state, loading) {
    state.currentGroup = { ...state.currentGroup, loading }
  },
}
