<template lang="pug">
portal.portal(selector="#event-portal")
  ConfirmationDialogProvider
    EventPage
  Lightbox(:service="lightboxService")
</template>

<script>
import EventPage from "../Event/EventPage.vue"
import { LightboxService } from "features/gallery/lightbox/lightbox.service"
import { provide } from "vue"
import ConfirmationDialogProvider from "features/common/confirmation-dialog/ConfirmationDialogProvider.vue"
const Lightbox = () => import("features/gallery/lightbox/Lightbox.vue")
export default {
  name: "PortalEvent",
  components: { ConfirmationDialogProvider, EventPage, Lightbox },
  setup() {
    const lightboxService = new LightboxService()
    provide("lightboxService", lightboxService)
    return {
      lightboxService,
    }
  },
}
</script>

<style>
#event-portal {
  justify-content: center;
}

#event-portal > div {
  width: 100%;
}
</style>
