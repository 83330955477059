import { __awaiter, __generator } from "tslib";
import { computed, defineComponent } from "vue";
import GalleryView from "features/gallery/view/GalleryView.vue";
import "suus-api";
import { eventMediaApi } from "store/api";
import PostAvatar from "components/Post/PostAvatar.vue";
import { getContextNameLong } from "concerns/useContextName";
import Subtitle from "components/UI/Subtitle.vue";
import PostHeader from "components/Post/PostHeader.vue";
import IconButton from "components/UI/IconButton.vue";
import { mdiDownload } from "@mdi/js";
export default defineComponent({
    name: "PostGalleryView",
    methods: { getContextNameLong: getContextNameLong },
    components: { IconButton: IconButton, PostHeader: PostHeader, Subtitle: Subtitle, PostAvatar: PostAvatar, GalleryView: GalleryView },
    props: {
        post: {
            type: Object,
            required: true,
        },
        viewContext: {
            type: Object,
        },
    },
    setup: function (props, _a) {
        var _this = this;
        var emit = _a.emit;
        var loadMoreMedia = function (offset, limit) { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, eventMediaApi.getEventMedia(props.post.id.toString(), offset, limit, "asc")];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        }); };
        var totalEventMedia = computed(function () {
            return props.post.event_media_count;
        });
        var isSortable = computed(function () {
            return !!props.post.links.update_url;
        });
        var handleReorder = function (_a) {
            var id = _a.id, index = _a.index;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, eventMediaApi.updateEventMedium(id.toString(), {
                                index: index,
                            })];
                        case 1:
                            _b.sent();
                            emit("reorder", { id: id, index: index });
                            return [2 /*return*/];
                    }
                });
            });
        };
        var onDownloadClick = function () {
            // Set the responseType to allow our axios interceptor to handle the file download
            eventMediaApi.downloadEventMedia(props.post.id.toString(), {
                responseType: "blob",
            });
        };
        return {
            loadMoreMedia: loadMoreMedia,
            totalEventMedia: totalEventMedia,
            onDownloadClick: onDownloadClick,
            mdiDownload: mdiDownload,
            isSortable: isSortable,
            handleReorder: handleReorder,
        };
    },
});
