import FormAlert from "../../features/common/forms/FormAlert.vue";
import ScrollTo from "../UI/ScrollTo.vue";
import "features/common/forms/FormAlert.vue";
import "vue";
import { defineComponent } from "vue";
import "axios";
import "components/TopicForm/index.vue";
import { parseAxiosError } from "features/common/errors/parseAxiosError";
export default defineComponent({
    name: "TopicFormAlert",
    components: { FormAlert: FormAlert, ScrollTo: ScrollTo },
    props: {
        fieldsWithErrors: {
            type: Array,
            required: true,
        },
        defaultText: { type: String, required: true },
        requestError: {
            type: [Object, Boolean, Error],
            required: false,
        },
    },
    computed: {
        fieldErrors: function () {
            return this.fieldsWithErrors.map(function (field) { return ({
                selector: field.selector,
                message: field.message,
            }); });
        },
        formatRequestError: function () {
            if (!this.requestError)
                return null;
            if (typeof this.requestError === "string") {
                return this.requestError;
            }
            if (typeof this.requestError === "boolean") {
                if (this.requestError) {
                    return "Es ist etwas schiefgelaufen. Bitte versuche es erneut.";
                }
                else {
                    return null;
                }
            }
            var _a = this
                .requestError, request = _a.request, response = _a.response, message = _a.message;
            console.log("request", request);
            console.log("response", response);
            console.log("message", message);
            if (response) {
                return typeof response.data.message === "string"
                    ? response.data.message
                    : parseAxiosError(response);
            }
            else if (request) {
                return request;
            }
            else {
                return message;
            }
        },
    },
});
