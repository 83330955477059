import { __awaiter, __generator } from "tslib";
import { computed, ref, watch } from "vue";
import "suus-api";
import "features/gallery/gallery.types";
var GalleryService = /** @class */ (function () {
    function GalleryService(_canEdit, _addGalleryToHighlight, _removeGalleryFromHighlight, _reorderHighlights, _initialHighlightsCount, initialShowUploader) {
        var _this = this;
        this._canEdit = _canEdit;
        this._addGalleryToHighlight = _addGalleryToHighlight;
        this._removeGalleryFromHighlight = _removeGalleryFromHighlight;
        this._reorderHighlights = _reorderHighlights;
        this._initialHighlightsCount = _initialHighlightsCount;
        this.initialShowUploader = initialShowUploader;
        this.canEdit = computed(function () { return _this._canEdit.value; });
        this.addGalleryToHighlight = function (galleryId) { return __awaiter(_this, void 0, void 0, function () {
            var highlight_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("addGalleryToHighlight", galleryId);
                        if (!(this._addGalleryToHighlight && this.canEdit.value)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._addGalleryToHighlight(galleryId)];
                    case 1:
                        highlight_1 = _a.sent();
                        this.addListeners.forEach(function (l) { return l(highlight_1); });
                        this._optimisticHighlightsCount.value++;
                        return [2 /*return*/, highlight_1];
                    case 2: return [2 /*return*/, null];
                }
            });
        }); };
        this.removeGalleryFromHighlight = function (gallery) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log("removeGalleryFromHighlight", gallery);
                        if (!(this._removeGalleryFromHighlight && this.canEdit.value)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._removeGalleryFromHighlight(gallery)];
                    case 1:
                        _a.sent();
                        this.removeListeners.forEach(function (l) { return l(gallery.id); });
                        this._optimisticHighlightsCount.value--;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); };
        this.reorderHighlights = function (_a) {
            var id = _a.id, index = _a.index;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            console.log("reorderHighlights");
                            if (!(this._reorderHighlights && this.canEdit.value)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this._reorderHighlights({ id: id, index: index })];
                        case 1:
                            _b.sent();
                            this.reorderListeners.forEach(function (l) { return l(); });
                            _b.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        };
        this.addListeners = [];
        this.removeListeners = [];
        this.reorderListeners = [];
        this.registerAddListener = function (listener) {
            _this.addListeners.push(listener);
        };
        this.removeAddListener = function (listener) {
            _this.addListeners = _this.addListeners.filter(function (l) { return l !== listener; });
        };
        this.registerRemoveListener = function (listener) {
            _this.removeListeners.push(listener);
        };
        this.removeRemoveListener = function (listener) {
            _this.removeListeners = _this.removeListeners.filter(function (l) { return l !== listener; });
        };
        this.registerReorderListener = function (listener) {
            _this.reorderListeners.push(listener);
        };
        this.removeReorderListener = function (listener) {
            _this.reorderListeners = _this.reorderListeners.filter(function (l) { return l !== listener; });
        };
        this._optimisticHighlightsCount = ref(0);
        this.highlightsCount = computed(function () {
            return Math.max(_this._initialHighlightsCount.value +
                _this._optimisticHighlightsCount.value, 0);
        });
        watch(function () { return _initialHighlightsCount.value; }, function () {
            _this._optimisticHighlightsCount.value = 0;
        }, { immediate: true });
    }
    return GalleryService;
}());
export { GalleryService };
