import { computed, defineComponent, inject } from "vue";
import GalleryViewSkeletonLoader from "features/gallery/view/GalleryViewSkeletonLoader.vue";
import "features/gallery/lightbox/lightbox.service";
import "features/gallery/gallery.types";
import "features/events/highlights/lightbox/event-lightbox.service";
import "features/posts/gallery/lightbox/post-lightbox.service";
export default defineComponent({
    name: "GalleryViewPreview",
    components: { GalleryViewSkeletonLoader: GalleryViewSkeletonLoader },
    props: {
        previewedGalleryMedia: {
            type: Object,
        },
    },
    setup: function (props) {
        var previewedGalleryMediaSrc = computed(function () {
            var _a, _b, _c;
            return (_c = (_b = (_a = props.previewedGalleryMedia) === null || _a === void 0 ? void 0 : _a.uploaded_file) === null || _b === void 0 ? void 0 : _b.preview) === null || _c === void 0 ? void 0 : _c.url;
        });
        var previewedGalleryMediaIndex = computed(function () {
            var _a;
            return (_a = props.previewedGalleryMedia) === null || _a === void 0 ? void 0 : _a.index;
        });
        var lightboxService = inject("lightboxService", null);
        var eventLightboxService = inject("EventLightboxService", null);
        var postLightboxService = inject("PostLightboxService", null);
        var openLightbox = function () {
            if (postLightboxService) {
                postLightboxService.openLightbox(previewedGalleryMediaIndex.value);
                return;
            }
            if (eventLightboxService) {
                eventLightboxService.openLightbox(previewedGalleryMediaIndex.value);
                return;
            }
            if (lightboxService) {
                lightboxService.openLightbox(previewedGalleryMediaSrc.value);
            }
        };
        return {
            previewedGalleryMediaSrc: previewedGalleryMediaSrc,
            openLightbox: openLightbox,
        };
    },
});
