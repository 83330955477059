import { __assign } from "tslib";
import { defineComponent } from "vue";
import THEME from "constants/theme";
var primaryColor = THEME.colors.light.primary;
var primaryLightColor = THEME.colors.light.primaryLight;
var textFont = THEME.typography.text;
var boldFontWeight = THEME.typography.fontWeightBold;
export default defineComponent({
    name: "AttachmentThumbnailProgress",
    props: {
        progress: {
            type: Number,
            required: true,
        },
    },
    computed: {
        progressStyle: function () {
            return {
                height: "100%",
                width: "".concat(Math.floor(50 * this.progress), "px"),
                backgroundColor: primaryColor,
            };
        },
        progressBackground: function () {
            return {
                backgroundColor: primaryLightColor,
            };
        },
        progressPercentage: function () {
            return "".concat(Math.floor(this.progress * 100), "%");
        },
        progressPercentageStyle: function () {
            return __assign(__assign({ color: "white" }, textFont), { fontWeight: boldFontWeight, position: "absolute", top: "0", left: "0", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" });
        },
    }
});
