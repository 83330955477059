<template lang="pug">
LoaderWrapper(:requires="[currentInode]")
  Header
    template(#buttons)
      Pin(:inode="currentInode" v-if="currentInode.pin_url")

      sui-dropdown.icon.dots(icon='suus-menu-dots' button direction="downward" v-if="currentInode.destroy_url")
        sui-dropdown-menu
          sui-dropdown-item(@click="destroy") Ordner und Inhalt löschen

  FolderForm(:folder="currentInode")
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")
import LoaderWrapper from "../LoaderWrapper.vue"
import FolderForm from "../FolderForm.vue"
import Header from "../Header.vue"
import Pin from "../Pin.vue"
export default {
  name: "EditFolder",
  data() {
    return {}
  },
  components: { Pin, Header, FolderForm, LoaderWrapper },
  async mounted() {
    await this.setCurrentInode(this.$route.params.inode_id)
  },
  computed: {
    ...mapGetters(["currentInode"]),
  },
  methods: {
    async destroy() {
      if (!confirm("Ordner UND INHALT wirklich löschen?")) return
      const parent_id = this.currentInode.parent_id
      console.log(parent_id)
      await this.destroyFolder(this.currentInode)
      if (parent_id) {
        this.setCurrentInode(parent_id)
        this.$router.replace({
          name: "drive/folder",
          params: { inode_id: parent_id },
        })
      } else {
        this.$router.replace({ name: "drive/latest" })
        this.setCurrentInode(null)
      }
    },
    ...mapActions(["destroyFolder"]),
    ...mapActions(["setCurrentInode"]),
  },
}
</script>
