<template lang="pug">
v-expansion-panels.suus__event-form__expansion-panels(
  accordion,
  flat,
  tile,
  multiple,
  :value="activePanels"
)
  v-expansion-panel(
    v-for="(panel, i) in permittedPanels",
    :key="panel.selector"
  ) 
    v-expansion-panel-header(:id="panel.selector")
      Header(:text="panel.title", :level="5", customClass="mb-0")
      template(v-slot:actions)
        v-icon(color="textColor") {{ panelIcon }}
    v-expansion-panel-content.mt-6
      component(
        :is="panel.componentName",
        @update-field="panel.updateField",
        v-bind="panel.componentProps",
        :viewContext="viewContext"
      )
</template>
<script>
import Header from "../UI/Header.vue"
import TextBody from "../UI/TextBody.vue"
import TopicFormPanelDescription from "./TopicFormPanelDescription.vue"
import TopicFormPanelGeneralFields from "./general-fields/TopicFormPanelGeneralFields.vue"
import TopicFormPanelReminder from "./TopicFormPanelReminder.vue"
import DangerZone from "../UI/DangerZone.vue"

import { mdiChevronDown } from "@mdi/js"

export default {
  name: "TopicFormPanels",
  components: {
    Header,
    TextBody,
    TopicFormPanelDescription,
    TopicFormPanelGeneralFields,
    TopicFormPanelReminder,
    DangerZone,
  },
  props: {
    permittedPanels: { type: Array },
    activePanels: { type: Array },
    viewContext: { type: Object },
  },
  data() {
    return { panelIcon: mdiChevronDown }
  },
}
</script>

<style lang="sass" scoped>
.suus__event-form__expansion-panels::v-deep
  .v-expansion-panel
    background-color: transparent !important
    .v-expansion-panel-header
      border-top: 1px solid #DADADA
      padding-right: 0
      padding-left: 0
    .v-expansion-panel-content
      .v-expansion-panel-content__wrap
        padding-right: 0 !important
        padding-left: 0 !important
</style>
