import { computed, defineComponent } from "vue";
import Theme from "constants/theme";
import { useIsBreakpointInNames, } from "features/common/vuetify/useVuetify";
export default defineComponent({
    name: "GalleryThumbnailActionButton",
    props: {
        icon: {
            type: String,
            required: true,
        },
        showAlways: {
            type: Boolean,
            default: false,
        },
        tooltip: {
            type: String,
            required: false,
        },
    },
    setup: function (props) {
        var isSmallerDevice = useIsBreakpointInNames(["xs", "sm", "md"]);
        var _showAlways = computed(function () {
            return isSmallerDevice.value ? true : props.showAlways;
        });
        return {
            primaryColor: Theme.colors.light.primary,
            showAlways: _showAlways,
        };
    },
});
