import { REACTION_OPTIONS } from "../constants/reactions";
import "../interfaces/emojiOptionName.type";
import "../interfaces/reactionOption.interface";
export default function useReactionOptions(_a) {
    var createReaction = _a.createReaction, deleteReaction = _a.deleteReaction, updateReaction = _a.updateReaction;
    var DEFAULT_OPTION_NAME = "default";
    var options = REACTION_OPTIONS;
    function getOptionByName(optionName) {
        if (optionName === void 0) { optionName = DEFAULT_OPTION_NAME; }
        return options.find(function (item) { return optionName === item.name; });
    }
    function getOptionIndex(optionName) {
        if (optionName === void 0) { optionName = DEFAULT_OPTION_NAME; }
        return options.findIndex(function (option) { return option.name === optionName; });
    }
    function mutateReaction(prevVal, newVal) {
        if (prevVal === DEFAULT_OPTION_NAME)
            createReaction(newVal);
        else if (prevVal === newVal)
            deleteReaction(prevVal);
        else
            updateReaction(prevVal, newVal);
    }
    function selectReaction(_a) {
        var _b, _c;
        var prevIndex = _a.prevIndex, newIndex = _a.newIndex;
        var prevReaction = (_b = options[prevIndex]) === null || _b === void 0 ? void 0 : _b.name;
        var newReaction = (_c = options[newIndex]) === null || _c === void 0 ? void 0 : _c.name;
        mutateReaction(prevReaction, newReaction);
    }
    return {
        options: options,
        getOptionByName: getOptionByName,
        getOptionIndex: getOptionIndex,
        selectReaction: selectReaction,
        DEFAULT_OPTION_NAME: DEFAULT_OPTION_NAME,
    };
}
