import { __awaiter, __generator } from "tslib";
import { ref } from "vue";
import { postsApi, topicsApi } from "store/api";
import "interfaces/error.interface";
import { Emoji } from "suus-api";
import "interfaces/reactionCounts.interface";
import { withTimeOut } from "helpers/delay";
import "features/common/shared/polymorphic";
export default function useReaction(reactionable, initialReactionCounts, initialUserReaction) {
    var _this = this;
    function reactionsApiFor(reactionable) {
        switch (reactionable.type) {
            case "Topic":
                return {
                    // NB: A method looses its "this" binding when retrieving a reference only,
                    // therefore we need to add the original this again. Javascript is awesome...
                    createReaction: topicsApi.createTopicReaction.bind(topicsApi),
                    deleteReaction: topicsApi.deleteTopicReaction.bind(topicsApi),
                    getReactions: topicsApi.getTopicReactions.bind(topicsApi),
                };
            case "Post":
                return {
                    createReaction: postsApi.createPostReaction.bind(postsApi),
                    deleteReaction: postsApi.deletePostReaction.bind(postsApi),
                    getReactions: postsApi.getPostReactions.bind(postsApi),
                };
            case "Comment":
                console.warn("Reactions for comments is not yet supported!");
                return {
                    createReaction: null,
                    deleteReaction: null,
                    getReactions: null,
                };
            default:
                throw "Unknown polymorphic type: " + reactionable.type;
        }
    }
    var api = reactionsApiFor(reactionable);
    var REMOVE_COUNT = -1;
    var ADD_COUNT = 1;
    var isPending = ref(false);
    var error = ref(null);
    var likesCount = ref(initialReactionCounts.value.likesCount);
    var ideasCount = ref(initialReactionCounts.value.ideasCount);
    var cheersCount = ref(initialReactionCounts.value.cheersCount);
    var userReaction = ref(initialUserReaction.value || undefined);
    var reactions = ref([]);
    function fetchReactions(reactionable) {
        api.getReactions(reactionable.id.toString()).then(function (response) {
            reactions.value = response.data;
        });
    }
    fetchReactions(reactionable);
    function changeCount(reaction, delta) {
        switch (reaction) {
            case Emoji.Cheers:
                cheersCount.value = cheersCount.value + delta;
                break;
            case Emoji.Like:
                likesCount.value = likesCount.value + delta;
                break;
            case Emoji.Idea:
                ideasCount.value = ideasCount.value + delta;
                break;
            default:
                break;
        }
    }
    function delayedChangeCount(reaction, delta) {
        return withTimeOut(function () { return changeCount(reaction, delta); }, 800);
    }
    var createReaction = function (id, newReaction) { return __awaiter(_this, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    userReaction.value = newReaction;
                    delayedChangeCount(newReaction, ADD_COUNT);
                    isPending.value = true;
                    error.value = null;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    console.log(postsApi.createPostReaction);
                    return [4 /*yield*/, api.createReaction(id, newReaction)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    error_1.value = error_1;
                    userReaction.value = null;
                    delayedChangeCount(newReaction, REMOVE_COUNT);
                    return [3 /*break*/, 5];
                case 4:
                    isPending.value = false;
                    fetchReactions(reactionable);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    function updateReaction(id, newReaction, prevReaction) {
        return __awaiter(this, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userReaction.value = newReaction;
                        delayedChangeCount(prevReaction, REMOVE_COUNT);
                        delayedChangeCount(newReaction, ADD_COUNT);
                        isPending.value = true;
                        error.value = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        return [4 /*yield*/, api.deleteReaction(id, prevReaction)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, api.createReaction(id, newReaction)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        error_2 = _a.sent();
                        error_2.value = error_2;
                        userReaction.value = prevReaction;
                        delayedChangeCount(newReaction, REMOVE_COUNT);
                        delayedChangeCount(prevReaction, ADD_COUNT);
                        return [3 /*break*/, 6];
                    case 5:
                        isPending.value = false;
                        fetchReactions(reactionable);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    }
    function deleteReaction(id, reaction) {
        return __awaiter(this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userReaction.value = null;
                        isPending.value = true;
                        error.value = null;
                        delayedChangeCount(reaction, REMOVE_COUNT);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, api.deleteReaction(id, reaction)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _a.sent();
                        error_3.value = error_3;
                        userReaction.value = reaction;
                        delayedChangeCount(reaction, ADD_COUNT);
                        return [3 /*break*/, 5];
                    case 4:
                        isPending.value = false;
                        fetchReactions(reactionable);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    return {
        createReaction: createReaction,
        updateReaction: updateReaction,
        deleteReaction: deleteReaction,
        likesCount: likesCount,
        ideasCount: ideasCount,
        cheersCount: cheersCount,
        userReaction: userReaction,
        reactions: reactions,
    };
}
