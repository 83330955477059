import { ref, computed, defineComponent, } from "vue";
import "suus-api";
import "../../interfaces/emojiOptionName.type";
import "../../interfaces/reactionOption.interface";
import useReactionOptions from "../../concerns/useReactionOptions";
import ComboActivatorButton from "./ComboActivatorButton.vue";
import THEME from "../../constants/theme";
export default defineComponent({
    props: {
        selectedReaction: {
            type: String,
            required: false,
        },
    },
    components: { ComboActivatorButton: ComboActivatorButton },
    setup: function (props, context) {
        var showDropdown = ref(false);
        var _a = useReactionOptions({ createReaction: createReaction, deleteReaction: deleteReaction, updateReaction: updateReaction }), options = _a.options, getOptionByName = _a.getOptionByName, getOptionIndex = _a.getOptionIndex, selectReaction = _a.selectReaction, DEFAULT_OPTION_NAME = _a.DEFAULT_OPTION_NAME;
        var selected = computed(function () {
            return getOptionByName(props.selectedReaction || DEFAULT_OPTION_NAME);
        });
        var activeOptionIndex = computed(function () { return getOptionIndex(selected.value.name); });
        function createReaction(newReaction) {
            context.emit("create-reaction", newReaction);
        }
        function deleteReaction(prevReaction) {
            context.emit("delete-reaction", prevReaction);
        }
        function updateReaction(prevReaction, newReaction) {
            context.emit("update-reaction", { prevReaction: prevReaction, newReaction: newReaction });
        }
        var _b = THEME.typography, fontFamily = _b.fontFamily, fontWeightRegular = _b.fontWeightRegular;
        var textColor = THEME.colors.light.textColor;
        return {
            options: options,
            activeOptionIndex: activeOptionIndex,
            selected: selected,
            showDropdown: showDropdown,
            selectReaction: selectReaction,
            listItemStyles: {
                fontFamily: fontFamily,
                fontSize: "1rem",
                fontWeight: fontWeightRegular,
                color: textColor,
            },
        };
    },
    computed: {
        reactionButtonStyles: function () {
            return {
                width: this.$vuetify.breakpoint.name === "xs" ? "100%" : "220px",
            };
        },
    },
});
