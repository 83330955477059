import 'cookieconsent/build/cookieconsent.min.js'
import 'cookieconsent/build/cookieconsent.min.css'

window.addEventListener 'load', ->
  window.cookieconsent.initialise
    'palette':
      'popup':
        'background': '#edeff5'
        'text': '#838391'
      'button':
        'background': 'transparent'
        'text': '#4b81e8'
        'border': '#4b81e8'
    'position': 'bottom-left'
    'content':
      'message': 'Wir nutzen Cookies, um die Nutzung für Sie so angenehm wie möglich zu gestalten.'
      'dismiss': 'Alles klar!'
      'link': 'Weiteres zum Datenschutz.'
      'href': "/privacy"
    'cookie':
      'domain': window.location.hostname.split('.').slice(-2).join('.')