import Vue from "vue";
export default Vue.extend({
    name: "OnlineEventIcon",
    props: {
        color: String,
        width: { type: Number, default: 31 },
    },
    computed: {
        iconColor: function () {
            var color = this.color
                ? this.color
                : this.$vuetify.theme.themes.light.textColor;
            return color;
        },
    },
});
