import { defineComponent } from "vue";
import THEME from "constants/theme";
import "components/ReactionCount/reaction-count.types";
import AvatarPlaceholder from "components/UI/AvatarPlaceholder.vue";
var _a = THEME.typography, fontFamily = _a.fontFamily, fontWeightRegular = _a.fontWeightRegular, fontWeightBold = _a.fontWeightBold;
export default defineComponent({
    name: "ReactionIcon",
    components: {
        AvatarPlaceholder: AvatarPlaceholder,
    },
    props: {
        count: { type: Number, default: 0 },
        icon: { type: String, default: "" },
        size: { type: Number, default: 0 },
        memberList: { type: Array, default: function () { return []; } },
        tooltip: { type: String, default: undefined },
    },
    computed: {
        listItemStyles: function () {
            return {
                fontFamily: fontFamily,
                fontSize: "0.8rem",
                fontWeight: fontWeightRegular,
                color: this.$vuetify.theme.themes.light.textColor,
                margin: 0,
            };
        },
    },
    setup: function (props) {
        return {
            fontWeightBold: fontWeightBold,
        };
    },
});
