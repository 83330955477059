import { __awaiter, __generator } from "tslib";
import Vue from "vue";
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiAccountGroupOutline } from "@mdi/js";
import * as _ from "lodash";
export default Vue.extend({
    name: "GroupMemberSingleSelect",
    props: {
        isMandatory: { type: Boolean, default: true },
        label: { type: String, required: true },
        getOptions: {
            type: Function,
            required: true, // "Function to retrieve all options for the select. Needs to be stateless, i.e. all change-tracked variables needs to be passed as argument via getOptionsParams.",
        },
        getOptionsParams: { type: Object, required: false },
        placeHolder: {
            type: String,
            default: "Name eingeben und auswählen..",
        },
        value: { type: Object, default: null },
        noDataText: { type: String, default: "Keine Ergebnisse" },
        hideNoData: { type: Boolean, default: true },
        attach: { type: String },
        required: { type: Boolean, default: false },
        rules: {
            type: Array,
            default: function () {
                return [];
            },
        },
        itemText: { type: String, default: "text" },
    },
    data: function () {
        return {
            placeholderText: this.placeHolder,
            search: "",
            memberIcon: mdiAccountCircleOutline,
            groupIcon: mdiAccountGroupOutline,
            loading: false,
            options: [this.value],
            loadOptionsDebounced: (function () { return Promise.resolve(); }),
        };
    },
    computed: {
        labelStyles: function () {
            return {
                fontFamily: "system-ui !important",
                color: this.$vuetify.theme.themes.light.primary,
                fontWeight: 400,
                marginBottom: 0,
            };
        },
        selectedOption: {
            get: function () {
                return this.addCompoundKey(this.value);
            },
            set: function (value) {
                this.$emit("input", this.addCompoundKey(value));
            },
        },
        mandatoryStyles: function () {
            return {
                color: "red",
                fontWeight: 400,
            };
        },
        specifySearch: function () {
            var _a;
            return ((_a = this.options) === null || _a === void 0 ? void 0 : _a.length) >= 100;
        },
        specifySearchMessage: function () {
            return "Deine Suche".concat(this.search ? " nach \"".concat(this.search, "\"") : "", " ergab zu viele Treffer. Bitte suche etwas genauer.");
        },
    },
    methods: {
        itemLabel: function (item) {
            return item.name + (item.members_count ? " (".concat(item.members_count, ")") : "");
        },
        isGroup: function (item) {
            return item.members_count !== undefined;
        },
        addCompoundKey: function (item) {
            if (!item)
                return item;
            item.compound_key =
                (this.isGroup(item) ? "Group#" : "GroupMember#") + item.id;
            return item;
        },
        loadOptions: function () {
            return __awaiter(this, void 0, Promise, function () {
                var results;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.loading = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, , 3, 4]);
                            return [4 /*yield*/, this.getOptions(this.getOptionsParams || {})];
                        case 2:
                            results = _a.sent();
                            if (this.value && !results.includes(this.value)) {
                                results.unshift(this.value);
                            }
                            // Since Group and GroupMembers may have the same ID, we introduce a compound key to distinguish them.
                            this.options = results.map(this.addCompoundKey.bind(this));
                            return [3 /*break*/, 4];
                        case 3:
                            this.loading = false;
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
    },
    watch: {
        getOptionsParams: function (newVal, oldVal) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(JSON.stringify(newVal) !== JSON.stringify(oldVal))) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.loadOptions()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        },
        search: function (newVal, oldVal) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (this.loading || newVal === oldVal)
                                return [2 /*return*/];
                            this.$emit("search", newVal || "");
                            return [4 /*yield*/, this.loadOptionsDebounced()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    mounted: function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.options = this.options.map(this.addCompoundKey.bind(this)); // Add compound key to existing options
                        this.loadOptionsDebounced = _.debounce(this.loadOptions, 500);
                        return [4 /*yield*/, this.loadOptionsDebounced()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    },
});
