import { defineComponent } from "vue";
import AvatarCard from "components/UI/AvatarCard.vue";
import { mdiAccountMultipleOutline } from "@mdi/js";
import TextButton from "components/UI/TextButton.vue";
import Subtitle from "components/UI/Subtitle.vue";
import "suus-api";
import useTranslate from "features/common/translate/concerns/useTranslate";
import EventAvatarCard from "features/events/attendees/event-attendee-list/EventAvatarCard.vue";
export default defineComponent({
    name: "EventAttendeeListSection",
    components: { EventAvatarCard: EventAvatarCard, Subtitle: Subtitle, TextButton: TextButton, AvatarCard: AvatarCard },
    props: {
        attendees: {
            type: Array,
            required: true,
        },
        attendeesLoading: {
            type: Boolean,
        },
        title: {
            type: String,
        },
        eventId: {
            type: Number,
        },
        isEditable: {
            type: Boolean,
        },
        guestsAllowed: {
            type: Boolean,
        },
        isLoadMoreAvailable: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        attendeeGuestsText: function (attendeeCount) {
            return this.tc("guest", attendeeCount);
        },
        handleAttendeeEdit: function (saveObject) {
            this.$emit("editAttendee", saveObject);
        },
        loadMore: function () {
            this.$emit("loadMore");
        },
    },
    setup: function () {
        var tc = useTranslate("components.attendee_list").tc;
        return {
            guestsIcon: mdiAccountMultipleOutline,
            tc: tc,
        };
    },
});
