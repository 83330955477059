<template lang="pug">
div
  .ui.segment.form.new_folder
    sui-form-field(v-if="inode.parent.type === 'Container'")
      label
        span.label-text Name
        span.icon-tooltip-wrapper(v-tooltip="'Der vollständige Name besteht aus dem Namen der Dokumentenserie, der Datierung und ggfs. einer Notiz.'")
          i.ui.icon.hint.suus-alert-circle-que
      input(type="text" placeholder="Notiz" v-model="name" name="name")

    sui-form-field(v-else required)
      label Name
      sui-input(type="text" placeholder="Dateiname" v-model="name" name="name")
      sui-label.hovering(v-if="$v.name && !$v.name.required" pointing color="red") Name ist erforderlich

    sui-form-field(:required="inode.parent.type === 'Container'")
      label
        span.label-text Datierung
        span.icon-tooltip-wrapper(v-tooltip="'Genaues oder ungefähres Datum, z.B. 2011 oder Januar 2011 oder WS 2011/12'")
          i.ui.icon.hint.suus-alert-circle-que
      sui-input(type="text" icon-position="left" placeholder="z.B. 22.11.1970, WS 1970/71, November 1970 oder 1970" v-model="physically_created_at" name="physically_created_at")
      sui-label.hovering(v-if="$v.physically_created_at && !$v.physically_created_at.required" pointing color="red") Datierung ist erforderlich

    sui-form-fields(fields="two" v-if="isPersisted")
      .field
        label
          span.label-text Verschieben
          span.icon-tooltip-wrapper(v-tooltip="'Du kannst den Ordner in einen anderen Ordner verschieben, den du bearbeiten kannst. Ist der Zugriff auf den übergeordnete Ordner strenger, werden die Beschränkungen dieses Ordners beim Verschieben entsprechend angepasst.'")
            i.ui.icon.hint.suus-alert-circle-que
        sui-checkbox(
          label="Verschieben"
          toggle
          v-model="move"
        )
      keep-alive
        .field(v-if="move")
          RemoteDropdown(
            v-model="parent_id"
            endpoint="/api/v1/drive/parents"
            :params="{inode_id: inode.id}"
            label="Neuer Ordner"
            placeholder="Ordner auswählen"
            name="parent_id"
            :valueFunction="(x) => x.id"
            :textFunction="(x) => x.name"
            :initialValues="inode.parent ? [{value: inode.parent?.id, text: inode.parent.name }] : []"
            :defaultValue="inode.parent_id"
            :preload="true"
          )
  .ui.message.visible.error(v-if="error")
    strong Datei konnte nicht gespeichert werden
    ul
      li(v-for="err in error.response.data") {{ err }}
  .ui.buttons
    .ui.button.primary(@click="save" :class="{disabled: $v.$anyError}") Speichern
    .ui.button(@click="$router.go(-1)") Abbrechen
  .error(v-if="$v.$anyError") Das Formular ist nicht korrekt ausgefüllt.
</template>

<script>
import RemoteDropdown from "../RemoteDropdown.vue"
import ListItem from "../ListItem.vue"
import { required } from "vuelidate/lib/validators"
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"

export default {
  name: "DriveFileForm",
  props: {
    state: [String],
    inode: {
      type: Object,
      default() {
        return {
          name: "",
          physically_created_at: "",
          stored_file: "",
          parent_id: this.parent_id,
        }
      },
    },
  },
  data() {
    return {
      name: this.inode.name,
      physically_created_at: this.inode.physically_created_at_formatted,
      parent_id: this.inode.parent_id,
      move: false,
      replace: false,
      showSuccess: false,
    }
  },
  validations() {
    return {
      ...(this.inode.parent.type !== "Container" && { name: { required } }),
      ...(this.inode.parent.type === "Container" && {
        physically_created_at: { required },
      }),
    }
  },
  computed: {
    formData() {
      const parent_id = this.parent_id
      return {
        inode: {
          id: this.inode.id,
          name: this.name,
          physically_created_at: this.physically_created_at,
          ...(this.move && { parent_id }),
        },
      }
    },
    isPersisted() {
      return !!this.inode.id
    },
    ...mapState("drive", ["error"]),
  },
  methods: {
    async save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          await this.updateInode({
            inode: this.inode,
            formData: this.formData,
          })
          this.$router.push({
            name: "drive/file",
            params: { inode_id: this.inode.id },
          })
          this.$store.commit("drive/setSuccess", "Erfolgreich gespeichert")
        } catch (e) {
          console.log(e)
          this.setError(e.toString())
        }
      }
    },
    ...mapMutations("drive", ["setError"]),
    ...mapActions("drive", ["createFolder", "updateInode"]),
  },
  components: {
    RemoteDropdown,
    ListItem,
  },
}
</script>

<style scoped lang="sass">
.new_folder
  .item .header .field
    clear: both
    margin: 2px 2px 0 !important

.upload-fields
  display: flex
  .field
    padding-right: 1rem
</style>
