<template lang="pug">
Inode(v-bind="settings")
  template(#filename)
    | {{ inode.parent.name }}
    |
    | {{ date?.length === 4 ? 'von' : 'vom' }}
    |
    strong {{date}}
    i(v-if="inode.name")
        |
        | ({{inode.name}})
</template>

<script>
import ListItem from "../../ListItem.vue"
import InodeIcon from "../InodeIcon.vue"
import Inode from "./Inode.vue"
export default {
  name: "ContainerFile",
  props: {
    inode: { type: Object, required: true },
    show: {
      type: Object,
      default() {
        return {
          image: true,
          path: false,
          category: false,
          physically_created_at: false,
          inodes_count: false,
          latest_drive_file: false,
          actions: true,
        }
      },
    },
  },
  components: { Inode, ListItem, InodeIcon },
  computed: {
    inodeLink() {
      return {
        name: "drive/file",
        params: { inode_id: this.inode.id },
      }
    },
    date() {
      if (this.inode.physically_created_at_formatted) {
        return this.inode.physically_created_at_formatted
      } else {
        return "<ohne Datum>"
      }
    },
    settings() {
      return {
        inode: this.inode,
        inodeLink: this.inodeLink,
        show: this.show,
      }
    },
  },
}
</script>

<style lang="sass">
.ui.red.label
    z-index: 1
</style>
