import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import { useNotifications } from "features/push/concerns/notifications";
import NotificationActivationWizard from "features/push/NotificationsActivationWizard.vue";
import PushUnsupportedWizard from "features/push/PushUnsupportedWizard.vue";
import NotificationPermissionWizard from "features/push/NotificationPermissionWizard.vue";
import { isIOS } from "features/push/concerns/platform";
import "axios";
import "suus-api";
import { enablePush, sendTestPush, waitForTestMessage, } from "initializers/firebase";
function isSafariNotPWA() {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var isNotPWA = !window.matchMedia("(display-mode: standalone)").matches;
    console.log("isSafari", isSafari);
    console.log("isNotPWA", isNotPWA);
    return isSafari && isNotPWA;
}
export default defineComponent({
    name: "WebPushDialog",
    components: {
        NotificationPermissionWizard: NotificationPermissionWizard,
        PushUnsupportedWizard: PushUnsupportedWizard,
        NotificationActivationWizard: NotificationActivationWizard,
    },
    data: function () {
        return {
            isWebPushWizardOpen: false,
            isPushAlreadyActivated: false,
            showActivationWizard: false,
            activationWizardKey: 0,
        };
    },
    watch: {
        "$route.params": {
            handler: function () {
                if (window.location.hash === "#push") {
                    if (isSafariNotPWA()) {
                        window.location.hash = "#pwa-install";
                        return;
                    }
                    this.openWizard();
                }
            },
            deep: true,
            immediate: true,
        },
        isWebPushWizardOpen: function (newVal) {
            window.location.hash = newVal ? "#push" : "";
        },
        isNotificationPermissionGranted: function (newVal) {
            if (newVal)
                this.openActivationWizard(false);
        },
        isNotificationPermissionMuted: function (newVal) {
            if (newVal)
                this.isNotificationPermissionDenied = true;
        },
    },
    methods: {
        handlePossibleClose: function (value) {
            if (!value) {
                this.closeWizard();
            }
        },
        closeWizard: function () {
            var _this = this;
            this.isWebPushWizardOpen = false;
            setTimeout(function () {
                _this.closeActivationWizard();
            }, 300);
        },
        openWizard: function () {
            this.reloadNotificationPermissions();
            if (this.isNotificationPermissionGranted) {
                this.openActivationWizard(true);
            }
            this.isWebPushWizardOpen = true;
        },
        openActivationWizard: function (alreadyActivated) {
            this.isPushAlreadyActivated = alreadyActivated;
            this.showActivationWizard = true;
            this.activationWizardKey++;
        },
        closeActivationWizard: function () {
            this.showActivationWizard = false;
            this.isPushAlreadyActivated = false;
        },
        handlePermissionRequest: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.requestNotificationPermission()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
    setup: function () {
        var _this = this;
        var runningAsPwa = window.matchMedia("(display-mode: standalone)").matches;
        var isIOSDevice = ref(isIOS());
        var registerDevice = function (repair) { return __awaiter(_this, void 0, void 0, function () {
            var deviceRegistration, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, enablePush(repair)];
                    case 1:
                        deviceRegistration = _a.sent();
                        return [2 /*return*/, (deviceRegistration.status === 200 || deviceRegistration.status === 201)];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var messageId = ref("");
        var pushAlreadyReceived = false;
        var sendTestPushNotification = function () { return __awaiter(_this, void 0, void 0, function () {
            var sendTestPushResponse, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sendTestPushResponse = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        waitForTestMessage("", 1000).then(function () {
                            pushAlreadyReceived = true;
                        });
                        return [4 /*yield*/, sendTestPush()];
                    case 2:
                        sendTestPushResponse = _a.sent();
                        messageId.value = sendTestPushResponse.data.message_id;
                        return [2 /*return*/, sendTestPushResponse.status === 200];
                    case 3:
                        e_2 = _a.sent();
                        if (e_2.isAxiosError &&
                            e_2.response.status === 410) {
                            // 410=gone, token was already deleted from DB after FCM reported it does not exist
                            return [2 /*return*/, false];
                        }
                        console.error(e_2);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var waitForPush = function () { return __awaiter(_this, void 0, void 0, function () {
            var waitForPushResponse, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // When delivery was so fast, that FCM was actually faster than our backend :0
                        if (pushAlreadyReceived) {
                            console.log("Push already received, glad we catched it!");
                            return [2 /*return*/, true];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, waitForTestMessage(messageId.value, 20000)];
                    case 2:
                        waitForPushResponse = _a.sent();
                        return [2 /*return*/, waitForPushResponse];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var _a = useNotifications(), notificationPermissionStatus = _a.notificationPermissionStatus, isNotificationPermissionGranted = _a.isNotificationPermissionGranted, isNotificationPermissionRequired = _a.isNotificationPermissionRequired, isNotificationPermissionDenied = _a.isNotificationPermissionDenied, isNotificationPermissionMuted = _a.isNotificationPermissionMuted, requestNotificationPermission = _a.requestNotificationPermission, reloadNotificationPermissions = _a.reloadNotificationPermissions, isPushSupported = _a.isPushSupported;
        return {
            runningAsPwa: runningAsPwa,
            registerDevice: registerDevice,
            notificationPermissionStatus: notificationPermissionStatus,
            isNotificationPermissionGranted: isNotificationPermissionGranted,
            isNotificationPermissionRequired: isNotificationPermissionRequired,
            isNotificationPermissionDenied: isNotificationPermissionDenied,
            isNotificationPermissionMuted: isNotificationPermissionMuted,
            reloadNotificationPermissions: reloadNotificationPermissions,
            requestNotificationPermission: requestNotificationPermission,
            isPushSupported: isPushSupported,
            isIOSDevice: isIOSDevice,
            sendTestPushNotification: sendTestPushNotification,
            waitForPush: waitForPush,
        };
    },
    mounted: function () {
        var _this = this;
        var pushWizardDismissed = this.$cookies.get("pushWizardDismissed") == "true";
        if (this.runningAsPwa &&
            this.isNotificationPermissionRequired &&
            !pushWizardDismissed) {
            setTimeout(function () {
                _this.$cookies.set("pushWizardDismissed", "true", "14d", "/");
                _this.openWizard();
            }, 3000);
        }
    },
});
