export function rejectAfter(msDelay, promise, onTimeout) {
    // Create a promise that rejects in <msDelay> milliseconds
    var timeout = new Promise(function (resolve, reject) {
        var id = setTimeout(function () {
            clearTimeout(id);
            if (onTimeout)
                onTimeout();
            reject("Promise did not resolve after " + msDelay + "msDelay.");
        }, msDelay);
    });
    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
}
