import { defineComponent, onMounted, ref } from "vue";
import GalleryDashboardSection from "features/gallery/dashboard/GalleryDashboardSection.vue";
export default defineComponent({
    name: "GalleryDashboardPortal",
    components: { GalleryDashboardSection: GalleryDashboardSection },
    setup: function () {
        var renderGallerDashboard = ref(false);
        onMounted(function () {
            var recentGalleryPostsElement = document.querySelector("#recent-gallery-posts.suus-mounting-point");
            if (recentGalleryPostsElement) {
                renderGallerDashboard.value = true;
            }
        });
        return { renderGallerDashboard: renderGallerDashboard };
    },
});
