import { defineComponent } from "vue";
import { mdiWindowClose } from "@mdi/js";
import { mdiCloseCircle } from "@mdi/js";
import THEME from "constants/theme";
import TextBody from "components/UI/TextBody.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
var errorColor = THEME.colors.light.error;
export default defineComponent({
    name: "ErrorPopup",
    components: { TextBody: TextBody },
    props: {
        message: { type: String },
    },
    computed: {
        getErrorColorStyle: function () {
            return {
                backgroundColor: errorColor,
            };
        },
        getErrorTextColorStyle: function () {
            return {
                color: errorColor,
            };
        },
        closeIcon: function () {
            return mdiWindowClose;
        },
        errorIcon: function () {
            return mdiCloseCircle;
        },
    },
    data: function () { return ({
        shown: false,
    }); },
    setup: function () {
        var t$ = useTranslate("components.error_popup").t$;
        return {
            t$: t$
        };
    },
});
