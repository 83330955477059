/**
 *  The Navigator API is well supported: https://developer.mozilla.org/en-US/docs/web/api/navigator#browser_compatibility
 *  The Navigator API is also available in the WorkerGlobalScope: https://developer.mozilla.org/en-US/docs/Web/API/WorkerGlobalScope/navigator
 */
export var isIOS = function () {
    try {
        if (navigator.platform === undefined)
            throw Error("navigator.platform is undefined");
        return ([
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
        ].includes(navigator.platform) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document));
    }
    catch (e) {
        var ua = navigator.userAgent;
        return /iPad|iPhone|iPod/.test(ua);
    }
};
export var isAndroid = function () {
    return navigator.userAgent.toLowerCase().includes("android");
};
export var isMobile = function () {
    return isIOS() || isAndroid();
};
