import Vue from "vue"

export default {
  updateInodes(state, inodes) {
    if (state.inodes === undefined) {
      state.inodes = {}
    }

    for (let inode of inodes) {
      Vue.set(state.inodes, inode.id.toString(), inode)
    }
  },
  setInodes(state, inodes) {
    const newInodes = {}
    for (let inode of inodes) {
      newInodes[inode.id.toString()] = inode
    }
    state.inodes = newInodes
  },
  removeInodes(state, inodes) {
    const newInodes = { ...state.inodes }
    for (let inode of inodes) {
      delete newInodes[inode.id.toString()]
    }
    state.inodes = newInodes
  },
  setCreateCategoryUrl(state, b) {
    state.createCategoryUrl = b
  },
  setCurrentInodeId(state, inodeId) {
    state.currentInodeId = inodeId
  },

  setError(state, error) {
    state.error = error
  },
  setSuccess(state, s) {
    state.success = s
  },
  setLoading(state, b) {
    state.loading = b
  },
  setLoadingProgress(state, { inodeId, offset }) {
    state.loadingProgress = { ...state.loadingProgress, [inodeId]: offset }
  },
  showUploader(state, show) {
    state.uploaderOpen = show
  },
}
