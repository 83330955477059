import Vue, { computed, onMounted } from "vue";
import { EditorContent } from "@tiptap/vue-2";
import "@tiptap/core";
import { ref, watch } from "vue";
import { useCoreWysiwyg } from "components/Wysiwyg/useWysiwygEditor";
import { useDragDetection } from "components/Wysiwyg/use-drag-detection";
export default Vue.extend({
    components: {
        EditorContent: EditorContent,
    },
    props: {
        value: { type: String, default: "" },
        placeholder: { type: String, default: "" },
        inline: { type: Boolean, default: false },
        extensions: { type: Array, default: function () { return []; } },
        showDropzoneIndication: { type: Boolean, default: false },
    },
    beforeDestroy: function () {
        var _a;
        (_a = this.editor.value) === null || _a === void 0 ? void 0 : _a.destroy();
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var editor = ref(null);
        var cssClasses = computed(function () {
            return "suus__editor__wysiwyg" + (props.inline ? " suus__editor--inline" : "");
        });
        onMounted(function () {
            var editorContent = ref(props.value);
            editor.value = useCoreWysiwyg(editorContent, props.placeholder, cssClasses.value, props.extensions);
            emit("editorReady", editor.value);
            watch(editorContent, function () { return emit("input", editorContent.value); });
        });
        var isDragging = useDragDetection().isDragging;
        watch(function () { return props.value; }, function (newVal) {
            var _a, _b, _c;
            if (newVal === "") {
                (_a = editor.value) === null || _a === void 0 ? void 0 : _a.commands.clearContent();
                emit("content-cleared", true);
                return;
            }
            if (((_b = editor.value) === null || _b === void 0 ? void 0 : _b.getHTML()) !== newVal) {
                (_c = editor.value) === null || _c === void 0 ? void 0 : _c.commands.setContent(newVal, false);
            }
        });
        return {
            editor: editor,
            isDragging: isDragging,
        };
    },
});
