/**
 * This is a BasicWysiwyg Entry Point to be used in Haml templates.
 */
import { computed, defineComponent, ref } from "vue";
import BasicWysiwyg from "components/Wysiwyg/BasicWysiwyg.vue";
import useMentionSearch from "concerns/useMentionSearch";
import "suus-api";
export default defineComponent({
    name: "BasicWysiwygEntry",
    components: { BasicWysiwyg: BasicWysiwyg },
    props: {
        groupId: {
            type: Number,
            required: false,
        },
        value: {
            type: String,
            default: "",
        },
        fieldName: {
            type: String,
            required: true,
        },
        inputId: {
            type: String,
            required: true,
        },
        toolbar: {
            type: Boolean,
            default: true,
        },
    },
    setup: function (props) {
        var _currentValue = ref(props.value);
        var currentValue = computed(function () { return _currentValue.value; });
        var setCurrentValue = function (val) {
            _currentValue.value = val;
        };
        var getMentionableFunc = useMentionSearch({
            group: { id: props.groupId },
        }).mentionableFunction;
        console.log(props);
        return {
            currentValue: currentValue,
            setCurrentValue: setCurrentValue,
            getMentionableFunc: getMentionableFunc,
        };
    },
});
