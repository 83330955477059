import { defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import CarouselArrow from "app/ui-primitives/carousel/CarouselArrow.vue";
import { useDragToScroll } from "app/ui-primitives/carousel/use-drag-to-scroll";
import { useScrollPositionCheck } from "app/ui-primitives/carousel/use-scroll-position-check";
import { useOverflowCheck } from "app/ui-primitives/carousel/use-overflow-check";
export default defineComponent({
    name: "Carousel",
    components: { CarouselArrow: CarouselArrow },
    props: {
        dragToScroll: {
            type: Boolean,
            default: true,
        },
    },
    setup: function (props) {
        var outerContainer = ref(null);
        var innerContainer = ref(null);
        var alreadyMounted = ref(false);
        var _a = useOverflowCheck(outerContainer, innerContainer), showArrows = _a.isOverflowing, registerOverflowCheck = _a.registerOverflowCheck, unregisterOverflowCheck = _a.unregisterOverflowCheck;
        var _b = useScrollPositionCheck(outerContainer, innerContainer), scrolledCompletelyLeft = _b.scrolledCompletelyLeft, scrolledCompletelyRight = _b.scrolledCompletelyRight, registerScrollPositionCheck = _b.registerScrollPositionCheck, unregisterScrollPositionCheck = _b.unregisterScrollPositionCheck;
        var _c = useDragToScroll(innerContainer), _registerDragToScroll = _c.registerDragToScroll, _unregisterDragToScroll = _c.unregisterDragToScroll;
        var registerDragToScroll = function () {
            if (props.dragToScroll) {
                _registerDragToScroll();
            }
        };
        var unregisterDragToScroll = function () {
            if (props.dragToScroll) {
                _unregisterDragToScroll();
            }
        };
        onMounted(function () {
            alreadyMounted.value = true;
            registerOverflowCheck();
            registerScrollPositionCheck();
            registerDragToScroll();
        });
        onUnmounted(function () {
            unregisterOverflowCheck();
            unregisterScrollPositionCheck();
            unregisterDragToScroll();
        });
        /**
         * If the inner-container is not overflowing, we don't need to register the drag to scroll functionality.
         */
        watch(showArrows, function (isOverflowing) {
            if (isOverflowing && alreadyMounted.value) {
                registerDragToScroll();
            }
            else if (alreadyMounted.value) {
                unregisterDragToScroll();
            }
        }, { immediate: true });
        var scrollLeft = function () {
            var _innerContainer = innerContainer.value;
            if (_innerContainer) {
                _innerContainer.scrollBy({
                    left: -320,
                    behavior: "smooth",
                });
            }
        };
        var scrollRight = function () {
            var _innerContainer = innerContainer.value;
            if (_innerContainer) {
                _innerContainer.scrollBy({
                    left: 320,
                    behavior: "smooth",
                });
            }
        };
        return {
            outerContainer: outerContainer,
            innerContainer: innerContainer,
            showArrows: showArrows,
            scrolledCompletelyLeft: scrolledCompletelyLeft,
            scrolledCompletelyRight: scrolledCompletelyRight,
            scrollLeft: scrollLeft,
            scrollRight: scrollRight,
        };
    },
});
