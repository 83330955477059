import "vuex";
import { computed } from "vue";
var GroupService = /** @class */ (function () {
    function GroupService(store) {
        var _this = this;
        this.store = store;
        this.currentGroup = computed(function () { var _a; return (_a = _this.store.state.group.currentGroup) === null || _a === void 0 ? void 0 : _a.data; });
    }
    return GroupService;
}());
export default GroupService;
