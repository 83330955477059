import { __awaiter, __generator } from "tslib";
import { defineComponent } from "vue";
import "concerns/useContextName";
import PostList from "features/posts/PostList.vue";
import { postsApi, topicsApi } from "store/api";
import "interfaces/error.interface";
import "features/posts/PostOmissionLoader.vue";
export default defineComponent({
    name: "TopicPostList",
    components: { PostList: PostList },
    props: {
        totalPostsCount: { type: Number, required: true },
        topicId: { type: Number, required: true },
        canCreatePosts: { type: Boolean, default: false },
        viewContext: { type: Object },
    },
    computed: {},
    methods: {
        emitOptimisticPostsCount: function (optimisticPostsCount) {
            this.$emit("optimistic-posts-count", optimisticPostsCount);
        },
    },
    setup: function (props) {
        var _this = this;
        var performCreatePost = function (topicId, postText) { return __awaiter(_this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, topicsApi.createTopicPost(topicId.toString(), {
                                text: postText,
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var performUpdatePost = function (topicId, postId, postText) { return __awaiter(_this, void 0, void 0, function () {
            var data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, postsApi.updateTopicPost(topicId.toString(), postId.toString(), {
                                text: postText,
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                    case 2:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var performDeletePost = function (topicId, postId) { return __awaiter(_this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, postsApi.deleteTopicPost(topicId.toString(), postId.toString())];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_3 = _a.sent();
                        console.error(error_3);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var loadTopicPosts = function (offset, limit) {
            return topicsApi
                .getTopicPosts(props.topicId.toString(), offset, limit, "asc")
                .then(function (response) { return response.data; });
        };
        return {
            performCreatePost: performCreatePost,
            performUpdatePost: performUpdatePost,
            performDeletePost: performDeletePost,
            loadTopicPosts: loadTopicPosts,
        };
    },
});
