# Used by groups#dashboard and groups#show
one = (dom, event, callback) ->
  handler = (e) ->
    callback.call this, e
    @removeEventListener event, handler
  dom.addEventListener event, handler

one(document, 'DOMContentLoaded', (event) ->
  loading = false
  triggered = false

  handleEvent = ->
    unless loading or triggered
      triggered = true
      more_items_url = $('.pagination a[rel=next]').attr('href')
      last_item = $("#infinite-items").children('.item').last()
      if !last_item?.length || !more_items_url
        return
      last_item_offset = last_item.offset().top + last_item.outerHeight()
      window_bottom = $(window).scrollTop() + $(window).height()

      if window_bottom >= last_item_offset
        if more_items_url
          loading = true
          $('#loading-indicator').removeClass('hide')
          $.ajax
            url: more_items_url
            success: (data) ->
              newHTML = $($.parseHTML("<div>"+data+"</div>"))
              $('.pagination').html(newHTML.find('.pagination').html())
              $("#infinite-items").append(newHTML.children())
              $("#infinite-items .pagination").remove()
              $("#infinite-items").trigger('change')
              initPage($("#infinite-items"))
              loading = false
              triggered = false
              $('#loading-indicator').addClass('hide')
        else
          $('#no-more-elements').removeClass('hide')
      else triggered = false

  document.addEventListener 'scroll', ->
    handleEvent()

  document.addEventListener 'wheel', ->
    handleEvent()
)


