import { defineComponent } from "vue";
import DividerZigZag from "../../components/UI/DividerZigZag.vue";
import TextBody from "../../components/UI/TextBody.vue";
import THEME from "../../constants/theme";
export default defineComponent({
    name: "PostListDivider",
    components: { DividerZigZag: DividerZigZag, TextBody: TextBody },
    props: { count: { type: Number, required: true } },
    setup: function () {
        return {
            dividerBackgroundColor: THEME.colors.light.surface,
        };
    },
});
