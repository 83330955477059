import { ClientAbility } from "suus-api"

export default {
  canAccessGroup(state, getters) {
    if (state.currentMember?.data) {
      return state.currentMember.data.client_permissions.includes(
        ClientAbility.AccessGroups
      )
    }
    return false
  },
  canCreateTopicOnClient(state, getters) {
    if (state.currentMember?.data) {
      return state.currentMember.data.client_permissions.includes(
        ClientAbility.AccessGroups
      )
    }
    return false
  },
  canCreateEventOnClient(state, getters) {
    if (state.currentMember?.data) {
      return state.currentMember.data.client_permissions.includes(
        ClientAbility.AccessEvents
      )
    }
    return false
  },
  canEditTopicOnClient(state, getters) {
    if (state.currentMember?.data) {
      return state.currentMember.data.client_permissions.includes(
        ClientAbility.AccessGroups
      )
    }
    return false
  },
  canUrgentDigestOnClient(state, getters) {
    if (state.currentMember?.data) {
      return state.currentMember.data.client_permissions.includes(
        ClientAbility.SendUrgentDigest
      )
    }
    return false
  },
  canPublish(state, getters) {
    if (state.currentMember?.data) {
      return state.currentMember.data.client_permissions.includes(
        ClientAbility.ManageOpenEvents
      )
    }
    return false
  },
  memberLevelIds(state, getters) {
    if (state.currentMember?.data?.levels) {
      return state.currentMember.data.levels.map((level) => level.id)
    }
  },
  memberScope(state, getters) {
    const memberLevels = state.currentMember?.data?.levels || []
    return [{ type: "Client", value: getters.currentClient }].concat(
      memberLevels.map((l) => ({ type: "Level", value: l }))
    )
  },
  currentClient(state, getters) {
    return state.currentMember?.data?.client || null
  },
  currentMember(state, getters) {
    return state.currentMember?.data || null
  },
  adHAddress(state, getters) {
    if (state.currentMember?.data?.client) {
      return state.currentMember?.data?.client.client_address_without_name
    }
  },
  getCurrentClientPermissions(state, getters) {
    return state.currentMember?.data?.client_permissions ?? []
  },
  getCurrentLevelPermissions(state, getters) {
    return state.currentMember?.data?.level_permissions ?? []
  },
  isCurrentMemberLoaded(state, getters) {
    return !!state.currentMember?.data
  },
}
