import { __spreadArray } from "tslib";
import "vuex";
import { computed } from "vue";
import { ClientAbility, } from "suus-api";
import { fetchGroupMemberByCurrentMember } from "features/member/member.functions";
var MemberService = /** @class */ (function () {
    function MemberService(store) {
        var _this = this;
        this.store = store;
        this.currentMember = computed(function () { var _a; return (_a = _this.store.state.member.currentMember) === null || _a === void 0 ? void 0 : _a.data; });
        this.memberScope = computed(function () {
            var _a, _b;
            var memberLevels = ((_a = _this.currentMember.value) === null || _a === void 0 ? void 0 : _a.levels) || [];
            return __spreadArray([
                { type: "Client", value: (_b = _this.currentMember.value) === null || _b === void 0 ? void 0 : _b.client }
            ], memberLevels.map(function (level) { return ({
                type: "Level",
                value: level,
            }); }), true);
        });
        this.groupMemberships = computed(function () {
            var _a;
            return ((_a = _this.currentMember.value) === null || _a === void 0 ? void 0 : _a.group_memberships) || [];
        });
        this.canUrgentDigestOnClient = computed(function () {
            if (_this.currentMember.value) {
                return _this.currentMember.value.client_permissions.includes(ClientAbility.SendUrgentDigest);
            }
            return false;
        });
    }
    MemberService.prototype.getAdhAddress = function () {
        var _a, _b;
        return (_b = (_a = this.currentMember.value) === null || _a === void 0 ? void 0 : _a.client) === null || _b === void 0 ? void 0 : _b.client_address_without_name;
    };
    // TODO: The current members should be cached globally, it is a heavy request
    MemberService.prototype.getCurrentGroupMember = function () {
        return fetchGroupMemberByCurrentMember(this.currentMember.value);
    };
    return MemberService;
}());
export default MemberService;
