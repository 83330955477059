import "features/gallery/lightbox/lightbox.service";
import "suus-api";
import { usePostMediaPhotoswipe } from "features/posts/gallery/lightbox/use-post-media-photoswipe";
import "concerns/useContextName";
var PostLightboxService = /** @class */ (function () {
    function PostLightboxService(_lightboxService, _post, _viewContext) {
        this._lightboxService = _lightboxService;
        this._post = _post;
        this._viewContext = _viewContext;
    }
    PostLightboxService.prototype.openLightbox = function (startIndex) {
        var openLightbox = usePostMediaPhotoswipe(this._post.id, this._post.event_media_count, this._lightboxService, this._viewContext).openLightbox;
        openLightbox(startIndex);
    };
    return PostLightboxService;
}());
export { PostLightboxService };
