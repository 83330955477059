import { defineComponent, watch } from "vue";
import { useWysiwygAddon } from "features/wysiwyg/addon/wysiwyg-addon.concern";
import "features/wysiwyg/addon/wysiwyg-addon.types";
import THEME from "constants/theme";
export default defineComponent({
    name: "WysiwygAddon",
    props: {
        title: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
            required: true,
        },
        registerAddon: {
            type: Function,
            required: true,
        },
        badgeCount: {
            type: Number,
            required: false,
        },
        initialActive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup: function (props) {
        var _a = useWysiwygAddon(props.title, props.icon, props.initialActive), addonKey = _a.addonKey, setBadgeCount = _a.setBadgeCount, resetAddon = _a.resetAddon, addonActive = _a.addonActive, addonMenuItem = _a.addonMenuItem;
        props.registerAddon({
            addonMenuItem: addonMenuItem,
            resetAddon: resetAddon,
        });
        watch(function () { return props.badgeCount; }, function (newCount) {
            setBadgeCount(newCount);
        }, { immediate: true });
        var addonContainerStyles = {
            backgroundColor: "#FFFFFF",
            borderRadius: "4px",
            border: "1px solid ".concat(THEME.colors.light.mediumGrey),
            padding: "32px",
        };
        return {
            addonActive: addonActive,
            addonKey: addonKey,
            addonContainerStyles: addonContainerStyles,
        };
    },
});
