<template lang="pug">
div
  .ui.form
    .ui.search.item.attached.top
      .ui.left.icon.input.large.transparent(:class='{ loading: searching }' style='width: 100%; background: white;')
        input.string.required.prompt.list-search(v-model='query' placeholder='Suche nach Name, PLZ, Beruf oder Telefon' type='text')
        i.search.icon(v-if='searching')
        i.suus-zoom-2.icon.link(v-else='')
    .ui.accordion
      h4.ui.header.title.mobile.and.tablet.only(@click='showFilter = !showFilter')
        i.dropdown.icon
        | Suchen &amp; Filtern
      .content(:style='filterBoxCss')
        .search-filter
          .ui.grid.four.column.doubling.stackable
            .column
              RemoteDropdown(
                v-model='cities'
                :endpoint='`/api/v1/members/cities`'
                name='cities' :params='dropdownFilter'
                placeholder='Städte'
                :valueFunction='(x) => x.value'
                label='Stadt'
                :multiple='true'
              )
            .column
              RemoteDropdown(
                v-model='clients'
                :endpoint='`/api/v1/members/clients`'
                name='clients'
                :params='dropdownFilter'
                :placeholder="$t('memberlist.filters.clients.placeholder')"
                :label="$t('memberlist.filters.clients.label')"
                :multiple='true'
              )
            template(v-if='!this.dead && !this.resigned')
              .column(v-for='(group, i) in searchableGroups')
                RemoteDropdown(
                  v-model='selectedSearchableGroups[i]'
                  :endpoint='`/api/v1/groups/${group.id}/children`'
                  :params='dropdownFilter' :name='group.name'
                  :valueFunction='(x) => x.value'
                  :placeholder='group.name'
                  :label='group.name'
                  :multiple='true'
                )
              .column
                RemoteDropdown(
                  v-model='groups'
                  :endpoint='`/api/v1/groups`'
                  :params='groupDropdownFilter'
                  name='groups'
                  placeholder='Gruppen'
                  :textFunction='(x) => x.name'
                  label='Gruppen'
                  :multiple='true'
                )
          .ui.grid.four.column.doubling.stackable
            .column
              .field
                .ui.checkbox
                  input#dead(type='checkbox' v-model='dead' name='dead')
                  label(for='dead')
                    | Verstorbene anzeigen
            .column
              .field
                .ui.checkbox
                  input#resigned(type='checkbox' v-model='resigned' name='resigned')
                  label(for='resigned')
                    | Ehemalige anzeigen
            .column(v-if='allianceFilter == 1')
              .field
                .ui.checkbox
                  input#alliances(type='checkbox' v-model='alliances' name='alliances')
                  label(for='alliances') {{ $t('memberlist.filters.alliances.label') }}
        .floated.right
          span(v-if='count == -1') ...
          span(v-else='') {{ this.count }}&nbsp;Ergebnisse
        .ui.text.menu.sort-box
          .header.item
            | Sortieren nach
          .item(:class="sortClasses('name')" @click="sort('name')")
            a.sort(:class="sortClasses('name')") Name
          .item(:class="sortClasses('zip')" @click="sort('zip')")
            a.sort(:class="sortClasses('zip')") {{$t('activerecord.attributes.address.postal_code')}}
          .item(:class="sortClasses('dob')" @click="sort('dob')")
            a.sort(:class="sortClasses('dob')") {{$t('activerecord.attributes.personal_record.birthdate')}}
          .item(:class="sortClasses('rec')" @click="sort('rec')")
            a.sort(:class="sortClasses('rec')") {{$t('activerecord.attributes.club_record.'+this.current_client.attribute_for_coetanen)}}
          .item(:class="sortClasses('number')" @click="sort('number')")
            a.sort(:class="sortClasses('number')") {{$t('activerecord.attributes.club_record.list_number')}}
          .item(:class="sortClasses('updated_at')" @click="sort('updated_at')")
            a.sort(:class="sortClasses('updated_at')") Letzte Aktualisierung
  .memberlist
    .error(v-if='error')
      b Fehler:
      |  {{ error }}
    .searchable-list.ui.divided.items.vuejs
      MemberlistItem(
        v-for='member in members'
        :key='member.id'
        :member='member'
        :expanded='member.id === activeItem'
        @click.native='activeItem = (activeItem === member.id ? null : member.id)'
      )
    div(ref='scrollIndicator')
    .loading(v-if='loading')
      | Weitere Ergebnisse werden geladen...
</template>

<script>
import Vue from "vue"
import _ from "lodash"
import MemberlistItem from "./MemberlistItem.vue"
import RemoteDropdown from "./RemoteDropdown.vue"

export default Vue.extend({
  data() {
    return {
      searching: true,
      loading: false,
      members: [],
      error: null,
      query: this.$route.query.q || "",
      offset: 0,
      count: -1,
      limitStart: 20,
      limitCurrent: 20,
      limitInertia: 1.5,
      limitMax: 200,
      preloadOffsetStart: 700,
      preloadOffsetCurrent: 700,
      preloadOffsetInertia: 3,
      preloadOffsetMax: 5000,
      sortKey: "name",
      sortAsc: true,
      cities: null,
      groups: [],
      selectedSearchableGroups: {},
      clients: null,
      dead: false,
      resigned: false,
      activeItem: null,
      showFilter: null,
      alliances: false,
    }
  },
  components: {
    MemberlistItem,
    RemoteDropdown,
  },
  watch: {
    query() {
      this.debouncedGetResults()
    },
    alliances() {
      this.getResults()
    },
    clients() {
      this.getResults()
    },
    cities() {
      this.getResults()
    },
    groups() {
      this.getResults()
    },
    group_ids() {
      this.getResults()
    },
    dead() {
      this.groups = null
      this.selectedSearchableGroups = {}
      this.getResults()
    },
    resigned() {
      this.groups = null
      this.selectedSearchableGroups = {}
      this.getResults()
    },
  },
  mounted: function () {
    this.scroll()
  },
  computed: {
    groupDropdownFilter() {
      const excludeSearchables = {
        exclude_tree_id: this.searchableGroups.map((e) => e.id).join(","),
      }
      if (this.alliances) {
        return { level_id: this.level_ids.join(","), ...excludeSearchables }
      } else {
        return { client_id: this.current_client.id, ...excludeSearchables }
      }
    },
    dropdownFilter() {
      return {
        dead: this.dead,
        resigned: this.resigned,
        alliances: this.alliances,
      }
    },
    filterBoxCss() {
      return this.showFilter === true ? "display: block !important;" : ""
    },
    queryUrl() {
      const endpoint = "/api/v1/members/index"
      const url = new URL(window.location.origin + endpoint)
      url.searchParams.append("q", this.query)
      url.searchParams.append("offset", this.query)
      url.searchParams.append("offset", this.offset)
      url.searchParams.append("limit", this.limitCurrent)
      url.searchParams.append("order", this.sortKey)
      url.searchParams.append("asc", this.sortAsc)
      url.searchParams.append("dead", this.dead)
      url.searchParams.append("alliances", this.alliances)
      url.searchParams.append("resigned", this.resigned)
      if (this.cities) url.searchParams.append("cities", this.cities)
      if (this.clients) url.searchParams.append("clients", this.clients)
      if (this.group_ids && this.group_ids.length > 0)
        url.searchParams.append("groups", this.group_ids)
      return url.toString()
    },
    group_ids() {
      return []
        .concat(...Object.values(this.selectedSearchableGroups))
        .concat(this.groups)
        .filter((g) => g !== null)
    },
  },
  created: function () {
    this.getResults()
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedGetResults = _.debounce(this.getResults, 500)
  },
  props: ["current_client", "allianceFilter", "searchableGroups", "level_ids"],
  methods: {
    sortClasses: function (key) {
      return {
        active: this.sortKey == key,
        asc: this.sortKey == key && this.sortAsc,
        desc: this.sortKey == key && !this.sortAsc,
      }
    },
    getResults() {
      this.error = null
      this.searching = true
      this.limitCurrent = this.limitStart
      this.preloadOffsetCurrent = this.preloadOffsetStart
      this.offset = 0
      fetch(this.queryUrl, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.searching = this.loading = false
          this.members = data.results
          this.count = data.count
          this.offset = data.results.length
        })
        .catch((err) => {
          this.loading = this.searching = false
          this.count = 0
          this.error = err.toString()
          console.error(err)
        })
    },
    sort(sortKey) {
      if (sortKey == this.sortKey) {
        this.sortAsc = !this.sortAsc
      } else {
        this.sortKey = sortKey
        this.sortAsc = true
      }
      this.getResults()
    },
    scroll() {
      window.addEventListener("scroll", () => {
        var scrollTop =
          (document.documentElement && document.documentElement.scrollTop) ||
          document.body.scrollTop
        var scrollHeight =
          (document.documentElement && document.documentElement.scrollHeight) ||
          document.body.scrollHeight
        var scrollOffset = scrollHeight - (scrollTop + window.innerHeight)

        if (
          this.offset == this.count ||
          this.loading ||
          this.searching ||
          scrollOffset > this.preloadOffsetCurrent
        ) {
          return false
        }
        this.loading = true
        this.limitCurrent = Math.min(
          this.limitMax,
          Math.floor(this.limitInertia * this.limitCurrent)
        )
        this.preloadOffsetCurrent = Math.min(
          this.preloadOffsetMax,
          Math.floor(this.preloadOffsetInertia * this.preloadOffsetCurrent)
        )
        fetch(this.queryUrl, {
          method: "GET",
          credentials: "same-origin",
        })
          .then((res) => {
            return res.json()
          })
          .then((data) => {
            this.offset += data.results.length
            this.loading = false
            for (var i = 0; i < data.results.length; i++) {
              this.members.push(data.results[i])
            }
          })
          .catch((err) => {
            this.loading = false
            this.error = err.toString()
          })
      })
    },
  },
})
</script>

<style scoped>
.field {
  margin-bottom: 0 !important;
}
label {
  cursor: pointer !important;
}
</style>
