import Vue from "vue"
import Vuex from "vuex"
import NProgress from "nprogress"
Vue.use(Vuex)

const typedStorePlugin = {
  install: function (VueInstance) {
    Object.defineProperty(VueInstance.prototype, "$vStore", {
      get: function () {
        return this.$store
      },
    })
  },
}
Vue.use(typedStorePlugin)

import drive from "./drive"
import group from "./group"
import loader from "./loader"
import event from "./event"
import member from "./member"
import topic from "./topic"

const debug = process.env.NODE_ENV !== "production"
const store = new Vuex.Store({
  modules: {
    drive: drive,
    group: group,
    loader: loader,
    event: event,
    member: member,
    topic: topic,
  },
  strict: debug,
})

store.watch(
  function (state) {
    return state.loader.loading
  }, // watch changes to this
  function (newVal, oldVal) {
    if (newVal === 0) {
      store.commit("loader/ALL_LOADED")
      return NProgress.done()
    }
    if (oldVal === 0) return NProgress.start()
    NProgress.set(1.8 / Math.max(oldVal, newVal))
  }
)
export default store
