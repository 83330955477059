import Vue from "vue";
import THEME from "../../constants/theme";
import { mdiReplyOutline } from "@mdi/js";
export default Vue.extend({
    name: "CommentButton",
    data: function () {
        return {
            labelStyles: THEME.typography.button,
            replyIcon: mdiReplyOutline,
        };
    },
});
