import { __awaiter, __generator } from "tslib";
import Axios from "../../initializers/axios";
import { inodePath } from "./util";
import { driveApi, driveFilesApi, foldersApi } from "store/api";
export default {
    setCurrentInode: function (_a, inodeId) {
        var commit = _a.commit, dispatch = _a.dispatch, state = _a.state, getters = _a.getters;
        commit("showUploader", false);
        commit("setCurrentInodeId", inodeId);
        if (inodeId && (!state.inodes || !state.inodes[inodeId])) {
            dispatch("loadInode", inodeId);
        }
    },
    loadInode: function (_a, inode_id) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var data, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("loader/SET_ERROR", false, { root: true });
                        commit("setLoading", true);
                        return [4 /*yield*/, foldersApi.getFolder(inode_id)];
                    case 1:
                        data = (_b.sent()).data;
                        commit("updateInodes", inodePath(data));
                        return [3 /*break*/, 4];
                    case 2:
                        err_1 = _b.sent();
                        switch (err_1.response.status) {
                            case 404:
                                commit("loader/SET_ERROR", err_1, { root: true });
                                console.log(err_1);
                                break;
                            case 500:
                                commit("loader/SET_ERROR", err_1, { root: true });
                                console.log(err_1);
                                break;
                            default:
                                console.error(err_1.response.status, err_1);
                                commit("loader/SET_ERROR", err_1, { root: true });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        commit("setLoading", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    loadCategories: function (_a) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var data, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("setLoading", true);
                        commit("loader/SET_ERROR", false, { root: true });
                        return [4 /*yield*/, driveApi.getCategories()];
                    case 1:
                        data = (_b.sent()).data;
                        commit("updateInodes", data.results);
                        commit("setCreateCategoryUrl", data.create_category_url);
                        return [3 /*break*/, 4];
                    case 2:
                        err_2 = _b.sent();
                        console.log(err_2);
                        commit("loader/SET_ERROR", err_2, { root: true });
                        return [3 /*break*/, 4];
                    case 3:
                        commit("setLoading", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    createFolder: function (_a, formData) {
        var commit = _a.commit, getters = _a.getters, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var url, folder, err_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("setLoading", true);
                        commit("loader/SET_ERROR", false, { root: true });
                        url = getters.currentFolder
                            ? getters.currentFolder.create_subfolder_url
                            : state.createCategoryUrl;
                        return [4 /*yield*/, Axios.post(url, formData)];
                    case 1:
                        folder = (_b.sent()).data;
                        commit("updateInodes", [folder]);
                        return [2 /*return*/, folder];
                    case 2:
                        err_3 = _b.sent();
                        console.log(err_3);
                        commit("loader/SET_ERROR", err_3, { root: true });
                        return [3 /*break*/, 4];
                    case 3:
                        commit("setLoading", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    updateInode: function (_a, _b) {
        var commit = _a.commit, dispatch = _a.dispatch, getters = _a.getters;
        var inode = _b.inode, formData = _b.formData;
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, Axios.put(inode.update_url, formData)];
                    case 1:
                        data = (_c.sent()).data;
                        commit("updateInodes", [data]);
                        return [2 /*return*/];
                }
            });
        });
    },
    destroyFolder: function (_a, folder) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var err_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("setLoading", true);
                        commit("loader/SET_ERROR", false, { root: true });
                        return [4 /*yield*/, foldersApi.deleteFolder(folder.id)];
                    case 1:
                        _b.sent();
                        commit("removeInodes", [folder]);
                        commit("setSuccess", "Ordner gelöscht.");
                        return [3 /*break*/, 4];
                    case 2:
                        err_4 = _b.sent();
                        console.log(err_4);
                        commit("loader/SET_ERROR", err_4, { root: true });
                        return [3 /*break*/, 4];
                    case 3:
                        commit("setLoading", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    destroyDriveFile: function (_a, driveFile) {
        var commit = _a.commit;
        return __awaiter(this, void 0, void 0, function () {
            var err_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        commit("setLoading", true);
                        commit("loader/SET_ERROR", false, { root: true });
                        return [4 /*yield*/, driveFilesApi.deleteDriveFile(driveFile.id)];
                    case 1:
                        _b.sent();
                        commit("removeInodes", [driveFile]);
                        commit("setSuccess", "Ordner gelöscht.");
                        return [3 /*break*/, 4];
                    case 2:
                        err_5 = _b.sent();
                        console.log(err_5);
                        commit("loader/SET_ERROR", err_5, { root: true });
                        return [3 /*break*/, 4];
                    case 3:
                        commit("setLoading", false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    },
    createFile: function (_a, formData) {
        var commit = _a.commit, getters = _a.getters;
        return __awaiter(this, void 0, void 0, function () {
            var driveFile;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, driveFilesApi.createDriveFile(formData)];
                    case 1:
                        driveFile = (_b.sent()).data;
                        commit("updateInodes", [driveFile]);
                        return [2 /*return*/, driveFile];
                }
            });
        });
    },
};
