import { ref } from "vue";
/**
 * Check if the inner container is overflowing the outer container.
 * @param outerContainer
 * @param innerContainer
 */
export var useOverflowCheck = function (outerContainer, innerContainer) {
    var isOverflowing = ref(false);
    var checkOverflowing = function () {
        var _outerContainer = outerContainer.value;
        var _innerContainer = innerContainer.value;
        if (_outerContainer && _innerContainer) {
            isOverflowing.value =
                _innerContainer.scrollWidth > _outerContainer.clientWidth;
        }
    };
    var resizeObserver = ref(null);
    var registerOverflowCheck = function () {
        checkOverflowing();
        window.addEventListener("resize", checkOverflowing);
        resizeObserver.value = new ResizeObserver(checkOverflowing);
        resizeObserver.value.observe(innerContainer.value);
    };
    var unregisterOverflowCheck = function () {
        var _a;
        window.removeEventListener("resize", checkOverflowing);
        (_a = resizeObserver.value) === null || _a === void 0 ? void 0 : _a.disconnect();
    };
    return {
        isOverflowing: isOverflowing,
        registerOverflowCheck: registerOverflowCheck,
        unregisterOverflowCheck: unregisterOverflowCheck,
    };
};
