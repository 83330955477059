import { computed, defineComponent, inject, toRef, } from "vue";
import "suus-api";
import "features/gallery/gallery.types";
import PostGalleryShowMore from "features/posts/gallery/PostGalleryShowMore.vue";
import ServicedGalleryThumbnail from "features/gallery/service/ServicedGalleryThumbnail.vue";
import "features/posts/gallery/lightbox/post-lightbox.service";
export default defineComponent({
    name: "PostGalleryPreviewMobile",
    components: {
        ServicedGalleryThumbnail: ServicedGalleryThumbnail,
        PostGalleryShowMore: PostGalleryShowMore,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        canEditHighlights: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        var postRef = toRef(props, "post");
        var previewThumbs = computed(function () { return postRef.value.event_media_preview; });
        var postLightboxService = inject("PostLightboxService");
        var openImage = function (index) {
            postLightboxService.openLightbox(index);
        };
        return {
            previewThumbs: previewThumbs,
            openImage: openImage,
        };
    },
});
