import { __spreadArray } from "tslib";
import { Editor } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import { Placeholder } from "@tiptap/extension-placeholder";
import { SmilieReplacer } from "components/Wysiwyg/extensions/SmilieReplacer";
import { ref } from "vue";
import { Link } from "@tiptap/extension-link";
import { ConfiguredSuusMentionExtension, } from "components/Wysiwyg/extensions/suus-mention/suus-mention.config";
export function useBasicExtensions(parentComponent, suggestionFunction) {
    if (suggestionFunction === void 0) { suggestionFunction = function (_) { return []; }; }
    return [
        ConfiguredSuusMentionExtension(suggestionFunction, parentComponent),
        Link, // a-Tags from mention has higher precedence!
    ];
}
export function useCoreWysiwyg(value, placeholder, css_classes, editorExtensions) {
    if (value === void 0) { value = ref(""); }
    if (placeholder === void 0) { placeholder = ""; }
    if (css_classes === void 0) { css_classes = "suus__editor__wysiwyg"; }
    if (editorExtensions === void 0) { editorExtensions = []; }
    var editor = new Editor({
        editorProps: {
            attributes: { class: css_classes },
        },
        editable: true,
        extensions: __spreadArray([
            StarterKit,
            Highlight,
            SmilieReplacer,
            Placeholder.configure({
                placeholder: placeholder,
                emptyNodeClass: "is-editor-empty",
            })
        ], (editorExtensions !== null && editorExtensions !== void 0 ? editorExtensions : []), true),
        content: value.value,
        onUpdate: function () {
            value.value = editor.getHTML();
        },
    });
    return editor;
}
