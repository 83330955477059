import { defineComponent } from "vue";
import { mdiMinus, mdiPlus } from "@mdi/js";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    props: {
        value: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        labelText: function () {
            return this.t("label");
        },
        rules: function () {
            var _this = this;
            return {
                required: function (value) {
                    if (value === 0 || !!value) {
                        return true;
                    }
                    return _this.tRules("rule1");
                },
                guests: function (value) {
                    if (value > 20) {
                        return _this.tRules("rule2");
                    }
                    return (_this.validationPattern.test(value) ||
                        _this.tRules("rule3"));
                },
            };
        },
        guests: {
            get: function () {
                var _a;
                return (_a = this.value) !== null && _a !== void 0 ? _a : 0;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        add: function () {
            this.guests++;
        },
        subtract: function () {
            if (this.guests > 0) {
                this.guests--;
            }
        },
    },
    setup: function () {
        var minusIcon = mdiMinus;
        var plusIcon = mdiPlus;
        var _a = useTranslate("components.guest_modal"), t = _a.t, getComponent = _a.getComponent;
        var tRules = getComponent("rules").t;
        return {
            minusIcon: minusIcon,
            plusIcon: plusIcon,
            validationPattern: /^[0-9]{1,2}$/,
            t: t,
            tRules: tRules
        };
    },
});
