import { defineComponent } from "vue";
import { PanelIdentifier } from "features/events/form/event-form.types";
import "features/events/form/FormExpansionPanel.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import Subtitle from "components/UI/Subtitle.vue";
import AddressField from "features/events/form/general-fields/AddressField.vue";
import OnlineConferenceField from "features/events/form/general-fields/OnlineConferenceField.vue";
import ParticipationCircleFields from "features/events/form/general-fields/ParticipationCircleFields.vue";
import OrganizerFields from "components/EventForm/OrganizerFields.vue";
import PublishingFields from "components/EventForm/PublishingFields.vue";
var OrganiaztionFields = defineComponent({
    name: "OrganizationFields",
    components: {
        PublishingFields: PublishingFields,
        OrganizerFields: OrganizerFields,
        ParticipationCircleFields: ParticipationCircleFields,
        OnlineConferenceField: OnlineConferenceField,
        AddressField: AddressField,
        Subtitle: Subtitle,
    },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        return {
            t$: t$,
        };
    },
});
export default OrganiaztionFields;
export var OrganizationFieldsDefinition = {
    key: "organization",
    component: OrganiaztionFields,
    id: PanelIdentifier.ORGANIZATION,
};
