<template>
  <div class="ui container">
    <div class="ui form">
      <div class="ui search item attached top">
        <div
          class="ui left icon input large transparent"
          :class="{ loading: loading }"
          style="width: 100%; background: white"
        >
          <input
            v-model="query"
            class="string required prompt list-search"
            placeholder="Suche nach Name"
            type="text"
          />
          <i v-if="loading" class="search icon"></i>
          <i v-else class="suus-zoom-2 icon link"></i>
        </div>
      </div>
    </div>
    <p class="text-right">{{ count }} Ergebnisse</p>
    <LazyList
      :endpoint="endpoint"
      @loading="loading = true"
      @loaded="loaded"
      :placeholders="{ items: 20, slots: ['image', 'title'] }"
      :order="[{ last_name: 'asc' }, { first_name: 'asc' }]"
    >
      <template slot="item" slot-scope="obj">
        <ListItem>
          <template slot="image">
            <template v-if="obj.item.colors.length > 0">
              <i
                v-for="c in obj.item.colors"
                class="avatar corps"
                :style="c.colors_css"
                :title="c.colors_title"
              ></i>
            </template>
            <i
              v-else
              class="ui icon"
              :class="icon(obj.item)"
              :title="title(obj.item)"
            ></i>
          </template>

          <template slot="title">
            <a :href="personal_record_path(obj.item.id)" class="title">
              <span class="particle">{{ obj.item.particle }}</span>
              <strong class="plain-name">{{
                obj.item.name_without_particle
              }}</strong
              >, {{ obj.item.first_name }}
              <template v-if="obj.item.alive">
                <span
                  class="age"
                  v-if="obj.item.birthdate"
                  :title="obj.item.birthdate"
                  >({{ obj.item.age }})</span
                >
              </template>
              <template v-else>
                <span class="age"
                  >(&dagger;<template v-if="obj.item.date_of_death">
                    {{ obj.item.date_of_death }}</template
                  >)</span
                >
              </template>
              <i
                v-if="obj.item.abos.length > 0"
                class="ui icon suus-archive-3d-content"
                v-tooltip="abos(obj.item.abos)"
              ></i>
              <i
                v-if="obj.item.is_spef"
                class="icon suus-badge-15"
                v-tooltip="$t('components.people_list.on_list')"
              ></i>
            </a>
          </template>

          <template slot="meta-columns">
            <div class="column">
              <div class="tag-container">
                <a
                  v-for="list in obj.item.address_lists"
                  :href="address_list_path(list.id)"
                  class="ui label"
                >
                  <i class="icon suus-contacts-2"></i>
                  {{ list.name }}
                </a>
              </div>
            </div>
          </template>

          <template slot="actions">
            <a
              v-if="obj.item.edit"
              :href="edit_personal_record_path(obj.item.id)"
              class="ui icon button"
            >
              <i class="ui icon suus-edit-74"></i>
              <span class="mobile only">Bearbeiten</span>
            </a>
          </template>

          <template slot="extra-items"> </template>

          <template slot="extra-action"> </template>
        </ListItem>
      </template>
    </LazyList>
  </div>
</template>

<script>
import Vue from "vue"
import ListItem from "./ListItem.vue"
import LazyList from "./LazyList.vue"

export default Vue.extend({
  components: { LazyList, ListItem },
  data() {
    return {
      query: "",
      loading: false,
      count: 0,
    }
  },
  computed: {
    endpoint: function () {
      return "/api/v1/personal_records?q=" + this.query
    },
  },
  methods: {
    loaded: function (c) {
      this.loading = false
      this.count = c
    },
    edit_personal_record_path: function (id) {
      return "/personal_records/" + id + "/edit"
    },
    personal_record_path: function (id) {
      return "/personal_records/" + id
    },
    address_list_path: function (id) {
      return "/address_lists/" + id
    },
    icon: function (person) {
      if (person.is_relative) {
        return "suus-multiple-19"
      }
      if (person.sex === "family") {
        return "suus-family"
      } else {
        return "suus-single-05"
      }
    },
    title: function (person) {
      if (person.is_relative) {
        return "$t('components.people_list.relative')"
      }
      return ""
    },
    abos: function (abos) {
      var r = ""
      for (var i = 0; i < abos.length; i++) {
        var a = abos[i]
        r += a.copies + "x " + a.magazine
        if (i + 1 < abos.length) {
          r += "; "
        }
      }
      return r
    },
  },
})
</script>
