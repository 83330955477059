import { ref } from "vue";
import "components/Wysiwyg/menu/MenuItem.vue";
export function useWysiwygAddon(title, icon, initialActive) {
    if (initialActive === void 0) { initialActive = false; }
    var badgeCount = ref(0);
    var addonKey = ref(1);
    var addonActive = ref(initialActive);
    var resetAddon = function () {
        badgeCount.value = 0;
        addonKey.value += 1;
    };
    var setBadgeCount = function (count) {
        badgeCount.value = count;
    };
    var addonMenuItem = {
        title: title,
        icon: icon,
        action: function () {
            addonActive.value = !addonActive.value;
        },
        isActive: function () { return addonActive.value; },
        badge: badgeCount,
    };
    return {
        addonKey: addonKey,
        setBadgeCount: setBadgeCount,
        resetAddon: resetAddon,
        addonActive: addonActive,
        addonMenuItem: addonMenuItem,
    };
}
