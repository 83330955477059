import moment from "moment";
export function formatDateToDDMonthYYYY(date) {
    var dateFormatter = new Intl.DateTimeFormat("de-DE", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    return dateFormatter.format(date);
}
/**
 * Extracted from "dateSpan" (https://github.com/suus-io/suus-networks/pull/692/files#diff-e1f6bf26da32ad2bd2f54cbf82efcbbc5cec5348eae8c661ddf97d2179d3646f)
 */
export var formatToDateSpanShort = function (starts, ends) {
    return _formatToDateSpan(starts, ends, {
        fullFormat: "DD.MM.YY",
        dayOnlyFormat: "ddd",
        noYearFormat: "DD.MM.",
    });
};
export var formatToDateSpanLong = function (starts, ends) {
    return _formatToDateSpan(starts, ends, {
        fullFormat: "ddd D. MMM YYYY",
        dayOnlyFormat: "ddd D.",
        noYearFormat: "ddd D. MMMM",
    });
};
function _formatToDateSpan(starts, ends, formats) {
    var startsDate = moment(starts);
    var endsDate = ends ? moment(ends) : null;
    if (!endsDate || startsDate.isSame(endsDate, "day")) {
        return startsDate.format(formats.fullFormat);
    }
    var formatStartAndEnd = function (startDateFormat) {
        return "".concat(startsDate.format(startDateFormat), " - ").concat(endsDate.format(formats.fullFormat));
    };
    var isSameYear = startsDate.isSame(endsDate, "year");
    if (startsDate.isSame(endsDate, "month") && isSameYear) {
        return formatStartAndEnd(formats.dayOnlyFormat);
    }
    if (isSameYear) {
        return formatStartAndEnd(formats.noYearFormat);
    }
    return formatStartAndEnd(formats.fullFormat);
}
