import Vue from "vue";
import TextBody from "../UI/TextBody.vue";
import Subtitle from "../UI/Subtitle.vue";
import Button from "../UI/Button.vue";
import TextButton from "../UI/TextButton.vue";
export default Vue.extend({
    name: "DangerZone",
    components: { TextBody: TextBody, Button: Button, TextButton: TextButton, Subtitle: Subtitle },
    props: {
        dangerZoneTitle: { type: String, required: true },
        dangerZoneText: { type: String, required: true },
        dangerZoneButtonLabel: { type: String, default: "Löschen" },
        confirmationTitle: { type: String, required: true },
        confirmationText: { type: String, required: true },
        handleConfirmation: { type: Function, required: true },
    },
    data: function () {
        return {
            dialog: false,
        };
    },
    computed: {
        containerStyles: function () {
            return {
                border: "1px solid ".concat(this.$vuetify.theme.themes.light.error),
                borderRadius: "4px",
            };
        },
        dangerZoneButtonStyles: function () {
            return {
                fontFamily: "system-ui !important",
                letterSpacing: "0px",
                fontWeight: 900,
            };
        },
    },
    methods: {
        handleConfirmationClick: function () {
            this.handleConfirmation();
            this.dialog = false;
        },
    },
});
