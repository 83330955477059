import { __assign, __awaiter, __generator } from "tslib";
import Header from "../UI/Header.vue";
import Button from "../UI/Button.vue";
import TextButton from "../UI/TextButton.vue";
import TextBody from "../UI/TextBody.vue";
import ScrollTo from "../UI/ScrollTo.vue";
import FormExpansionPanel from "features/events/form/FormExpansionPanel.vue";
import { createNamespacedHelpers } from "vuex";
import "store/api";
import FormTopSection from "features/common/forms/FormTopSection.vue";
import FormActions from "features/common/forms/FormActions.vue";
import FormAlertTipp from "features/common/forms/FormAlertTipp.vue";
import FormAlert from "features/common/forms/FormAlert.vue";
import { defineComponent, provide } from "vue";
import { useCanSendUrgentOnLevelOrClientFallback } from "features/common/permissions/usePermissionCheckHelper";
import { PanelIdentifier } from "features/events/form/event-form.types";
import EventFormService from "features/events/form/event-form.service";
import store from "store";
import "suus-api";
import "components/EventForm/OrganizerFields.vue";
import "features/common/forms/form.service";
import "components/TopicForm/general-fields/group-access";
var _a = createNamespacedHelpers("event"), mapState = _a.mapState, mapActions = _a.mapActions, mapMutations = _a.mapMutations;
export default defineComponent({
    name: "EventForm",
    components: {
        FormAlert: FormAlert,
        FormAlertTipp: FormAlertTipp,
        FormActions: FormActions,
        FormTopSection: FormTopSection,
        Header: Header,
        Button: Button,
        TextButton: TextButton,
        ScrollTo: ScrollTo,
        FormExpansionPanel: FormExpansionPanel,
        TextBody: TextBody,
    },
    props: {
        isEdit: { type: Boolean, default: false },
        scrollTo: { type: String, default: "" },
    },
    watch: {
        scrollTo: {
            handler: function (newVal) {
                if (newVal !== "")
                    this.scrollIntoView(newVal);
            },
            immediate: true,
        },
    },
    data: function () { return ({
        isSaving: false,
        isSaved: false,
        valid: true,
        showSheet: false,
        fieldErrors: [],
    }); },
    computed: __assign({ disableSaveButton: function () {
            return this.isSaving;
        }, defaultAlertMessage: function () {
            return this.$t("components.event_form.default_info_message").toString();
        }, allowedToPublish: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return (!!(((_a = this.currentEvent) === null || _a === void 0 ? void 0 : _a.data) &&
                ((_c = (_b = this.currentEvent) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.links) &&
                (((_e = (_d = this.currentEvent) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.links.publish_url) ||
                    ((_g = (_f = this.currentEvent) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.links.unpublish_url))) ||
                useCanSendUrgentOnLevelOrClientFallback((_j = (_h = this.eventForm) === null || _h === void 0 ? void 0 : _h.group) === null || _j === void 0 ? void 0 : _j.level_id)
                    .value);
        }, formTitle: function () {
            return this.isEdit
                ? this.$t("components.event_form.edit_event").toString()
                : this.$t("components.event_form.new_event").toString();
        }, containerStyles: function () {
            return { maxWidth: "770px" };
        }, alertTipp: function () {
            var _this = this;
            var description = this.eventFormService.getFormValue("description").value;
            if (description && description.length) {
                return {
                    label: this.$t("components.event_form.info_notifications_label").toString(),
                    selector: "suus__event-form__notifications",
                    message: this.$t("components.event_form.info_notifications_message").toString(),
                    actionBeforeCall: function () {
                        _this.addActivePanel(PanelIdentifier.REMINDER);
                    },
                };
            }
            return {
                label: this.$t("components.event_form.info_description_label").toString(),
                selector: "suus__event-form__description",
                message: this.$t("components.event_form.info_description_message").toString(),
                actionBeforeCall: function () {
                    _this.addActivePanel(PanelIdentifier.GENERAL);
                },
            };
        } }, mapState(["eventForm", "currentEvent"])),
    methods: __assign(__assign({ convertFormToDTO: function (eventForm) {
            var _a;
            return {
                title: eventForm.title,
                description: eventForm.description || null,
                location: eventForm.location,
                start_date: eventForm.start_date,
                start_time: eventForm.start_time || null,
                end_date: eventForm.end_date || null,
                end_time: eventForm.end_time || null,
                all_day: eventForm.all_day,
                urgent: eventForm.urgent,
                online: eventForm.online,
                reminders: eventForm.reminders,
                allow_guests: eventForm.allow_guests,
                nonmember_access: eventForm.nonmember_access,
                group_id: eventForm.group && eventForm.group.id,
                contact_id: eventForm.contact && eventForm.contact.id,
                contact_type: (eventForm === null || eventForm === void 0 ? void 0 : eventForm.contact_type) ||
                    (!!eventForm.contact && "type" in eventForm.contact
                        ? (_a = eventForm === null || eventForm === void 0 ? void 0 : eventForm.contact) === null || _a === void 0 ? void 0 : _a.type
                        : null),
                custom_call_url: eventForm.custom_call_url || null,
                is_public: eventForm.is_public,
            };
        }, addActivePanel: function (panelId) {
            ;
            this.$refs.expansionPanel.addActivePanel(panelId);
        }, onCompleted: function () {
            this.$emit("completed", true);
            this.isSaving = false;
        }, saveForm: function () {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var isEditMode;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            this.isSaving = true;
                            this.isSaved = true;
                            isEditMode = !!((_b = (_a = this.currentEvent) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id);
                            this.eventFormService.setFormError(null);
                            if (!this.eventFormService.validate()) return [3 /*break*/, 4];
                            if (!isEditMode) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.editEvent()];
                        case 1:
                            _c.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.createNewEvent(this.convertFormToDTO(this.eventFormService.getFormValues()))];
                        case 3:
                            _c.sent();
                            _c.label = 4;
                        case 4:
                            this.isSaving = false;
                            return [2 /*return*/];
                    }
                });
            });
        }, createNewEvent: function (requestBody) {
            return __awaiter(this, void 0, void 0, function () {
                var event, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.createEvent(requestBody)];
                        case 1:
                            event = _a.sent();
                            this.onCompleted();
                            window.open("/events/".concat(event.id), "_self");
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            console.log(e_1);
                            this.eventFormService.setFormError(e_1 instanceof Error ? e_1.message : e_1);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }, editEvent: function () {
            return __awaiter(this, void 0, void 0, function () {
                var e_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.updateEvent({
                                    event_id: this.currentEvent.data.id,
                                    request_body: this.convertFormToDTO(this.eventFormService.getFormValues()),
                                })];
                        case 1:
                            _a.sent();
                            this.onCompleted();
                            return [3 /*break*/, 3];
                        case 2:
                            e_2 = _a.sent();
                            console.log(e_2);
                            this.eventFormService.setFormError(e_2 instanceof Error ? e_2.message : e_2);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }, scrollIntoView: function (selector) {
            //const element = document.querySelector(selector)
            setTimeout(function () {
                var element = document.getElementById(selector);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "center" });
                }
            }, 500);
        }, validationWatcher: function (failedRules) {
            var _this = this;
            if (!this.isSaved) {
                return true;
            }
            this.fieldErrors = failedRules.map(function (rule) {
                _this.addActivePanel(rule.panelIdentifier);
                return {
                    message: rule.message,
                    selector: rule.selector,
                };
            });
            return !this.fieldErrors.length;
        }, validateOnChange: function () {
            if (this.isSaved) {
                this.eventFormService.validate();
            }
        } }, mapActions(["createEvent", "updateEvent", "addPublic", "deletePublic"])), mapMutations(["SET_EVENT_ERROR", "SET_OPTIMISTIC_ATTACHMENTS_COUNT"])),
    mounted: function () {
        this.eventFormService.registerValidationWatcher(this.validationWatcher);
    },
    setup: function () {
        var eventFormService = new EventFormService(store);
        provide("EventFormService", eventFormService);
        var formError = eventFormService.formError;
        return { eventFormService: eventFormService, formError: formError };
    },
});
