import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import Header2 from "app/ui-primitives/header/Header2.vue";
import { eventMediaApi } from "store/api";
import { AscDescOrder } from "suus-api";
import GalleryDashboardCardLoader from "features/gallery/dashboard/GalleryDashboardCardLoader.vue";
import GalleryDashboardCard from "features/gallery/dashboard/GalleryDashboardCard.vue";
import Carousel from "app/ui-primitives/carousel/Carousel.vue";
export default defineComponent({
    name: "GalleryDashboardSection",
    components: {
        Carousel: Carousel,
        GalleryDashboardCardLoader: GalleryDashboardCardLoader,
        Header2: Header2,
        GalleryDashboardCard: GalleryDashboardCard,
    },
    setup: function () {
        var _this = this;
        var loading = ref(true);
        var fetchError = ref(false);
        var eventGalleryItems = ref([]);
        var fetchEventGalleryItems = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, eventMediaApi.getGalleries(0, 5, AscDescOrder.Desc, 4)];
                    case 1:
                        response = _a.sent();
                        eventGalleryItems.value = response.data;
                        loading.value = false;
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        console.error("Error fetching event gallery items", e_1);
                        fetchError.value = true;
                        return [3 /*break*/, 4];
                    case 3:
                        loading.value = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchEventGalleryItems();
        return {
            loading: loading,
            fetchError: fetchError,
            eventGalleryItems: eventGalleryItems,
        };
    },
});
