import Vue, { getCurrentInstance } from "vue";
import { EditorContent } from "@tiptap/vue-2";
import "@tiptap/core";
import { ref } from "vue";
import MenuBar from "./menu/MenuBar.vue";
import "components/Wysiwyg/extensions/suus-mention/suus-mention.config";
import CoreWysiwyg from "components/Wysiwyg/CoreWysiwyg.vue";
import { useBasicExtensions } from "components/Wysiwyg/useWysiwygEditor";
export default Vue.extend({
    components: {
        CoreWysiwyg: CoreWysiwyg,
        EditorContent: EditorContent,
        MenuBar: MenuBar,
    },
    props: {
        value: { type: String, default: "" },
        getMentionables: {
            type: Function,
            default: function (_) { return []; },
        },
        showControls: { type: Boolean, default: true },
        placeholder: { type: String, default: "" },
        inline: { type: Boolean, default: false }, // note that this prop is not reactive, because it is set during mount
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var editor = ref(null);
        var onEditorReady = function (_editor) {
            editor.value = _editor;
        };
        var basicExtensions = useBasicExtensions(getCurrentInstance().proxy, props.getMentionables);
        var onInput = function (val) {
            emit("input", val);
        };
        return {
            editor: editor,
            onEditorReady: onEditorReady,
            basicExtensions: basicExtensions,
            onInput: onInput,
        };
    },
});
