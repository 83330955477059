import { defineComponent } from "vue";
import Subtitle from "components/UI/Subtitle.vue";
export default defineComponent({
    name: "ScreenStepsRow",
    components: { Subtitle: Subtitle },
    props: {
        step1: {
            type: String,
            required: true,
        },
        step2: {
            type: String,
            required: true,
        },
        step3: {
            type: String,
            required: true,
        },
    },
    setup: function (props, context) {
        return {};
    },
});
