import { __extends } from "tslib";
var EventBus = /** @class */ (function () {
    function EventBus() {
    }
    return EventBus;
}());
export { EventBus };
var SingleListenerEventBus = /** @class */ (function (_super) {
    __extends(SingleListenerEventBus, _super);
    function SingleListenerEventBus() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.listener = null;
        return _this;
    }
    SingleListenerEventBus.prototype.on = function (callback) {
        this.listener = callback;
    };
    SingleListenerEventBus.prototype.off = function () {
        this.listener = null;
    };
    return SingleListenerEventBus;
}(EventBus));
export { SingleListenerEventBus };
var SimpleEventBus = /** @class */ (function (_super) {
    __extends(SimpleEventBus, _super);
    function SimpleEventBus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimpleEventBus.prototype.emit = function (data) {
        if (this.listener)
            this.listener(data);
    };
    return SimpleEventBus;
}(SingleListenerEventBus));
export { SimpleEventBus };
