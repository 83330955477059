import { ref } from "vue";
export var useScrollPositionCheck = function (outerContainer, innerContainer) {
    var scrolledCompletelyLeft = ref(true);
    var scrolledCompletelyRight = ref(false);
    // Check if the carousel is scrolled completely to the left or right
    var checkScrollPosition = function () {
        var _outerContainer = outerContainer.value;
        var _innerContainer = innerContainer.value;
        if (_outerContainer && _innerContainer) {
            scrolledCompletelyLeft.value = _innerContainer.scrollLeft <= 10;
            scrolledCompletelyRight.value =
                _innerContainer.scrollLeft + _innerContainer.clientWidth >=
                    _innerContainer.scrollWidth - 10;
        }
    };
    var registerScrollPositionCheck = function () {
        checkScrollPosition();
        window.addEventListener("resize", checkScrollPosition);
        innerContainer.value.addEventListener("scroll", checkScrollPosition);
    };
    var unregisterScrollPositionCheck = function () {
        window.removeEventListener("resize", checkScrollPosition);
        innerContainer.value.removeEventListener("scroll", checkScrollPosition);
    };
    return {
        scrolledCompletelyLeft: scrolledCompletelyLeft,
        scrolledCompletelyRight: scrolledCompletelyRight,
        registerScrollPositionCheck: registerScrollPositionCheck,
        unregisterScrollPositionCheck: unregisterScrollPositionCheck,
    };
};
