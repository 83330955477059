export default {
  loading: (state) => {
    return !!state.currentEvent.loading
  },
  eventContact: (state) => {
    if (state.currentEvent.data && state.currentEvent.data.contact) {
      return {
        name: state.currentEvent.data.contact.name || null,
        url: state.currentEvent.data.contact.url || null,
      }
    }
    if (state.currentEvent.data && state.currentEvent.data.creator) {
      return {
        name: state.currentEvent.data.creator.name || null,
        url: state.currentEvent.data.creator.url || null,
      }
    }
    return null
  },
  eventOrganizer: (state) => {
    return state.currentEvent.data?.contact ?? state.currentEvent.data?.creator
  },
  eventCircleOfPartcipants: (state) => {
    if (state.currentEvent.data && state.currentEvent.data.group) {
      return {
        name: state.currentEvent.data.group.name,
        url: state.currentEvent.data.group.url,
      }
    }
    return null
  },
  guestsAllowed: (state) => {
    return state.currentEvent.data && state.currentEvent.data.allow_guests
  },
  currentEvent: (state) => {
    return state.currentEvent.data
  },
  currentEventAttachmentsOptimisticCount: (state, getters) => {
    return (
      state.currentEvent.attachmentsOptimisticCount ??
      getters.currentEvent?.attachments?.length ??
      0
    )
  },
  hasError: (state) => {
    return state.currentEvent.error && state.currentEvent.error.code !== 422
  },
}
