var TOPIC_API = "/api/v1/topics";
var EVENT_URL = "/events/";
var PUBLIC_FLAG_URL = "/flag/publish";
var EVENT_STATUS;
(function (EVENT_STATUS) {
    EVENT_STATUS["REGISTER"] = "register";
    EVENT_STATUS["REGISTERED"] = "registered";
    EVENT_STATUS["DEREGISTERED"] = "deregistered";
    EVENT_STATUS["FINISHED_REGISTRATION"] = "finished_registration";
    EVENT_STATUS["ATTEND_ONLINE_NOW"] = "attend_online_now";
    EVENT_STATUS["EVENT_PASSED"] = "event_passed";
})(EVENT_STATUS || (EVENT_STATUS = {}));
var EVENT_REMINDERS = {
    on_creation: {
        label: "Im nächsten Digest",
        intervalType: "seconds",
        intervalAmount: 1,
    },
    two_weeks: {
        label: "Zwei Wochen vor Startdatum",
        intervalType: "weeks",
        intervalAmount: 1,
    },
    one_month: {
        label: "Einen Monat vor Startdatum",
        intervalType: "months",
        intervalAmount: 1,
    },
    two_months: {
        label: "Zwei Monate vor Startdatum",
        intervalType: "months",
        intervalAmount: 2,
    },
    three_months: {
        label: "Drei Monate vor Startdatum",
        intervalType: "months",
        intervalAmount: 2,
    },
    six_months: {
        label: "Sechs Monate vor Startdatum",
        intervalType: "months",
        intervalAmount: 6,
    },
    twelve_months: {
        label: "12 Monate vor Startdatum",
        intervalType: "months",
        intervalAmount: 12,
    },
    eighteen_months: {
        label: "18 Monate vor Startdatum",
        intervalType: "months",
        intervalAmount: 18,
    },
};
export { TOPIC_API, EVENT_URL, EVENT_STATUS, PUBLIC_FLAG_URL, EVENT_REMINDERS };
