import { computed, defineComponent, inject, provide } from "vue";
import "suus-api";
import EventHighlightsIcon from "features/events/highlights/EventHighlightsIcon.vue";
import EventHighlightsGrid from "features/events/highlights/highlights-grid/EventHighlightsGrid.vue";
import EventHighlightsFeaturedImage from "features/events/highlights/EventHighlightsFeaturedImage.vue";
import "features/gallery/service/gallery.service";
import { EventHighlightThumbnailService } from "features/events/highlights/event-highlight-thumbnail.service";
import { useHighlightsWithOptimism } from "features/events/highlights/concerns/use-highlights-with-optimism";
import EventHighlightsView from "features/events/highlights/EventHighlightsView.vue";
import { SimpleEventBus } from "core/event-bus/event-bus";
import EventHighlightsEmptyContent from "features/events/highlights/EventHighlightsEmptyContent.vue";
import Button from "components/UI/Button.vue";
import IconButton from "components/UI/IconButton.vue";
import { getContextName } from "concerns/useContextName";
export default defineComponent({
    name: "EventHighlightsTab",
    components: {
        IconButton: IconButton,
        Button: Button,
        EventHighlightsEmptyContent: EventHighlightsEmptyContent,
        EventHighlightsView: EventHighlightsView,
        EventHighlightsFeaturedImage: EventHighlightsFeaturedImage,
        EventHighlightsGrid: EventHighlightsGrid,
        EventHighlightsIcon: EventHighlightsIcon,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        viewContext: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        var galleryService = inject("GalleryService");
        provide("ThumbnailService", new EventHighlightThumbnailService(galleryService));
        var highlightsCount = galleryService.highlightsCount;
        var _a = useHighlightsWithOptimism(props.event.id, galleryService), highlights = _a.highlights, isLoading = _a.isLoading, error = _a.error, loadHighlights = _a.loadHighlights, featuredImage = _a.featuredImage;
        loadHighlights();
        var openGalleryViewEvent = new SimpleEventBus();
        var showEmptyContent = computed(function () { return galleryService.highlightsCount.value === 0; });
        var highlightAuthors = computed(function () {
            return props.event.contributors.map(function (contributor) {
                return getContextName(props.viewContext, contributor.group_member);
            });
        });
        var highlightAuthorsText = computed(function () {
            var boldHighlightAuthors = highlightAuthors.value.map(function (author) { return "<b>".concat(author, "</b>"); });
            if (boldHighlightAuthors.length === 1)
                return "Dieses Highlight wurde von ".concat(boldHighlightAuthors[0], " aufgenommen. Vielen Dank!");
            return "Diese Highlights wurden von ".concat(boldHighlightAuthors
                .slice(0, boldHighlightAuthors.length - 1)
                .join(", "), " und ").concat(boldHighlightAuthors[boldHighlightAuthors.length - 1], " aufgenommen. Vielen Dank!");
        });
        return {
            highlights: highlights,
            isLoading: isLoading,
            error: error,
            loadHighlights: loadHighlights,
            highlightsCount: highlightsCount,
            featuredImage: featuredImage,
            openGalleryViewEvent: openGalleryViewEvent,
            showEmptyContent: showEmptyContent,
            highlightAuthors: highlightAuthors,
            highlightAuthorsText: highlightAuthorsText,
        };
    },
});
