import { defineComponent, reactive, toRefs } from "vue";
import ScrollTo from "components/UI/ScrollTo.vue";
export default defineComponent({
    name: "FormAlertTipp",
    components: {
        ScrollTo: ScrollTo,
    },
    props: {
        alertTipp: { type: Object, required: true },
    },
    computed: {
        alertBadgeStyles: function () {
            return {
                backgroundColor: "".concat(this.$vuetify.theme.themes.light.primary, " !important"),
                color: "white",
                padding: "0 5px 2px 5px",
                borderRadius: "4px",
            };
        },
    },
    setup: function (props) {
        var reactiveAlertTipp = reactive(props.alertTipp);
        var _a = toRefs(reactiveAlertTipp), message = _a.message, selectorLabel = _a.label, selector = _a.selector, actionBeforeCall = _a.actionBeforeCall;
        return {
            message: message,
            selectorLabel: selectorLabel,
            selector: selector,
            actionBeforeCall: actionBeforeCall,
        };
    },
});
