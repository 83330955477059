import { computed, defineComponent, ref, } from "vue";
import { useIsBreakpointInNames } from "features/common/vuetify/useVuetify";
import GalleryViewPreview from "features/gallery/view/GalleryViewPreview.vue";
import GalleryViewGrid from "features/gallery/view/GalleryViewGrid.vue";
import CloseButton from "features/common/ui/CloseButton.vue";
import "features/gallery/gallery.types";
import "core/event-bus/event-bus";
import CloseableDialog from "components/UI/CloseableDialog.vue";
export default defineComponent({
    name: "GalleryView",
    components: {
        CloseableDialog: CloseableDialog,
        CloseButton: CloseButton,
        GalleryViewGrid: GalleryViewGrid,
        GalleryViewPreview: GalleryViewPreview,
    },
    props: {
        loadGalleryMedia: {
            type: Function,
        },
        totalGalleryMedia: {
            type: Number,
            default: 0,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
        optimisticUpdate: {
            type: Boolean,
            default: false,
        },
        openEvent: {
            type: Object,
            default: null,
        },
        id: {
            type: String,
            required: true,
        },
        isHighlightGallery: {
            type: Boolean,
            default: true,
        },
    },
    setup: function (props) {
        var previewedGalleryMedia = ref(null);
        if (props.openEvent) {
            props.openEvent.on(function (value) {
                dialog.value = value;
            });
        }
        var isMobile = useIsBreakpointInNames(["xs", "sm"]);
        var isTabletLandscape = useIsBreakpointInNames(["md"]);
        var hidePreview = computed(function () { return isMobile.value || isTabletLandscape.value; });
        var previewKey = ref(0);
        var gridKey = ref(0);
        var _dialog = ref(false);
        var dialog = computed({
            get: function () {
                return _dialog.value;
            },
            set: function (value) {
                _dialog.value = value;
                if (props.optimisticUpdate) {
                    previewKey.value++;
                    gridKey.value++;
                }
            },
        });
        return {
            previewedGalleryMedia: previewedGalleryMedia,
            hidePreview: hidePreview,
            isMobile: isMobile,
            dialog: dialog,
            previewKey: previewKey,
            gridKey: gridKey,
            id: props.id,
        };
    },
});
