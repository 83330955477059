import { __assign, __awaiter, __generator } from "tslib";
import Vue, { defineComponent, inject } from "vue";
import "./media.types";
import useTranslate from "features/common/translate/concerns/useTranslate";
import TextBody from "components/UI/TextBody.vue";
import Header from "components/UI/Header.vue";
import vue2Dropzone from "vue2-dropzone";
import { mdiFileUploadOutline } from "@mdi/js";
import { driveApi } from "store/api";
import "features/media/media-uploader.service";
import "suus-api/dist/api";
import { UploadWaitingList } from "features/media/upload-waiting-list";
var MediaUploaderComponent = defineComponent({
    name: "MediaUploader",
    props: {
        existingUploads: {
            type: Number,
            default: 0,
        },
        onlyImages: {
            type: Boolean,
            default: false,
        },
        titleOverride: {
            type: String,
            default: undefined,
        },
        validateFileBeforeUpload: {
            type: Function,
            default: function () { return true; },
        },
    },
    components: {
        TextBody: TextBody,
        Header: Header,
        vueDropzone: vue2Dropzone,
    },
    computed: {
        uploadsArray: function () {
            return Object.values(this.uploads);
        },
        totalProgress: function () {
            var progressSumFunc = function (acc, el) { return acc + el.uploadProgress; };
            var accInit = 0;
            var progress = this.uploadsArray.reduce(progressSumFunc, accInit) /
                this.uploadsArray.length;
            var result = isNaN(progress) ? 0 : progress;
            if (result > 0 && result < 100) {
                this.$emit("saving", true);
            }
            this.$emit("progress", result);
            return result;
        },
        // eslint-disable-next-line @typescript-eslint/ban-types
        handlers: function () {
            var _this = this;
            return {
                "vdropzone-file-added": this.fileAdded,
                "vdropzone-thumbnail": function (file, dataUrl) {
                    if (typeof dataUrl === "object" && dataUrl instanceof Event) {
                        // A event is returned in case no preview URL could be generated, e.g. for TIFF or RAW files
                        _this.updateUpload({ thumbDataUrl: null })(file.token);
                    }
                    else {
                        _this.updateUpload({ thumbDataUrl: dataUrl })(file.token);
                    }
                },
                "vdropzone-processing": function (file) {
                    return _this.updateUpload({ processing: true })(file.token);
                },
                "vdropzone-sending": function (file) {
                    return _this.updateUpload({ sending: true })(file.token);
                },
                "vdropzone-success": function (file, response) {
                    return _this.updateUpload({ success: response })(file.token);
                },
                "vdropzone-error": function (file, message) {
                    if (message === "You can't upload files of this type.") {
                        _this.removeUpload(file.token);
                        return;
                    }
                    _this.updateUpload({ errors: [message] })(file.token);
                },
                "vdropzone-upload-progress": function (file, progress) {
                    return _this.updateUpload({ uploadProgress: progress })(file.token);
                },
                "vdropzone-queue-complete": function () {
                    _this.finishSaving();
                    _this.validate();
                },
            };
        },
    },
    data: function () {
        var _this = this;
        return {
            dropzoneOptions: {
                url: "toBeSetByGetSignedUrl",
                maxFiles: null,
                maxFilesize: 2000,
                maxThumbnailFilesize: 20,
                previewTemplate: "<span></span>",
                createImageThumbnails: true,
                chunking: false,
                timeout: 1000 * 60 * 60,
                autoProcessQueue: true,
                acceptedFiles: this.onlyImages ? "image/*" : undefined,
                // capture: "camera",
                dictResponseError: "Fehler beim Hochladen ({{statusCode}})!",
                parallelUploads: 1,
                headers: {
                    "X-CSRF-Token": Vue.prototype.$http.defaults.headers.common["X-CSRF-Token"],
                },
                sending: function (file, xhr, formData) {
                    return _this.appendS3SignatureToFormData(file, xhr, formData);
                },
                renameFile: function (f) { return _this.renameFile(f); },
            },
            s3Signature: {},
            valid: false,
            uploads: {},
            timeout: 5000,
        };
    },
    mounted: function () {
        var _this = this;
        var _a;
        this.getPresignedS3Url();
        (_a = this.mediaSerivce) === null || _a === void 0 ? void 0 : _a.addOnResetFunction(function () {
            _this.uploadsArray.forEach(function (upload) {
                _this.removeUpload(upload.file.token);
            });
        });
    },
    setup: function () {
        var mediaSerivce = inject("mediaService", null);
        var _a = useTranslate("components.media_uploader"), tc$ = _a.tc$, t$ = _a.t$;
        var waitingList = new UploadWaitingList();
        return { tc$: tc$, t$: t$, mdiFileUploadOutline: mdiFileUploadOutline, mediaSerivce: mediaSerivce, waitingList: waitingList };
    },
    methods: {
        getPresignedS3Url: function () {
            return __awaiter(this, void 0, Promise, function () {
                var data, postEndpoint, signature;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, driveApi.getUploadURL()];
                        case 1:
                            data = (_a.sent()).data;
                            postEndpoint = data.postEndpoint, signature = data.signature;
                            this.s3Signature = signature;
                            this.dropzoneOptions.url = postEndpoint;
                            return [2 /*return*/];
                    }
                });
            });
        },
        appendS3SignatureToFormData: function (file, xhr, formData) {
            for (var _i = 0, _a = Object.entries(this.s3Signature); _i < _a.length; _i++) {
                var _b = _a[_i], k = _b[0], v = _b[1];
                formData.append(k, v);
            }
        },
        createToken: function () {
            return Math.random().toString(36).substring(2);
        },
        renameFile: function (file) {
            var splittedFile = file.name.split(".");
            if (splittedFile.length <= 1) {
                return this.createToken();
            }
            var ext = splittedFile.slice(-1)[0];
            return this.createToken() + "." + ext;
        },
        fileAdded: function (file) {
            var isFileValid = this.validateFileBeforeUpload(file);
            if (!isFileValid) {
                console.warn("File is not valid, removing it from the list.");
                this.removeFile(file);
                return;
            }
            file.token = this.createToken();
            var upload = {
                file: file,
                name: file.name,
                success: undefined,
                complete: false,
                errors: [],
                sending: false,
                canceled: false,
                processing: false,
                uploadProgress: 0,
                thumbDataUrl: undefined,
                formData: undefined,
            };
            this.$set(this.uploads, file.token, __assign(__assign({}, this.uploads[file.token]), upload));
        },
        updateUpload: function (obj) {
            var _this = this;
            return function (token) {
                if (!_this.uploads[token]) {
                    return;
                }
                if (obj) {
                    var newUpload = __assign(__assign({}, _this.uploads[token]), obj);
                    // If success or errors are set, we finish the waiting
                    if (obj.errors || obj.s3Key) {
                        _this.waitingList.finishWaiting(_this.uploads[token].file, newUpload);
                    }
                    _this.$set(_this.uploads, token, newUpload);
                }
                else {
                    _this.removeUpload(token);
                }
                _this.$emit("uploads", _this.uploadsArray);
            };
        },
        removeUpload: function (token) {
            var upload = this.uploads[token];
            if (!upload)
                return;
            if (upload.file)
                this.removeFile(upload.file);
            this.$delete(this.uploads, token);
            this.$emit("uploads", this.uploadsArray);
        },
        removeFile: function (file) {
            var _a;
            ;
            (_a = this.$refs.dropzone) === null || _a === void 0 ? void 0 : _a.removeFile(file);
        },
        handleUploadError: function (error, token) {
            this.updateUpload({ errors: [error] })(token);
        },
        finishSaving: function () {
            this.$emit("saving", false);
        },
        validate: function () {
            var isS3KeyAvailable = this.uploadsArray.every(function (u) { return u.s3Key; });
            if (!isS3KeyAvailable) {
                for (var _i = 0, _a = this.uploadsArray; _i < _a.length; _i++) {
                    var upload = _a[_i];
                    if (!upload.s3Key && upload.success) {
                        try {
                            var parser = new DOMParser();
                            var xmlDoc = parser.parseFromString(upload.success, "text/xml").documentElement;
                            var s3Key = xmlDoc.getElementsByTagName("Key")[0].textContent;
                            this.updateUpload({ s3Key: s3Key })(upload.file.token);
                        }
                        catch (e) {
                            this.updateUpload({ errors: [e.message] })(upload.file.token);
                        }
                    }
                }
            }
            var isNowS3KeyAvailable = this.uploadsArray.every(function (u) { return u.s3Key; });
            this.valid =
                (this.uploadsArray.length > 0 &&
                    this.totalProgress === 100 &&
                    isNowS3KeyAvailable) ||
                    this.uploadsArray.length === 0;
            this.$emit("valid", this.valid);
        },
        reset: function () {
            this.valid = false;
            this.uploads = {};
        },
        retry: function (token) {
            var _a;
            var upload = this.uploads[token];
            upload.errors = [];
            upload.file.status = "queued";
            (_a = this.$refs.dropzone) === null || _a === void 0 ? void 0 : _a.processQueue();
        },
        // Returns a Promise which resolves to the s3/stored file key
        manuallyAddFileAndUpload: function (file) {
            var _a;
            var promise = this.waitingList.startWaiting(file);
            (_a = this.$refs.dropzone) === null || _a === void 0 ? void 0 : _a.dropzone.addFile(file);
            return {
                storedFile: promise,
                upload: file,
            };
        },
        manuallyOpenFileBrowser: function () {
            var _a;
            ;
            (_a = this.$refs.dropzone) === null || _a === void 0 ? void 0 : _a.dropzone.hiddenFileInput.click();
        },
        manuallyDeleteUpload: function (tokenToDelete) {
            var upload = this.uploadsArray.find(function (upload) { return upload.file.token === tokenToDelete; });
            if (upload) {
                this.removeFile(upload.file);
                this.uploads = Object.fromEntries(Object.entries(this.uploads).filter(function (_a) {
                    var token = _a[0], _ = _a[1];
                    return token !== tokenToDelete;
                }));
            }
        },
        manuallyReset: function () {
            var _a;
            this.reset();
            (_a = this.$refs.dropzone) === null || _a === void 0 ? void 0 : _a.dropzone.removeAllFiles();
        },
    },
    watch: {
        totalProgress: {
            immediate: true,
            handler: function () {
                this.validate();
            },
        },
        uploads: function () {
            this.validate();
        },
    },
});
export default MediaUploaderComponent;
