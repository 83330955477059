/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from "vue"
import moment from "moment"
import vueMoment from "vue-moment"
import "moment/locale/de"

moment.locale("de")
Vue.use(vueMoment, { moment })

export default moment
