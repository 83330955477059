import { defineComponent, inject } from "vue";
import "concerns/useContextName";
import TextBody from "components/UI/TextBody.vue";
import PostGalleryView from "features/posts/gallery/PostGalleryView.vue";
import "suus-api";
import GalleryShowMore from "features/gallery/GalleryShowMore.vue";
export default defineComponent({
    name: "PostGalleryShowMore",
    components: { PostGalleryView: PostGalleryView, TextBody: TextBody, GalleryShowMore: GalleryShowMore },
    props: {
        post: { type: Object, required: true },
        totalElements: { type: Number, required: true },
        size: { type: Number, default: 128 },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var viewContext = inject("viewContext");
        if (viewContext === undefined)
            throw new Error("viewContext is undefined");
        var emitReorder = function (event) {
            emit("reorder", event);
        };
        return {
            viewContext: viewContext,
            emitReorder: emitReorder,
        };
    },
});
