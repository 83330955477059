import { defineComponent } from "vue";
export default defineComponent({
    name: "MenuItem",
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        action: {
            type: Function,
            required: true,
        },
        isActive: {
            type: Function,
            default: null,
        },
        badge: {
            type: Object,
            default: null,
        },
    },
});
