<template lang="pug">
.ui.container
  h1.ui.header {{$t('level_stats.header')}}
    .sub.header {{$t('level_stats.subheader')}}

  p(v-if="error_msg") {{$t('level_stats.error', {error: error_msg})}}

  .ui.stackable.grid
    .row
      .column.eight.wide
        h2.ui.header(v-html="$t('level_stats.by_status.header_html', {total: total_members})")

        pie-chart(
          :data="status_stats.stats"
        )

      .column.eight.wide
        h2.ui.header(v-html="$t('level_stats.by_age.header_html', {avg: avg_age})")

        column-chart(
          :data="age_stats.stats"
          :label="$t('level_stats.by_age.label')"
          :xtitle="$t('level_stats.by_age.xtitle')"
          :ytitle="$t('level_stats.by_age.ytitle')"
        )

        p.text-right.secondary(v-if="(age_stats.invalid.length)")
          | {{ $t('level_stats.no_data') }}
          | {{ invalids(age_stats) }}


    .row
      .column.sixteen.wide
        h2.ui.header {{$t('level_stats.by_rec.header')}}

        area-chart(
          :data="recipiert_stats.stats"
          :label="$t('level_stats.by_rec.label')"
          :xtitle="$t('level_stats.by_rec.xtitle')"
          :ytitle="$t('level_stats.by_rec.ytitle')"
        )

        p.text-right.secondary(v-if="(recipiert_stats.invalid.length)")
          | {{ $t('level_stats.no_data') }}
          | {{ invalids(recipiert_stats) }}


    .row
      .column.sixteen.wide
        h2.ui.header {{$t('level_stats.origin_clients.header')}}
          a(:href="ersband_url")
            sui-icon(name="suus-hit-down")

        pie-chart(
          :data="origin_clients.stats"
          legend="right"
      )

    .row
      .column.sixteen.wide
        h2.ui.header {{$t('level_stats.clubs_of_members.header')}}
          a(:href="clubs_url")
            sui-icon(name="suus-hit-down")

        pie-chart(
          :data="clubs_of_members.stats"
          legend="right"
      )

    .row
      .column.eight.wide
        h2.ui.header {{$t('level_stats.by_usage.header')}}

        pie-chart(
          :data="usage_stats.stats"
        )

        p.text-right.secondary(v-if="(usage_stats.invalid.length)")
          | {{ $t('level_stats.no_data') }}
          | {{ invalids(usage_stats) }}


      .column.eight.wide
        h2.ui.header {{$t('level_stats.by_pickup.header')}}

        area-chart(
          :data="pickup_stats.stats"
          :label="$t('level_stats.by_pickup.label')"
        )

        p.text-right.secondary(v-if="(pickup_stats.invalid.length)")
          | {{ $t('level_stats.no_data') }}
          | {{ invalids(pickup_stats) }}


    .row
      .column.eight.wide
        h2.ui.header {{$t('level_stats.by_summary_freq.header')}}

        pie-chart(
          :data="summary_freq_stats.stats"
          legend="bottom"
        )


      .column.eight.wide
        h2.ui.header {{$t('level_stats.by_meantime.header')}}

        pie-chart(
          :data="login_by_meantime.stats"
          legend="bottom"
      )
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "LevelStats",
  props: ["level_id", "system_name", "total_members", "avg_age"],

  data() {
    return {
      error_msg: "",
      status_stats: { stats: null, invalid: [] },
      age_stats: { stats: null, invalid: [] },
      recipiert_stats: { stats: null, invalid: [] },
      usage_stats: { stats: null, invalid: [] },
      pickup_stats: { stats: null, invalid: [] },
      summary_freq_stats: { stats: null, invalid: [] },
      login_by_meantime: { stats: null, invalid: [] },
      origin_clients: { stats: null, invalid: [] },
      clubs_of_members: { stats: null, invalid: [] },
    }
  },

  computed: {
    ersband_url() {
      return `/api/v1/stats/level/origin_clients.csv?level_id=${this.level_id}`
    },

    clubs_url() {
      return `/api/v1/stats/level/clubs_of_members.csv?level_id=${this.level_id}`
    },
  },

  created() {
    this.loadStatusStats()
    this.loadAgeStats()
    this.loadRecipiertStats()
    this.loadUsageStats()
    this.loadPickupStats()
    this.loadSummaryFreqStats()
    this.loadLoginByMeantimeStats()
    this.loadOriginClientsStats()
    this.loadClubsOfMembersStats()
  },

  methods: {
    invalids(data) {
      if (data && "invalid" in data) {
        if (data.invalid.length - 8 > 0) {
          let fewNames = data.invalid
            .slice(0, 8)
            .map(function (m) {
              return `${m.first_name} ${m.last_name}`
            })
            .join(", ")
          let counter = ` und ${data.invalid.length - 8} weitere`
          return fewNames + counter
        } else {
          return data.invalid
        }
      } else {
        return ""
      }
    },

    loadStatusStats() {
      let url = `/api/v1/stats/level/status?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.status_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadAgeStats() {
      let url = `/api/v1/stats/level/age?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.age_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadLoginByMeantimeStats() {
      let url = `/api/v1/stats/level/logins/by_meantime?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.login_by_meantime = this.labelData(
            "level_stats.login_by_meantime.label",
            data
          )
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadOriginClientsStats() {
      let url = `/api/v1/stats/level/origin_clients?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          data["stats"] = this.sumLastKeys(data["stats"])
          this.origin_clients = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadClubsOfMembersStats() {
      let url = `/api/v1/stats/level/clubs_of_members?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          data["stats"] = this.sumLastKeys(data["stats"])
          this.clubs_of_members = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadRecipiertStats() {
      let url = `/api/v1/stats/level/recipiert?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.recipiert_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadSummaryFreqStats() {
      let url = `/api/v1/stats/level/summary/freq?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.summary_freq_stats = this.labelData(
            "level_stats.summary_freq.label",
            data
          )
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadUsageStats() {
      let url = `/api/v1/stats/level/usage?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.usage_stats = this.labelData(
            "level_stats.usage_stats.label",
            data
          )
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    loadPickupStats() {
      let url = `/api/v1/stats/level/pickup?level_id=${this.level_id}`
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.pickup_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },

    labelData(locale, data) {
      let stats = {}
      for (let [k, v] of Object.entries(data.stats)) {
        let label = this.$t(`${locale}_${k}`)
        stats = { ...stats, [label]: v }
      }
      return { stats: stats, invalid: [] }
    },

    sumLastKeys(data) {
      let lastKeys = Object.keys(data)
      lastKeys = lastKeys.slice(25, lastKeys.length)
      let others = 0

      lastKeys.forEach((lastKey) => {
        others += data[lastKey]
        delete data[lastKey]
      })
      if (others != 0) {
        data["weitere"] = others
      }
      return data
    },
  },
})
</script>

<style scoped></style>
