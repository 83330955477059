import "features/attachments/attachment.types";
function isImage(attachment) {
    var _a, _b, _c;
    return (attachment.attachmentType == "Image" ||
        ((_a = attachment.mimeContentType) === null || _a === void 0 ? void 0 : _a.startsWith("image/")) ||
        ((_c = (_b = attachment.file) === null || _b === void 0 ? void 0 : _b.type) === null || _c === void 0 ? void 0 : _c.startsWith("image/")));
}
var AttachmentFunctions = /** @class */ (function () {
    function AttachmentFunctions() {
    }
    AttachmentFunctions.isImage = isImage;
    return AttachmentFunctions;
}());
export { AttachmentFunctions };
