import { defineComponent, useSlots } from "vue";
import theme from "constants/theme";
export default defineComponent({
    name: "DriveUploadCard",
    props: {
        fileName: {
            type: String,
        },
        success: {
            type: Boolean,
        },
        errors: {
            type: Array,
            default: function () {
                return [];
            },
        },
        thumbnailUrl: {
            type: String,
        },
        fileExtension: {
            type: String,
        },
        progress: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        topBarBackgroundColor: function () {
            // This now returns a constant or status-based color without progress consideration
            if (this.errors.length > 0) {
                return theme.colors.light.error;
            }
            else if (this.success) {
                return theme.colors.light.highlight;
            }
            else {
                return theme.colors.light.primary;
            }
        },
        progressBarStyle: function () {
            return {
                width: "".concat(this.progress, "%"),
                backgroundColor: theme.colors.light.highlight,
                transition: "width 0.5s ease-in-out",
                height: "100%",
            };
        },
        primaryColor: function () {
            return theme.colors.light.primary;
        },
        themeFont: function () {
            return theme.typography.fontFamily;
        },
        errorColor: function () {
            return theme.colors.light.error;
        },
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        },
        extension: function () {
            var fileNameSplitted = this.fileName.split(".");
            var lastElement = fileNameSplitted[fileNameSplitted.length - 1];
            if (fileNameSplitted.length <= 1 || lastElement.length > 3) {
                return "FILE";
            }
            return lastElement.toUpperCase();
        },
    },
    setup: function () {
        var slots = useSlots();
        return {
            slots: slots,
        };
    },
});
