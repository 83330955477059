window.initZendeskHelp = (client_name, full_name, email) ->
  window.zESettings =
    webWidget:
      color:
        theme: '#307EF7'
      contactForm:
        fields: [
          { id: "360006592092", prefill: { '*': client_name } }
        ]
  if(typeof zE != 'undefined')
    zE ->
      zE.identify name: full_name, email: email
      zE.setLocale 'de-de'
      defaultQuery = document.title.replace('Corpshaus 2.0', '')
      console.log defaultQuery
      zE.setHelpCenterSuggestions({ search: defaultQuery })
  else
    console.log 'Zendesk could ne be instantiated!'