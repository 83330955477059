import { __assign, __awaiter, __generator } from "tslib";
import Vue, { onMounted, ref, toRef, watch } from "vue";
import THEME from "../../constants/theme";
import TextHTMLAttachmentListItem from "components/TextHTMLAttachmentListItem.vue";
var text = THEME.typography.text;
export default Vue.extend({
    name: "TextHTML",
    components: {
        TextHTMLAttachmentListItem: TextHTMLAttachmentListItem,
    },
    props: {
        html: String,
        customClass: { type: String, default: "" },
        renderComponents: {
            type: Function,
            default: function (_) { return Promise.resolve(); },
        },
    },
    computed: {
        htmlStyles: function () {
            var styles = __assign(__assign({}, text), { color: this.$vuetify.theme.themes.light.textColor });
            return styles;
        },
    },
    setup: function (props) {
        var _this = this;
        var rootRef = ref();
        var html = toRef(props, "html");
        onMounted(function () {
            watch(html, function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!props.renderComponents) return [3 /*break*/, 2];
                            return [4 /*yield*/, props.renderComponents(rootRef)];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, { immediate: true });
        });
        return {
            rootRef: rootRef,
        };
    },
});
