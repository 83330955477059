var MediaUploaderService = /** @class */ (function () {
    function MediaUploaderService() {
        this.callbackFunctions = [];
    }
    MediaUploaderService.prototype.addOnResetFunction = function (callbackFunction) {
        this.callbackFunctions.push(callbackFunction);
    };
    MediaUploaderService.prototype.reset = function () {
        this.callbackFunctions.forEach(function (callbackFunction) {
            callbackFunction();
        });
    };
    return MediaUploaderService;
}());
export { MediaUploaderService };
