import Vue from "vue";
export default Vue.extend({
    name: "ConfettiSearch",
    data: function () {
        var _a;
        return {
            primaryColor: (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString(),
        };
    },
    methods: {
        getColor: function (alphaString) {
            if (alphaString === void 0) { alphaString = "1"; }
            if (!this.primaryColor)
                return "";
            var r = parseInt(this.primaryColor.slice(1, 3), 16), g = parseInt(this.primaryColor.slice(3, 5), 16), b = parseInt(this.primaryColor.slice(5, 7), 16);
            return "rgba(" + r + ", " + g + ", " + b + ", " + alphaString + ")";
        },
    },
});
