import { defineComponent } from "vue";
export default defineComponent({
    name: "WidthFullIcon",
    props: {
        fill: {
            type: String,
            default: "#5f6368",
        },
    },
});
