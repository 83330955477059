import { defineComponent } from "vue";
import { mdiStar } from "@mdi/js";
import { useVuetify } from "features/common/vuetify/useVuetify";
export default defineComponent({
    name: "EventHighlightsIcon",
    props: {
        highlightsCount: {
            type: Number,
            required: true,
        },
    },
    setup: function (props) {
        var iconColor = useVuetify().theme.themes.light.textColor;
        return {
            mdiStar: mdiStar,
            iconColor: iconColor,
        };
    },
});
