import { __assign } from "tslib";
import Vue from "vue";
export default Vue.extend({
    name: "Header",
    props: {
        text: String,
        level: { type: Number, default: 1 },
        customClass: { type: String, default: "" },
        customStyles: { type: Object, required: false },
        customSkeletonClass: { type: String, default: "" },
        bold: { type: Boolean, default: true },
        color: { type: String, default: "textColor" },
        isLoading: { type: Boolean, default: false },
    },
    computed: {
        setColor: function () {
            var _a, _b;
            switch (this.color) {
                case "primary":
                    return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
                case "textColor":
                    return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
                default:
                    return this.color;
            }
        },
        setFontWeight: function () {
            if (this.bold) {
                return 900;
            }
            return 300;
        },
        styleObject: function () {
            return __assign(__assign({}, this.customStyles), { color: this.setColor, fontWeight: this.setFontWeight, fontFamily: "system-ui !important" });
        },
        classArray: function () {
            return ["text-h".concat(this.level, " suus__header"), "".concat(this.customClass)];
        },
        skeletonClasses: function () {
            return [
                "suus__header__skeleton skeleton--".concat(this.level),
                this.customSkeletonClass,
            ];
        },
        h1Styles: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return {
                        fontFamily: "system-ui !important",
                        fontSize: "1.8rem !important",
                        lineHeight: "2.4rem !important",
                    };
                default:
                    return {
                        fontFamily: "system-ui !important",
                        fontSize: "2.5rem !important",
                        lineHeight: "3.5rem !important",
                    };
            }
        },
    },
});
