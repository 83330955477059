<template lang="pug">
Inode(v-bind="settings")
  template(#filename)
    template(v-if="isEmpty") {{ inode.name }}
    template(v-else)
      | {{ inode.name }}
      i(v-if="inode.latest_drive_file.name")
        |
        | ({{inode.latest_drive_file.name}})
      |
      | vom
      |
      strong {{inode.latest_drive_file.physically_created_at_formatted}}

  template(#actions)
    template(v-if="isEmpty")
      a.ui.icon.button.disabled(v-tooltip="$t('drive.folder.empty.tooltip')")
        i.ui.icon.suus-hit-down
        | {{$t('drive.folder.empty.text')}}

    template(v-else)
      a.ui.icon.button(
        :class="{disabled: !inode.latest_drive_file}"
        v-tooltip="inode.latest_drive_file ? '' : $t('drive.folder.empty.tooltip')"
        :href="inode.latest_drive_file ? inode.latest_drive_file.download_url : '#'"
      )
        i.ui.icon.suus-hit-down
        span.mobile.hidden {{$t('drive.downloadButton.text')}}

    router-link.ui.icon.button(:to="{name: 'drive/folder', params: {inode_id: inode.id}}")
      i.ui.icon.suus-single-copies
      | {{$t('drive.container.olderLink')}}
</template>

<script>
import ListItem from "../../ListItem.vue"
import InodeIcon from "../InodeIcon.vue"
import Inode from "./Inode.vue"
export default {
  name: "PinnedContainer",
  props: {
    inode: { type: Object, required: true },
    show: {
      type: Object,
      default() {
        return {
          image: true,
          path: false,
          category: false,
          physically_created_at: false,
          inodes_count: true,
          latest_drive_file: true,
          actions: true,
        }
      },
    },
  },
  components: { Inode, ListItem, InodeIcon },
  computed: {
    isEmpty() {
      return !this.inode.latest_drive_file
    },
    settings() {
      return {
        inode: this.inode,
        inodeLink: this.inodeLink,
        show: this.show,
      }
    },
    inodeLink() {
      if (this.isEmpty) {
        return { name: "drive/folder", params: { inode_id: this.inode.id } }
      } else {
        return {
          name: "drive/file",
          params: { inode_id: this.inode.latest_drive_file.id },
        }
      }
    },
  },
}
</script>
