import { __awaiter, __generator, __spreadArray } from "tslib";
import "features/gallery/service/gallery.service";
import { computed, ref } from "vue";
import "suus-api";
import { useFetchPreviewHighlights } from "features/events/highlights/concerns/use-fetch-preview-highlights";
export var useHighlightsWithOptimism = function (eventId, galleryService) {
    var _a = useFetchPreviewHighlights(eventId), isLoading = _a.isLoading, error = _a.error, highlights = _a.highlights, loadHighlights = _a.loadHighlights;
    var optimisticHighlights = ref([]);
    var _highlights = computed(function () {
        return __spreadArray(__spreadArray([], highlights.value, true), optimisticHighlights.value, true);
    });
    var _loadHighlights = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    optimisticHighlights.value = [];
                    return [4 /*yield*/, loadHighlights()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    galleryService.registerAddListener(function (highlight) {
        optimisticHighlights.value.push(highlight);
    });
    galleryService.registerRemoveListener(function () {
        _loadHighlights();
    });
    galleryService.registerReorderListener(function () {
        _loadHighlights();
    });
    var featuredImage = computed(function () { return _highlights.value[0]; });
    var gridHighlights = computed(function () { return _highlights.value.slice(1, 10); });
    return {
        highlights: gridHighlights,
        isLoading: isLoading,
        error: error,
        loadHighlights: _loadHighlights,
        featuredImage: featuredImage,
    };
};
