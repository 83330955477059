import { defineComponent, provide, ref, watch } from "vue";
import AttachmentUploader from "features/attachments/AttachmentUploader.vue";
import "components/Wysiwyg/extensions/suus-mention/suus-mention.config";
import { attachmentToAttachmentMediaUpload, } from "features/attachments/attachment.types";
import ForumWysiwyg from "components/Wysiwyg/ForumWysiwyg.vue";
import "suus-api";
export default defineComponent({
    name: "ForumWysiwygWithUploader",
    components: { ForumWysiwyg: ForumWysiwyg, AttachmentUploader: AttachmentUploader },
    props: {
        value: { type: String, default: "" },
        showControls: { type: Boolean, default: true },
        placeholder: {
            type: String,
            default: "Schreibe hier Deinen Beitrag. Du kannst Dateien und Fotos direkt hier hineinziehen oder kopieren, um sie einzubinden. Erwähne Personen, Ämter oder Gruppen mit einem @-Zeichen.",
        },
        inline: { type: Boolean, default: false },
        getMentionables: {
            type: Function,
            default: function (_) { return []; },
        },
        loadAttachment: {
            type: Function,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var editorValue = ref(props.value);
        watch(function () { return props.value; }, function (v) { return (editorValue.value = v); });
        watch(editorValue, function (v) { return emit("input", v); });
        var attachmentUploader = ref(null);
        var uploadedAttachments = ref([]);
        var retryUploadingAttachment = function (frontendId) {
            var _a, _b;
            var token = (_a = uploadedAttachments.value.find(function (u) { return u.value.frontendId === frontendId; })) === null || _a === void 0 ? void 0 : _a.value.file.token;
            if (token) {
                (_b = attachmentUploader.value) === null || _b === void 0 ? void 0 : _b.retryUpload(token);
            }
            else {
                console.warn("No token found while trying reloading attachment.");
            }
        };
        // Adds the file to the upload-list and afterwards performs the upload
        var uploadFile = function (file) {
            var ret = attachmentUploader.value.manuallyAddFileAndUpload(file);
            uploadedAttachments.value.push(ret);
            return ret;
        };
        provide("uploadFile", uploadFile);
        provide("loadAttachmentByFrontendId", function (frontendId) {
            var existingUpload = uploadedAttachments.value.find(function (u) { return u.value.frontendId === frontendId; });
            if (existingUpload) {
                return existingUpload;
            }
            else {
                var refAtt_1 = ref(undefined);
                props.loadAttachment(frontendId).then(function (a) {
                    refAtt_1.value = attachmentToAttachmentMediaUpload(a);
                });
                return refAtt_1;
            }
        });
        var cancelUpload = function (frontendId) {
            var _a;
            (_a = attachmentUploader.value) === null || _a === void 0 ? void 0 : _a.manuallyDeleteUpload(frontendId);
        };
        var emitAttachmentsValid = function (valid) {
            return emit("attachments-valid", valid);
        };
        return {
            attachmentUploader: attachmentUploader,
            emitAttachmentsValid: emitAttachmentsValid,
            retryUploadingAttachment: retryUploadingAttachment,
            cancelUpload: cancelUpload,
            editorValue: editorValue,
            uploadedAttachments: uploadedAttachments,
        };
    },
});
