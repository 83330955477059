<template lang="pug">
router-link.ui.icon.button.tiny(
  :to="{name: 'drive/folder', params: { inode_id: inode.id} }"
)
  slot {{ inode.name | truncate(30) }}
</template>

<script>
export default {
  name: "ContainerBreadcrumb",
  props: { inode: { type: Object, required: true } },
}
</script>

<style scoped></style>
