import Vue from "vue";
import Button from "./Button.vue";
import { mdiClose } from "@mdi/js";
export default Vue.extend({
    name: "Sheet",
    components: { Button: Button },
    props: {
        showSheet: { type: Boolean, default: false, required: true },
    },
    data: function () {
        return {
            closeIcon: mdiClose,
        };
    },
    computed: {
        sheet: {
            get: function () {
                return this.showSheet;
            },
            set: function (value) {
                this.$emit("changeVisibility", value);
            },
        },
        sheetStyles: function () {
            return {
                overflowY: "scroll",
                borderRadius: "4px 4px 0 0 !important",
                borderTop: "10px solid ".concat(this.$vuetify.theme.themes.light.primary),
            };
        },
        sheetMaxWidth: function () {
            return this.$vuetify.breakpoint.name === "sm" ? "90%" : "auto";
        },
    },
    methods: {
        closeSheet: function () {
            this.sheet = false;
        },
    },
});
