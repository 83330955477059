import { computed, ref } from "vue";
var FormService = /** @class */ (function () {
    function FormService() {
        var _this = this;
        this._rules = [];
        this.isEdit = computed(function () { var _a; return !!((_a = _this._form.value) === null || _a === void 0 ? void 0 : _a.id); });
        this.formError = ref(null);
        this._properties = [];
    }
    FormService.prototype.getFormValue = function (key) {
        var _this = this;
        return computed(function () { return _this._form.value[key]; });
    };
    FormService.prototype.setFormValue = function (key, value) {
        this._updateForm(key, value);
    };
    FormService.prototype.registerRule = function (rule) {
        this._rules.push(rule);
    };
    FormService.prototype.validate = function () {
        var _this = this;
        var failedRules = this._rules.filter(function (rule) { return !rule.action(_this._form.value[rule.field]); });
        if (this._validationWatcher) {
            this._validationWatcher(failedRules);
        }
        return !failedRules.length;
    };
    FormService.prototype.registerValidationWatcher = function (validator) {
        this._validationWatcher = validator;
    };
    FormService.prototype.setFormError = function (error) {
        this.formError.value = error;
    };
    FormService.prototype.registerProperty = function (property) {
        this._properties.push(property);
    };
    FormService.prototype.getFormValues = function () {
        var _this = this;
        var values = {};
        this._properties.forEach(function (property) {
            values[property.name] = _this.getFormValue(property.name).value;
        });
        return values;
    };
    return FormService;
}());
export default FormService;
