import { defineComponent, ref, toRef } from "vue";
import WidthNormalIcon from "components/Wysiwyg/extensions/attachment/attachment-item/WidthNormalIcon.vue";
import WidthFullIcon from "components/Wysiwyg/extensions/attachment/attachment-item/WidthFullIcon.vue";
import AttachmentIcon from "components/Wysiwyg/extensions/attachment/attachment-item/AttachmentIcon.vue";
import ImageModeMenuItem from "components/Wysiwyg/extensions/attachment/attachment-item/ImageModeMenuItem.vue";
import { mdiCheck, mdiClose } from "@mdi/js";
import "components/Wysiwyg/extensions/attachment/attachment-item/AttachmentItemNodeView.vue";
export default defineComponent({
    name: "AttachmentItemImageMode",
    components: {
        ImageModeMenuItem: ImageModeMenuItem,
        AttachmentIcon: AttachmentIcon,
        WidthFullIcon: WidthFullIcon,
        WidthNormalIcon: WidthNormalIcon,
    },
    props: {
        fileName: {
            type: String,
            required: true,
        },
        imageSrc: {
            type: String,
            required: true,
        },
        imageSize: {
            type: Number,
            required: false,
        },
        imageMode: {
            type: String,
            required: true,
        },
        selected: {
            type: Boolean,
            required: true,
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var nameIsEdited = ref(false);
        var startEditing = function () {
            nameIsEdited.value = true;
        };
        var nameRule = function (v) { return !!v || "Name wird benötigt."; };
        var editedFileName = ref(props.fileName);
        var closeEdit = function () {
            nameIsEdited.value = false;
            editedFileName.value = props.fileName;
        };
        var emitEditAndStopEditing = function () {
            if (!editedFileName.value) {
                closeEdit();
                return;
            }
            emit("fileNameChange", editedFileName.value);
            nameIsEdited.value = false;
        };
        var changeMode = function (mode) {
            emit("updateDisplayMode", mode);
            if (mode === "max-height") {
                emit("updateSize", "350");
            }
            else {
                emit("updateSize", undefined);
            }
        };
        var imgLoaded = ref(false);
        var setImgLoaded = function () {
            console.log("loaded");
            imgLoaded.value = true;
        };
        return {
            imageMode: toRef(props, "imageMode"),
            changeMode: changeMode,
            nameIsEdited: nameIsEdited,
            startEditing: startEditing,
            mdiCheck: mdiCheck,
            mdiClose: mdiClose,
            nameRule: nameRule,
            editedFileName: editedFileName,
            emitEditAndStopEditing: emitEditAndStopEditing,
            closeEdit: closeEdit,
            imgLoaded: imgLoaded,
            setImgLoaded: setImgLoaded,
        };
    },
});
