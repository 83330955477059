import Subtitle from "../../../../components/UI/Subtitle.vue";
import { defineComponent } from "vue";
import "features/events/form/FormExpansionPanel.vue";
import { PanelIdentifier } from "features/events/form/event-form.types";
import OrganizerFields from "components/EventForm/OrganizerFields.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import ToggleableTextField from "features/common/forms/form-fields/ToggleableTextField.vue";
import TitleTextField from "features/events/form/general-fields/TitleTextField.vue";
import AddressField from "features/events/form/general-fields/AddressField.vue";
import OnlineConferenceField from "features/events/form/general-fields/OnlineConferenceField.vue";
import DateAndTimeFields from "features/events/form/general-fields/DateAndTimeFields.vue";
import ParticipationCircleFields from "features/events/form/general-fields/ParticipationCircleFields.vue";
import DescriptionFields from "features/events/form/general-fields/DescriptionFields.vue";
var GeneralFields = defineComponent({
    name: "GeneralFields",
    components: {
        DescriptionFields: DescriptionFields,
        ParticipationCircleFields: ParticipationCircleFields,
        DateAndTimeFields: DateAndTimeFields,
        OnlineConferenceField: OnlineConferenceField,
        AddressField: AddressField,
        TitleTextField: TitleTextField,
        ToggleableTextField: ToggleableTextField,
        OrganizerFields: OrganizerFields,
        Subtitle: Subtitle,
    },
    computed: {
        panelIdentifier: function () {
            return PanelIdentifier.GENERAL;
        },
    },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        return {
            t$: t$,
        };
    },
});
export default GeneralFields;
export var GeneralFieldsDefinition = {
    key: "general",
    component: GeneralFields,
    id: PanelIdentifier.GENERAL,
};
