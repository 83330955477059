import { defineComponent } from "vue";
import InstallPWADesktopWizard from "features/push/InstallPWADesktopWizard.vue";
import InstallPWAMobileWizard from "features/push/InstallPWAMobileWizard.vue";
import { isIOS } from "features/push/concerns/platform";
export default defineComponent({
    name: "InstallPWADialog",
    components: { InstallPWAMobileWizard: InstallPWAMobileWizard, InstallPWADesktopWizard: InstallPWADesktopWizard },
    data: function () {
        return {
            isDialogOpen: false,
            dialogKey: 0,
        };
    },
    watch: {
        "$route.params": {
            handler: function () {
                if (window.location.hash === "#pwa-install") {
                    this.openWizard();
                }
            },
            deep: true,
            immediate: true,
        },
        isDialogOpen: function (newVal) {
            window.location.hash = newVal ? "#pwa-install" : "";
        },
    },
    methods: {
        openWizard: function () {
            this.dialogKey++;
            this.isDialogOpen = true;
        },
    },
    setup: function () {
        function isMobileDevice() {
            if (!("navigator" in window)) {
                return false;
            }
            var userAgent = navigator.userAgent.toLowerCase();
            var mobileKeywords = [
                "mobile",
                "iphone",
                "ipod",
                "android",
                "blackberry",
                "windows phone",
            ];
            for (var i = 0; i < mobileKeywords.length; i++) {
                if (userAgent.indexOf(mobileKeywords[i]) !== -1) {
                    return true; // Client is a mobile device
                }
            }
            return false; // Client is not a mobile device
        }
        return {
            isMobileDevice: isMobileDevice,
            isIOS: isIOS,
        };
    },
});
