import Vue from "vue";
export default Vue.extend({
    name: "ConfettiBag",
    props: {
        width: { type: String, default: "265" },
    },
    data: function () {
        return {
            primaryColor: this.$vuetify.theme.themes.light.primary,
            primaryLight: this.$vuetify.theme.themes.light.primaryLight,
            textColor: this.$vuetify.theme.themes.light.textColor,
        };
    },
    methods: {
        getColor: function (alphaString, color) {
            if (alphaString === void 0) { alphaString = "1"; }
            var r = parseInt(color.slice(1, 3), 16), g = parseInt(color.slice(3, 5), 16), b = parseInt(color.slice(5, 7), 16);
            return "rgba(" + r + ", " + g + ", " + b + ", " + alphaString + ")";
        },
    },
});
