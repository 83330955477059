window.initSelectableLists = (scope) ->
  setSelectableItemState = (item, state) ->
    checkbox = $(item).find('input[type="checkbox"]')
    isActive = checkbox.prop('checked')
    if(state != undefined)
      if(isActive)
        checkbox.prop('checked', false)
      else
        checkbox.prop('checked', 'checked')
      isActive = !isActive
    $(item).toggleClass 'active', isActive


  # Init selectable list
  $(scope).findIncludingSelf('.items.selectable:not(.vuejs)').each (_, el) ->
    $chkboxes = $(el).find('.item')
    lastChecked = null
    $chkboxes.click (e) ->
      if !lastChecked
        lastChecked = this
      if e.shiftKey
        start = $chkboxes.index(this)
        end = $chkboxes.index(lastChecked)
        $chkboxes.slice(Math.min(start, end)+1, Math.max(start, end)).each (_, e) ->
          setSelectableItemState e, true
      lastChecked = this

  $(scope).findIncludingSelf('.items.selectable .item').each (_, el) ->
    setSelectableItemState el

  $(scope).findIncludingSelf('.items.selectable .item').click (e) ->
    item = $(e.target).closest('.item')
    setSelectableItemState item, item.hasClass('active')


  openElement = false
  selector = '.items:not(.selectable):not(.vuejs) .item:has(.extra), ' +
             '.items.selectable:not(.vuejs) .item .icon.chevron'

  $(scope).findIncludingSelf(selector).click (e) ->
    el = e.target
    item = $(el).closest('.item')

    isSelectable = item.parent().hasClass 'selectable'
    isExpanded = item.hasClass('expanded')
    isActive = item.hasClass('active')
    isInactive = item.hasClass('inactive')

    e.stopPropagation() if isSelectable

    # prevent the title link to be followed when clicked, open only detail view instead
    if $(el).closest('a.title').length > 0
      e.preventDefault()
      e.stopPropagation()

    # Dont toggle open/closed when a button is clicked
    if $(el).closest('.ui.button').length > 0
      return

    # Add or remove highlight first
    unless isInactive || isSelectable
      if isActive
        item.removeClass('active')
        openElement = false
      else
        # close/unselect other items
        item.addClass('active')
        $(openElement).trigger('click') unless isSelectable && openElement
        openElement = item

    # add classes indicating opening/closing action
    if isExpanded
      item.addClass('closing')
    else
      item.addClass('opening')

    # When toggle finished, remove/add state classes
    item.find('.description, .extra').slideToggle isExpanded, ->
      if isExpanded
        item.removeClass('expanded')
        item.removeClass('closing')
      else
        item.addClass('expanded')
        item.removeClass('opening')

      if isActive && !isSelectable
        item.removeClass('active')
