<template lang="pug">
.ui.large.subsub.menu.attached.borderless.vertical
  .item.header.title.mobile.and.tablet.only Dateiablage
  .item.header Eigene Ordner

  template(v-if="inodes !== undefined")
    router-link.item(
      :to="{path: '/drive'}"
      :class="{ active: !currentCategory || !$route.params.inode_id}"
    ) Neueste Dateien

    router-link.item(
      v-for="inode in localCategories"
      v-bind:key="inode.id"
      :class="activeCategory(inode)"
      :to="{path: `/drive/folders/${inode.id}`, params: { inode_id: inode.id} }"
    )  {{ inode.name }}


    .item.header Geteilte Ordner

    router-link.item(
      v-for="inode in sharedCategories"
      v-bind:key="inode.id"
      :class="activeCategory(inode)"
      :to="{path: `/drive/folders/${inode.id}`, params: { inode_id: inode.id} }"
    )
      sui-icon(name="suus-connect")
      | {{ inode.name }}

    template(v-if="createCategoryUrl")
      .ui.divider
      router-link.item(
        :to="{name: 'drive/categories/new'}"
      )
        i.ui.icon.suus-circle-add
        | Neuen Wurzelordner erstellen

  template(v-else)
    .ui.placeholder
      div
      .header(v-for="_ in [1, 2, 3, 4, 5]")
        .line
        .line
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"

export default {
  name: "Navigation",
  props: ["slideout"],
  computed: {
    localCategories() {
      return Object.values(this.inodes)
        .filter((c) => !c.parent_id && !c.level)
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.order - b.order)
    },
    sharedCategories() {
      return Object.values(this.inodes)
        .filter((c) => !c.parent_id && c.level)
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => a.order - b.order)
    },
    ...mapState("drive", ["inodes", "createCategoryUrl"]),
    ...mapGetters("drive", ["currentCategory", "path"]),
  },
  methods: {
    activeCategory(inode) {
      return {
        active:
          this.currentCategory &&
          inode.id === this.currentCategory.id &&
          this.$route.params.inode_id,
      }
    },
    ...mapActions("drive", ["loadCategories"]),
  },
  watch: {
    async $route() {
      this.slideout.close()
    },
  },
  created() {
    this.$store.dispatch("drive/loadCategories")
  },
}
</script>

<style>
@media screen and (max-width: 780px) {
  body {
    min-height: 100vh;
  }

  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 0;
    width: 300px !important;
    height: 100%;
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: block;
    padding: 3rem 0 !important;
    padding-top: 0 !important;
  }

  .slideout-move.slideout-menu {
    z-index: 3;
    will-change: transform;
  }

  .slideout-move.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
  }

  .slideout-move.slideout-menu-left {
    left: -300px;
  }

  .slideout-move.slideout-menu-right {
    right: -300px;
  }

  .slideout-panel {
    background-color: #fff; /* A background-color is required */
    min-height: 100vh;
  }

  .slideout-dimmer {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }

  .slideout-open .slideout-menu {
    display: block;
  }

  .slideout-open .slideout-dimmer {
    visibility: visible;
    opacity: 1;
    z-index: 2;
  }
}
</style>
