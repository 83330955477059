import { __assign } from "tslib";
import { defineComponent } from "vue";
import "interfaces/reactionOption.interface";
import { REACTION_OPTIONS } from "../../constants/reactions";
import THEME from "../../constants/theme";
import ReactionIcon from "components/ReactionIcon/ReactionIcon.vue";
import "./reaction-count.types";
var _a = THEME.typography, fontFamily = _a.fontFamily, fontWeightRegular = _a.fontWeightRegular;
export default defineComponent({
    name: "ReactionCountFull",
    components: {
        ReactionIcon: ReactionIcon,
    },
    props: {
        topics: {
            type: Array,
            default: function () { return []; },
        },
        likesCount: { type: Number, default: 0 },
        ideasCount: { type: Number, default: 0 },
        cheersCount: { type: Number, default: 0 },
        memberReactions: { type: Array, default: function () { return []; } },
    },
    computed: {
        reactionOptions: function () {
            return REACTION_OPTIONS.filter(function (option) { return option.name !== "default"; });
        },
        reactionsWithCounter: function () {
            var _this = this;
            return this.reactionOptions.map(function (option) {
                return __assign(__assign({}, option), { count: _this.mapCountToOption(option.name) });
            });
        },
        listStyles: function () {
            return {
                listStyleType: "none",
                margin: 0,
                padding: 0,
                minHeight: "24px",
            };
        },
        listItemStyles: function () {
            return {
                fontFamily: fontFamily,
                fontSize: "0.8rem",
                fontWeight: fontWeightRegular,
                color: this.$vuetify.theme.themes.light.textColor,
                margin: 0,
            };
        },
        getMemberList: function () {
            var _this = this;
            return function (icon) { return _this.memberReactions.filter(function (reaction) { return reaction.reaction === icon; }); };
        },
    },
    methods: {
        mapCountToOption: function (optionName) {
            switch (optionName) {
                case "like":
                    return this.likesCount;
                case "idea":
                    return this.ideasCount;
                default:
                    return this.cheersCount;
            }
        },
    },
});
