import Vue from "vue";
import { mdiClockOutline } from "@mdi/js";
export default Vue.extend({
    data: function () {
        return {
            time: this.value,
            menu: false,
            timeIcon: mdiClockOutline,
            hour: null,
            minute: null,
        };
    },
    props: {
        label: { type: String, default: "Zeit" },
        isMandatory: { type: Boolean, default: true },
        value: { type: String, default: null },
        minTime: { type: String, default: undefined },
        maxTime: { type: String, default: undefined },
        rules: { type: Array, default: function () { return []; } },
    },
    computed: {
        labelStyles: function () {
            return {
                color: this.$vuetify.theme.themes.light.primary,
                fontSize: "1.1rem",
                fontWeight: 400,
                top: "14px",
            };
        },
        mandatoryStyles: function () {
            return {
                color: "red",
                fontWeight: 400,
            };
        },
    },
    methods: {
        closeMenu: function () {
            this.menu = false;
        },
        onInput: function (val) {
            this.$emit("input", val);
        },
        onClear: function () {
            this.$emit("input", null);
        },
    },
});
