<template lang="pug">
div
  drive-upload-card.mt-4.mb-4(:file-name="file.name" :progress="uploadProgress" :success="success != undefined" :errors="aws_error_messages" :thumbnail-url="thumbDataUrl" :file-extension="fileExtension" @remove="$emit('remove', file)")
    template(#field1)
      template(v-if="withinContainer")
        sui-form-field.ma-0(required)
          label(style="white-space:nowrap") Anzeigename (nicht änderbar)
          sui-input(type="text" readonly disabled :value="fullName")
      template(v-else)
        sui-form-field.ma-0(required)
          label Name
          sui-input(type="text" placeholder="Dateiname" v-model="name" name="name" required)
          sui-label.hovering(v-if="$v.name && !$v.name.required" pointing color="red") Name ist erforderlich
    template(#field2)
      sui-form-field.ma-0
        label
          span.label-text Datierung
          span.icon-tooltip-wrapper(v-tooltip="'Genaues oder ungefähres Datum, z.B. 2011 oder Januar 2011 oder WS 2011/12'")
            i.ui.icon.hint.suus-alert-circle-que
        sui-input(type="text" icon-position="left" placeholder="z.B. 22.11.1970, WS 1970/71, November 1970 oder 1970" v-model="physically_created_at" name="physically_created_at")
        sui-label.hovering(v-if="$v.physically_created_at && !$v.physically_created_at.required" pointing color="error" style="z-index:10") Datierung ist erforderlich
    template(#field3)
      template(v-if="withinContainer")
        sui-form-field.ma-0(required)
          label Kommentar
          sui-input(type="text" placeholder="Dateiname" v-model="name" name="name" required)
</template>

<script>
import ListItem from "../ListItem.vue"
import { required } from "vuelidate/lib/validators"
import DriveUploadCard from "features/drive/DriveUploadCard.vue"

export default {
  name: "DriveUploadComponent",
  props: {
    file: { type: [File], required: true },
    parent: { type: [Object], required: true },
    success: { type: String },
    complete: { type: Boolean, default: false },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    fileExtension: { type: String },
    sending: { type: Boolean, default: false },
    canceled: { type: Boolean, default: false },
    processing: { type: Boolean, default: false },
    uploadProgress: { type: Number, default: 0 },
    saved: { type: Boolean, default: false },
    thumbDataUrl: { type: String },
  },
  data() {
    return {
      name: this.defaultName,
      physically_created_at: "",
      s3Key: undefined,
    }
  },
  created() {
    this.$emit("change")
    this.name = this.defaultName
  },
  watch: {
    name() {
      this.$emit("change")
    },
    physically_created_at() {
      this.$emit("change")
    },
    success(msg) {
      if (!msg) return
      try {
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(msg, "text/xml").documentElement
        this.s3Key = xmlDoc.getElementsByTagName("Key")[0].textContent
        this.$emit("s3Key", this.s3Key)
      } catch (e) {
        this.errors = [e.message()]
      }
    },
    formData() {
      this.$emit("input", this.formData)
    },
    file() {
      this.$emit("input", this.formData)
    },
  },
  computed: {
    hasErrors() {
      return this.errors.length > 0
    },
    aws_error_messages() {
      return this.errors.map((e) => {
        try {
          if (e[0] === "<") {
            const parser = new DOMParser()
            const xmlDoc = parser.parseFromString(e, "text/xml")
            if (xmlDoc.documentElement.tagName === "Error") {
              const msg = xmlDoc.getElementsByTagName("Message")[0].textContent
              const code = xmlDoc.getElementsByTagName("Code")[0].textContent

              if (
                code === "AccessDenied" &&
                msg === "Invalid according to Policy: Policy expired."
              ) {
                return "Der Upload hat zu lange gebraucht und ist zwischenzeitlich abgelaufen. Versuche die Dateigröße zu reduzieren und weniger Dateien auf einmal hochzuladen."
              } else {
                return `${msg} (Code: ${code})`
              }
            } else {
              return e
            }
          } else {
            return e
          }
        } catch (e) {
          return e
        }
      })
    },
    fullName() {
      let name = this.parent.name
      if (this.physically_created_at) {
        name += ` vom ${this.physically_created_at}`
      } else {
        name += " vom <Datum>"
      }
      if (this.name) {
        name += ` (${this.name})`
      }
      return name
    },
    formData() {
      const h = {
        stored_file: this.s3Key,
        name: this.name,
        physically_created_at: this.physically_created_at,
        content_type: this.file.type,
      }

      if (this.file.type.startsWith("image/")) {
        const width = this.file.width
        const height = this.file.height
        return { inode: { ...h, width, height } }
      } else {
        return { inode: h }
      }
    },
    withinContainer() {
      return this.parent.type === "Container"
    },
    defaultName() {
      return this.withinContainer
        ? ""
        : this.file.name.split(".").slice(0, -1).join(".")
    },
  },
  validations() {
    if (this.withinContainer) {
      return {
        physically_created_at: { required },
        errors: { maxLength: 0 },
      }
    } else {
      return {
        name: { required },
        errors: { maxLength: 0 },
      }
    }
  },
  components: {
    ListItem,
    DriveUploadCard,
  },
}
</script>

<style scoped lang="sass">
.dropzone
    .item .header .field
        clear: both
        margin: 2px 2px 0 !important

    .upload-fields
        display: flex

        .field
            padding-right: 1rem
</style>

<style>
i.ui.icon.suus-check-bold.large.green {
  background-color: transparent !important;
}
i.ui.icon.suus-simple-remove.large.red {
  background-color: transparent !important;
}

.ui.message.error.visible {
  color: white !important;
}
</style>
