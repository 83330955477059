window.createTopic = (scope) ->

  $('#topic_link_type').change ->
    linked = $('#topic_link_type').is(':checked')

    if linked
      $('.wizard .step.create-event.breadcrumb').removeClass('hide')
      $('#topic_next_button').removeClass 'hide'
      $('#topic_save_button').addClass 'hide'
    else
      $('.wizard .step.create-event.breadcrumb').addClass 'hide'
      $('#topic_next_button').addClass 'hide'
      $('#topic_save_button').removeClass 'hide'

  $('#topic_link_type').trigger 'change'
