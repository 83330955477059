import { computed, defineComponent, } from "vue";
import "suus-api";
import "../../interfaces/reactionOption.interface";
import "../../interfaces/emojiOptionName.type";
import useReactionOptions from "../../concerns/useReactionOptions";
import ReactionIconButton from "./ReactionIconButton.vue";
import ReactionTextButton from "./ReactionTextButton.vue";
export default defineComponent({
    name: "ReactionButton",
    props: {
        selectedReaction: {
            type: String,
            required: false,
            default: undefined,
        },
        variant: {
            type: String,
            validator: function (val) { return ["icon", "text"].includes(val); },
            default: "text",
        },
    },
    components: { ReactionIconButton: ReactionIconButton, ReactionTextButton: ReactionTextButton },
    setup: function (props, context) {
        var _a = useReactionOptions({ createReaction: createReaction, deleteReaction: deleteReaction, updateReaction: updateReaction }), options = _a.options, getOptionByName = _a.getOptionByName, getOptionIndex = _a.getOptionIndex, selectReaction = _a.selectReaction, DEFAULT_OPTION_NAME = _a.DEFAULT_OPTION_NAME;
        var selected = computed(function () {
            return getOptionByName(props.selectedReaction || DEFAULT_OPTION_NAME);
        });
        var activeOptionIndex = computed(function () { return getOptionIndex(selected.value.name); });
        function createReaction(newReaction) {
            context.emit("create-reaction", newReaction);
        }
        function deleteReaction(prevReaction) {
            context.emit("delete-reaction", prevReaction);
        }
        function updateReaction(prevReaction, newReaction) {
            context.emit("update-reaction", { prevReaction: prevReaction, newReaction: newReaction });
        }
        return {
            options: options,
            activeOptionIndex: activeOptionIndex,
            selected: selected,
            selectReaction: selectReaction,
        };
    },
});
