import Vue from "vue";
import "vue";
import TextHTML from "../UI/TextHTML.vue";
import Subtitle from "../UI/Subtitle.vue";
import RelativeTime from "../UI/RelativeTime.vue";
import Avatar from "../UI/Avatar.vue";
import "interfaces/avatar.interface";
import { goToUrl } from "../../helpers/navigation";
import "suus-api";
import RenderedWysiwygContent from "components/Wysiwyg/RenderedWysiwygContent.vue";
export default Vue.extend({
    name: "TopicBlock",
    components: {
        RenderedWysiwygContent: RenderedWysiwygContent,
        Subtitle: Subtitle,
        RelativeTime: RelativeTime,
        TextHTML: TextHTML,
        Avatar: Avatar,
    },
    props: {
        name: { type: String, required: true },
        createdAt: { type: String, required: true },
        text: { type: String, required: true },
        avatarData: { type: Object, required: true },
        profileUrl: { type: String, required: false },
        attachments: {
            type: Array,
            required: false,
        },
    },
    computed: {
        isXsScreenSize: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
        variant: function () {
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "md";
                case "sm":
                    return "md";
                case "md":
                    return "md";
                default:
                    return "lg";
            }
        },
    },
    methods: {
        goToProfile: function () {
            if (this.profileUrl)
                goToUrl(this.profileUrl);
        },
    },
});
