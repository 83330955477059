import { __awaiter, __generator } from "tslib";
import "features/gallery/service/gallery.service";
import "features/gallery/service/thumbnail.service";
import "features/gallery/gallery.types";
import { mdiStar, mdiStarOutline } from "@mdi/js";
import "features/gallery/thumbnail/GalleryThumbnail.vue";
/**
 * Builds and Returns the Add-Button (Adding to Highlights) for a GalleryThumbnail.
 */
var buildAddToHighlightsAction = function (galleryService, thumbnailRepresentation) {
    // Flag to prevent multiple clicks/calls
    var isAdding = false;
    return {
        icon: mdiStarOutline,
        tooltip: "Zu den Highlights hinzufügen",
        action: function () { return __awaiter(void 0, void 0, void 0, function () {
            var highlight;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isAdding)
                            return [2 /*return*/];
                        isAdding = true;
                        return [4 /*yield*/, galleryService.addGalleryToHighlight(thumbnailRepresentation.galleryMedium.id)];
                    case 1:
                        highlight = _a.sent();
                        if (highlight) {
                            thumbnailRepresentation.galleryMedium.highlight_id = highlight.id;
                        }
                        isAdding = false;
                        return [2 /*return*/];
                }
            });
        }); },
    };
};
/**
 * Builds and Returns the Remove-Button (Removing from Highlights) for a GalleryThumbnail.
 */
var buildRemoveFromHighlightsButton = function (galleryService, galleryMedium) { return ({
    icon: mdiStar,
    showAlways: true,
    tooltip: "Aus den Highlights entfernen",
    action: function () { return galleryService.removeGalleryFromHighlight(galleryMedium); },
}); };
/**
 * Builds the ThumbnailRepresentation for a GalleryThumbnail. If user has no edit rights, the top-left action is removed.
 * Else either the Add-Button or the Remove-Button is added to the top-left action.
 */
export var buildThumbnailRepresentation = function (galleryService) {
    return function (thumbnailRepresentation, canEdit) {
        if (!canEdit) {
            thumbnailRepresentation.topLeftAction.value = null;
            return thumbnailRepresentation;
        }
        else {
            thumbnailRepresentation.topLeftAction.value = !thumbnailRepresentation
                .isHighlight.value
                ? buildAddToHighlightsAction(galleryService, thumbnailRepresentation)
                : buildRemoveFromHighlightsButton(galleryService, thumbnailRepresentation.galleryMedium);
            return thumbnailRepresentation;
        }
    };
};
