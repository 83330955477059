import { defineComponent } from "vue";
import THEME from "constants/theme";
export default defineComponent({
    name: "GalleryShowMore",
    props: {
        totalElements: { type: Number, required: true },
        size: { type: Number, default: 128 },
    },
    setup: function (props) {
        var containerStyles = {
            backgroundColor: THEME.colors.light.primary,
            color: THEME.colors.light.lightGrey,
        };
        if (props.size > 0) {
            containerStyles.height = "".concat(props.size, "px");
            containerStyles.width = "".concat(props.size, "px");
        }
        return {
            containerStyles: containerStyles,
        };
    },
});
