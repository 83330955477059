import { usePermissionCheck } from "features/common/permissions/usePermissionCheck";
import { ClientAbility, LevelAbility } from "suus-api";
import "vue";
/**
 * Collection of helper functions to check permissions.
 * Functions on this layer of abstraction shouldn't implement much logic.
 * They should just be a wrapper around the usePermissionCheck hook.
 * Implement logic inside the usePermissionCheck hook.
 */
export function useCanSendUrgentOnLevelOrClientFallback(levelId) {
    var hasLevelPermissionOrClientFallback = usePermissionCheck().hasLevelPermissionOrClientFallback;
    return hasLevelPermissionOrClientFallback(levelId, LevelAbility.SendUrgentLevelDigest, ClientAbility.SendUrgentDigest);
}
export function useCanAccessGroup() {
    var hasClientPermission = usePermissionCheck().hasClientPermission;
    return hasClientPermission(ClientAbility.AccessGroups);
}
