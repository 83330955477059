<template lang="pug">
div
  .plain-layout-title.wide
    h1.ui.header {{$t('components.admin_stats.header')}}
      .ui.sub.header {{$t('components.admin_stats.subheader')}}

  .inner-container.wide
    p(v-if="error_msg") {{$t('components.admin_stats.error', {error: error_msg})}}

    .ui.segment
      h1.ui.header.dividing {{$t('components.admin_stats.usage.header')}}
      area-chart(:data="pickup_stats.stats" :label="$t('components.admin_stats.usage.header')")
      a.text-right(href="/api/v1/stats/usage.csv") {{$t('components.admin_stats.usage.download_csv')}}

    .ui.segment
      h1.ui.header.dividing {{$t('components.admin_stats.login_stats.header')}}
      .ui.grid.two.columns.stackable
        .column
          h2.ui.header.dividing {{$t('components.admin_stats.login_stats.by_age')}}
          column-chart(:data="login_by_age.stats" :stacked="true" legend="bottom")

        .column
          h2.ui.header.dividing {{$t('components.admin_stats.login_stats.by_meantime')}}
          pie-chart(:data="login_by_meantime.stats" legend="bottom")

      .ui.grid.two.columns.stackable
        .column
          h2.ui.header.dividing {{$t('components.admin_stats.login_stats.by_weeks')}}
          column-chart(:data="login_by_weeks.stats")

        .column
          h2.ui.header.dividing  {{$t('components.admin_stats.login_stats.by_freq')}}
          pie-chart(:data="summary_freq_stats.stats" legend="bottom")

    .ui.segment
      h1.ui.header.dividing  {{$t('components.admin_stats.usage_stats.header')}}
      .ui.grid.doubling.four.columns.stackable(style="margin-top: 2rem;")
        .row
          .column.eight.wide.centered(style="margin-bottom: 2rem;")
            h4.ui.header Mitglider Gesamt
            pie-chart(:data="usage_stats.stats" legend="left")
          .column.eight.wide.centered(style="margin-bottom: 2erm;")
            h4.ui.header {{$t('components.admin_stats.client_total')}}
              pie-chart(:data="client_stats" legend="left")

        .column(v-for="client in clients")
          h4.ui.header(style="min-height: 3rem")
            .content
              i.ui.icon.suus-hourglass(v-if="!client.released" v-tooltip="`Aktiviert: ${client.activated_at}`")
              i.ui.icon.suus-check-bold(v-if="client.released" v-tooltip="`Released: ${client.released_at}`")
              | {{client.name}}
              sui-icon(style="cursor: pointer" name="suus-receipt-list-43" @click="checkHealth(client.id)")
          sui-message(
            style="position: absolute; width: 90%; z-index: 999"
            dismissable
            @dismiss="$set(clients_health_stats, client.id, null)"
            v-if="clients_health_stats[client.id]"
            header="Health check"
          )
            p(v-tooltip="clients_health_stats[client.id].ability_fractions")
              sui-icon(name="suus-check-bold" v-if="clients_health_stats[client.id].abilities")
              sui-icon(name="suus-simple-remove" v-else)
              | Abilities healthy
            p
              sui-icon(name="suus-check-bold" v-if="clients_health_stats[client.id].active_users")
              sui-icon(name="suus-simple-remove" v-else)
              | Active Users
            p
              sui-icon(name="suus-check-bold" v-if="clients_health_stats[client.id].topics")
              sui-icon(name="suus-simple-remove" v-else)
              | Topics created
            p
              sui-icon(name="suus-check-bold" v-if="clients_health_stats[client.id].events")
              sui-icon(name="suus-simple-remove" v-else)
              | Events created
            p
              sui-icon(name="suus-check-bold" v-if="clients_health_stats[client.id].drive")
              sui-icon(name="suus-simple-remove" v-else)
              | Files in Drive
            p {{ clients_health_stats[client.id].client_activity.last_month }} Beiträge letzter Monat (Client)
            p {{ clients_health_stats[client.id].client_activity.last_three_months }} Beiträge letzte 3 Monate (Client)
            p {{ clients_health_stats[client.id].client_activity.since_activation }} Beiträge gesamt (Client)
            p {{ clients_health_stats[client.id].level_activity.last_month }} Beiträge letzter Monat (Level)
            p {{ clients_health_stats[client.id].level_activity.last_three_months }} Beiträge letzte 3 Monate (Level)
            p {{ clients_health_stats[client.id].level_activity.since_activation }} Beiträge gesamt (Level)
            p {{Math.round(clients_health_stats[client.id].two_week_open_rate * 100)}}% 2-week open rate
            .ui.button.fluid(v-if='!client.released'
                             v-on:click="release(client)"
                             :class="{loading: loading}") Release
          pie-chart(:data="clients_usage_stats[client.id] ? clients_usage_stats[client.id].stats : null" :legend="false")
</template>

<script>
import Vue from "vue"
import Axios from "../initializers/axios"
export default Vue.extend({
  name: "AdminStats",
  props: ["system_name", "clients"],
  data() {
    return {
      error_msg: "",
      pickup_stats: { stats: null, invalid: [] },
      login_by_age: { stats: null, invalid: [] },
      login_by_meantime: { stats: null, invalid: [] },
      login_by_weeks: { stats: null, invalid: [] },
      summary_freq_stats: { stats: null, invalid: [] },
      usage_stats: { stats: null, invalid: [] },
      clients_usage_stats: {},
      clients_health_stats: {},
      loading: false,
    }
  },
  created() {
    this.loadPickupStats()
    this.loadLoginByAgeStats()
    this.loadLoginByMeantimeStats()
    this.loadLoginByWeeksStats()
    this.loadSummaryFreqStats()
    this.loadUsageStats()
    for (let i = 0; i < this.clients.length; i++) {
      this.loadClientUsageStats(this.clients[i].id, i * 50)
    }
  },
  computed: {
    client_stats() {
      const released = this.clients.filter((e) => e.released).length
      const activated = this.clients.filter(
        (e) => e.active && !e.released
      ).length
      return { Live: released, Einrichtungsphase: activated }
    },
  },
  methods: {
    async release(client) {
      if (this.loading) return false
      let url = `/api/v1/stats/release`
      let client_id = client.id
      if (confirm("Do you really want to release this client?")) {
        this.loading = true
        await Axios.post(url, { client_id })
        this.loading = false
        client.released = true
      }
    },

    async checkHealth(client_id) {
      let url = `/api/v1/stats/health?client_id=${client_id}`
      let res = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
      const json = await res.json()
      let ability_string = ""
      for (let [ability, data] of Object.entries(json.ability_fractions)) {
        ability_string += `${
          data.fraction >= data.healthy_range[0] &&
          data.fraction <= data.healthy_range[1]
            ? "&#x2705;"
            : "&#10060;"
        } ${this.$t(`abilities.${ability}.title`)} (${
          Math.round(data.fraction * 1000) / 10
        }%)<br />`
      }
      json.ability_fractions = ability_string
      this.$set(this.clients_health_stats, client_id, json)
    },

    loadPickupStats() {
      let url = "/api/v1/stats/pickup"
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.pickup_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },
    loadLoginByAgeStats() {
      let url = "/api/v1/stats/logins/by_age"
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.login_by_age = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },
    loadLoginByMeantimeStats() {
      let url = "/api/v1/stats/logins/by_meantime"
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.login_by_meantime = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },
    loadLoginByWeeksStats() {
      let url = "/api/v1/stats/logins/by_weeks"
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.login_by_weeks = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },
    loadUsageStats() {
      let url = "/api/v1/stats/usage"
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.usage_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },
    loadSummaryFreqStats() {
      let url = "/api/v1/stats/summary/freq"
      fetch(url, {
        method: "GET",
        credentials: "same-origin",
      })
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          this.summary_freq_stats = data
        })
        .catch((err) => {
          this.error = err.toString()
        })
    },
    loadClientUsageStats(client_id, msDelay) {
      let url = `/api/v1/stats/usage?client_id=${client_id}`
      setTimeout(
        function () {
          fetch(url, {
            method: "GET",
            credentials: "same-origin",
          })
            .then((res) => {
              return res.json()
            })
            .then((data) => {
              this.$set(this.clients_usage_stats, client_id, data)
            })
            .catch((err) => {
              this.error = err.toString()
            })
        }.bind(this),
        msDelay
      )
    },
  },
})
</script>
