# used by partial club_records/_form_fields
window.initClubRecordFieldToggles = (el) ->
  form = $(el)
  awarded_toggle = form.find('.is_awarded input')

  awarded_toggle.on 'change', ->
    awarded = $(this).is(':checked')
    rows_only_when_not_awarded = form.find('.field.renonciert_at, .field.recipiert_at, .field.philistriert_at')
    rows_only_when_awarded = form.find('.field.awarded_at')

    if(awarded)
      rows_only_when_not_awarded.addClass('hidden')
      rows_only_when_awarded.removeClass('hidden')
    else
      rows_only_when_not_awarded.removeClass('hidden')
      rows_only_when_awarded.addClass('hidden')
  awarded_toggle.trigger 'change'


  resigned_toggle = form.find('.is_resigned input')

  resigned_toggle.on 'change', ->
    resigned = $(this).is(':checked')
    form.find('.field.resigned_reason, .field.resigned_at').toggleClass 'hidden', !resigned
  resigned_toggle.trigger 'change'
