import { __awaiter, __generator } from "tslib";
import { defineComponent, toRef } from "vue";
import "features/wysiwyg/addon/wysiwyg-addon.types";
import "features/posts/form/addon/post-form-addon.types";
import "suus-api";
import PostFormAddon from "features/posts/form/addon/PostFormAddon.vue";
import GalleryUploader from "features/gallery/uploader/GalleryUploader.vue";
import { mdiImageOutline } from "@mdi/js";
import { useGalleryAddon } from "features/posts/form/gallery/gallery-addon.concern";
import { deleteGalleryMedium } from "features/posts/form/gallery/gallery.functions";
import "features/posts/form/post-form.types";
/**
 * TODO: Remove the PostFormAddon, inline the functionality here
         It's overengineered and not needed.
 */
export default defineComponent({
    name: "GalleryAddon",
    components: { GalleryUploader: GalleryUploader, PostFormAddon: PostFormAddon },
    props: {
        registerWysiwygAddon: {
            type: Function,
            required: true,
        },
        registerPostFormAddon: {
            type: Function,
            required: true,
        },
        post: {
            type: Object,
        },
        deletePost: {
            type: Function,
            required: false,
        },
        initialShowUploader: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        valid: function (valid) { return true; },
    },
    setup: function (props) {
        var _this = this;
        var post = toRef(props, "post");
        var _a = useGalleryAddon(post, props.deletePost), galleryCount = _a.galleryCount, createGalleryMedia = _a.createGalleryMedia, updateGalleryMedia = _a.updateGalleryMedia, setGalleryUploads = _a.setGalleryUploads, existingGalleryMedia = _a.existingGalleryMedia, resetFunction = _a.resetFunction, existingGalleryMediaLoading = _a.existingGalleryMediaLoading, loadAllExistingGalleryMedia = _a.loadAllExistingGalleryMedia, removeExistingGalleryMedia = _a.removeExistingGalleryMedia;
        loadAllExistingGalleryMedia();
        var _deleteGalleryMedium = function (mediumId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, deleteGalleryMedium(mediumId)];
                    case 1:
                        _a.sent();
                        removeExistingGalleryMedia(mediumId);
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            mdiImageOutline: mdiImageOutline,
            galleryCount: galleryCount,
            createGalleryMedia: createGalleryMedia,
            updateGalleryMedia: updateGalleryMedia,
            existingGalleryMedia: existingGalleryMedia,
            setGalleryUploads: setGalleryUploads,
            resetFunction: resetFunction,
            deleteGalleryMedium: _deleteGalleryMedium,
            existingGalleryMediaLoading: existingGalleryMediaLoading,
        };
    },
});
