import "suus-api";
export var getViewContextOfGroup = function (group) {
    return {
        group: group,
        level: group.level,
        level_id: group.level_id,
        client: group.client,
        client_id: group.client_id,
    };
};
function getContextClass(context) {
    if (context === null) {
        console.warn("ViewContext is null!");
        return null;
    }
    if (context.group) {
        return getContextClass({
            level: context.group.level,
            client: context.group.client,
        });
    }
    if (context.level || context.level_id)
        return "Level";
    if (context.client || context.client_id)
        return "Client";
    return null;
}
// TODO: Find out why context is missing
function _getContextName(context, internalName, umbrellaName) {
    var contextClass = getContextClass(context);
    if (contextClass === "Level") {
        return umbrellaName;
    }
    else if (contextClass === "Client") {
        return internalName;
    }
    else {
        console.warn("contextClass could not be derived from viewContext!", context);
        return internalName;
    }
}
var getContextName = function (context, member) {
    return _getContextName(context, member.internal_name_short, member.umbrella_name_short);
};
var getContextNameLong = function (context, member) {
    return _getContextName(context, member.internal_name_long, member.umbrella_name_long);
};
var getContextNameShortPropertyName = function (context) {
    var contextClass = getContextClass(context);
    if (contextClass === "Level") {
        return "umbrella_name_short";
    }
    else {
        return "internal_name_short";
    }
};
export { getContextName, getContextNameLong, getContextNameShortPropertyName, };
