function findInodeById(inodes, inode) {
  console.log(`${inode.id}`)
  let path = this.inodePath(inode)
  for (let node_id in path) {
    inode = inode.children[node_id]
    console.log(`${inode.id}`)
  }
  return inode
}
function inodePath(inode) {
  if (inode.parent) {
    return inodePath(inode.parent).concat([inode])
  } else {
    return [inode]
  }
}

export { findInodeById, inodePath }
