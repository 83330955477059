import Vue from "vue";
import Header from "./Header.vue";
import TextBody from "./TextBody.vue";
import { mdiCalendarRange } from "@mdi/js";
export default Vue.extend({
    name: "FormLayout",
    components: {
        Header: Header,
        TextBody: TextBody,
    },
    props: {
        formTitle: { type: String, required: true },
        fieldsText: { type: String, default: "* Pflichtfelder" },
    },
    data: function () { return ({
        calenderIcon: mdiCalendarRange,
    }); },
    computed: {
        headerIconStyles: function () {
            return {
                border: "2px solid ".concat(this.$vuetify.theme.themes.light.primary),
                borderRadius: "4px",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            };
        },
        containerStyles: function () {
            return { maxWidth: "960px" };
        },
        formActionsStyles: function () {
            return {
                backgroundColor: "".concat(this.$vuetify.theme.themes.light.lightGrey, " !important"),
                borderRadius: "4px",
                height: "100%",
            };
        },
    },
});
