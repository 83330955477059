import { defineComponent } from "vue";
import THEME from "../constants/theme";
var _a = THEME.typography, fontFamily = _a.fontFamily, fontWeightRegular = _a.fontWeightRegular, rootFontSize = _a.rootFontSize, textColor = THEME.colors.light.textColor;
export default defineComponent({
    name: "AttachmentListItemContent",
    props: { text: { type: String, required: true } },
    setup: function () {
        var labelStyles = {
            color: textColor,
            fontFamily: fontFamily,
            fontWeight: fontWeightRegular,
            fontSize: "".concat(rootFontSize, "px"),
            lineHeight: "".concat(rootFontSize * 0.8 * 1.5, "px"),
            textDecoration: "underline",
        };
        return {
            labelStyles: labelStyles,
        };
    },
});
