<template lang="pug">
portal.portal(selector="#decline-portal")
  AttendPage 
</template>

<script>
import AttendPage from "../Event/AttendPage.vue"
export default {
  name: "PortalDecline",
  components: { AttendPage },
}
</script>
<style lang="sass">
#decline-portal
  width: 100% !important
</style>
