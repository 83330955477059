import { defineComponent } from "vue";
import GalleryThumbnailOverlay from "features/gallery/thumbnail/GalleryThumbnailOverlay.vue";
import "features/gallery/thumbnail/GalleryThumbnail.vue";
import TextBody from "components/UI/TextBody.vue";
export default defineComponent({
    name: "GalleryThumbnailErrorOverlay",
    components: { TextBody: TextBody, GalleryThumbnailOverlay: GalleryThumbnailOverlay },
    props: {
        message: {
            type: String,
            required: true,
        },
        action: {
            type: Object,
        },
    },
});
