import Vue from "vue";
export default Vue.extend({
    name: "TextBody",
    props: {
        text: String,
        customClass: { type: String, default: "" },
        large: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        fontSize: { type: Number, default: 1 },
        color: { type: String, default: "textColor" },
        looksEditable: { type: Boolean, default: false },
    },
    computed: {
        textBodyStyles: function () {
            var _a;
            var styles = {
                fontSize: "".concat(this.fontSize, "rem !important"),
                fontFamily: "system-ui !important",
                color: (_a = this.$vuetify.theme.themes.light[this.color]) === null || _a === void 0 ? void 0 : _a.toString(),
                display: "",
                lineHeight: "unset",
                marginBottom: "0"
            };
            this.inline && (styles.display = "inline !important");
            if (this.large) {
                styles.fontSize = "1.2rem !important";
                styles.lineHeight = "1.8rem !important";
            }
            return styles;
        },
        textBodyClass: function () {
            var _a;
            var classes = (_a = {},
                _a[this.customClass] = true,
                _a["editable"] = this.looksEditable,
                _a);
            return classes;
        },
    },
});
