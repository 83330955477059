import Vue from "vue";
import { mdiClockOutline } from "@mdi/js";
import THEME from "../../constants/theme";
import moment from "moment";
var fontFamily = THEME.typography.fontFamily;
moment.locale("de");
export default Vue.extend({
    name: "TimeDelta",
    props: {
        currDateTime: { type: String, required: true },
        prevDateTime: { type: String, required: true },
    },
    data: function () {
        return {
            timeIcon: mdiClockOutline,
        };
    },
    computed: {
        isXsScreenSize: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
        styles: function () {
            return {
                color: this.$vuetify.theme.themes.light.darkGrey,
                fontWeight: 400,
                fontFamily: fontFamily,
                fontSize: "".concat(this.isXsScreenSize ? 0.75 : 0.8, "rem"),
            };
        },
        weeksDelta: function () {
            return Math.floor(moment
                .duration(moment(this.currDateTime).diff(moment(this.prevDateTime)))
                .asWeeks());
        },
        monthsDelta: function () {
            return Math.floor(moment
                .duration(moment(this.currDateTime).diff(moment(this.prevDateTime)))
                .asMonths());
        },
        yearsDelta: function () {
            return Math.floor(moment
                .duration(moment(this.currDateTime).diff(moment(this.prevDateTime)))
                .asYears());
        },
        deltaText: function () {
            if (this.yearsDelta === 1)
                return "".concat(this.yearsDelta, " Jahr sp\u00E4ter");
            if (this.yearsDelta >= 1)
                return "".concat(this.yearsDelta, " Jahre sp\u00E4ter");
            if (this.monthsDelta === 1)
                return "".concat(this.monthsDelta, " Monat sp\u00E4ter");
            if (this.monthsDelta >= 1)
                return "".concat(this.monthsDelta, " Monate sp\u00E4ter");
            if (this.weeksDelta === 1)
                return "".concat(this.weeksDelta, " Woche sp\u00E4ter");
            if (this.weeksDelta >= 1)
                return "".concat(this.weeksDelta, " Wochen sp\u00E4ter");
            return null;
        },
    },
});
