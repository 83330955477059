window.curatorToggle = (scope) ->

  toggleField = (field, state) ->
    input = $(field).find('input.select')
    if !state
      input.attr('name', input.attr('name').replace(/^disabled_/, ''))
    else
      input.attr('name', 'disabled_' + input.attr('name'))
      input.val('')
      input.closest('.dropdown').dropdown('clear')

    $(field).toggleClass 'hide', state

  $(scope).find('#uploaded_file_owner_type_, #folder_owner_type_').change ->
    toggleField $(scope).find('.member-curator-select'), $(this).is(':checked')
    toggleField $(scope).find('.group-curator-select'), $(this).is(':checked')
  
  $(scope).find('#uploaded_file_owner_type_group, #folder_owner_type_group').change ->
    toggleField $(scope).find('.member-curator-select'), $(this).is(':checked')
    toggleField $(scope).find('.group-curator-select'), !$(this).is(':checked')
  
  $(scope).find('#uploaded_file_owner_type_clubrecord, #folder_owner_type_clubrecord').change ->
    toggleField $(scope).find('.member-curator-select'), !$(this).is(':checked')
    toggleField $(scope).find('.group-curator-select'), $(this).is(':checked')
  
  $(scope).find('#uploaded_file_owner_type_group, #uploaded_file_owner_type_clubrecord, #uploaded_file_owner_type_').filter(':checked').trigger('change')
  $(scope).find('#folder_file_owner_type_group, #folder_owner_type_clubrecord, #folderoaded_file_owner_type_').filter(':checked').trigger('change')