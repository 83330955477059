import { computed, defineComponent, inject } from "vue";
import ToggleableTextField from "features/common/forms/form-fields/ToggleableTextField.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/events/form/event-form.service";
import trimIfString from "features/common/util/trimIfString";
import "features/member/member.service";
import "components/EventForm/EventForm.vue";
export default defineComponent({
    name: "AddressField",
    components: { ToggleableTextField: ToggleableTextField },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        var memberService = inject("MemberService");
        eventFormService.registerProperty({
            name: "location",
        });
        var location = computed({
            get: function () {
                return eventFormService.getFormValue("location").value;
            },
            set: function (value) {
                eventFormService.setFormValue("location", trimIfString(value));
            },
        });
        var handleAdhClick = function () {
            var adh = memberService.getAdhAddress();
            if (adh) {
                location.value = adh;
            }
        };
        return {
            t$: t$,
            location: location,
            handleAdhClick: handleAdhClick,
        };
    },
});
