import { computed, defineComponent, inject } from "vue";
import GalleryViewSkeletonLoader from "features/gallery/view/GalleryViewSkeletonLoader.vue";
import "suus-api";
import GalleryThumbnail from "features/gallery/thumbnail/GalleryThumbnail.vue";
import "suus-api";
import "features/gallery/service/gallery.service";
import EventHighlightsView from "features/events/highlights/EventHighlightsView.vue";
import GalleryShowMore from "features/gallery/GalleryShowMore.vue";
import "core/event-bus/event-bus";
import "features/events/highlights/lightbox/event-lightbox.service";
var SHOWN_HIGHLIGHTS_COUNT = 9;
export default defineComponent({
    name: "EventHighlightsGrid",
    components: {
        GalleryShowMore: GalleryShowMore,
        EventHighlightsView: EventHighlightsView,
        GalleryThumbnail: GalleryThumbnail,
        GalleryViewSkeletonLoader: GalleryViewSkeletonLoader,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        highlights: {
            type: Array,
            default: function () { return []; },
        },
        event: {
            type: Object,
            required: true,
        },
        openGalleryViewEvent: {
            type: Object,
            default: null,
        },
    },
    setup: function (props) {
        var galleryService = inject("GalleryService");
        var eventLightboxService = inject("EventLightboxService");
        var highlightsCount = galleryService.highlightsCount;
        var highlights = computed(function () { return props.highlights; });
        var firstHighlights = computed(function () {
            return highlights.value.slice(0, SHOWN_HIGHLIGHTS_COUNT - 1);
        });
        var loaderCount = computed(function () {
            return Math.max(Math.min(SHOWN_HIGHLIGHTS_COUNT, highlightsCount.value - 1), 0);
        });
        var openLightbox = function (index) {
            eventLightboxService.openLightbox(index);
        };
        return {
            firstHighlights: firstHighlights,
            openLightbox: openLightbox,
            loaderCount: loaderCount,
            highlightsCount: highlightsCount,
        };
    },
});
