import { __assign, __awaiter, __generator } from "tslib";
import { computed, defineComponent, inject, onMounted, onUnmounted, ref, watch, } from "vue";
import "suus-api";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import "features/gallery/gallery.types";
import { usePostGalleryCarousel } from "features/posts/gallery/carousel.concern";
import PostGalleryShowMore from "features/posts/gallery/PostGalleryShowMore.vue";
import "features/gallery/lightbox/lightbox.service";
import ServicedGalleryThumbnail from "features/gallery/service/ServicedGalleryThumbnail.vue";
import "features/posts/gallery/lightbox/post-lightbox.service";
import { eventMediaApi } from "store/api";
export default defineComponent({
    name: "PostGalleryPreviewDesktop",
    components: {
        ServicedGalleryThumbnail: ServicedGalleryThumbnail,
        PostGalleryShowMore: PostGalleryShowMore,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
        canEditHighlights: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        var _this = this;
        var dynamicPost = ref(props.post);
        watch(function () { return props.post; }, function (post) {
            dynamicPost.value = post;
        });
        var reloadPostMedia = function () { return __awaiter(_this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, eventMediaApi.getEventMedia(props.post.id.toString(), 0, 8, "asc")];
                    case 1:
                        response = _a.sent();
                        dynamicPost.value = __assign(__assign({}, dynamicPost.value), { event_media_preview: response.data });
                        console.log(dynamicPost.value);
                        console.log("calling resize");
                        onResize();
                        return [2 /*return*/];
                }
            });
        }); };
        var _carouselIndex = ref(0);
        var carouselIndex = computed({
            get: function () { return _carouselIndex.value; },
            set: function (value) {
                _carouselIndex.value = value;
            },
        });
        var leftIcon = mdiArrowLeft;
        var rightIcon = mdiArrowRight;
        var carousel = ref(null);
        var _a = usePostGalleryCarousel(), slides = _a.slides, resizeSlides = _a.resizeSlides, slideItemsCount = _a.slideItemsCount;
        var onResize = function () {
            resizeSlides(carousel.value.$el, dynamicPost.value);
        };
        onMounted(function () {
            onResize();
            window.addEventListener("resize", onResize);
        });
        onUnmounted(function () {
            window.removeEventListener("resize", onResize);
        });
        var navigateLeft = function () {
            if (carouselIndex.value > 0) {
                carouselIndex.value -= 1;
            }
        };
        var navigateRight = function () {
            if (carouselIndex.value < slides.value.length - 1) {
                carouselIndex.value += 1;
            }
        };
        var leftDisabled = computed(function () { return carouselIndex.value === 0; });
        var rightDisabled = computed(function () { return carouselIndex.value === slides.value.length - 1; });
        var lightboxService = inject("lightboxService");
        var postLightboxService = inject("PostLightboxService", null);
        var openImage = function (medium, slideIndex, mediumIndex) {
            if (postLightboxService) {
                postLightboxService.openLightbox(slideIndex * slideItemsCount.value + mediumIndex);
                return;
            }
            lightboxService.openLightbox(medium.uploaded_file.original.url);
        };
        var getMediumKey = function (medium, index, slideIndex) {
            if (medium === "placeholder" || medium === "more") {
                return medium + "-" + index + "-" + slideIndex;
            }
            else {
                return medium.id.toString() + "-" + index + "-" + slideIndex;
            }
        };
        return {
            carouselIndex: carouselIndex,
            leftIcon: leftIcon,
            rightIcon: rightIcon,
            slides: slides,
            carousel: carousel,
            navigateLeft: navigateLeft,
            navigateRight: navigateRight,
            leftDisabled: leftDisabled,
            rightDisabled: rightDisabled,
            openImage: openImage,
            reloadPostMedia: reloadPostMedia,
            dynamicPost: dynamicPost,
            getMediumKey: getMediumKey,
        };
    },
});
