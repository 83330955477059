import Sheet from "components/UI/Sheet.vue";
import EventForm from "components/EventForm/EventForm.vue";
import { defineComponent } from "vue";
import "features/media/MediaUploader.vue";
var EventSheetButton = defineComponent({
    name: "EventSheetButton",
    components: { EventForm: EventForm, Sheet: Sheet },
    props: {
        isEdit: {
            type: Boolean,
            default: false,
        },
        scrollTo: { type: String, default: "" },
    },
    data: function () { return ({
        showSheet: false,
        teardownTrigger: false,
    }); },
    methods: {
        openSheet: function () {
            this.handleVisibilityChange(true);
        },
        closeSheet: function () {
            this.handleVisibilityChange(false);
        },
        handleVisibilityChange: function (newVisibility) {
            this.showSheet = newVisibility;
            if (newVisibility == false) {
                this.teardownTrigger = !this.teardownTrigger;
            }
        },
    },
});
export default EventSheetButton;
