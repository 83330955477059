export default {
  watch: {
    $route(to, from) {
      this.$store.commit("loader/SET_FROM_ROUTE", from)
    },
  },

  methods: {
    /**
     * Handle Back
     * @desc Extends default router back functionality
     * @param {string} fallback - The fallback path if there is no history to use with $router.back(). This is usually the case if the page was visited directly or from another site
     **/
    handleBack(fallback) {
      if (this.$store.state.loader.fromRoute) {
        this.$router.go(-1)
      } else {
        console.log(fallback)
        this.$router.push(fallback)
      }
    },
  },
}
