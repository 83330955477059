<template lang="pug">
div
  sui-form-fields(fields="two" v-if="shareable")
    .field
      LabelWithHint(tooltip-key="folders.edit.share.tooltip") Freigabe
      sui-checkbox(
        :label="$t('folders.edit.share.label')"
        toggle
        v-model="share"
      )
    keep-alive
      .field(v-if="share")
        RemoteDropdown(
          v-model="level_id"
          :endpoint="levelEndpoint"
          :params="{inode_id: inode.id, ...(move && {parent_id})}"
          label="Freigabe"
          placeholder="Ebene auswählen"
          name="level_id"
          :valueFunction="(x) => x.id"
          :textFunction="(x) => x.name"
          :initialValues="inode.level ? [{id: inode.level.id, name: inode.level.name}] : []"
          :defaultValue="inode.level_id"
          :preload="true"
        )



  sui-form-fields(fields="two" v-if="isPersisted")
    .field
      LabelWithHint(tooltip-key="folders.edit.move.tooltip") Verschieben
      sui-checkbox(
        label="Verschieben"
        toggle
        v-model="move"
      )
    keep-alive
      .field(v-if="move")
        RemoteDropdown(
          v-model="parent_id"
          endpoint="/api/v1/drive/parents"
          :params="{inode_id: inode.id}"
          label="Neuer Ordner"
          placeholder="Keiner (als Wurzelordner verwenden)"
          name="parent_id"
          :disabled="share"
          :valueFunction="(x) => x.id"
          :textFunction="(x) => x.name"
          :initialValues="inode.parent ? [{value: inode.parent.id, text: inode.parent.name }] : []"
          :defaultValue="inode.parent_id"
          :preload="true"
        )

  .two.fields
    .field
      LabelWithHint(tooltip-key="folders.edit.restrict_to.tooltip") Lesen
      sui-checkbox(
          label="Sichtbarkeit einschränken"
          toggle
          v-model="restrict"
          :disabled="!move && !!(inode.parent && inode.parent.restrict_to)"
      )
      .text(v-if="!move && inode.parent && inode.parent.restrict_to")
        | Der Ordner ist in einem Ordner, der nur für die Gruppe '{{inode.parent.restrict_to.name}}' sichtbar ist.
        | Du kannst die Sichtbarkeit weiter einschränken, indem du eine der Untergruppen auswählst.
        | Du kannst nur Gruppen auswählen, in denen du Mitglied bist.

    keep-alive
      RemoteDropdown(
        v-if="restrict"
        v-model="restrict_to_id"
        :endpoint="aclReadEndpoint"
        :params="{mode: 'r', ...groupAclParams}"
        label="Gruppe"
        placeholder="Gruppe auswählen"
        :textFunction="(x) => x.name"
        :iconFunction="(x) => x.level_id ? 'suus-connect' : null"
        :valueFunction="(x) => x.id"
        name="restrict_to_id"
        :initialValues="inode.restrict_to ? [{value: inode.restrict_to.id, text: inode.restrict_to.name }] : []"
        :defaultValue="inode.restrict_to_id"
        :preload="true"
      )


  .two.fields(v-if="inode.parent && inode.parent.owner_type == 'GroupMember'")
    .field
      .text
        | Dieser Ordner wird wie der übergeordnete Ordner verwaltet von
        MemberLink(:member="inode.parent.owner" :context="context")
  .two.fields(v-else)
    .field
      LabelWithHint(tooltip-key="folders.edit.owner.tooltip") Inhalte hinzufügen & Ordner bearbeiten
      sui-checkbox(:inputValue="['null', 'GroupMember', 'Group']" radio v-model='owner_type' name="owner_type" label='Alle Leser'    value='null' v-if="!inode.parent || !inode.parent.owner || !inode.parent.owner_type")
      sui-checkbox(:inputValue="['null', 'GroupMember', 'Group']" radio v-model='owner_type' name="owner_type" label='Gruppe/Amt'    value='Group' v-if="!inode.parent || !inode.parent.owner || inode.parent.owner_type === 'Group'")
      sui-checkbox(:inputValue="['null', 'GroupMember', 'Group']" radio v-model='owner_type' name="owner_type" label='Person'        value='GroupMember' v-if="!inode.parent || !inode.parent.owner || inode.parent.owner_type === 'GroupMember'")

    RemoteDropdown(v-if="owner_type === 'GroupMember'"
      v-model="owner_id"
      endpoint="/api/v1/drive/member_acl"
      :params="{mode: 'w', ...groupAclParams}"
      label="Person"
      placeholder="Person suchen"
      :textFunction="printName"
      remoteSearch
      :minQueryLength="3"
      :valueFunction="(x) => x.id"
      name="owner_id"
      :initialValues="inode.owner_id ? [{value: inode.owner.id, text: printName(inode.owner)}] : []"
      :defaultValue="inode.owner_id"
    )

    RemoteDropdown(v-if="owner_type === 'Group'"
      v-model="owner_id"
      endpoint="/api/v1/drive/group_acl"
      :params="{mode: 'w', ...groupAclParams}"
      label="Gruppe/Amt"
      placeholder="Gruppe auswählen"
      :textFunction="(x) => x.name"
      :iconFunction="(x) => x.level_id ? 'suus-connect' : null"
      :valueFunction="(x) => x.id"
      name="owner_id"
      :initialValues="inode.owner_id ? [{value: inode.owner.id, text: inode.owner.name}] : []"
      :defaultValue="inode.owner_id"
    )
</template>

<script>
import RemoteDropdown from "../RemoteDropdown.vue"
import LabelWithHint from "../LabelWithHint.vue"
import MemberLink from "../MemberLink.vue"
export default {
  name: "InodeAclInput",
  props: {
    inode: [Object],
  },
  data() {
    return {
      owner_type: this.inode.owner_type || "null",
      owner_id: this.inode.owner_id,
      restrict_to_id: this.inode.restrict_to_id,
      restrict: !!this.inode.restrict_to_id,
      level_id: this.inode.level_id,
      parent_id: this.inode.parent_id,
      share: !!this.inode.level_id,
      move: false,
    }
  },
  watch: {
    move(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.parent_id = this.inode.parent_id
        this.level_id = this.inode.level_id
      }
    },
    value(newVal) {
      this.$emit("input", newVal)
    },
  },
  computed: {
    groupAclParams() {
      return {
        ...(this.isPersisted && { inode_id: this.inode.id }),
        restrict_to_id: this.restrict ? this.restrict_to_id : null,
        ...(!this.move && this.share && { level_id: this.level_id }),
        ...(this.move && !this.share && this.shareable && { level_id: "" }),
        ...(this.move &&
          this.share &&
          this.shareable && { level_id: this.level_id }),
        ...((this.move || !this.isPersisted) && { parent_id: this.parent_id }),
      }
    },
    aclReadEndpoint() {
      return `/api/v1/drive/group_acl?mode=r`
    },
    levelEndpoint() {
      return `/api/v1/drive/level_acl`
    },
    shareable() {
      return !this.parent_id
    },
    isPersisted() {
      return !!this.inode.id
    },
    value() {
      const owner_type = this.owner_type === "null" ? "" : this.owner_type
      const owner_id = this.owner_type === "null" ? "" : this.owner_id
      const restrict_to_id = this.restrict ? this.restrict_to_id : null
      const parent_id = !this.isPersisted || this.move ? this.parent_id : null
      const level_id = this.share ? this.level_id : null
      return {
        owner_type,
        owner_id,
        restrict_to_id,
        ...((!this.isPersisted || this.move) && { parent_id }), // only include if shareable
        ...(this.shareable && { shared_level_id: level_id }), // only include if shareable
      }
    },
    context() {
      return {
        level: this.inode.parent.level,
        client: this.inode.parent.client,
      }
    },
  },
  methods: {
    printName(group_member) {
      if (this.value.shared_level_id) {
        return group_member.umbrella_name_short
      } else {
        return group_member.internal_name_long
      }
    },
  },
  components: {
    MemberLink,
    LabelWithHint,
    RemoteDropdown,
  },
}
</script>

<style scoped></style>
