import { computed, defineComponent, inject, } from "vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/events/form/event-form.service";
import DatePicker from "components/UI/DatePicker.vue";
import TextBody from "components/UI/TextBody.vue";
import TimePicker from "components/UI/TimePicker.vue";
import Subtitle from "components/UI/Subtitle.vue";
import "features/events/form/event-form.types";
import "components/EventForm/EventForm.vue";
import "features/common/forms/form.service";
export default defineComponent({
    name: "DateAndTimeFields",
    components: { Subtitle: Subtitle, TextBody: TextBody, DatePicker: DatePicker, TimePicker: TimePicker },
    props: {
        panelIdentifier: {
            type: String,
            required: true,
        },
    },
    setup: function (props) {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        eventFormService.registerProperty({
            name: "start_date",
        });
        eventFormService.registerProperty({
            name: "start_time",
        });
        eventFormService.registerProperty({
            name: "end_date",
        });
        eventFormService.registerProperty({
            name: "end_time",
        });
        eventFormService.registerProperty({
            name: "all_day",
        });
        var startTimeRule = {
            field: "start_time",
            message: t$(".please_enter_start_time"),
            selector: "event-form__start_time",
            panelIdentifier: props.panelIdentifier,
            action: function (v) { return !!v || eventFormService.getFormValue("all_day").value; },
        };
        var endTimeRule = {
            field: "end_time",
            message: t$(".please_enter_end_time"),
            selector: "event-form__end_time",
            panelIdentifier: props.panelIdentifier,
            action: function (v) { return !!v || eventFormService.getFormValue("all_day").value; },
        };
        var startDateRule = {
            field: "start_date",
            message: t$(".validate_start_date"),
            selector: "event-form__start_date",
            panelIdentifier: props.panelIdentifier,
            action: function (v) { return !!v && !!v.length; },
        };
        eventFormService.registerRule(startTimeRule);
        eventFormService.registerRule(endTimeRule);
        eventFormService.registerRule(startDateRule);
        var startDate = computed({
            get: function () {
                var _a;
                return (_a = eventFormService.getFormValue("start_date").value) !== null && _a !== void 0 ? _a : null;
            },
            set: function (value) {
                eventFormService.setFormValue("start_date", value);
                eventFormService.validate();
            },
        });
        var endDate = computed({
            get: function () {
                var _a;
                return (_a = eventFormService.getFormValue("end_date").value) !== null && _a !== void 0 ? _a : null;
            },
            set: function (value) {
                eventFormService.setFormValue("end_date", value);
            },
        });
        var endDatePickerDate = computed(function () {
            return endDate.value || startDate.value;
        });
        var allDay = computed({
            get: function () {
                var allDay = eventFormService.getFormValue("all_day").value;
                if (allDay !== undefined) {
                    return allDay;
                }
                else {
                    eventFormService.setFormValue("all_day", true);
                    return true;
                }
            },
            set: function (value) {
                if (value) {
                    eventFormService.setFormValue("start_time", null);
                    eventFormService.setFormValue("end_time", null);
                }
                eventFormService.setFormValue("all_day", value);
            },
        });
        var startTime = computed({
            get: function () {
                var start_time = eventFormService.getFormValue("start_time").value;
                return start_time && start_time !== "00:00" ? start_time : null;
            },
            set: function (value) {
                eventFormService.setFormValue("start_time", value);
                eventFormService.validate();
            },
        });
        var endTime = computed({
            get: function () {
                var end_time = eventFormService.getFormValue("end_time").value;
                return end_time && end_time !== "00:00" ? end_time : null;
            },
            set: function (value) {
                if (!endDate.value && startDate.value) {
                    endDate.value = startDate.value;
                }
                eventFormService.setFormValue("end_time", value);
                eventFormService.validate();
            },
        });
        var minTime = computed(function () {
            return endDate.value === startDate.value && startTime.value
                ? startTime.value
                : null;
        });
        return {
            t$: t$,
            eventFormService: eventFormService,
            startDate: startDate,
            endDate: endDate,
            endDatePickerDate: endDatePickerDate,
            allDay: allDay,
            startTime: startTime,
            endTime: endTime,
            minTime: minTime,
        };
    },
});
