import { __assign } from "tslib";
import { defineComponent, ref } from "vue";
import MediaUploader from "features/media/MediaUploader.vue";
import Header from "../../components/UI/Header.vue";
import "features/attachments/attachment.types";
import "features/media/media.types";
import AttachmentUploadTableItem from "features/attachments/AttachmentUploadTableItem.vue";
import { v4 as uuidv4 } from "uuid";
export default defineComponent({
    name: "AttachmentUploader",
    components: {
        MediaUploader: MediaUploader,
        Header: Header,
        AttachmentUploadTableItem: AttachmentUploadTableItem,
    },
    computed: {
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        },
    },
    data: function () {
        return {
            attachmentUploads: [],
        };
    },
    methods: {
        handleSaving: function (isSaving) {
            this.$emit("saving", isSaving);
        },
        updateUploads: function (updatedUploads) {
            var _this = this;
            updatedUploads.forEach(function (updatedUpload) {
                var oldUpload = _this.attachmentUploads.find(function (u) { return u.value.file.token === updatedUpload.file.token; });
                if (!oldUpload) {
                    console.warn("Updating an non-existent upload!!", updatedUpload, _this.attachmentUploads);
                }
                oldUpload.value = __assign(__assign({}, oldUpload.value), updatedUpload);
            });
        },
        emitValid: function (valid) {
            this.$emit("valid", valid);
        },
        retryUpload: function (token) {
            if (token) {
                var mediaUploader = this.$refs.mediaUploader;
                mediaUploader.retry(token);
            }
        },
        manuallyAddFileAndUpload: function (file) {
            var _a;
            var mediaUploader = this.$refs.mediaUploader;
            var _b = mediaUploader.manuallyAddFileAndUpload(file), storedFile = _b.storedFile, upload = _b.upload;
            var fileNameComponents = (_a = file.name) === null || _a === void 0 ? void 0 : _a.split(".");
            var ext = fileNameComponents.length > 1 ? fileNameComponents.pop() : "FILE";
            var fileName = fileNameComponents.join();
            var attachmentMediaUpload = ref({
                frontendId: uuidv4(),
                file: upload,
                name: fileName,
                type: ext,
                storedFile: storedFile,
                uploadProgress: 0,
            });
            this.attachmentUploads.push(attachmentMediaUpload);
            return attachmentMediaUpload;
        },
        manuallyOpenFileBrowser: function () {
            var mediaUploader = this.$refs.mediaUploader;
            mediaUploader.manuallyOpenFileBrowser();
        },
        manuallyDeleteUpload: function (frontendId) {
            var attachment = this.attachmentUploads.find(function (upload) { return upload.value.frontendId === frontendId; });
            // Will emit the uploads once more including the cancelled upload with cancelled state
            // Therefore trigger it first and afterwards delete from list
            var mediaUploader = this.$refs.mediaUploader;
            mediaUploader.manuallyDeleteUpload(attachment.value.file.token);
            console.log("Attachment found for cancellation", attachment);
            this.attachmentUploads = this.attachmentUploads.filter(function (upload) { return upload.value.frontendId !== frontendId; });
        },
        manuallyReset: function () {
            this.attachmentUploads = [];
            var mediaUploader = this.$refs.mediaUploader;
            mediaUploader.manuallyReset();
        },
    },
});
