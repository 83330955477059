import { computed, defineComponent } from "vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "UploadButton",
    props: {
        signingUrl: {
            type: String,
            default: null,
        },
        uploaderOpen: {
            type: Boolean,
            default: false,
        },
        showUploader: {
            type: Function,
        },
    },
    setup: function (props) {
        var t$Upload = useTranslate("drive.uploadButton").t$;
        var t$Cancel = useTranslate("drive.cancelUploadButton").t$;
        var toolTipText = computed(function () {
            return props.uploaderOpen ? t$Cancel(".tooltip") : t$Upload(".tooltip");
        });
        return {
            toolTipText: toolTipText,
            t$Upload: t$Upload,
            t$Cancel: t$Cancel,
        };
    },
});
