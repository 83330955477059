import { defineComponent } from "vue";
import Subtitle from "components/UI/Subtitle.vue";
import TextBody from "components/UI/TextBody.vue";
import Button from "components/UI/Button.vue";
export default defineComponent({
    name: "InstallPWAMobileView",
    components: { Button: Button, TextBody: TextBody, Subtitle: Subtitle },
    props: {
        mainHeader: {
            type: String,
            required: true,
        },
        hint: {
            type: String,
            required: true,
        },
        step1: {
            type: String,
            required: true,
        },
        step2: {
            type: String,
            required: true,
        },
        step3: {
            type: String,
            required: true,
        },
        forwardsButtonText: {
            type: String,
            required: true,
        },
        backwardsButtonText: {
            type: String,
            required: false,
        },
    },
    data: function () {
        return {
            currentImage: this.step1,
        };
    },
    mounted: function () {
        console.log("mounted called");
    },
    methods: {
        changeImage: function (tab) {
            switch (tab) {
                case "schritt-1":
                    this.currentImage = this.step1;
                    break;
                case "schritt-2":
                    this.currentImage = this.step2;
                    break;
                case "schritt-3":
                    this.currentImage = this.step3;
                    break;
                default:
                    this.currentImage = this.step1;
                    break;
            }
        },
    },
    setup: function (props, context) {
        return {};
    },
});
