<template lang="pug">
LoaderWrapper
  .header.context
    h1.ui.header {{$t('drive.edit_category.header')}}
  Header
  FolderForm(:folder="newCategory")
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")
import LoaderWrapper from "../LoaderWrapper.vue"
import FolderForm from "../FolderForm.vue"

export default {
  name: "EditCategory",
  data() {
    return {
      loaded: false,
    }
  },
  components: { FolderForm, LoaderWrapper },
  async created() {
    if (this.$route.params.inode_id) {
      await this.setCurrentInode(this.$route.params.inode_id)
    }
    this.loaded = true
  },
  computed: {
    newCategory() {
      return {
        name: "",
        description: "",
        series: false,
        owner: undefined,
        restrict_to_id: undefined,
        level_id: undefined,
        parent_id: undefined,
        parent: undefined,
        ordering: ["<name"],
      }
    },
    ...mapGetters(["currentInode"]),
  },
  methods: {},
}
</script>

<style scoped></style>
