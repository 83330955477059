import { __awaiter, __generator } from "tslib";
import { defineComponent } from "vue";
export default defineComponent({
    name: "AutocompleteFormField",
    props: {
        optionsFetch: {
            type: Function,
            default: function () { return []; },
        },
        value: { type: Object, default: null },
        optionText: { type: String },
        optionValue: { type: String },
        label: { type: String },
        placeholder: { type: String },
        rules: { type: Array },
        disabled: { type: Boolean, default: false },
        selectedItems: { type: Array, default: function () { return []; } },
    },
    data: function () {
        return {
            search: "",
            options: [this.value],
            isLoading: false,
            debounceTimer: null,
        };
    },
    computed: {
        selectedOption: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit("input", value);
            },
        },
    },
    methods: {
        isItemDisabled: function (item) {
            var _this = this;
            if (!this.selectedItems)
                return false;
            return this.selectedItems.some(function (selectedItem) { return selectedItem[_this.optionValue] === item[_this.optionValue]; });
        },
    },
    watch: {
        search: function (query) {
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    if (this.disabled)
                        return [2 /*return*/];
                    if (query && query.length > 0) {
                        clearTimeout(this.debounceTimer);
                        this.debounceTimer = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                            var _a;
                            return __generator(this, function (_b) {
                                switch (_b.label) {
                                    case 0:
                                        this.isLoading = true;
                                        _a = this;
                                        return [4 /*yield*/, this.optionsFetch(query)];
                                    case 1:
                                        _a.options = _b.sent();
                                        this.isLoading = false;
                                        return [2 /*return*/];
                                }
                            });
                        }); }, 500);
                    }
                    return [2 /*return*/];
                });
            });
        },
        value: function (value) {
            this.options = [value];
        },
    },
});
