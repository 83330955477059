import Vue from "vue";
import "suus-api";
export default Vue.extend({
    name: "MemberLink",
    props: {
        member: { type: Object, required: true },
        context: { type: Object, required: true },
    },
    computed: {
        contextClass: function () {
            return this.getContextClass(this.context);
        },
        contextName: function () {
            if (this.contextClass === "Level")
                return this.member.umbrella_name_short;
            if (this.contextClass === "Client")
                return this.member.internal_name_short;
            return this.member.umbrella_name_long;
        },
    },
    methods: {
        getContextClass: function (context) {
            if (context.group)
                return this.getContextClass({
                    level: context.group.level,
                    client: context.group.client,
                });
            if (context.level || context.level_id)
                return "Level";
            if (context.client || context.client_id)
                return "Client";
            return null;
        },
    },
});
