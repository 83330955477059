import { __awaiter, __generator } from "tslib";
import { defineComponent, ref } from "vue";
import Button from "components/UI/Button.vue";
import HappyGuy from "./assets/HappyGuy.svg";
import TextBody from "components/UI/TextBody.vue";
import TextButton from "components/UI/TextButton.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import ActivationWizardStep from "features/push/ActivationWizardStep.vue";
import "features/push/ActivationWizardStep.vue";
import Subtitle from "components/UI/Subtitle.vue";
export default defineComponent({
    name: "NotificationActivationWizard",
    components: {
        Subtitle: Subtitle,
        TextButton: TextButton,
        TextBody: TextBody,
        Button: Button,
        HappyGuy: HappyGuy,
        ActivationWizardStep: ActivationWizardStep,
    },
    props: {
        alreadyActivated: {
            type: Boolean,
            default: false,
        },
        requestNotificationPermission: {
            type: Function,
            required: true,
        },
        isNotificationPermissionGranted: {
            type: Boolean,
            required: true,
        },
        registerDevice: {
            type: Function,
            required: true,
        },
        sendTestPush: {
            type: Function,
            required: true,
        },
        waitForPush: {
            type: Function,
            required: true,
        },
    },
    computed: {
        showRetries: function () {
            var _a, _b;
            if (this.currentStep == null || this.currentStepState == "error") {
                return false;
            }
            return (((_a = this.currentStep) === null || _a === void 0 ? void 0 : _a.id) == "received" || ((_b = this.currentStep) === null || _b === void 0 ? void 0 : _b.id) == "sent");
        },
        getMessage: function () {
            if (this.success) {
                return { message: this.t$(".success"), isError: false };
            }
            else if (this.currentStepState == "error") {
                return { message: this.t$(".error"), isError: true };
            }
            else {
                return null;
            }
        },
        success: function () {
            return this.currentStep == null;
        },
        headerText: function () {
            var activatedText = this.t$(".header.activated");
            var successText = this.t$(".header.success");
            var activatingText = this.t$(".header.activating");
            return this.pushAlreadyActivated
                ? activatedText
                : this.success
                    ? successText
                    : activatingText;
        },
        getPermittedState: function () {
            return this.getStepState(this.currentStep, this.currentStepState, "permitted");
        },
        getRegisterState: function () {
            return this.getStepState(this.currentStep, this.currentStepState, "registered");
        },
        getSendState: function () {
            return this.getStepState(this.currentStep, this.currentStepState, "sent");
        },
        getReceiveState: function () {
            return this.getStepState(this.currentStep, this.currentStepState, "received");
        },
    },
    data: function () {
        return {
            receiveTries: 1,
            pushAlreadyActivated: this.alreadyActivated,
        };
    },
    mounted: function () {
        return __awaiter(this, void 0, void 0, function () {
            var startStep;
            return __generator(this, function (_a) {
                if (this.pushAlreadyActivated) {
                    return [2 /*return*/];
                }
                startStep = this.steps.find(function (step) { return step.id == "registered"; });
                this.install(startStep);
                return [2 /*return*/];
            });
        });
    },
    methods: {
        getStepState: function (currentStep, currentStepState, id) {
            if (currentStep == null) {
                return "success";
            }
            if (currentStep.id == id) {
                return currentStepState == "running" ? "active" : "error";
            }
            var stepIndex = this.steps.findIndex(function (step) { return step.id == id; });
            var currentStepIndex = this.steps.findIndex(function (step) { return step.id == currentStep.id; });
            return stepIndex < currentStepIndex ? "success" : "waiting";
        },
        repairPush: function () {
            this.currentStepState = null;
            this.receiveTries = 1;
            this.install(null, true);
        },
        testPush: function () {
            this.pushAlreadyActivated = false;
            var startStep = this.steps.find(function (step) { return step.id == "sent"; });
            this.install(startStep);
        },
        install: function (startStep, optionalFlag) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var success;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            this.currentStep = startStep || this.steps[0];
                            _b.label = 1;
                        case 1:
                            if (!(this.currentStep != null)) return [3 /*break*/, 3];
                            this.currentStepState = "running";
                            return [4 /*yield*/, this.currentStep.fn(optionalFlag)];
                        case 2:
                            success = _b.sent();
                            if (!success) {
                                if (this.currentStep.resetTo && this.receiveTries < 3) {
                                    this.receiveTries++;
                                    this.currentStep = this.steps.find(function (step) { return step.id == _this.currentStep.resetTo; });
                                    return [3 /*break*/, 1];
                                }
                                this.currentStepState = "error";
                                return [2 /*return*/];
                            }
                            this.currentStep =
                                (_a = this.steps[this.steps.indexOf(this.currentStep) + 1]) !== null && _a !== void 0 ? _a : null;
                            return [3 /*break*/, 1];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        },
    },
    setup: function (props) {
        var steps = [
            {
                id: "permitted",
                fn: props.requestNotificationPermission,
            },
            {
                id: "registered",
                fn: props.registerDevice,
            },
            { id: "sent", fn: props.sendTestPush },
            {
                id: "received",
                fn: props.waitForPush,
                resetTo: "sent",
            },
        ];
        var currentStep = ref(steps[0]);
        var currentStepState = ref(null);
        var t$ = useTranslate("components.push_wizard.activation_wizard").t$;
        return { t$: t$, steps: steps, currentStep: currentStep, currentStepState: currentStepState };
    },
});
