window.initGroupMembershipsIndex = (scope) ->
  $('.client .subgroup input:not(.button)').each (_, el) ->
    $(el).change (event) ->
      group_id = event.target.value
      user_id = $(el).closest('.client').find('#group_memberships_user_id').val()
      path = '/users/' + user_id + '/group_memberships/' + group_id + '?subgroups'
      return if group_id == undefined || user_id == undefined
      $.get path, (resp) ->
        newEl = $(resp)
        $('.subgroups').replaceWith newEl
        initSUI newEl
        initPage newEl

  $('.client form').each (_, form) ->
    $(form).submit (event) ->
      container = $(form)
      idField = '.'+container.find('.subgroups').data('id-from')+' input'
      group_id = container.find(idField).val()
      user_id = container.find('#group_memberships_user_id').val()
      url = 'users/' + user_id + '/group_memberships/'+group_id
      $('form').attr('href', url)
      form.submit()