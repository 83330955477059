import { __awaiter, __generator } from "tslib";
import { computed, ref } from "vue";
import { isSupported } from "firebase/messaging";
import { rejectAfter } from "helpers/promiseTimeout";
import "vue-cookies";
export var useNotifications = function () {
    var isPushSupported = ref(false);
    isSupported().then(function (v) { return (isPushSupported.value = v); });
    // Chrome has a feature called "quieter notifications" that prevents the
    // promise from Notification.requestPermission() from settling.
    // https://blog.chromium.org/2020/01/introducing-quieter-permission-ui-for.html
    var isNotificationPermissionMuted = ref(false);
    var notificationAvailable = "Notification" in window;
    var notificationPermissionStatus = ref(notificationAvailable ? Notification.permission : "denied");
    if (navigator === null || navigator === void 0 ? void 0 : navigator.serviceWorker) {
        navigator.serviceWorker
            .getRegistration()
            .then(function () {
            return (notificationPermissionStatus.value = notificationAvailable
                ? Notification.permission
                : "denied");
        });
    }
    var isNotificationPermissionGranted = computed(function () {
        return notificationPermissionStatus.value === "granted";
    });
    var isNotificationPermissionDenied = computed(function () {
        return notificationPermissionStatus.value === "denied";
    });
    var isNotificationPermissionRequired = computed(function () {
        return (notificationPermissionStatus.value === "default" &&
            !isNotificationPermissionMuted.value);
    });
    var requestNotificationPermission = function () { return __awaiter(void 0, void 0, Promise, function () {
        var _a, e_1, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    if (!notificationAvailable)
                        return [2 /*return*/, isNotificationPermissionGranted.value];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    _a = notificationPermissionStatus;
                    return [4 /*yield*/, rejectAfter(5000, Notification.requestPermission().then(function (eventuallyReceivedPermission) {
                            console.log("eventuallyReceivedPermission", eventuallyReceivedPermission);
                            isNotificationPermissionMuted.value = false;
                            notificationPermissionStatus.value = eventuallyReceivedPermission;
                            return eventuallyReceivedPermission;
                        }), function () { return (isNotificationPermissionMuted.value = true); })];
                case 2:
                    _a.value = _b.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    notificationPermissionStatus.value = "quiet";
                    console.warn("Permission request timed out, denied: ");
                    return [3 /*break*/, 4];
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_1 = _b.sent();
                    console.error(error_1);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/, isNotificationPermissionGranted.value];
            }
        });
    }); };
    var reloadNotificationPermissions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = isPushSupported;
                    return [4 /*yield*/, isSupported()];
                case 1:
                    _a.value = _b.sent();
                    notificationPermissionStatus.value = notificationAvailable
                        ? Notification.permission
                        : "denied";
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        isPushSupported: isPushSupported,
        notificationPermissionStatus: notificationPermissionStatus,
        isNotificationPermissionMuted: isNotificationPermissionMuted,
        isNotificationPermissionGranted: isNotificationPermissionGranted,
        isNotificationPermissionDenied: isNotificationPermissionDenied,
        isNotificationPermissionRequired: isNotificationPermissionRequired,
        reloadNotificationPermissions: reloadNotificationPermissions,
        requestNotificationPermission: requestNotificationPermission,
    };
};
