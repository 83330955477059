import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import THEME from "../constants/theme"

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: "mdiSvg",
  },
  theme: {
    themes: {
      ...THEME.colors,
    },
  },
}

export default new Vuetify(opts)
