import { computed, defineComponent } from "vue";
import "suus-api";
import { useIsBreakpointInNames } from "features/common/vuetify/useVuetify";
import PostGalleryPreviewDesktop from "features/posts/gallery/PostGalleryPreviewDesktop.vue";
import PostGalleryPreviewMobile from "features/posts/gallery/PostGalleryPreviewMobile.vue";
import TextButton from "components/UI/TextButton.vue";
import Button from "components/UI/Button.vue";
export default defineComponent({
    name: "PostGalleryPreview",
    components: {
        Button: Button,
        TextButton: TextButton,
        PostGalleryPreviewMobile: PostGalleryPreviewMobile,
        PostGalleryPreviewDesktop: PostGalleryPreviewDesktop,
    },
    props: {
        post: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        var isMobile = useIsBreakpointInNames(["xs", "sm"]);
        var buttonStyles = computed(function () {
            return isMobile.value ? { width: "100%" } : {};
        });
        return {
            isMobile: isMobile,
            buttonStyles: buttonStyles,
        };
    },
});
