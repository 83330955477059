import Vue from "vue";
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiAccountGroupOutline } from "@mdi/js";
export default Vue.extend({
    props: {
        items: {
            type: Array,
            default: [],
        },
        command: {
            type: Function,
            required: true,
        },
    },
    data: function () {
        return {
            selectedIndex: 0,
            memberIcon: mdiAccountCircleOutline,
            groupIcon: mdiAccountGroupOutline,
        };
    },
    watch: {
        items: function () {
            this.selectedIndex = 0;
        },
    },
    methods: {
        onKeyDown: function (_a) {
            var event = _a.event;
            if (event.key === "ArrowUp") {
                this.upHandler();
                return true;
            }
            if (event.key === "ArrowDown") {
                this.downHandler();
                return true;
            }
            if (event.key === "Enter") {
                this.enterHandler();
                return true;
            }
            return false;
        },
        upHandler: function () {
            this.selectedIndex =
                (this.selectedIndex + this.items.length - 1) % this.items.length;
        },
        downHandler: function () {
            this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
        },
        enterHandler: function () {
            this.selectItem(this.items[this.selectedIndex]);
        },
        selectItem: function (item) {
            //here are the mention attributes passed in
            if (item) {
                this.command({
                    label: item.name,
                    key: item.key,
                    type: item.key.split("#")[0],
                    name: item.name,
                });
            }
        },
    },
});
