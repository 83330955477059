import { render, staticRenderFns } from "./TopicFormPanels.vue?vue&type=template&id=d3769b76&scoped=true&lang=pug&"
import script from "./TopicFormPanels.vue?vue&type=script&lang=js&"
export * from "./TopicFormPanels.vue?vue&type=script&lang=js&"
import style0 from "./TopicFormPanels.vue?vue&type=style&index=0&id=d3769b76&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3769b76",
  null
  
)

export default component.exports