import { __awaiter, __generator } from "tslib";
import Vue, { defineComponent, inject } from "vue";
import TextHTMLAttachmentListItem from "components/TextHTMLAttachmentListItem.vue";
import vuetify from "initializers/vuetify";
import "suus-api";
import TextHTML from "components/UI/TextHTML.vue";
import RenderedImage from "components/Wysiwyg/RenderedImage.vue";
export default defineComponent({
    name: "RenderedWysiwygContent",
    components: { TextHTML: TextHTML },
    props: {
        html: String,
        attachments: { type: Array, default: [] },
        loadAttachment: {
            type: Function,
            default: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    console.log("No loadAttachment function provided in TextHTML.");
                    return [2 /*return*/];
                });
            }); },
        },
        customClass: { type: String, default: "" },
    },
    setup: function (props) {
        var _this = this;
        var lightboxService = inject("lightboxService", null);
        if (!lightboxService) {
            console.error("No lightbox service found in TextHTML. Make sure to provide the lightbox service in the app-root.");
        }
        var renderAttachments = function (root) { return __awaiter(_this, void 0, void 0, function () {
            var attachmentNodes, _loop_1, _a, _b, _i, nodeKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!root.value) return [3 /*break*/, 5];
                        attachmentNodes = root.value.querySelectorAll("attachment-item");
                        _loop_1 = function (nodeKey) {
                            var node, frontendId, attachment, _d, imageMode, ComponentClass, instance, imageSize, imageSrc, lightboxHeight, lightboxWidth, ComponentClass, instance;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        node = attachmentNodes[nodeKey];
                                        if (!node.getAttribute) {
                                            return [2 /*return*/, "continue"];
                                        }
                                        frontendId = node.getAttribute("data-frontend-id");
                                        attachment = props.attachments.find(function (a) { return a.frontend_id === frontendId; });
                                        _d = attachment;
                                        if (_d) return [3 /*break*/, 2];
                                        return [4 /*yield*/, props.loadAttachment(frontendId)];
                                    case 1:
                                        _d = (attachment = _e.sent());
                                        _e.label = 2;
                                    case 2:
                                        _d;
                                        imageMode = node.getAttribute("data-image-mode") || "attachment";
                                        if (!attachment) {
                                            console.error("Expected attachment not found in TextHTML.");
                                        }
                                        else if (imageMode === "attachment") {
                                            ComponentClass = Vue.extend(TextHTMLAttachmentListItem);
                                            instance = new ComponentClass({
                                                propsData: {
                                                    attachment: attachment,
                                                    lightboxService: lightboxService,
                                                },
                                                vuetify: vuetify,
                                            });
                                            instance.$mount();
                                            if (node.parentNode) {
                                                node.parentNode.replaceChild(instance.$el, node);
                                            }
                                            else {
                                                console.error("Parent node not found in TextHTML.");
                                            }
                                        }
                                        else {
                                            imageSize = node.getAttribute("data-image-size");
                                            imageSrc = attachment.links.original_url;
                                            lightboxHeight = attachment.height;
                                            lightboxWidth = attachment.width;
                                            ComponentClass = Vue.extend(RenderedImage);
                                            instance = new ComponentClass({
                                                propsData: {
                                                    src: imageSrc,
                                                    size: imageSize || undefined,
                                                    lightboxHeight: lightboxHeight,
                                                    lightboxWidth: lightboxWidth,
                                                    displayMode: imageMode || undefined,
                                                    fileName: attachment.name,
                                                    lightboxService: lightboxService,
                                                },
                                                vuetify: vuetify,
                                            });
                                            instance.$mount();
                                            if (node.parentNode) {
                                                node.parentNode.replaceChild(instance.$el, node);
                                            }
                                            else {
                                                console.error("Parent node not found in TextHTML.");
                                            }
                                        }
                                        return [2 /*return*/];
                                }
                            });
                        };
                        _a = [];
                        for (_b in attachmentNodes)
                            _a.push(_b);
                        _i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        nodeKey = _a[_i];
                        return [5 /*yield**/, _loop_1(nodeKey)];
                    case 2:
                        _c.sent();
                        _c.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        console.error("Root element not found in TextHTML.");
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        return { renderAttachments: renderAttachments };
    },
});
