import { __awaiter, __generator } from "tslib";
import TextBody from "../../../../components/UI/TextBody.vue";
import { computed, defineComponent, inject, ref, } from "vue";
import useMentionSearch from "concerns/useMentionSearch";
import "features/events/form/event-form.service";
import "components/EventForm/EventForm.vue";
import Subtitle from "components/UI/Subtitle.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "app/modules/core/types/dtos";
import "components/Wysiwyg/extensions/suus-mention/SuusMention";
import BasicWysiwyg from "components/Wysiwyg/BasicWysiwyg.vue";
import "features/common/forms/form.service";
import { PanelIdentifier } from "features/events/form/event-form.types";
import ForumWysiwygWithUploader from "components/Wysiwyg/ForumWysiwygWithUploader.vue";
import { eventsApi } from "store/api";
import "suus-api";
var DescriptionFields = defineComponent({
    name: "DescriptionFields",
    components: { ForumWysiwygWithUploader: ForumWysiwygWithUploader, BasicWysiwyg: BasicWysiwyg, Subtitle: Subtitle, TextBody: TextBody },
    props: {
        onFileDrop: {
            type: Function,
            required: false,
        },
    },
    methods: {
        getMentionableFunc: function (query) {
            return __awaiter(this, void 0, Promise, function () {
                var mentionableFunction;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            mentionableFunction = useMentionSearch(this.viewContext).mentionableFunction;
                            return [4 /*yield*/, mentionableFunction(query)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        },
    },
    setup: function (props) {
        var _this = this;
        var eventFormService = inject("EventFormService");
        var t$ = useTranslate("components.event_form").t$;
        eventFormService.registerProperty({
            name: "description",
        });
        var attachmentsValid = ref(true);
        var updateAttachmentsValid = function (valid) {
            attachmentsValid.value = valid;
        };
        var rule = {
            field: "description",
            selector: "event-form__description",
            message: t$("components.event_form.validate_attachments"),
            panelIdentifier: PanelIdentifier.GENERAL,
            action: function (v) { return attachmentsValid.value; },
        };
        eventFormService.registerRule(rule);
        var description = computed({
            get: function () {
                return eventFormService.getFormValue("description").value || "";
            },
            set: function (value) {
                eventFormService.setFormValue("description", value);
            },
        });
        var viewContext = computed(function () {
            var group = eventFormService.getFormValue("group").value;
            return {
                group: group,
                level_id: group === null || group === void 0 ? void 0 : group.level_id,
                client_id: group === null || group === void 0 ? void 0 : group.client_id,
            };
        });
        var attachments = ref([]);
        var loadAttachments = function () { return __awaiter(_this, void 0, Promise, function () {
            var eventId, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        eventId = eventFormService.currentId.toString();
                        // if new event is created, return empty array
                        if (!eventId) {
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, eventsApi.getEventAttachments(eventId)];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        }); };
        // Delay loading until there is the first request for it & do it only once
        var getAttachments = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(attachments.value.length === 0)) return [3 /*break*/, 2];
                        _a = attachments;
                        return [4 /*yield*/, loadAttachments()];
                    case 1:
                        _a.value = _b.sent();
                        console.log("Loading attachments the first time", attachments.value);
                        _b.label = 2;
                    case 2:
                        console.log("Returning already loaded attachments", attachments.value);
                        return [2 /*return*/, attachments.value];
                }
            });
        }); };
        var loadAttachment = function (frontendId) { return __awaiter(_this, void 0, Promise, function () {
            var attachments, attachment;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getAttachments()];
                    case 1:
                        attachments = _a.sent();
                        attachment = attachments.find(function (a) { return a.frontend_id === frontendId; });
                        if (attachment) {
                            return [2 /*return*/, attachment];
                        }
                        else {
                            console.error("Attachment not found!", frontendId, attachments);
                            throw "Attachment not found!";
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            description: description,
            viewContext: viewContext,
            loadAttachment: loadAttachment,
            updateAttachmentsValid: updateAttachmentsValid,
        };
    },
});
export default DescriptionFields;
