import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import Sheet from "../UI/Sheet.vue";
import Button from "../UI/Button.vue";
import FormLayout from "../UI/FormLayout.vue";
import TextButton from "../UI/TextButton.vue";
import TopicFormAlert from "./TopicFormAlert.vue";
import TopicFormPanels from "./TopicFormPanels.vue";
import { i18n } from "packs/application";
import { createNamespacedHelpers } from "vuex";
import { computed, defineComponent, getCurrentInstance, reactive, ref, watch, } from "vue";
import "concerns/useContextName";
import { useCanSendUrgentOnLevelOrClientFallback } from "features/common/permissions/usePermissionCheckHelper";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "app/modules/core/types/dtos";
var _a = createNamespacedHelpers("topic"), mapTopicActions = _a.mapActions, mapTopicState = _a.mapState, mapTopicMutations = _a.mapMutations;
export default defineComponent({
    name: "TopicForm",
    components: {
        Sheet: Sheet,
        Button: Button,
        FormLayout: FormLayout,
        TextButton: TextButton,
        TopicFormPanels: TopicFormPanels,
        TopicFormAlert: TopicFormAlert,
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            showSheet: false,
            isSaving: false,
        };
    },
    computed: __assign({ currentGroupIdFromRoute: function () {
            return this.$route.params.group_id || null;
        }, currentTopicIdFromRoute: function () {
            return this.$route.params.topic_id || null;
        }, btnLabel: function () {
            return this.t$(".new_topic");
        }, formTitle: function () {
            return this.isEdit ? this.t$(".edit_topic") : this.t$(".new_topic");
        }, alertText: function () {
            return this.t$(".topic_alert_info");
        }, isXsScreen: function () {
            return this.$vuetify.breakpoint.name === "xs";
        }, disableSaveButton: function () {
            return this.isSaving;
        } }, mapTopicState(["currentTopic"])),
    methods: __assign(__assign({ handleSheet: function (val) {
            this.showSheet = val;
        }, openSheetAndScrollToId: function (selector) {
            this.showSheet = true;
            setTimeout(function () {
                var el = document.getElementById(selector);
                if (el)
                    el.scrollIntoView({ behavior: "smooth" });
            }, 500);
        }, navigateToTopic: function (groupId, topicId) {
            window.open("/groups/".concat(groupId, "/topics/").concat(topicId), "_self");
        }, handleSave: function () {
            return __awaiter(this, void 0, void 0, function () {
                var isValid;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.isSaving = true;
                            isValid = this.validateBeforeSave();
                            if (!isValid) return [3 /*break*/, 4];
                            if (!this.isEditCurrently) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.editTopic()];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.createNewTopic()];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4:
                            this.isSaving = false;
                            return [2 /*return*/];
                    }
                });
            });
        }, validateBeforeSave: function () {
            this.fieldsWithErrors = this.validateFields(this.validationFields);
            var panelsWithValidationErrors = this.getPanelsWithValidationErrors(this.permittedPanels, this.fieldsWithErrors);
            if (panelsWithValidationErrors.length) {
                this.activePanels = __spreadArray(__spreadArray([], this.initialActivePanels, true), panelsWithValidationErrors, true);
            }
            return !this.fieldsWithErrors.length;
        }, createNewTopic: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return __awaiter(this, void 0, void 0, function () {
                var _j;
                return __generator(this, function (_k) {
                    switch (_k.label) {
                        case 0:
                            _j = this;
                            return [4 /*yield*/, this.createTopic(this.requestBody)];
                        case 1:
                            _j.isEditCurrently = _k.sent();
                            if (!((_a = this.currentTopic) === null || _a === void 0 ? void 0 : _a.error) && ((_c = (_b = this.currentTopic) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.id)) {
                                this.handleSheet(false);
                                this.navigateToTopic((_f = (_e = (_d = this.currentTopic) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.group) === null || _f === void 0 ? void 0 : _f.id, (_h = (_g = this.currentTopic) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.id);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }, editTopic: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_m) {
                    switch (_m.label) {
                        case 0: return [4 /*yield*/, this.updateTopic({
                                topicId: (_a = this.currentTopicIdFromRoute) !== null && _a !== void 0 ? _a : (_c = (_b = this.currentTopic) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.id,
                                requestBody: this.requestBody,
                            })];
                        case 1:
                            _m.sent();
                            if (!((_d = this.currentTopic) === null || _d === void 0 ? void 0 : _d.error)) {
                                this.handleSheet(false);
                                this.navigateToTopic((_e = this.currentGroupIdFromRoute) !== null && _e !== void 0 ? _e : (_h = (_g = (_f = this.currentTopic) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.group) === null || _h === void 0 ? void 0 : _h.id, (_j = this.currentTopicIdFromRoute) !== null && _j !== void 0 ? _j : (_l = (_k = this.currentTopic) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.id);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        } }, mapTopicActions(["updateTopic", "createTopic"])), mapTopicMutations(["SET_TOPIC_ERROR"])),
    setup: function (props, context) {
        var _a;
        var isEditCurrently = ref(props.isEdit);
        watch(function () { return props.isEdit; }, function (newVal) {
            isEditCurrently.value = newVal;
        });
        //input fields default values
        var formData = reactive({
            inputTitle: "",
            inputLevelOrClient: {
                type: null,
                value: null,
            },
            inputGroup: null,
            inputAccessVal: null,
            inputTags: [],
            inputText: "",
            inputRemind: false,
            remindMailedAt: null,
            remindMailedBy: null,
            inputReminder: null,
        });
        var setFormData = function (value) {
            formData[value.key] = value.val;
        };
        var root = getCurrentInstance().proxy;
        //Default dropdown options for inputLevelOrClient field
        var levelOrClientOptions = computed(function () {
            return root.$store.getters["member/memberScope"];
        });
        //when member api data has arrived and levelOrClientOptions are set
        //set inputLevelOrClient to current client as a initial value
        watch(levelOrClientOptions, function (newVal) {
            if (newVal) {
                var currentClient = newVal.find(function (l) { return l.type === "Client"; });
                formData.inputLevelOrClient = currentClient;
            }
        }, { immediate: true });
        //when group api data has arrived then the currentpage is a grouppage
        //set group level as the inputLevelOrClient
        //set grouppage group as groupinput
        var currentPageGroup = computed(function () {
            var _a, _b;
            return (_b = (_a = root.$store.state.group) === null || _a === void 0 ? void 0 : _a.currentGroup) === null || _b === void 0 ? void 0 : _b.data;
        });
        watch(currentPageGroup, function (newPageGroupVal) {
            if (newPageGroupVal) {
                formData.inputLevelOrClient = findClientOrLevelOfPageGroup(levelOrClientOptions === null || levelOrClientOptions === void 0 ? void 0 : levelOrClientOptions.value, newPageGroupVal);
                formData.inputGroup = newPageGroupVal;
            }
        }, { immediate: true });
        function findClientOrLevelOfPageGroup(levelsAndClientList, group) {
            if (group.level_id) {
                return levelsAndClientList.find(function (l) { return l.type === "Level" && l.value.id === group.level_id; });
            }
            return levelsAndClientList.find(function (l) { return l.type === "Client"; });
        }
        //when the topic api data has arrived
        //then the current page is a topic page and this form is an edit form
        //and the topic data should be set to the input fields
        var currentTopic = computed(function () {
            var _a, _b;
            return (_b = (_a = root.$store.state.topic) === null || _a === void 0 ? void 0 : _a.currentTopic) === null || _b === void 0 ? void 0 : _b.data;
        });
        var canEdit = computed(function () {
            return root.$store.getters["member/canEditTopicOnClient"];
        });
        watch(currentTopic, function (newTopicVal) {
            if (newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.id) {
                formData.inputTitle = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.title;
                formData.inputAccessVal = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.nonmember_access;
                formData.inputGroup = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.group;
                formData.inputTags = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.topic_tag_list;
                formData.inputText = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.text;
                formData.inputRemind = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.urgent;
                formData.remindMailedAt = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.mailed_at;
                formData.remindMailedBy = newTopicVal === null || newTopicVal === void 0 ? void 0 : newTopicVal.mailed_by;
            }
        }, { immediate: true });
        //Validation
        var inputTitleRules = [
            function (v) {
                return (!!v && !!v.length) ||
                    i18n.t("components.topic_form.validate_titleInput");
            },
        ];
        var inputTextRules = [
            function (v) {
                var isEmpty = !v || !v.length || v.toString() === "<p></p>";
                return isEmpty
                    ? i18n.t("components.topic_form.validate_textInput")
                    : true;
            },
        ];
        var inputGroupRules = [
            function (v) {
                return (!!v && !!v.name.length) ||
                    i18n.t("components.topic_form.validate_groupInput");
            },
        ];
        var fieldsWithErrors = ref([]);
        var validationFields = computed(function () { return [
            {
                fieldName: "inputTitle",
                panel: "general-fields",
                selector: "topic-form__titleInput",
                rules: inputTitleRules,
            },
            {
                fieldName: "inputGroup",
                panel: "general-fields",
                selector: "topic-form__groupInput",
                rules: inputGroupRules,
            },
            {
                fieldName: "inputText",
                panel: "description",
                selector: "topic-form__textInput",
                rules: inputTextRules,
            },
        ]; });
        var attachmentsValid = ref(true);
        function validateFields(fields) {
            var errorList = [];
            fields.forEach(function (field) {
                var message = "";
                field.rules.forEach(function (rule) {
                    var errorMessage = rule(formData[field.fieldName]);
                    if (typeof errorMessage == "string") {
                        message += errorMessage;
                    }
                });
                if (message) {
                    errorList.push({
                        panel: field.panel,
                        fieldName: field.fieldName,
                        selector: field.selector,
                        message: message,
                    });
                }
            });
            if (!attachmentsValid.value) {
                errorList.push({
                    panel: "description",
                    selector: "topic-form__textInput",
                    message: "Warte bis alle Anhänge hochgeladen sind oder entferne fehlerhafte Anhänge.",
                });
            }
            return errorList;
        }
        function getPanelsWithValidationErrors(panelsList, fieldsWithErrors) {
            var panelNames = fieldsWithErrors.map(function (errorField) { return errorField.panel; });
            var panelIndexes = panelsList.reduce(function (prevVal, currPanel, currIndex) {
                if (panelNames.includes(currPanel.name)) {
                    prevVal.push(currIndex);
                }
                return prevVal;
            }, []);
            return panelIndexes;
        }
        //permissions for panels
        var canSendUrgent = useCanSendUrgentOnLevelOrClientFallback((_a = formData.inputGroup) === null || _a === void 0 ? void 0 : _a.level_id);
        watch(function () { return formData.inputGroup; }, function (newVal) {
            canSendUrgent = useCanSendUrgentOnLevelOrClientFallback(newVal === null || newVal === void 0 ? void 0 : newVal.level_id);
        }, { immediate: true });
        var viewContext = computed(function () {
            var _a, _b, _c;
            var type = (_a = formData === null || formData === void 0 ? void 0 : formData.inputLevelOrClient) === null || _a === void 0 ? void 0 : _a.type;
            if (type === "Level") {
                return {
                    group_id: (_b = formData === null || formData === void 0 ? void 0 : formData.inputGroup) === null || _b === void 0 ? void 0 : _b.id,
                    level: formData.inputLevelOrClient.value,
                };
            }
            else {
                return {
                    group_id: (_c = formData === null || formData === void 0 ? void 0 : formData.inputGroup) === null || _c === void 0 ? void 0 : _c.id,
                    client: formData.inputLevelOrClient.value,
                };
            }
        });
        var onFileAddedListener = ref(null);
        var addFile = function (fileAndId) {
            if (onFileAddedListener.value) {
                onFileAddedListener.value(fileAndId);
            }
        };
        //panels
        var panels = computed(function () {
            var _a;
            return [
                {
                    name: "general-fields",
                    selector: "suus__topic-form__general-fields",
                    title: "Allgemein",
                    componentName: "TopicFormPanelGeneralFields",
                    componentProps: {
                        inputTitle: formData.inputTitle,
                        inputTitleValidation: inputTitleRules,
                        inputGroup: formData.inputGroup,
                        inputGroupValidation: inputGroupRules,
                        inputLevelOrClient: formData.inputLevelOrClient,
                        inputTags: formData.inputTags,
                        inputAccessVal: formData.inputAccessVal,
                        levelOrClientOptions: levelOrClientOptions.value,
                        isEdit: props.isEdit,
                    },
                    updateField: setFormData,
                    isActive: true,
                    permitted: true,
                },
                {
                    name: "description",
                    selector: "suus__topic-form__description",
                    title: "Diskussionstext",
                    componentName: "TopicFormPanelDescription",
                    componentProps: {
                        inputText: formData.inputText,
                        inputTextValidation: inputTextRules,
                        viewContext: viewContext.value,
                        updateValidationState: function (isValid) {
                            attachmentsValid.value = isValid;
                        },
                        addFile: addFile,
                        topicId: (_a = currentTopic.value) === null || _a === void 0 ? void 0 : _a.id,
                    },
                    updateField: setFormData,
                    isActive: true,
                    permitted: true,
                },
                {
                    name: "reminder",
                    selector: "suus__topic-form__reminder",
                    title: "Benachrichtigungen",
                    componentName: "TopicFormPanelReminder",
                    componentProps: {
                        inputReminder: formData.inputReminder,
                        remindMailedAt: formData.remindMailedAt,
                        remindMailedBy: formData.remindMailedBy,
                    },
                    updateField: setFormData,
                    isActive: canSendUrgent.value,
                    permitted: canSendUrgent.value,
                },
                {
                    name: "dangerzone",
                    selector: "suus__topic-form__dangerzone",
                    title: "Gefahrenzone",
                    componentName: "DangerZone",
                    componentProps: {
                        dangerZoneTitle: i18n.t("components.topic_form.delete_topic"),
                        dangerZoneText: i18n.t("components.topic_form.delete_topic_text"),
                        dangerZoneButtonLabel: i18n.t("components.topic_form.delete"),
                        confirmationTitle: i18n.t("components.topic_form.delete_topic_confirmation_title"),
                        confirmationText: i18n.t("components.topic_form.delete_topic_confirmation_text"),
                        handleConfirmation: handleTopicDeleteConfirmation,
                    },
                    updateField: function () { return null; },
                    isActive: props.isEdit,
                    permitted: props.isEdit && canEdit.value,
                },
            ];
        });
        var permittedPanels = computed(function () {
            return panels.value.filter(function (panel) { return panel.permitted; });
        });
        var initialActivePanels = computed(function () {
            var list = permittedPanels.value.reduce(function (prevVal, currPanel, currIndex) {
                if (currPanel.isActive)
                    prevVal.push(currIndex);
                return prevVal;
            }, []);
            return list;
        });
        var activePanels = ref(initialActivePanels.value);
        function handleTopicDeleteConfirmation(getTopicId) {
            if (getTopicId === void 0) { getTopicId = function () { var _a; return (_a = currentTopic.value) === null || _a === void 0 ? void 0 : _a.id; }; }
            root.$store.dispatch("topic/deleteTopic", getTopicId()).then(function () {
                window.history.back();
                window.location.reload();
            });
        }
        //Compose request body for API
        function composeTagsString(tagsList) {
            return tagsList.join(",");
        }
        var requestBody = computed(function () {
            var _a;
            return {
                title: formData.inputTitle,
                nonmember_access: formData.inputAccessVal,
                group: formData.inputGroup,
                group_id: (_a = formData.inputGroup) === null || _a === void 0 ? void 0 : _a.id,
                topic_tag_list: composeTagsString(formData.inputTags),
                text: formData.inputText,
                urgent: formData.inputRemind,
            };
        });
        var t$ = useTranslate("components.topic_form").t$;
        return {
            levelOrClientOptions: levelOrClientOptions,
            viewContext: viewContext,
            requestBody: requestBody,
            validationFields: validationFields,
            validateFields: validateFields,
            fieldsWithErrors: fieldsWithErrors,
            activePanels: activePanels,
            permittedPanels: permittedPanels,
            getPanelsWithValidationErrors: getPanelsWithValidationErrors,
            initialActivePanels: initialActivePanels,
            isEditCurrently: isEditCurrently,
            t$: t$,
        };
    },
});
