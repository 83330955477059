import Vue from "vue";
import { mdiAccountCircleOutline } from "@mdi/js";
import "suus-api";
import { goToUrl } from "../../helpers/navigation";
export default Vue.extend({
    name: "Avatar",
    props: {
        name: { type: String, default: "avatar" },
        imageUrl: { type: String, required: false },
        profileUrl: { type: String, required: false },
        variant: {
            type: String,
            default: "lg",
            validator: function (val) { return ["xxs", "xs", "sm", "md", "lg"].includes(val); },
        },
        size: { type: Number, default: 100 },
        clients: { type: Array, default: function () { return []; } },
    },
    data: function () { return ({
        userIcon: mdiAccountCircleOutline,
    }); },
    computed: {
        imageSize: function () {
            switch (this.variant) {
                case "xxs":
                    return 36;
                case "xs":
                    return 50;
                case "sm":
                    return 60;
                case "md":
                    return 80;
                default:
                    return 110;
            }
        },
        thumbSize: function () {
            switch (this.variant) {
                case "xs":
                    return 12;
                case "sm":
                    return 14;
                case "md":
                    return 18;
                default:
                    return 24;
            }
        },
        containerStyles: function () {
            return {
                width: "".concat(this.imageSize, "px"),
            };
        },
        thumbsStyles: function () {
            return {
                minHeight: this.thumbSize * 1.5,
                padding: "".concat(this.thumbSize / 8, "px 0 0 0"),
            };
        },
        defaultStyles: function () {
            return "background-color: ".concat(this.$vuetify.theme.themes.light.primary, ";");
        },
        borderStyles: function () {
            return {
                border: "1px solid white",
                boxShadow: "0px 0px 16px 1px ".concat(this.$vuetify.theme.themes.light.mediumGrey),
            };
        },
        firstRibbonStyles: function () {
            var _a;
            var styles = "".concat((_a = this.clients[0]) === null || _a === void 0 ? void 0 : _a.ribbon_css, "; transform: rotate(-45deg);");
            return this.clients.length ? styles : this.defaultStyles;
        },
    },
    methods: {
        getRibbonStyles: function (ribbon_css) {
            return "".concat(ribbon_css, ";").concat("transform: rotate(-45deg)", "; ");
        },
        goToProfile: function () {
            if (this.profileUrl)
                goToUrl(this.profileUrl);
        },
    },
});
