import { __assign, __awaiter, __generator } from "tslib";
import InodeAclInput from "./InodeAclInput.vue";
import RemoteDropdown from "../RemoteDropdown.vue";
import ListItem from "../ListItem.vue";
import LabelWithHint from "../LabelWithHint.vue";
import { computed, ref, watch } from "vue";
import "suus-api";
import { DriveVuexKiller } from "app/vuex-killers";
import { useRouter } from "app/vue-instance/use-router";
var ORDER_OPTIONS = [
    { value: "<name", text: "Name (A-Z)" },
    { value: ">name", text: "Name (Z-A)" },
    { value: "<created_at", text: "Hochgeladen (Alt -> Neu)" },
    { value: ">created_at", text: "Hochgeladen (Neu -> Alt)" },
    { value: "<physically_created_at", text: "Datierung (Alt -> Neu)" },
    { value: ">physically_created_at", text: "Datierung (Neu -> Alt)" },
];
export default {
    name: "FolderForm",
    components: {
        LabelWithHint: LabelWithHint,
        InodeAclInput: InodeAclInput,
        RemoteDropdown: RemoteDropdown,
        ListItem: ListItem,
    },
    props: {
        folder: { type: Object, required: true },
    },
    setup: function (props) {
        var _this = this;
        var createFolder = DriveVuexKiller.createFolder;
        var updateInode = DriveVuexKiller.updateInode;
        var driveError = DriveVuexKiller.driveError;
        var setDriveError = DriveVuexKiller.setDriveError;
        var setDriveSuccess = DriveVuexKiller.setDriveSuccess;
        var formErrors = ref({});
        var isAnyError = computed(function () {
            return Object.values(formErrors.value).some(function (v) { return !!v; });
        });
        var validateForm = function () {
            formErrors.value = {};
            if (!name.value) {
                formErrors.value.name = "required";
            }
            return !Object.values(formErrors.value).some(function (v) { return !!v; });
        };
        var name = ref(props.folder.name);
        var isNameMissing = computed(function () { return formErrors.value.name === "required"; });
        watch(name, function () {
            if (isNameMissing.value && name.value) {
                formErrors.value = __assign(__assign({}, formErrors.value), { name: undefined });
            }
        });
        var description = ref(props.folder.description);
        var ordering = ref(props.folder.ordering);
        // Order of a root folder in the menu
        var order = ref(props.folder.order);
        var series = ref(props.folder.series);
        var type = computed(function () { return (series.value ? "Container" : "Folder"); });
        var icon = computed(function () {
            return series.value ? "suus-single-copies" : "suus-folder-13";
        });
        var acl = ref(null);
        var setAcl = function (value) {
            acl.value = value;
        };
        var formData = computed(function () {
            var acl_data = acl.value || {};
            return {
                inode: __assign({ id: props.folder.id, name: name.value, ordering: ordering.value, order: order.value, type: type.value, description: description.value }, acl_data),
            };
        });
        var router = useRouter();
        var saveForm = function () { return __awaiter(_this, void 0, void 0, function () {
            var id, response, e_1;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!validateForm())
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, , 7]);
                        id = (_b = (_a = props.folder) === null || _a === void 0 ? void 0 : _a.id) === null || _b === void 0 ? void 0 : _b.toString();
                        if (!id) return [3 /*break*/, 3];
                        return [4 /*yield*/, updateInode(props.folder.update_url, formData.value)];
                    case 2:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, createFolder(formData.value)];
                    case 4:
                        response = _c.sent();
                        id = response.id.toString(); // set id to newly created id
                        _c.label = 5;
                    case 5:
                        setDriveSuccess("Erfolgreich gespeichert");
                        router.push({ name: "drive/folder", params: { inode_id: id } });
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _c.sent();
                        console.log(e_1);
                        setDriveError(e_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        return {
            ORDER_OPTIONS: ORDER_OPTIONS,
            createFolder: createFolder,
            updateInode: updateInode,
            driveError: driveError,
            setDriveError: setDriveError,
            setDriveSuccess: setDriveSuccess,
            isAnyError: isAnyError,
            validateForm: validateForm,
            name: name,
            isNameMissing: isNameMissing,
            description: description,
            ordering: ordering,
            order: order,
            series: series,
            type: type,
            icon: icon,
            setAcl: setAcl,
            formData: formData,
            saveForm: saveForm,
        };
    },
};
