import { defineComponent } from "vue";
import { EVENT_STATUS } from "constants/constants";
import "vue";
import StatusIcon from "components/UI/StatusIcon.vue";
import { mdiCheck, mdiClose } from "@mdi/js";
import Header from "components/UI/Header.vue";
import TextBody from "components/UI/TextBody.vue";
import TextButton from "components/UI/TextButton.vue";
import GuestModal from "components/Event/GuestModal.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "EventAttendAlert",
    components: { GuestModal: GuestModal, TextButton: TextButton, TextBody: TextBody, Header: Header, StatusIcon: StatusIcon },
    props: {
        attendStatus: {
            type: String,
            required: true,
        },
        guestCount: {
            type: Number,
        },
        guestsAllowed: {
            type: Boolean,
        },
    },
    data: function () { return ({
        showModal: false,
        mdiCheck: mdiCheck,
        mdiClose: mdiClose,
    }); },
    computed: {
        modalWidth: function () {
            // TODO: Refactor into mixin, also used in AttendCard
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return 600;
                case "sm":
                    return 800;
                case "md":
                    return 600;
                default:
                    return 800;
            }
        },
        isEventPassed: function () {
            return this.attendStatus === EVENT_STATUS.EVENT_PASSED;
        },
        register: function () {
            return this.attendStatus === EVENT_STATUS.REGISTER;
        },
        isRegistered: function () {
            return (this.attendStatus === EVENT_STATUS.REGISTERED ||
                this.attendStatus === EVENT_STATUS.FINISHED_REGISTRATION ||
                this.attendStatus === EVENT_STATUS.ATTEND_ONLINE_NOW);
        },
        isDeregistered: function () {
            return this.attendStatus === EVENT_STATUS.DEREGISTERED;
        },
        getEventStateTranslateComponent: function () {
            if (this.register)
                return "REGISTER";
            else if (this.isRegistered)
                return "REGISTERED";
            else if (this.isDeregistered)
                return "DEREGISTERED";
            return null;
        },
        alertContent: function () {
            var eventState = this.getEventStateTranslateComponent;
            if (!eventState)
                return null;
            var tEventState = this.getComponent(eventState).t;
            return {
                headerText: tEventState("headerText"),
                bodyText: tEventState("bodyText"),
                icon: this.isRegistered
                    ? mdiCheck
                    : this.isDeregistered
                        ? mdiClose
                        : null,
                registerBtn: tEventState("registerBtn"),
                guestText: this.isRegistered ? this.hasGuests : null,
                guestBtn: this.isRegistered ? this.guestButtonText : null,
            };
        },
        hasGuests: function () {
            if (!this.guestsAllowed)
                return null;
            var tHasGuest = useTranslate("components.attendee_list.has_guests").t;
            var guestQuantity = this.guestCount < 1 ? "none" : this.guestCount === 1 ? "one" : "more";
            return tHasGuest(guestQuantity, [this.guestCount]);
        },
        guestButtonText: function () {
            if (!this.guestsAllowed)
                return null;
            return "Ändern";
        },
    },
    methods: {
        toggleAttending: function () {
            var isAttend = !this.isRegistered;
            this.$emit("toggleAttending", isAttend);
        },
    },
    setup: function () {
        var _a = useTranslate("components.attendee_list.alert_content"), t = _a.t, getComponent = _a.getComponent;
        return {
            t: t,
            getComponent: getComponent,
        };
    },
});
