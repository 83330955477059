import Vue from "vue";
export default Vue.extend({
    name: "FieldLabel",
    props: ["mandatory", "label"],
    computed: {
        labelStyles: function () {
            return {
                fontFamily: "system-ui !important",
                color: this.$vuetify.theme.themes.light.primary,
                fontWeight: 400,
                marginBottom: 0,
            };
        },
        mandatoryStyles: function () {
            return {
                color: "red",
                fontWeight: 400,
            };
        },
    },
});
