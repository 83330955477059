import { __assign } from "tslib";
import Vue from "vue";
import THEME from "../../constants/theme";
var button = THEME.typography.button;
export default Vue.extend({
    name: "TextButton",
    props: {
        label: { type: String },
        color: { type: String, default: "textColor" },
        plain: { type: Boolean, default: false },
        block: { type: Boolean, default: false },
        variant: {
            type: String,
            default: "large",
        },
    },
    computed: {
        setColor: function () {
            var _a, _b;
            if (this.color === "primary") {
                return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
            }
            if (this.color === "textColor") {
                return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
            }
            return this.color;
        },
        styleObject: function () {
            return __assign(__assign({}, button), { fontSize: this.variant === "small" ? "".concat(0.7, "em") : button.fontSize, "--color": this.setColor, "--color-hover": this.$vuetify.theme.themes.light.primary });
        },
    },
});
