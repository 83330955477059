import Vue from "vue"

Vue.filter("googleMaps", function (address) {
  let URIquery = address
    .trim()
    .split(",")
    .map((el) => encodeURIComponent(el))
    .join("+")
  return `https://www.google.com/maps/search/?api=1&query=${URIquery}`
})
