import { defineComponent, ref } from "vue";
import AutocompleteFormField from "features/common/forms/form-fields/AutocompleteFormField.vue";
import "suus-api";
import GuestAmountSelection from "components/Event/GuestAmountSelection.vue";
import TextButton from "components/UI/TextButton.vue";
import Button from "components/UI/Button.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import { RsvpReply } from "suus-api";
import { getContextNameShortPropertyName, } from "concerns/useContextName";
export default defineComponent({
    name: "AddEditAttendeeDialog",
    components: {
        Button: Button,
        TextButton: TextButton,
        AutocompleteFormField: AutocompleteFormField,
        GuestAmountSelection: GuestAmountSelection,
    },
    props: {
        attendee: Object,
        response: String,
        guests: { type: Number, default: 0 },
        fetchAttendees: Function,
        guestsAllowed: Boolean,
        selectedItems: Array,
        viewContext: Object,
    },
    computed: {
        RsvpReply: function () {
            return RsvpReply;
        },
        isEdit: function () {
            return !!this.attendee;
        },
        guestButtonDisabled: function () {
            return this.selectedResponse == RsvpReply.Decline;
        },
    },
    data: function () {
        return {
            guest_count: this.guests,
            selectedAttendee: this.attendee,
            selectedResponse: this.response,
            dialog: false,
            valid: true,
        };
    },
    methods: {
        handleCancel: function () {
            this.$emit("cancel");
            this.dialog = false;
        },
        handleSave: function () {
            this.form.validate();
            if (!this.valid)
                return;
            this.$emit("save", {
                attendee: this.selectedAttendee,
                response: this.selectedResponse,
                guest_count: this.guest_count,
            });
            this.dialog = false;
        },
        openDialog: function () {
            var _this = this;
            this.dialog = true;
            this.$nextTick(function () {
                _this.form.resetValidation();
                _this.selectedAttendee = _this.attendee;
                _this.selectedResponse = _this.response;
                _this.guest_count = _this.guests;
            });
        },
        getContextName: function () {
            if (this.viewContext == null) {
                return "full_name";
            }
            else
                return getContextNameShortPropertyName(this.viewContext);
        },
    },
    setup: function () {
        var responseOptions = [
            RsvpReply.Attend,
            RsvpReply.Decline,
            RsvpReply.Pending,
        ];
        var form = ref(null);
        var t$ = useTranslate("components.add_attendee_dialog").t$;
        return {
            responseOptions: responseOptions,
            form: form,
            t$: t$,
            getContextNameShortPropertyName: getContextNameShortPropertyName,
        };
    },
});
