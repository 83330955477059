import Vue from "vue";
import MenuItem from "./MenuItem.vue";
import Button from "../../UI/Button.vue";
import { mdiUndo, mdiRedo, mdiClose, mdiWrap, mdiLinkVariant, mdiFormatClear, mdiFormatQuoteOpen, mdiFormatListNumbered, mdiFormatBold, mdiFormatItalic, mdiFormatStrikethrough, mdiFormatColorHighlight, mdiFormatListBulleted, } from "@mdi/js";
import "components/Wysiwyg/menu/MenuItem.vue";
export default Vue.extend({
    components: {
        MenuItem: MenuItem,
        Button: Button,
    },
    props: {
        editor: { type: Object, required: true },
        inline: { type: Boolean, default: false },
        additionalMenuItems: {
            type: Array,
            default: function () { return []; },
        },
    },
    data: function () {
        return {
            url: null,
            dialog: false,
            closeIcon: mdiClose,
        };
    },
    computed: {
        items: function () {
            var _this = this;
            var menuItems = [
                {
                    icon: mdiFormatBold,
                    title: "Bold",
                    action: function () {
                        _this.editor.chain().focus().toggleBold().run();
                    },
                    isActive: function () { return _this.editor.isActive("bold"); },
                },
                {
                    icon: mdiFormatItalic,
                    title: "Italic",
                    action: function () {
                        _this.editor.chain().focus().toggleItalic().run();
                    },
                    isActive: function () { return _this.editor.isActive("italic"); },
                },
                {
                    icon: mdiFormatStrikethrough,
                    title: "Strike",
                    action: function () {
                        _this.editor.chain().focus().toggleStrike().run();
                    },
                    isActive: function () { return _this.editor.isActive("strike"); },
                },
                {
                    icon: mdiFormatColorHighlight,
                    title: "Highlight",
                    action: function () {
                        _this.editor.chain().focus().toggleHighlight().run();
                    },
                    isActive: function () { return _this.editor.isActive("highlight"); },
                },
                {
                    type: "divider",
                },
                {
                    icon: mdiFormatListBulleted,
                    title: "Bullet List",
                    action: function () {
                        _this.editor.chain().focus().toggleBulletList().run();
                    },
                    isActive: function () { return _this.editor.isActive("bulletList"); },
                },
                {
                    icon: mdiFormatListNumbered,
                    title: "Ordered List",
                    action: function () {
                        _this.editor.chain().focus().toggleOrderedList().run();
                    },
                    isActive: function () { return _this.editor.isActive("orderedList"); },
                },
                {
                    icon: mdiFormatQuoteOpen,
                    title: "Blockquote",
                    action: function () {
                        _this.editor.chain().focus().toggleBlockquote().run();
                    },
                    isActive: function () { return _this.editor.isActive("blockquote"); },
                },
                {
                    icon: mdiLinkVariant,
                    title: "Link",
                    action: function () {
                        _this.handleModal(true);
                    },
                    isActive: function () { return _this.editor.isActive("link"); },
                },
                {
                    type: "divider",
                },
                {
                    icon: mdiWrap,
                    title: "Hard Break",
                    action: function () {
                        _this.editor.chain().focus().setHardBreak().run();
                    },
                },
                {
                    icon: mdiFormatClear,
                    title: "Clear Format",
                    action: function () {
                        _this.editor.chain().focus().clearNodes().unsetAllMarks().run();
                    },
                },
                {
                    icon: mdiUndo,
                    title: "Undo",
                    action: function () {
                        _this.editor.chain().focus().undo().run();
                    },
                },
                {
                    icon: mdiRedo,
                    title: "Redo",
                    action: function () {
                        _this.editor.chain().focus().redo().run();
                    },
                },
            ];
            if (this.additionalMenuItems.length > 0) {
                menuItems.push({ type: "divider" });
                menuItems.push.apply(menuItems, this.additionalMenuItems);
            }
            return menuItems;
        },
        isLinkActive: function () {
            return this.editor.isActive("link");
        },
        isXsScreen: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
        modalTitle: function () {
            return this.isLinkActive ? "URL bearbeiten" : "URL hinzufügen";
        },
        labelStyles: function () {
            return {
                color: this.$vuetify.theme.themes.light.primary,
                fontWeight: 900,
                fontSize: "1.1em",
            };
        },
        editorClasses: function () {
            return "d-flex flex-wrap align-center justify-start suus__editor__menu ".concat(this.inline ? "suus__editor__menu--inline" : "");
        },
    },
    methods: {
        handleModal: function (bool) {
            this.dialog = bool;
        },
        handleSubmit: function () {
            this.editor.chain().focus().setLink({ href: this.url }).run();
            this.dialog = false;
        },
        removeLink: function () {
            this.url = null;
            this.editor.chain().focus().unsetLink().run();
            this.dialog = false;
        },
    },
});
