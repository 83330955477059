import { __assign } from "tslib";
import Vue from "vue";
import THEME from "../../constants/theme";
var _a = THEME.typography, subtitle = _a.subtitle, rootFontSize = _a.rootFontSize;
export default Vue.extend({
    name: "Subtitle",
    props: {
        text: { type: String, required: false },
        fontSize: { type: Number, default: 1 },
        customClass: { type: String, default: "" },
        fontWeight: { type: Number, default: 700 },
        color: { type: String, default: "textColor" },
        lineHeight: { type: Number },
    },
    computed: {
        setColor: function () {
            var _a, _b;
            switch (this.color) {
                case "primary":
                    return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
                case "textColor":
                    return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
                default:
                    return "".concat(this.color);
            }
        },
        styleObject: function () {
            return __assign(__assign(__assign({ color: this.setColor }, subtitle), (this.fontWeight && { fontWeight: this.fontWeight })), (this.fontSize && {
                fontSize: "".concat(this.fontSize * rootFontSize, "px !important"),
                lineHeight: this.lineHeight ? "".concat(this.lineHeight, "px !important") : "".concat(this.fontSize * 1.5 * rootFontSize, "px !important"),
            }));
        },
        classArray: function () {
            return ["text-subtitle-1 suus__subtitle", "".concat(this.customClass)];
        },
    },
});
