import { __awaiter, __generator } from "tslib";
import "features/gallery/gallery.types";
import { eventMediaApi } from "store/api";
export function getGalleryMedia(postId, offset, limit) {
    return __awaiter(this, void 0, Promise, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, eventMediaApi.getEventMedia(postId.toString(), offset, limit, "asc")];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
