import { __awaiter, __generator } from "tslib";
import { eventsApi, highlightsApi } from "store/api";
import "features/gallery/gallery.types";
import "suus-api";
export function addGalleyMediumToHighlights(eventId, galleryMediumId) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, highlightsApi.addHighlight(eventId.toString(), {
                        event_medium_id: galleryMediumId,
                        send_to_back: true,
                    })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function removeHighlight(gallery) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!gallery.highlight_id)
                        return [2 /*return*/];
                    return [4 /*yield*/, highlightsApi.removeFromHighlights(gallery.highlight_id.toString())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function getHighlights(eventId, offset, limit) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, highlightsApi.getHighlights(eventId.toString(), offset, limit, "asc")];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function getHighlightsGalleryMedia(eventId, offset, limit) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getHighlights(eventId, offset, limit)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.map(function (highlight) { return highlight.event_medium; })];
            }
        });
    });
}
export function setFeaturedImage(eventId, uploaded_file) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, eventsApi.updateEvent(eventId.toString(), {
                        featured_image_id: uploaded_file.id,
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
export function reorderHighlights(_a) {
    var id = _a.id, index = _a.index;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, highlightsApi.updateHighlight(id.toString(), { index: index })];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
}
