import List from 'list.js'

# To be used in combination with Listjs
# Adds ability to combine multiple filters

# Class attributes!
List::activeFilters = []
List::sortAttr = undefined
List::sortAsc = true

# Register a new input field that is watched for changes to filter
List::registerFilterOption = (input, attr, matchFnc) ->
  activeFilters = this.activeFilters

  # Do not register the same filter twice
  # return false unless activeFilters[attr] == undefined

  list = this
  #$(input).off 'change'
  $(input).change (el) ->
    filterVal = if el.target.type == 'checkbox' then $(el.target).is(':checked') else el.target.value
    # console.log 'Filtering: '+attr+'='+filterVal
    if (filterVal.length > 0 && filterVal != "&nbsp;") || el.target.type == 'checkbox'
      attrFilter = (item) ->
        itemVal = item.values()[attr]
        return matchFnc itemVal, filterVal
      # console.log 'Adding filter '+attr
      activeFilters[attr] = attrFilter
    else
      # console.log 'Removing filter '+attr
      delete activeFilters[attr]

    activeFilterFunctions = []
    Object.keys(activeFilters).forEach (attrFilter) ->
      activeFilterFunctions.push activeFilters[attrFilter]

    list.filter (item) ->
      activeFilterFunctions.every (f) -> f(item)

  # console.log 'Attribute '+input+' not found!' unless $(input)[0]
  if $(input).val().length > 0
    $(input).trigger 'change'

List::registerSortOption = (el, attr) ->
  list = this
  list.alphabet = "AÄBCDEFGHIJKLMNOÖPQRSßTUÜVXYZaäbcdefghijklmnoöpqrstuüvxyz"

  toggleSorting = (attr, el) ->
    list.sortAsc = !list.sortAsc

    if attr != list.sortAttr
      list.sortAttr = attr
      list.sortAsc = true

    d = if list.sortAsc then 'asc' else 'desc'

    $('.'+list.sortClass).removeClass('active asc desc')
    $(el).addClass('active')
    $(el).addClass(d)
    list.sort attr, { order: d}

  $(el).click -> toggleSorting(attr, el)

List::registerSearchForm = (id) ->
  list = this
  $('#'+id).on 'keyup', ->
    searchString = $(this).val()
    list.search(searchString)

##############################
# Item/filter matching modes #
##############################

# Do an exact match on strings
List.exactMatch = (itemVal, filterVal) ->
  itemVal == filterVal

# Match, when list of given attribute (comma seperated) are
# all contained in the item attributes (comma seperated). The ordering
# of the items is ignored.
List.subsetMatch = (itemVal, filterVal) ->
  return false unless itemVal
  itemVals = itemVal.split(',')
  return filterVal.split(',').every (el) ->
    itemVals.includes(el)

# Matches, as soon as the intersection of both sets is not empty
# I.e. of one of the attributes matches
List.containsAny = (itemVal, filterVal) ->
  return false unless itemVal
  itemVals = itemVal.split(',')
  return filterVal.split(',').some (el) ->
    itemVals.includes(el)

List.booleanMatch = (val) ->
  return (itemVal, filterVal) ->
    if filterVal then itemVal == val else itemVal != val
