import { __awaiter, __generator } from "tslib";
import { defineComponent, ref, watch } from "vue";
import "../interfaces/error.interface";
import AttachmentListItemGroup from "./AttachmentListItemGroup.vue";
import AttachmentListSkeleton from "./AttachmentListSkeleton.vue";
import "suus-api";
import { topicsApi, eventsApi } from "store/api";
import "features/common/shared/polymorphic";
export default defineComponent({
    name: "AttachmentList",
    components: { AttachmentListItemGroup: AttachmentListItemGroup, AttachmentListSkeleton: AttachmentListSkeleton },
    props: {
        attachmentCount: { type: Number, required: false, default: 0 },
        attachable: { type: Object, required: false },
    },
    setup: function (props) {
        var attachments = ref([]);
        var error = ref(null);
        var isPending = ref(false);
        function getAttachments(attachableId, attachableType) {
            return __awaiter(this, void 0, Promise, function () {
                var data, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            isPending.value = true;
                            error.value = null;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 6, 7, 8]);
                            data = {};
                            if (!(attachableType === "Topic")) return [3 /*break*/, 3];
                            return [4 /*yield*/, topicsApi.getTopicAttachments(attachableId)];
                        case 2:
                            data = (_a.sent()).data;
                            return [3 /*break*/, 5];
                        case 3:
                            if (!(attachableType === "Event")) return [3 /*break*/, 5];
                            return [4 /*yield*/, eventsApi.getEventAttachments(attachableId)];
                        case 4:
                            data = (_a.sent()).data;
                            _a.label = 5;
                        case 5:
                            attachments.value = data;
                            return [3 /*break*/, 8];
                        case 6:
                            err_1 = _a.sent();
                            error.value = err_1;
                            return [3 /*break*/, 8];
                        case 7:
                            isPending.value = false;
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        }
        watch(function () { return props.attachable; }, function (value, oldValue) {
            isPending.value = true;
            setTimeout(function () {
                var _a;
                getAttachments((_a = value.id) === null || _a === void 0 ? void 0 : _a.toString(), value.type);
            }, 2500);
        }, { immediate: true });
        return { attachments: attachments, isPending: isPending, error: error };
    },
});
