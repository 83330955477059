import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
  currentGroup: {
    data: null,
    error: null,
    loading: false,
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
