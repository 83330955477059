import Vue from "vue";
export default Vue.extend({
    name: "LoadingListItem",
    props: {
        image: { type: Boolean, default: true },
        title: { type: Boolean, default: true },
        meta: { type: Boolean, default: false },
        actions: { type: Boolean, default: false },
    },
});
