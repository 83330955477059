import { defineComponent } from "vue";
import InstallPWADesktopView from "features/push/InstallPWADesktopView.vue";
import ScreenStepsRow from "features/push/ScreenStepsRow.vue";
import AndroidStep1 from "./assets/install-screens/AndroidStep1.png";
import AndroidStep2 from "./assets/install-screens/AndroidStep2.png";
import AndroidStep3 from "./assets/install-screens/AndroidStep3.png";
import AndroidStep4 from "./assets/install-screens/AndroidStep4.png";
import AndroidStep5 from "./assets/install-screens/AndroidStep5.png";
import AndroidStep6 from "./assets/install-screens/AndroidStep6.png";
import IOSStep1 from "./assets/install-screens/IOSStep1.png";
import IOSStep2 from "./assets/install-screens/IOSStep2.png";
import IOSStep3 from "./assets/install-screens/IOSStep3.png";
import IOSStep4 from "./assets/install-screens/IOSStep4.png";
import IOSStep5 from "./assets/install-screens/IOSStep5.png";
import IOSStep6 from "./assets/install-screens/IOSStep6.png";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "InstallPWADesktopWizard",
    components: {
        ScreenStepsRow: ScreenStepsRow,
        InstallPWADesktopView: InstallPWADesktopView,
    },
    props: {},
    computed: {
        AndroidStep1: function () {
            return AndroidStep1;
        },
        AndroidStep2: function () {
            return AndroidStep2;
        },
        AndroidStep3: function () {
            return AndroidStep3;
        },
        AndroidStep4: function () {
            return AndroidStep4;
        },
        AndroidStep5: function () {
            return AndroidStep5;
        },
        AndroidStep6: function () {
            return AndroidStep6;
        },
        IOSStep1: function () {
            return IOSStep1;
        },
        IOSStep2: function () {
            return IOSStep2;
        },
        IOSStep3: function () {
            return IOSStep3;
        },
        IOSStep4: function () {
            return IOSStep4;
        },
        IOSStep5: function () {
            return IOSStep5;
        },
        IOSStep6: function () {
            return IOSStep6;
        },
    },
    data: function () {
        return {
            step: 0,
        };
    },
    setup: function (props, context) {
        var preload = function (src) {
            var image = new Image();
            image.src = src;
        };
        preload(AndroidStep1);
        preload(AndroidStep2);
        preload(AndroidStep3);
        preload(AndroidStep4);
        preload(AndroidStep5);
        preload(AndroidStep6);
        preload(IOSStep1);
        preload(IOSStep2);
        preload(IOSStep3);
        preload(IOSStep4);
        preload(IOSStep5);
        preload(IOSStep6);
        var t$ = useTranslate("components.push_wizard.install_pwa_wizard").t$;
        return {
            t$: t$,
        };
    },
});
