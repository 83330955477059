import { defineComponent } from "vue";
export default defineComponent({
    name: "GalleryThumbnailOverlay",
    props: {
        backgroundColor: {
            type: String,
            required: true,
        },
    },
});
