export default {
  SET_TOPIC_DATA(state, topic) {
    state.currentTopic = { ...state.currentTopic, data: topic }
  },
  SET_TOPIC_LOADING(state, loading) {
    state.currentTopic = { ...state.currentTopic, loading: loading }
  },
  SET_TOPIC_ERROR(state, error) {
    state.currentTopic = { ...state.currentTopic, error: error }
  },
  UPDATE_TOPICSLIST(state, topic) {
    const updatedTopicList = state.topics.map((el, index) => {
      if (Object.keys(el)[index] !== topic.id) {
        return el
      }
      return { [topic.id]: topic }
    })
    state.topics = updatedTopicList
  },
  REMOVE_TOPIC_FROM_TOPICSLIST(state, topicId) {
    const updatedTopicList = state.topics.filter(
      (el, index) => Object.keys(el)[index] !== topicId
    )
    state.topics = updatedTopicList
  },
  SET_TOPIC_REACTIONS(state, reactions) {
    state.currentTopicReactions = reactions
  }
}
