import { defineComponent } from "vue";
import "components/Drive/drive.types";
import DriveUploadComponent from "components/Drive/DriveUpload.vue";
export default defineComponent({
    name: "DriveUploaderForm",
    components: {
        DriveUploadComponent: DriveUploadComponent,
    },
    props: {
        uploadsArray: {
            type: Array,
            default: function () { return []; },
        },
        parent: {
            type: Object,
        },
    },
    data: function () {
        return {
            formData: {},
        };
    },
    methods: {
        onChange: function () {
            this.$emit("change");
        },
        emitFormData: function () {
            this.$emit("formData", this.formData);
        },
        removeUpload: function (token) {
            this.$emit("remove", token);
        },
        emitS3Key: function (s3Key, token) {
            this.$emit("s3Key", { s3Key: s3Key, token: token });
        },
    },
    watch: {
        uploadsArray: {
            handler: function (val, oldVal) {
                var newFormData = {};
                for (var _i = 0, val_1 = val; _i < val_1.length; _i++) {
                    var upload = val_1[_i];
                    newFormData[upload.file.token] =
                        this.formData[upload.file.token] || {};
                }
                this.formData = newFormData;
            },
        },
    },
});
