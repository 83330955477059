<template lang="pug">
DangerZone(
  :dangerZoneTitle="deleteHeaderText",
  :confirmationTitle="confirmationTitle",
  :confirmationText="confirmationText",
  :dangerZoneText="deleteBodyText",
  :handleConfirmation="handleDelete"
)
</template>

<script>
import DangerZone from "../UI/DangerZone.vue"
import { createNamespacedHelpers } from "vuex"
const { mapActions, mapState } = createNamespacedHelpers("event")
import { defineComponent } from "vue"
import { PanelIdentifier } from "features/events/form/event-form.types"
const DangerZoneFields = defineComponent({
  name: "DangerzoneFields",
  components: { DangerZone },
  computed: {
    deleteHeaderText() {
      return this.$t("components.event_form.delete_event")
    },
    deleteBodyText() {
      return this.$t("components.event_form.delete_event_description")
    },
    confirmationTitle() {
      return this.$t("components.event_form.confirmation_title")
    },
    confirmationText() {
      return this.$t("components.event_form.confirmation_text")
    },
    ...mapState(["eventForm"]),
  },
  methods: {
    goBack() {
      window.history.back()
      window.location.reload()
    },
    async handleDelete() {
      await this.deleteEvent(this.eventForm.id)
      this.goBack()
    },
    ...mapActions(["deleteEvent"]),
  },
})

export default DangerZoneFields

export const DangerZoneFieldsDefinition = {
  key: "dangerzone",
  component: DangerZoneFields,
  id: PanelIdentifier.DANGER_ZONE,
}
</script>
