import { onMounted, onUnmounted, ref } from "vue";
export var useDragDetection = function () {
    var isDragging = ref(false);
    var timeout;
    var onDragOver = function (event) {
        isDragging.value = true;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(function () {
            isDragging.value = false;
        }, 100);
    };
    onMounted(function () {
        window.addEventListener("dragover", onDragOver);
    });
    onUnmounted(function () {
        window.removeEventListener("dragover", onDragOver);
    });
    return {
        isDragging: isDragging,
    };
};
