import "suus-api";
import { defineComponent } from "vue";
import AttachmentsListItem from "components/AttachmentListItem.vue";
export default defineComponent({
    name: "AttachmentListItemGroup",
    components: { AttachmentsListItem: AttachmentsListItem },
    props: {
        attachments: { type: Array, required: true },
        twoColumns: { type: Boolean, required: false, default: false },
    },
});
