import Mention from "@tiptap/extension-mention";
import "@tiptap/core";
import "prosemirror-model";
import { mergeAttributes } from "@tiptap/vue-2";
export var SuusMention = Mention.extend({
    // Add attributes to the virtual mention node and
    addAttributes: function () {
        return {
            key: {
                renderHTML: function (attributes) { return ({ "data-key": attributes.key }); },
            },
            type: {
                renderHTML: function (attributes) {
                    return {
                        class: attributes.type === "GroupMember"
                            ? "mention member-mention"
                            : "mention group-mention",
                    };
                },
            },
            label: {
                renderHTML: function (attributes) { return ({ "data-label": attributes.label }); },
            },
        };
    },
    // The set of conjunctive rules to parse a Mention-Node from HTML
    // @see https://tiptap.dev/guide/custom-extensions#parse-html
    parseHTML: function () {
        return [
            {
                // match by css selector
                tag: "a.mention",
                // Link-extension watches also for a-Tags. Default is 50.
                priority: 100,
                // match by tag attributes
                getAttrs: function (node) {
                    if (typeof node == "string") {
                        // a style rule
                        return false;
                    }
                    else {
                        // a Tag rule always receives a HTMLElement
                        var htmlElement = node;
                        try {
                            if (htmlElement.classList.contains("mention")) {
                                return {
                                    key: htmlElement.getAttribute("data-key"),
                                    type: htmlElement.getAttribute("data-key").split("#")[0],
                                    label: htmlElement.innerHTML,
                                };
                            }
                        }
                        catch (e) {
                            console.error(e);
                            return false;
                        }
                        return false;
                    }
                },
            },
        ];
    },
    // Render the Mention-Node to a HTML element
    // @see https://tiptap.dev/guide/custom-extensions#render-html
    renderHTML: function (_a) {
        var node = _a.node, htmlAttributes = _a.HTMLAttributes;
        return [
            "a",
            mergeAttributes(htmlAttributes, {
                style: "pointer-events: none",
            }),
            node.attrs.label, // content (or children)
        ];
    },
});
