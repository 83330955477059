import { defineComponent } from "vue";
import { mdiClose } from "@mdi/js";
import Header from "components/UI/Header.vue";
import Subtitle from "components/UI/Subtitle.vue";
import GuideDecoration from "./assets/GuideDecoration.svg";
import Button from "components/UI/Button.vue";
import TextBody from "components/UI/TextBody.vue";
import TextButton from "components/UI/TextButton.vue";
export default defineComponent({
    name: "InstallPWADesktopView",
    components: {
        TextButton: TextButton,
        TextBody: TextBody,
        Button: Button,
        Subtitle: Subtitle,
        Header: Header,
        GuideDecoration: GuideDecoration,
    },
    props: {
        mainHeader: {
            type: String,
            required: true,
        },
        iosHint: {
            type: String,
            required: true,
        },
        androidHint: {
            type: String,
            required: true,
        },
        forwardsButtonText: {
            type: String,
            required: true,
        },
        backwardsButtonText: {
            type: String,
            required: false,
        },
    },
    computed: {
        isAndroid: function () {
            return this.tab === 0;
        },
        isIos: function () {
            return this.tab === 1;
        },
        hint: function () {
            console.log(this.tab);
            return this.isAndroid ? this.androidHint : this.iosHint;
        },
    },
    data: function () { return ({
        tab: 0,
    }); },
    setup: function (props, context) {
        return {
            mdiClose: mdiClose,
        };
    },
});
