<template lang="pug">
div(
  class="field select optional search selection remote"
  :class="{ multiple: multiple, disabled: isDisabled }"
  @focus="loadOptions"
)
  label(class="select optional") {{ label }}
  SuiDropdown(ref="dropdown"
    :class="{diabled: isDisabled}"
    :multiple="multiple"
    :options="blankOption.concat(items)"
    :placeholder="placeholder"
    :loading="loading"
    v-model="value"
    @focus="loadOptions"
    @keyup="filter = $refs.dropdown.filter"
    fluid
    search
    selection
  )
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  props: {
    name: [String],
    endpoint: [String],
    multiple: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return name
      },
    },
    label: {
      type: String,
      default() {
        return name
      },
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
    initialValues: {
      type: Array,
      default() {
        return []
      },
    },
    defaultValue: { type: [Number, String, Object], default: null },
    textFunction: {
      type: Function,
      default(x) {
        return x.text
      },
    },
    iconFunction: {
      type: Function,
      default(x) {
        return null
      },
    },
    valueFunction: {
      type: Function,
      default(x) {
        return x.id
      },
    },
    preload: { type: Boolean, default: false },
    minQueryLength: { type: Number, default: 0 },
    remoteSearch: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    includeBlank: {
      type: [Object, Boolean],
      default() {
        return false
      },
    },
  },
  data() {
    return {
      value: this.defaultValue,
      loading: false,
      items: this.initialValues,
      filter: "",
      loadedEndpoint: "",
      isDisabled: this.disabled,
    }
  },
  computed: {
    endpointUrl() {
      const url = new URL(window.location.origin + this.endpoint)
      Object.entries(this.params).forEach(([key, value]) => {
        url.searchParams.set(key, value)
      })
      if (this.remoteSearch) {
        url.searchParams.set("filter", this.filter)
      }
      return url.toString()
    },
    blankOption() {
      if (this.includeBlank === false) {
        return []
      } else if (this.includeBlank === true) {
        return [{ text: "", value: " " }]
      } else {
        return [
          {
            text: this.textFunction(this.includeBlank),
            value: this.valueFunction(this.includeBlank),
            icon: this.iconFunction(this.includeBlank),
          },
        ]
      }
    },
  },
  watch: {
    value(val) {
      this.$emit("input", val)
    },
    endpoint() {
      this.$refs.dropdown.updateFilter("")
      if (this.minQueryLength === 0) {
        this.items = []
        this.value = undefined
        this.loadOptions()
      } else {
        this.items = [{ text: "Tippen zum Suchen...", disabled: true }]
        this.loadedEndpoint = ""
      }
    },
    params() {
      this.filter = ""
      this.loadOptions()
    },
    filter() {
      if (
        !this.value &&
        this.remoteSearch &&
        this.filter.length < this.minQueryLength
      ) {
        this.items = [{ text: "Tippen zum Suchen...", disabled: true }]
      } else {
        this.loadOptions()
      }
    },
  },
  methods: {
    loadOptions: async function () {
      const loadingEndpoint = this.endpointUrl

      if (
        loadingEndpoint === this.loadedEndpoint ||
        this.filter.length < this.minQueryLength
      )
        return
      console.log(`Loading ${loadingEndpoint}`)
      this.items = [{ text: "Lade...", disabled: true }]
      this.loading = true
      this.error = false

      try {
        const { data } = await this.$http.get(loadingEndpoint)
        const loadedItems = data.results.map((e) => {
          return {
            text: this.textFunction(e),
            value: this.valueFunction(e),
            icon: this.iconFunction(e),
          }
        })
        if (loadedItems.length === 0) {
          this.items = [{ text: "Keine Ergebnisse", disabled: true }]
        } else {
          this.items = loadedItems
          if (!this.items.find((i) => i.value == this.value)) this.value = null
        }
        this.loadedEndpoint = loadingEndpoint
      } catch (err) {
        this.error = err
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
  created: function () {
    if (this.preload) this.loadOptions()
  },
})
</script>
