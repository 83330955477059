import { __assign } from "tslib";
import Vue from "vue";
import THEME from "../../constants/theme";
var _a = THEME.typography, hyperlink = _a.hyperlink, rootFontSize = _a.rootFontSize;
export default Vue.extend({
    name: "Hyperlink",
    props: {
        url: { type: String, default: "#" },
        small: { type: Boolean, default: false },
        text: { type: String, default: "Mehr" },
        clickHandler: Function,
        targetBlank: { type: Boolean, default: false },
        color: { type: String, default: "primary" },
        customClass: { type: String, required: false },
    },
    computed: {
        linkStyles: function () {
            return __assign(__assign(__assign({}, hyperlink), (this.small && this.smallLinkStyles)), { color: this.setColor });
        },
        smallLinkStyles: function () {
            return {
                fontSize: "".concat(0.8 * rootFontSize, "px"),
                lineHeight: "".concat(0.8 * 1.6 * rootFontSize, "px"),
            };
        },
        setColor: function () {
            var _a, _b;
            switch (this.color) {
                case "primary":
                    return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
                case "textColor":
                    return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
                default:
                    return "".concat(this.color);
            }
        },
        hrefTarget: function () {
            return this.targetBlank ? "_blank" : "_self";
        },
    },
});
