import Vue from "vue";
export default Vue.extend({
    props: {
        expanded: { type: Boolean, default: false },
        highlight: { type: Boolean, default: true },
        clickToOpen: { type: Boolean, default: false },
    },
    data: function () {
        return {
            clicked: false,
        };
    },
    computed: {
        isExpanded: function () {
            if (!this.$slots["extra-action"] &&
                !this.$slots["extra-items"] &&
                !this.$slots["description"]) {
                return false;
            }
            if (this.expanded === undefined) {
                return this.clicked;
            }
            else {
                return this.expanded;
            }
        },
    },
});
