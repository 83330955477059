window.initLazyComponentLoad = (scope) ->

  doInsert = (insertTarget) ->
    (rawData) ->
      initSUI insertTarget.parent()
      initPage insertTarget.parent()
      insertTarget.trigger('lazy-loaded')
      console.log 'Component lazy loaded'

  doReplace = (insertTarget) ->
    (rawData) ->
      data = $(rawData)
      parent = insertTarget.parent()
      insertTarget.replaceWith(data)
      initSUI data
      initPage data
      parent.trigger('lazy-loaded')
      console.log 'Component lazy loaded and initial element replaced'

  # Replaces a element with the returned content from a form submit or an remote link
  # if data-load-replace is present but without value, the form is replaced, otherwise the id
  $("a[data-load-replace], form[data-load-replace]").each ->
    replaceTargetId = '#'+$(this).attr('data-load-replace')
    handler =  (event) ->
      data    = event.detail[0].getElementsByTagName('body')[0].innerHTML
      status  = event.detail[1]
      xhr     = event.detail[2];
      replaceTarget = $(replaceTargetId) if $(replaceTargetId)[0]
      doReplace(replaceTarget)(data)
    $(this).on 'ajax:success', handler
    $(this).on 'ajax:error', handler
    $(this).removeAttr('data-load-replace')

  # Loads an partial lazely by inserting received html inside the element as soon as the page is loaded
  # data-load must contain the URL to be loaded
  # Optional options:
  #   data-load-replace (bool): Do not load inside the contain but replace container with loaded content
  #   data-load-target (id): Load the received html into the given Element. Trigger is element itself
  #   data-load-trigger (selector): Dont load on page load but onclick.
  $("[data-load]").each (_, elem) ->
    el = $(elem)
    insertTarget = el
    targetId = el.attr('data-load-target')
    insertTarget = $('#'+targetId) if targetId
    url = el.attr('data-load')
    trigger = el.attr('data-load-trigger')
    replace = el.attr('data-load-replace') != undefined

    loadFnc = if replace
                ->
                  insertTarget.addClass('loading').html('<div class="ui loader"></div>')
                  $.get url, doReplace(insertTarget)
              else
                ->
                  insertTarget.addClass('loading').html('<div class="ui loader"></div>')
                  insertTarget.load url, doInsert(insertTarget)

    if targetId
      $(el).click loadFnc
    else if trigger
      $('#'+trigger).click loadFnc
    else
      loadFnc()

    el.removeAttr('data-load')
    el.removeAttr('data-load-target')
    el.removeAttr('data-load-trigger')
    el.removeAttr('data-load-replace')