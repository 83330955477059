import { __extends } from "tslib";
import { ThumbnailService, } from "features/gallery/service/thumbnail.service";
import "features/gallery/service/gallery.service";
import { buildThumbnailRepresentation } from "features/gallery/service/thumbnail.functions";
/**
 * This service is responsible for managing the state of the thumbnails.
 * Is based on the ThumbnailService, but adds the functionality to support featured images.
 * When a featured image is set, the thumbnail representation is updated.
 * Overrides the buildThumbnailRepresentation with "_buildEventHighlightsThumbnailRepresentation"
 *  function to add the featured image functionality.
 *
 */
var EventHighlightThumbnailService = /** @class */ (function (_super) {
    __extends(EventHighlightThumbnailService, _super);
    function EventHighlightThumbnailService(galleryService) {
        var _this = _super.call(this, galleryService, buildThumbnailRepresentation(galleryService)) || this;
        _this.buildThumbnailRepresentation =
            _this._buildEventHighlightsThumbnailRepresentation.bind(_this);
        return _this;
    }
    /**
     * If user has no edit rights, the top-right action is removed.
     * Else either the button for setting the featured image or the display of the filled-star is added to the top-right action.
     */
    EventHighlightThumbnailService.prototype._buildEventHighlightsThumbnailRepresentation = function (thumbnailRepresentation, canEdit) {
        if (canEdit) {
            return buildThumbnailRepresentation(this.galleryService)(thumbnailRepresentation, canEdit);
        }
        else {
            var updatedThumbnailRepresentation = buildThumbnailRepresentation(this.galleryService)(thumbnailRepresentation, canEdit);
            updatedThumbnailRepresentation.topRightAction.value = null;
            return updatedThumbnailRepresentation;
        }
    };
    return EventHighlightThumbnailService;
}(ThumbnailService));
export { EventHighlightThumbnailService };
