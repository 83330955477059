import store from "../../../store";
import { computed } from "vue";
export function usePermissionStore() {
    var levelPermissions = computed(function () { return store.getters["member/getCurrentLevelPermissions"]; });
    var clientPermissions = computed(function () { return store.getters["member/getCurrentClientPermissions"]; } //store.state.member?.currentMember?.data?.client_permissions ?? []
    );
    var isCurrentMemberLoaded = computed(function () { return store.getters["member/isCurrentMemberLoaded"]; });
    if (!isCurrentMemberLoaded.value) {
        store.dispatch("member/getCurrentMember");
    }
    return {
        levelPermissions: levelPermissions,
        clientPermissions: clientPermissions,
    };
}
