import { defineComponent } from "vue";
import Avatar from "components/UI/Avatar.vue";
import { getContextNameLong } from "concerns/useContextName";
import "suus-api";
export default defineComponent({
    name: "PostAvatar",
    methods: { getContextNameLong: getContextNameLong },
    components: { Avatar: Avatar },
    props: {
        viewContext: Object,
        creator: {
            type: Object,
            required: true,
        },
        alwaysSmall: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        variant: function () {
            if (this.alwaysSmall) {
                return "sm";
            }
            switch (this.$vuetify.breakpoint.name) {
                case "xs":
                    return "sm";
                case "sm":
                    return "md";
                default:
                    return "md";
            }
        },
    },
});
