import { __awaiter, __generator } from "tslib";
import { ref, watchEffect } from "vue";
import { commentsApi } from "../store/api";
import "../interfaces/error.interface";
export default function useDeleteComment(commentId) {
    var isSuccess = ref(false);
    var error = ref(null);
    var isPending = ref(false);
    function deletePostComment() {
        return __awaiter(this, void 0, Promise, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isSuccess.value = false;
                        isPending.value = true;
                        error.value = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, commentsApi.deleteComment(commentId)];
                    case 2:
                        _a.sent();
                        isSuccess.value = true;
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        error.value = err_1;
                        return [3 /*break*/, 5];
                    case 4:
                        isPending.value = false;
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    watchEffect(deletePostComment);
    return {
        isPending: isPending,
        isSuccess: isSuccess,
        error: error,
    };
}
