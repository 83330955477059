import NProgress from "nprogress"
import "./nprogress.sass"

window.addEventListener("DOMContentLoaded", function () {
  const selector1 = ".ui.large.sub.menu.attached.borderless"
  const selector2 = ".ui.main.menu.attached.left-middle-right.borderless"

  if (document.querySelector(selector1)) {
    NProgress.configure({ parent: selector1 })
  } else if (document.querySelector(selector2)) {
    NProgress.configure({ parent: selector2 })
  }
})
