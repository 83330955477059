import "features/media/media.types";
var UploadWaitingList = /** @class */ (function () {
    function UploadWaitingList() {
        this._waitingList = new Map();
    }
    UploadWaitingList.prototype.startWaiting = function (file) {
        var _this = this;
        return new Promise(function (resolve) {
            _this._waitingList.set(file, resolve);
        });
    };
    UploadWaitingList.prototype.finishWaiting = function (file, mediaUpload) {
        var resolver = this._waitingList.get(file);
        if (resolver) {
            resolver(mediaUpload.s3Key);
            this._waitingList.delete(file);
        }
    };
    return UploadWaitingList;
}());
export { UploadWaitingList };
