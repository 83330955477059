import Vue from "vue";
import { mdiCalendarRange } from "@mdi/js";
import moment from "moment";
export default Vue.extend({
    name: "DatePicker",
    data: function () {
        return {
            date: this.value,
            menu: false,
            calenderIcon: mdiCalendarRange,
            currentPickerDateValue: null,
        };
    },
    props: {
        value: { type: String },
        pickerDate: { type: String },
        label: { type: String, default: "Datum" },
        isMandatory: { type: Boolean, default: true },
        minDate: { type: String, default: undefined },
        maxDate: { type: String, default: undefined },
        allowedDates: {
            type: Function,
            default: function (_) { return true; },
        },
    },
    computed: {
        formattedDisplayDate: function () {
            return this.date ? moment(this.date).format("dd. DD.MM.YYYY") : null;
        },
        labelStyles: function () {
            return {
                color: this.$vuetify.theme.themes.light.primary,
                fontWeight: 400,
            };
        },
        mandatoryStyles: function () {
            return {
                color: "red",
                fontWeight: 400,
            };
        },
        errorMessages: function () {
            if (!this.date && this.isMandatory) {
                return ["Bitte wähle einen Datum."];
            }
            return [];
        },
        currentPickerDate: {
            set: function (val) {
                if (val !== this.pickerDate)
                    this.currentPickerDateValue = val
                        ? moment(val).format("YYYY-MM")
                        : null;
            },
            get: function () {
                return this.currentPickerDateValue;
            },
        },
    },
    methods: {
        onInput: function (val) {
            this.menu = false;
            if (this.errorMessages.length === 0) {
                this.$emit("input", val);
            }
        },
        togglePicker: function () {
            this.menu = !this.menu;
        },
        onClear: function () {
            this.$emit("input", null);
        },
    },
    watch: {
        pickerDate: function (val) {
            this.currentPickerDate = val;
        },
        value: function (val) {
            if (val !== this.date)
                this.date = val;
        },
    },
});
