import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

import Drive from "./components/Drive/Drive.vue"
import LatestFiles from "./components/Drive/Views/LatestFiles.vue"
import FileBrowser from "./components/Drive/Views/FileBrowser.vue"
import CreateFolder from "./components/Drive/Views/CreateFolder.vue"
import EditFolder from "./components/Drive/Views/EditFolder.vue"
import DriveFile from "./components/Drive/Views/DriveFile.vue"
import CreateCategory from "./components/Drive/Views/CreateCategory.vue"
import EditDriveFile from "./components/Drive/Views/EditDriveFile.vue"
import PortalTopic from "./components/Portal/PortalTopic.vue"
import PortalEvent from "./components/Portal/PortalEvent.vue"
import PortalAttend from "./components/Portal/PortalAttend.vue"
import PortalDecline from "./components/Portal/PortalDecline.vue"
import PortalEventForm from "./components/Portal/PortalEventForm.vue"
import PortalTopicAndEventForm from "./components/Portal/PortalTopicAndEventForm.vue"
import { useCanAccessGroup } from "features/common/permissions/usePermissionCheckHelper"

const routes = [
  {
    path: "/events/:event_id",
    component: PortalEvent,
    name: "Event",
  },
  {
    path: "/global_calendar",
    component: PortalEventForm,
  },
  {
    path: "/open_calendar",
    component: PortalEventForm,
  },
  {
    path: "/user_calendar",
    component: PortalEventForm,
  },
  {
    path: "/groups/new",
    component: null,
  },
  {
    path: "/",
    name: "dashboard",
    component: PortalTopicAndEventForm,
  },
  {
    path: "/groups",
    name: "groups",
    component: PortalTopicAndEventForm,
  },
  {
    path: "/groups/:group_id",
    name: "group",
    component: PortalTopicAndEventForm,
  },
  {
    path: "/groups/:group_id/info",
    name: "groupInfo",
    component: PortalTopicAndEventForm,
  },
  {
    path: "/groups/:group_id/topics/:topic_id",
    name: "topic",
    component: PortalTopic,
  },
  {
    path: "/digest/events/:event_id/attend",
    component: PortalAttend,
    name: "Attend",
  },
  {
    path: "/digest/events/:event_id/decline",
    component: PortalDecline,
    name: "Decline",
  },
  {
    path: "/drive",
    components: {
      drive: Drive,
    },
    children: [
      {
        name: "drive/latest",
        path: "/",
        component: LatestFiles,
      },
      {
        name: "drive/folder",
        path: "folders/:inode_id",
        component: FileBrowser,
      },
      {
        name: "drive/folder/edit",
        path: "folders/:inode_id/edit",
        component: EditFolder,
        meta: {
          fullscreen: true,
        },
      },
      {
        name: "drive/folder/new",
        path: "folders/:inode_id/new",
        component: CreateFolder,
        meta: {
          fullscreen: true,
        },
      },
      {
        name: "drive/uploaded_file/edit",
        path: "uploaded_files/:inode_id/edit",
        component: EditDriveFile,
        meta: {
          fullscreen: true,
        },
      },
      {
        name: "drive/file/edit",
        path: "files/:inode_id/edit",
        component: EditDriveFile,
        meta: {
          fullscreen: true,
        },
      },
      {
        name: "drive/uploaded_file",
        path: "uploaded_files/:inode_id",
        component: DriveFile,
      },
      {
        name: "drive/file",
        path: "files/:inode_id",
        component: DriveFile,
      },
      {
        name: "drive/categories/new",
        path: "categories/new",
        component: CreateCategory,
        meta: {
          fullscreen: true,
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes,
})

router.beforeEach(async (to, from, next) => {
  const _canAccessGroup = useCanAccessGroup()
  const groupUrls = ["groups", "group", "groupInfo", "topic"]

  if (!_canAccessGroup && groupUrls.includes(to.name)) return false
  next()
})

export default router
