import "../interfaces/reactionOption.interface";
import { mdiThumbUpOutline } from "@mdi/js";
import { mdiLightbulbOnOutline } from "@mdi/js";
import cheersPath from "../components/icons/paths/cheers.js";
import createReaction from "../components/icons/paths/createReaction.js";
export var REACTION_OPTIONS = [
    {
        name: "like",
        icon: mdiThumbUpOutline,
        size: 20,
        color: "primary",
        label: "Ich stimme zu!",
    },
    {
        name: "cheers",
        icon: cheersPath,
        size: 28,
        color: "primary",
        label: "Prost!",
    },
    {
        name: "idea",
        icon: mdiLightbulbOnOutline,
        size: 22,
        color: "primary",
        label: "Gute Idee!",
    },
    {
        name: "default",
        icon: createReaction,
        size: 24,
        color: "textColor",
        label: "Zustimmen",
    },
];
