import { ref } from "vue";
import "features/posts/gallery/PostGalleryPreviewDesktop.vue";
import "suus-api";
export var usePostGalleryCarousel = function () {
    var slides = ref([]);
    var slideItemsCount = ref(0);
    var resizeSlides = function (carouselElement, post) {
        var carouselWidth = carouselElement.offsetWidth;
        var thumbnailWidth = 128;
        var padding = 8;
        var fittingThumbnailsWithPadding = Math.floor(carouselWidth / (thumbnailWidth + padding));
        var isOneMoreFitting = carouselWidth % (thumbnailWidth + padding) >= thumbnailWidth;
        var thumbsPerSlide = isOneMoreFitting
            ? fittingThumbnailsWithPadding + 1
            : fittingThumbnailsWithPadding;
        slideItemsCount.value = thumbsPerSlide;
        var previewThumbnails = post.event_media_preview;
        slides.value = [];
        for (var i = 0; i < previewThumbnails.length; i += thumbsPerSlide) {
            slides.value.push(previewThumbnails.slice(i, i + thumbsPerSlide));
        }
        var lastSlide = slides.value[slides.value.length - 1];
        if (lastSlide.length < thumbsPerSlide) {
            var missingThumbnails = thumbsPerSlide - lastSlide.length;
            for (var i = 0; i < missingThumbnails; i++) {
                if (i === 0) {
                    lastSlide.push("more");
                    continue;
                }
                lastSlide.push("placeholder");
            }
        }
        else {
            lastSlide[lastSlide.length - 1] = "more";
        }
    };
    return {
        slides: slides,
        resizeSlides: resizeSlides,
        slideItemsCount: slideItemsCount,
    };
};
