import Vue from "vue";
import { mdiAccountCircleOutline } from "@mdi/js";
import { mdiAccountGroupOutline } from "@mdi/js";
export default Vue.extend({
    name: "AvatarPlaceholder",
    props: {
        avatarType: {
            type: String,
            default: "GroupMember",
            validator: function (val) { return ["GroupMember", "Group"].includes(val); },
        },
    },
    computed: {
        icon: function () {
            return this.avatarType === "Group"
                ? mdiAccountGroupOutline
                : mdiAccountCircleOutline;
        },
    },
});
