import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, inject, ref, watch, } from "vue";
import "concerns/useContextName";
import useMentionSearch from "concerns/useMentionSearch";
import "suus-api";
import GalleryUploader from "features/gallery/uploader/GalleryUploader.vue";
import "features/gallery/gallery.types";
import PostFormHeader from "features/posts/form/PostFormHeader.vue";
import PostFormButton from "features/posts/form/PostFormButton.vue";
import PostFormError from "features/posts/form/PostFormError.vue";
import "features/posts/form/addon/post-form-addon.types";
import "features/posts/form/post-form.types";
import GalleryAddon from "features/posts/form/gallery/GalleryAddon.vue";
import "features/gallery/service/gallery.service";
import ForumWysiwyg from "components/Wysiwyg/ForumWysiwyg.vue";
import AttachmentUploader from "features/attachments/AttachmentUploader.vue";
import ForumWysiwygWithUploader from "components/Wysiwyg/ForumWysiwygWithUploader.vue";
export default defineComponent({
    name: "PostForm",
    components: {
        ForumWysiwygWithUploader: ForumWysiwygWithUploader,
        AttachmentUploader: AttachmentUploader,
        ForumWysiwyg: ForumWysiwyg,
        GalleryAddon: GalleryAddon,
        PostFormError: PostFormError,
        PostFormButton: PostFormButton,
        PostFormHeader: PostFormHeader,
        GalleryUploader: GalleryUploader,
    },
    props: {
        value: {
            type: Object,
            default: null,
        },
        inline: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false },
        viewContext: { type: Object },
        createPost: {
            type: Function,
        },
        updatePost: {
            type: Function,
        },
        deletePost: {
            type: Function,
            required: false,
        },
    },
    setup: function (props, context) {
        var _this = this;
        var _a, _b, _c, _d, _e;
        /**
         * SETUP
         */
        var attachments = (_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.attachments) !== null && _b !== void 0 ? _b : [];
        var isSaving = ref(false);
        var _postId = ref((_c = props.value) === null || _c === void 0 ? void 0 : _c.id);
        watch(function () { var _a; return (_a = props.value) === null || _a === void 0 ? void 0 : _a.id; }, function (newVal) { return (_postId.value = newVal); });
        var attachmentsValid = ref(true);
        var updateAttachmentsValid = function (valid) {
            attachmentsValid.value = valid;
            if (validationCalledBefore.value) {
                validatePost(postText.value);
            }
        };
        var galleryService = inject("GalleryService", undefined);
        var galleryActive = computed(function () { return !!galleryService; });
        var galleryAddon = ref(null);
        var registerGalleryAddon = function (postAddon) {
            galleryAddon.value = postAddon;
        };
        var postText = ref((_e = (_d = props.value) === null || _d === void 0 ? void 0 : _d.text) !== null && _e !== void 0 ? _e : "");
        watch(function () { var _a, _b; return (_b = (_a = props.value) === null || _a === void 0 ? void 0 : _a.text) !== null && _b !== void 0 ? _b : ""; }, function (newVal) { return (postText.value = newVal); });
        var errorText = ref("");
        var disabled = computed(function () { return !!errorText.value.length; });
        var postRules = [
            function (v) {
                var isEmpty = (!v && !v.length) || v.toString() === "<p></p>";
                return isEmpty ? "Bitte gib eine Antwort ein." : true;
            },
        ];
        var validationCalledBefore = ref(false);
        // Only validate if there is an error text
        function revalidate() {
            if (errorText.value.length > 0) {
                validatePost(postText.value);
            }
        }
        watch(function () { return postText.value; }, revalidate);
        function validatePost(text) {
            validationCalledBefore.value = true;
            errorText.value = "";
            postRules.forEach(function (rule) {
                var message = rule(text);
                if (typeof message === "string") {
                    errorText.value += "".concat(message, " ");
                }
            });
            if (!galleryValid.value) {
                errorText.value +=
                    "Bitte überprüfe ob oder warte bis alle Dateien fehlerfrei hochgeladen wurden.";
            }
            if (!attachmentsValid.value) {
                errorText.value +=
                    "Bitte überprüfe ob oder warte bis alle Dateien fehlerfrei hochgeladen wurden.";
            }
        }
        function resetForm() {
            var _a;
            postText.value = "";
            errorText.value = "";
            (_a = galleryAddon.value) === null || _a === void 0 ? void 0 : _a.resetAddon();
        }
        function savePost() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            isSaving.value = true;
                            validatePost(postText.value);
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, , 6, 7]);
                            if (!(!disabled.value && postText.value)) return [3 /*break*/, 5];
                            if (!props.isEdit) return [3 /*break*/, 3];
                            return [4 /*yield*/, updatePost(postText.value)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, createPost(postText.value)];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5: return [3 /*break*/, 7];
                        case 6:
                            isSaving.value = false;
                            return [7 /*endfinally*/];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        }
        function createPost(text) {
            return __awaiter(this, void 0, void 0, function () {
                var createdPost, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, createPostWithAttachmentsAndMedia(text)];
                        case 1:
                            createdPost = _a.sent();
                            context.emit("postCreated", createdPost);
                            resetForm();
                            return [3 /*break*/, 3];
                        case 2:
                            e_1 = _a.sent();
                            if (e_1 instanceof Error) {
                                errorText.value = e_1.message;
                            }
                            else {
                                throw e_1;
                            }
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
        function createPostWithAttachmentsAndMedia(text) {
            var _a;
            return __awaiter(this, void 0, Promise, function () {
                var createdPost, createdGalleryMedia;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, props.createPost(text)];
                        case 1:
                            createdPost = _b.sent();
                            _postId.value = createdPost.id;
                            if (!galleryActive.value) return [3 /*break*/, 3];
                            return [4 /*yield*/, ((_a = galleryAddon.value) === null || _a === void 0 ? void 0 : _a.createAddonData(createdPost))];
                        case 2:
                            createdGalleryMedia = _b.sent();
                            createdPost.event_media_preview = createdGalleryMedia.slice(0, 11);
                            createdPost.event_media_count = createdGalleryMedia.length;
                            _b.label = 3;
                        case 3: return [2 /*return*/, createdPost];
                    }
                });
            });
        }
        function updatePost(text) {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var updatedPost, updatedGalleryMedia, e_2;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 4, , 5]);
                            return [4 /*yield*/, props.updatePost(props.value.id, text)];
                        case 1:
                            updatedPost = _b.sent();
                            if (!galleryActive.value) return [3 /*break*/, 3];
                            return [4 /*yield*/, ((_a = galleryAddon.value) === null || _a === void 0 ? void 0 : _a.updateAddonData(updatedPost))];
                        case 2:
                            updatedGalleryMedia = _b.sent();
                            updatedPost.event_media_preview = updatedGalleryMedia.slice(0, 11);
                            updatedPost.event_media_count = updatedGalleryMedia.length;
                            _b.label = 3;
                        case 3:
                            context.emit("postUpdated", updatedPost);
                            return [3 /*break*/, 5];
                        case 4:
                            e_2 = _b.sent();
                            if (e_2 instanceof Error) {
                                errorText.value = e_2.message;
                            }
                            return [3 /*break*/, 5];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }
        var getMentionableFunc = useMentionSearch(props.viewContext).mentionableFunction;
        var galleryValid = ref(true);
        var setGalleryValididity = function (valid) {
            return (galleryValid.value = valid);
        };
        var initialShowUploader = !!(galleryService === null || galleryService === void 0 ? void 0 : galleryService.initialShowUploader);
        var loadAttachment = function (frontendId) { return __awaiter(_this, void 0, Promise, function () {
            var attachment;
            return __generator(this, function (_a) {
                attachment = attachments.find(function (_a) {
                    var frontend_id = _a.frontend_id;
                    return frontend_id === frontendId;
                });
                if (attachment) {
                    return [2 /*return*/, attachment];
                }
                else {
                    console.error("Attachment not found " + frontendId, attachments);
                    throw "Attachment not found!";
                }
                return [2 /*return*/];
            });
        }); };
        return {
            getMentionableFunc: getMentionableFunc,
            savePost: savePost,
            errorText: errorText,
            disabled: disabled,
            postText: postText,
            isSaving: isSaving,
            setGalleryValididity: setGalleryValididity,
            registerGalleryAddon: registerGalleryAddon,
            galleryActive: galleryActive,
            initialShowUploader: initialShowUploader,
            updateAttachmentsValid: updateAttachmentsValid,
            loadAttachment: loadAttachment,
        };
    },
});
