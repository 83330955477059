<template lang="pug">
.lazy-list
  .error(v-if='error')
    b Fehler: {{ error }}

  div(:class="wrapperClass")
    template(v-if='placeholders && items.length === 0 && loading')
      loading-list-item(v-for="i in placeholders.items" meta="" v-bind:key="i.id")

    template
      slot(v-for="item in items" name="item" v-bind:item="item")

  template(v-if="items.length < 1 && !loading")
    slot(name='empty')
      p.no-elements Keine Ergebnisse

  div(ref='scrollIndicator')

  .loading(v-if='loading && totalCount > 0')
    | Weitere Ergebnisse werden geladen...

  .error(v-if='error && totalCount > 5')
    b Fehler: {{ error }}

</template>

<script>
import Vue from "vue"
import LoadingListItem from "./LoadingListItem.vue"
import _ from "lodash"

export default Vue.extend({
  components: { LoadingListItem },
  props: {
    endpoint: { type: String },
    initialOffset: { type: Number, default: 0 },
    initialLoad: { type: Number, default: 20 },
    placeholders: {
      type: [Object, Boolean],
      default() {
        return { items: this.initialLoad, slots: ["image", "title"] }
      },
    },
    getResultsFun: {
      type: [Function],
      default() {
        return this.localResults
      },
    },
    setResultsFun: {
      type: Function,
      default(rs) {
        this.localResults = rs
      },
    },
    order: { type: [Array], required: true },
    wrapperClass: { type: String, default: "ui divided items vuejs" },
  },
  data() {
    return {
      loading: false,
      totalCount: -1,
      error: null,
      offset: this.initialOffset,
      limitStart: this.initialLoad,
      limitCurrent: 20,
      limitInertia: 1.5,
      limitMax: 100,
      preloadOffsetStart: 700,
      preloadOffsetCurrent: 700,
      preloadOffsetInertia: 3,
      preloadOffsetMax: 5000,
      activeItem: null,
      localResults: [],
    }
  },
  mounted: function () {
    window.addEventListener("scroll", this.loadMoreItems)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.loadMoreItems)
  },
  watch: {
    loading: function (o, n) {
      if (n) {
        this.$emit("loaded", this.totalCount)
      } else {
        this.$emit("loading")
      }
    },
    endpoint: function (url) {
      if (!url) {
        return
      }
      this.totalCount = 0
      this.load()
    },
  },
  created: function () {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.load = _.debounce(this.load, 500)
    this.load()
  },
  methods: {
    reset() {
      this.error = null
      this.limitCurrent = this.limitStart
      this.preloadOffsetCurrent = this.preloadOffsetStart
      this.offset = 0
      this.loading = true
    },
    requestFailed(requestUrl, err) {
      if (requestUrl === this.queryUrl()) {
        this.totalCount = 0
        this.error = err.toString()
        this.$emit("error", err)
      }
    },
    async load() {
      if (!this.endpoint) {
        return
      }
      this.reset()
      const callerEndpoint = this.queryUrl()

      try {
        const { data } = await this.$http.get(callerEndpoint)

        // Only process data if the endpoint has not changed during the request.
        // If the endpoint has changed, there is already a new request happening
        // and the old request could override the new one. This could happen when
        // the user typed in a new search query and the old request resolves slower
        // than the new one => results of the new query are overriden by the old results
        if (callerEndpoint === this.queryUrl()) {
          this.totalCount = data.count
          this.offset = data.results.length
          this.setResultsFun(data.results)
          this.$emit("offset", this.offset)
          this.loading = false
        }
      } catch (e) {
        this.requestFailed(callerEndpoint, e)
        this.loading = false
      } finally {
      }
    },
    queryUrl() {
      return (
        this.endpoint + "&offset=" + this.offset + "&limit=" + this.limitCurrent
      )
    },
    shouldPreload() {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight
      const scrollOffset = scrollHeight - (scrollTop + window.innerHeight)
      const abovePreloadLine = scrollOffset > this.preloadOffsetCurrent
      return !(
        !this.endpoint ||
        this.loading ||
        abovePreloadLine ||
        this.allLoaded
      )
    },
    async loadMoreItems() {
      if (!this.shouldPreload()) return

      this.loading = true
      this.limitCurrent = Math.min(
        this.limitMax,
        Math.floor(this.limitInertia * this.limitCurrent)
      )
      this.preloadOffsetCurrent = Math.min(
        this.preloadOffsetMax,
        Math.floor(this.preloadOffsetInertia * this.preloadOffsetCurrent)
      )

      const callerEndpoint = this.queryUrl()
      try {
        const { data } = await this.$http.get(callerEndpoint)
        if (callerEndpoint === this.queryUrl()) {
          this.offset += data.results.length
          this.loading = false
          this.setResultsFun(this.getResultsFun().concat(data.results))
          this.$emit("offset", this.offset)
        }
      } catch (e) {
        console.log(e)
        if (callerEndpoint === this.queryUrl()) {
          this.error = e.toString()
        }
      } finally {
        this.loading = false
      }
    },
    orderFun(a, b) {
      for (const [key, orderAttr] of this.order.map(
        (e) => Object.entries(e)[0]
      )) {
        // property doesn't exist on either object
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          continue
        }
        let { order = orderAttr, transform = (x) => x } = orderAttr
        const asc = order.toLowerCase() === "asc" ? 1 : -1
        const varA = transform(a[key])
        const varB = transform(b[key])

        let comparison = 0
        if (varA > varB) {
          return asc
        } else if (varA < varB) {
          return -asc
        }
      }
      return 0
    },
  },
  computed: {
    allLoaded() {
      return this.offset === this.totalCount
    },
    items() {
      return this.getResultsFun().sort(this.orderFun)
    },
  },
})
</script>

<style scoped>
.field {
  margin-bottom: 0 !important;
}
label {
  cursor: pointer !important;
}
</style>
