import Vue from "vue";
export default Vue.extend({
    name: "MailedIcon",
    props: {
        color: String,
        size: { type: Number, default: 24 },
    },
    computed: {
        iconColor: function () {
            var _a, _b, _c;
            if (this.color === "primary") {
                return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
            }
            if (this.color === "textColor") {
                return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
            }
            return (this.color || ((_c = this.$vuetify.theme.themes.light.textColor) === null || _c === void 0 ? void 0 : _c.toString()));
        },
    },
});
