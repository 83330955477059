import Vue from "vue";
export default Vue.extend({
    name: "Tag",
    props: {
        text: { type: String, required: false },
        color: {
            type: String,
            required: false,
        },
    },
    computed: {
        tagColor: function () {
            var _a, _b, _c;
            if (this.color === "primary") {
                return (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString();
            }
            if (this.color === "textColor") {
                return (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
            }
            return (this.color || ((_c = this.$vuetify.theme.themes.light.textColor) === null || _c === void 0 ? void 0 : _c.toString()));
        },
        tagStyles: function () {
            return {
                fontFamily: "system-ui !important",
                color: "".concat(this.tagColor, " !important"),
                fontWeight: "900 !important",
            };
        },
    },
});
