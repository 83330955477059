<template lang="pug">
Inode(v-bind="settings")
</template>

<script>
import ListItem from "../../ListItem.vue"
import InodeIcon from "../InodeIcon.vue"
import Inode from "./Inode.vue"
export default {
  name: "Folder",
  props: {
    inode: { type: Object, required: true },
    show: {
      type: Object,
      default() {
        return {
          image: true,
          path: false,
          category: false,
          physically_created_at: false,
          inodes_count: true,
          latest_drive_file: false,
          actions: false,
        }
      },
    },
  },
  components: { Inode, ListItem, InodeIcon },
  computed: {
    inodeLink() {
      return { name: "drive/folder", params: { inode_id: this.inode.id } }
    },
    settings() {
      return {
        inode: this.inode,
        inodeLink: this.inodeLink,
        show: this.show,
      }
    },
  },
}
</script>

<style scoped></style>
