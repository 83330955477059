export default {
  SET_CURRENT_MEMBER_DATA(state, data) {
    state.currentMember = { ...state.currentMember, data }
  },
  SET_CURRENT_MEMBER_ERROR(state, error) {
    state.currentMember = { ...state.currentMember, error }
  },
  SET_CURRENT_MEMBER_LOADING(state, loading) {
    state.currentMember = { ...state.currentMember, loading }
  },
}
