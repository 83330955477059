import { __awaiter, __generator } from "tslib";
import Axios from "initializers/axios";
import "concerns/useContextName";
import asyncDebounce from "helpers/asyncDebounce";
import "components/Wysiwyg/extensions/suus-mention/SuusMention";
export default function useMentionSearch(context) {
    if (context === void 0) { context = {}; }
    function mentionableFunction(_a) {
        var _b, _c, _d;
        var query = _a.query;
        return __awaiter(this, void 0, Promise, function () {
            var params, data, err_1;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        params = {
                            group_id: (_b = context === null || context === void 0 ? void 0 : context.group) === null || _b === void 0 ? void 0 : _b.id,
                            client_id: context.client_id || ((_c = context.client) === null || _c === void 0 ? void 0 : _c.id),
                            level_id: context.level_id || ((_d = context.level) === null || _d === void 0 ? void 0 : _d.id),
                            query: query,
                        };
                        _e.label = 1;
                    case 1:
                        _e.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Axios.get("/mentionables.json", { params: params })];
                    case 2:
                        data = (_e.sent()).data;
                        return [2 /*return*/, data];
                    case 3:
                        err_1 = _e.sent();
                        console.error(err_1);
                        return [2 /*return*/, []];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    return {
        mentionableFunction: asyncDebounce(mentionableFunction, 500),
    };
}
