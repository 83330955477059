import { __awaiter, __generator } from "tslib";
import store from "store";
import "suus-api";
import { computed } from "vue";
/**
 *  The point of this file is to provide "Killers" that first just wrap the vuex-store calls.
 *  When all relevant components are referencing these killers instead of directly calling the store,
 *  we can replace the store with an own state management solution.
 *  This way we can kill the store without changing the components.
 *  Afterward the killers can be easily renamed and moved by using the IDE's refactoring tools.
 */
export var DriveVuexKiller = {
    createFolder: function (folderData) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, store.dispatch("drive/createFolder", folderData)];
        });
    }); },
    updateInode: function (update_url, folderData) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // The update-url is plumbed here, so we dont have a dependency on inode.
            // TODO: Refactor to use the inode id & the generated API-client. (when store is safe be killed)
            return [2 /*return*/, store.dispatch("drive/updateInode", {
                    inode: { update_url: update_url },
                    formData: folderData,
                })];
        });
    }); },
    setDriveSuccess: function (message) {
        store.commit("drive/setSuccess", message);
    },
    driveError: computed(function () { return store.getters["drive/error"]; }),
    setDriveError: function (error) {
        store.commit("drive/setError", error);
    },
};
