import { __awaiter, __generator } from "tslib";
import "features/gallery/lightbox/lightbox.service";
import { getGalleryMedia } from "features/posts/gallery/gallery.functions";
import { getContextName } from "concerns/useContextName";
import "suus-api";
export var usePostMediaPhotoswipe = function (postId, postMediaCount, lightboxService, viewContext) {
    var loadMedium = function (index) { return __awaiter(void 0, void 0, Promise, function () {
        var media, originalFile, author, contextAuthorName;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getGalleryMedia(postId, index, 1)];
                case 1:
                    media = _c.sent();
                    originalFile = (_a = media[0]) === null || _a === void 0 ? void 0 : _a.uploaded_file.original;
                    author = (_b = media[0]) === null || _b === void 0 ? void 0 : _b.uploaded_file.uploaded_by;
                    contextAuthorName = getContextName(viewContext, author);
                    return [2 /*return*/, {
                            src: originalFile.url,
                            width: originalFile.width,
                            height: originalFile.height,
                            authorName: contextAuthorName,
                        }];
            }
        });
    }); };
    var openLightbox = function (index) {
        lightboxService.openPhotoLightbox(index, postMediaCount, loadMedium);
    };
    return {
        openLightbox: openLightbox,
    };
};
