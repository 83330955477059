import { ref } from "vue";
export var useDragToScroll = function (container) {
    var startedDragging = ref(false);
    var startX = ref(0);
    var scrollLeftForDrag = ref(0);
    var dragEnabled = ref(false);
    var _a = useClickAndTextSelectionPrevention(container), deactivateChildrenTextSelection = _a.deactivateChildrenTextSelection, activateChildrenTextSelection = _a.activateChildrenTextSelection, deactivateChildrenMouseEvents = _a.deactivateChildrenMouseEvents, activateChildrenMouseEvents = _a.activateChildrenMouseEvents;
    var thresholdTimer;
    var startDragging = function () {
        deactivateChildrenMouseEvents();
        startedDragging.value = true;
        scrollLeftForDrag.value = container.value.scrollLeft;
        container.value.style.cursor = "grabbing";
    };
    var handleMouseDown = function (e) {
        startX.value = e.pageX;
        deactivateChildrenTextSelection();
        // Start the drag after a threshold time to still allow click events to be triggered
        thresholdTimer = setTimeout(function () {
            startDragging();
        }, 300);
    };
    var stopDragging = function () {
        clearTimeout(thresholdTimer);
        thresholdTimer = null;
        activateChildrenTextSelection();
        activateChildrenMouseEvents();
        startedDragging.value = false;
        container.value.style.cursor = "grab";
    };
    var handleMouseMove = function (e) {
        if (!startedDragging.value) {
            // if the user moves the mouse before the threshold time, just start dragging
            if (thresholdTimer) {
                clearTimeout(thresholdTimer);
                thresholdTimer = null;
                startDragging();
            }
            return;
        }
        e.preventDefault();
        var pageX = e.pageX;
        var walk = pageX - startX.value;
        container.value.scrollLeft = scrollLeftForDrag.value - walk;
    };
    var enableDragToScroll = function () {
        if (dragEnabled.value)
            return;
        dragEnabled.value = true;
        container.value.style.cursor = "grab";
        container.value.addEventListener("mousedown", handleMouseDown);
        container.value.addEventListener("mouseleave", stopDragging);
        container.value.addEventListener("mouseup", stopDragging);
        container.value.addEventListener("mousemove", handleMouseMove);
    };
    var disableDragToScroll = function () {
        dragEnabled.value = false;
        container.value.style.cursor = "unset";
        container.value.removeEventListener("mousedown", handleMouseDown);
        container.value.removeEventListener("mouseleave", stopDragging);
        container.value.removeEventListener("mouseup", stopDragging);
        container.value.removeEventListener("mousemove", handleMouseMove);
    };
    var enableOnDevicesGreaterThanPhones = function () {
        if (window.innerWidth <= 600) {
            disableDragToScroll();
        }
        else {
            enableDragToScroll();
        }
    };
    var registerDragToScroll = function () {
        enableOnDevicesGreaterThanPhones();
        window.addEventListener("resize", enableOnDevicesGreaterThanPhones);
    };
    var unregisterDragToScroll = function () {
        window.removeEventListener("resize", enableOnDevicesGreaterThanPhones);
        disableDragToScroll();
    };
    return {
        registerDragToScroll: registerDragToScroll,
        unregisterDragToScroll: unregisterDragToScroll,
    };
};
var useClickAndTextSelectionPrevention = function (container) {
    // Adds a style tag with CSS to the head of the document for
    // deactivating pointer events & text-selection on children
    var registerNoPointerStyleClass = function () {
        var style = document.createElement("style");
        style.innerHTML = "\n      .drag-to-scroll-no-pointer {\n        pointer-events: none;\n      }\n      .drag-to-scroll-prevent-text-selection {\n        -webkit-user-select: none; /* Safari */\n        -ms-user-select: none; /* IE 10 and IE 11 */\n        user-select: none; /* Standard syntax */\n      }\n    ";
        document.head.appendChild(style);
    };
    registerNoPointerStyleClass();
    var deactivateChildrenTextSelection = function () {
        container.value.querySelectorAll("*").forEach(function (el) {
            if (el instanceof HTMLElement) {
                el.classList.add("drag-to-scroll-prevent-text-selection");
            }
        });
    };
    var activateChildrenTextSelection = function () {
        container.value.querySelectorAll("*").forEach(function (el) {
            if (el instanceof HTMLElement) {
                el.classList.remove("drag-to-scroll-prevent-text-selection");
            }
        });
    };
    var deactivateChildrenMouseEvents = function () {
        container.value.querySelectorAll("*").forEach(function (el) {
            if (el instanceof HTMLElement) {
                el.classList.add("drag-to-scroll-no-pointer");
            }
        });
    };
    var activateChildrenMouseEvents = function () {
        container.value.querySelectorAll("*").forEach(function (el) {
            if (el instanceof HTMLElement) {
                el.classList.remove("drag-to-scroll-no-pointer");
            }
        });
    };
    return {
        deactivateChildrenTextSelection: deactivateChildrenTextSelection,
        activateChildrenTextSelection: activateChildrenTextSelection,
        deactivateChildrenMouseEvents: deactivateChildrenMouseEvents,
        activateChildrenMouseEvents: activateChildrenMouseEvents,
    };
};
