import { __awaiter, __generator } from "tslib";
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm";
import PhotoSwipe from "photoswipe/dist/photoswipe.esm";
import { inject } from "vue";
var buildDynamicLoader = function (lightbox, fetchFunction) {
    return function (_a) {
        var content = _a.content;
        return __awaiter(void 0, void 0, void 0, function () {
            var pswp, index, loadedItem;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        pswp = lightbox.pswp;
                        if (!pswp) {
                            return [2 /*return*/];
                        }
                        index = content.index;
                        if (pswp.options.dataSource[index]) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, fetchFunction(index)];
                    case 1:
                        loadedItem = _b.sent();
                        pswp.options.dataSource[index] = loadedItem;
                        pswp.refreshSlideContent(index);
                        return [2 /*return*/];
                }
            });
        });
    };
};
var LightboxService = /** @class */ (function () {
    function LightboxService() {
    }
    LightboxService.prototype.openLightbox = function (image) {
        this._validateRegistration();
        if (!image) {
            throw new Error("No valid image URL was passed to the LightboxService.");
        }
        this.callback(image);
    };
    LightboxService.prototype.openPhotoLightbox = function (startIndex, totalItems, fetchFunction) {
        return __awaiter(this, void 0, void 0, function () {
            var lightbox;
            return __generator(this, function (_a) {
                lightbox = new PhotoSwipeLightbox({
                    pswpModule: PhotoSwipe,
                    preload: [startIndex],
                    dataSource: [],
                });
                lightbox.init();
                this._registerCaption(lightbox);
                this._registerDownloadButton(lightbox);
                this._setTotalItems(lightbox, totalItems);
                this._setDynamicLoader(lightbox, fetchFunction);
                lightbox.loadAndOpen(startIndex);
                return [2 /*return*/];
            });
        });
    };
    LightboxService.prototype._registerCaption = function (lightbox) {
        lightbox.on("uiRegister", function () {
            lightbox.pswp.ui.registerElement({
                name: "custom-caption",
                order: 9,
                isButton: false,
                appendTo: "root",
                html: "Caption text",
                onInit: function (el, pswp) {
                    lightbox.pswp.on("change", function () {
                        console.log(lightbox.pswp.currSlide.data);
                        var currSlide = lightbox.pswp.currSlide.data;
                        var captionHTML = currSlide.authorName;
                        var takenBy = "Aufgenommen von ";
                        if (captionHTML) {
                            el.style.display = "block";
                            el.innerHTML = takenBy + captionHTML;
                        }
                        else {
                            el.style.display = "none";
                        }
                    });
                },
            });
        });
    };
    LightboxService.prototype._registerDownloadButton = function (lightbox) {
        lightbox.on("uiRegister", function () {
            lightbox.pswp.ui.registerElement({
                name: "download-button",
                order: 8,
                isButton: true,
                tagName: "a",
                // SVG with outline
                html: {
                    isCustomSVG: true,
                    inner: '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1ZM23 23H9v2h14Z" id="pswp__icn-download"/>',
                    outlineID: "pswp__icn-download",
                },
                onInit: function (el, pswp) {
                    el.setAttribute("download", "");
                    el.setAttribute("target", "_blank");
                    el.setAttribute("rel", "noopener");
                    pswp.on("change", function () {
                        el.href = pswp.currSlide.data.src;
                    });
                },
            });
        });
    };
    LightboxService.prototype._setTotalItems = function (lightbox, totalItems) {
        lightbox.addFilter("numItems", function (items) {
            return totalItems;
        });
    };
    LightboxService.prototype._setDynamicLoader = function (lightbox, fetchFunction) {
        lightbox.on("contentInit", buildDynamicLoader(lightbox, fetchFunction));
        lightbox.on("contentActivate", buildDynamicLoader(lightbox, fetchFunction));
    };
    LightboxService.prototype._validateRegistration = function () {
        if (!this.callback) {
            throw new Error("No Lightbox was registered in the LightboxService. Maybe you forgot to pass the service to the Lightbox component?");
        }
    };
    LightboxService.prototype.registerCallback = function (callback) {
        this.callback = callback;
    };
    return LightboxService;
}());
export { LightboxService };
export function useLightbox() {
    var lightboxService = inject("lightboxService", null);
    if (!lightboxService) {
        console.warn("No LightboxService was found. Did you forget to provide it?");
    }
    return lightboxService;
}
