import Vue from "vue";
import { mdiChevronDown } from "@mdi/js";
import THEME from "../../constants/theme";
export default Vue.extend({
    name: "ComboActivatorButton",
    props: {
        selected: { type: Object, required: true },
        isActive: { type: Boolean, required: true },
    },
    data: function () {
        return {
            chevronIcon: mdiChevronDown,
            labelStyles: THEME.typography.button,
        };
    },
    computed: {
        buttonWidth: function () {
            return this.isXsScreen ? "100%" : "220px";
        },
        isXsScreen: function () {
            return this.$vuetify.breakpoint.name === "xs";
        },
    },
});
