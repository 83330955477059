export var firebaseConfig = {
    apiKey: "AIzaSyAPUW9Ifk6jV6JeCTgbHPekojWkSFQO5OQ",
    authDomain: "suus-networks.firebaseapp.com",
    projectId: "suus-networks",
    storageBucket: "suus-networks.appspot.com",
    messagingSenderId: "458257466847",
    appId: "1:458257466847:web:9ef17b593ca9067dfe706a",
    measurementId: "G-1GWS0Q4CKL",
};
// vapdiKey is public
export var vapidKey = "BAN0avdS8EzL5YhRr898_FQo_DaUqSTiTvCJHNfYeI_YF9oJyUYmhUFG3jHzFebpNKiGLl951geLeYUxQ4kfXD8";
export var SW_VERSION = process.env.HEROKU_RELEASE_CREATED_AT || "UNKNOWN";
