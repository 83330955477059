import "features/gallery/lightbox/lightbox.service";
import { useEventHighlightPhotoswipe } from "features/events/highlights/concerns/use-event-highlight-photoswipe";
import "features/gallery/service/gallery.service";
var EventLightboxService = /** @class */ (function () {
    function EventLightboxService(_lighboxService, _galleryService, _eventId, _viewContext) {
        this._lighboxService = _lighboxService;
        this._galleryService = _galleryService;
        this._eventId = _eventId;
        this._viewContext = _viewContext;
    }
    EventLightboxService.prototype.openLightbox = function (startIndex) {
        var openLightbox = useEventHighlightPhotoswipe(this._eventId, this._galleryService, this._lighboxService, this._viewContext).openLightbox;
        openLightbox(startIndex);
    };
    return EventLightboxService;
}());
export { EventLightboxService };
