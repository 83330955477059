import { __assign } from "tslib";
import { defineComponent } from "vue";
import "suus-api";
import { createNamespacedHelpers } from "vuex";
var mapTopicState = createNamespacedHelpers("topic").mapState;
import AttachmentList from "../AttachmentList.vue";
import Header from "../UI/Header.vue";
import TopicForm from "../TopicForm/index.vue";
import Topic from "../Topic/Topic.vue";
import TopicSkeleton from "../Topic/TopicSkeleton.vue";
import { mdiChevronDown, mdiPencilOutline } from "@mdi/js";
import { mdiCommentPlusOutline } from "@mdi/js";
import Theme from "../../constants/theme";
import "concerns/useContextName";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/common/shared/polymorphic";
import TopicPostList from "features/topics/posts/TopicPostList.vue";
var mediumGrey = Theme.colors.light.mediumGrey;
export default defineComponent({
    name: "TopicPage",
    components: {
        TopicForm: TopicForm,
        TopicPostList: TopicPostList,
        Header: Header,
        Topic: Topic,
        TopicSkeleton: TopicSkeleton,
        AttachmentList: AttachmentList,
    },
    data: function () {
        return {
            editIcon: mdiPencilOutline,
            newPostIcon: mdiCommentPlusOutline,
            totalPostsCount: 0,
            panelIcon: mdiChevronDown,
        };
    },
    computed: __assign({ btnStyles: function () {
            return {
                fontFamily: "system-ui",
                fontWeight: 700,
                letterSpacing: "0.05em",
            };
        }, headerStyles: function () {
            return {
                borderTop: "1px solid ".concat(mediumGrey),
            };
        }, isEdit: function () {
            return !!this.$route.params.topic_id;
        }, canEditTopic: function () {
            var _a, _b;
            return !!((_b = (_a = this.topicData) === null || _a === void 0 ? void 0 : _a.links) === null || _b === void 0 ? void 0 : _b.update_url);
        }, canCreatePosts: function () {
            var _a, _b, _c;
            return !!((_c = (_b = (_a = this.topicData) === null || _a === void 0 ? void 0 : _a.links) === null || _b === void 0 ? void 0 : _b.posts) === null || _c === void 0 ? void 0 : _c.create_url);
        }, canCreateReactions: function () {
            var _a, _b, _c;
            return !!((_c = (_b = (_a = this.topicData) === null || _a === void 0 ? void 0 : _a.links) === null || _b === void 0 ? void 0 : _b.reactions) === null || _c === void 0 ? void 0 : _c.create_url);
        }, topicId: function () {
            return parseInt(this.$route.params.topic_id);
        }, attachable: function () {
            var _a;
            return {
                id: (_a = this.topicData) === null || _a === void 0 ? void 0 : _a.id,
                type: "Topic",
            };
        }, viewContext: function () {
            var _a, _b, _c, _d, _e;
            return {
                group: (_a = this.topicData) === null || _a === void 0 ? void 0 : _a.group,
                client_id: (_c = (_b = this.topicData) === null || _b === void 0 ? void 0 : _b.group) === null || _c === void 0 ? void 0 : _c.client_id,
                level_id: (_e = (_d = this.topicData) === null || _d === void 0 ? void 0 : _d.group) === null || _e === void 0 ? void 0 : _e.level_id,
            };
        } }, mapTopicState({
        topicData: function (state) { return state.currentTopic.data; },
        topicReactions: function (state) {
            return state.currentTopicReactions;
        },
        topicLoading: function (state) { return state.currentTopic.loading; },
    })),
    methods: {
        handleOptimisticPostsCount: function (count) {
            this.totalPostsCount = count;
        },
        openEditAndScrollToAttachments: function () {
            ;
            this.$refs.topicForm.openSheetAndScrollToId("suus__topic-form__attachments");
        },
    },
    watch: {
        topicData: function (newVal) {
            this.totalPostsCount = newVal.posts_count;
        },
    },
    setup: function () {
        var t$ = useTranslate("attachments").t$;
        return {
            t$: t$,
        };
    },
});
