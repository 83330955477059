import { __assign, __awaiter, __generator } from "tslib";
import vue2Dropzone from "vue2-dropzone";
import DriveUploadComponent from "./DriveUpload.vue";
import DriveUploaderSubmit from "components/Drive/DriveUploaderSubmit.vue";
import { createNamespacedHelpers } from "vuex";
import DriveUploaderForm from "components/Drive/DriveUploaderForm.vue";
import UploadProgress from "components/Drive/UploadProgress.vue";
import MediaUploader from "features/media/MediaUploader.vue";
import "features/media/media.types";
import { defineComponent } from "vue";
import "components/Drive/drive.types";
var _a = createNamespacedHelpers("drive"), mapState = _a.mapState, mapMutations = _a.mapMutations, mapActions = _a.mapActions;
export default defineComponent({
    components: {
        MediaUploader: MediaUploader,
        DriveUploadComponent: DriveUploadComponent,
        DriveUploaderSubmit: DriveUploaderSubmit,
        DriveUploaderForm: DriveUploaderForm,
        vueDropzone: vue2Dropzone,
        UploadProgress: UploadProgress,
    },
    props: {
        parent: {
            type: Object,
        },
        showSuccess: {
            type: Boolean,
            default: false,
        },
    },
    computed: __assign({ saving: function () {
            return (this.totalUploads > 0 && this.totalUploads !== this.processedUploads);
        }, saved: function () {
            return (this.totalUploads > 0 && this.totalUploads === this.processedUploads);
        }, updateUpload: function () {
            return this.$refs.mediaUploader.updateUpload;
        } }, mapState(["uploaderOpen"])),
    data: function () {
        return {
            totalUploads: 0,
            processedUploads: 0,
            savedUploads: 0,
            errorUploads: 0,
            uploadsInProgress: 0,
            uploads: [],
            totalProgress: 0,
        };
    },
    methods: __assign(__assign({ getUpload: function (token) {
            return this.uploads.find(function (e) { return e.file.token === token; });
        }, appendFormDataToUploads: function (formData) {
            for (var _i = 0, _a = this.uploads; _i < _a.length; _i++) {
                var upload = _a[_i];
                var fd = formData[upload.file.token];
                if (fd)
                    upload.formData = fd;
            }
        }, appendS3KeyToUploads: function (_a) {
            var s3Key = _a.s3Key, token = _a.token;
            var upload = this.getUpload(token);
            upload.s3Key = s3Key;
            this.updateUpload({ s3Key: s3Key })(token);
        }, removeUpload: function (token) {
            var upload = this.uploads.find(function (e) { return e.file.token === token; });
            if (upload) {
                if (upload.errors.length > 0)
                    this.errorUploads--;
                this.$refs.mediaUploader.removeUpload(token);
            }
        }, updateUploads: function (uploads) {
            var _this = this;
            this.uploads = uploads.map(function (mediaUpload) {
                var availableUpload = _this.uploads.find(function (upload) { return upload.file.token === mediaUpload.file.token; });
                return availableUpload
                    ? __assign(__assign(__assign({}, availableUpload), mediaUpload), { formData: availableUpload.formData }) : mediaUpload;
            });
        }, submit: function (parallelUploads, valid) {
            if (!valid)
                return;
            this.totalUploads = this.uploads.length;
            this.processedUploads = 0;
            this.savedUploads = 0;
            this.errorUploads = 0;
            var uploadQueue = this.uploads.map(function (e) { return e.file.token; });
            this.upload(parallelUploads, uploadQueue);
        }, upload: function (parallelUploads, uploadQueue) {
            return __awaiter(this, void 0, void 0, function () {
                var fileToken;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(uploadQueue.length > 0)) return [3 /*break*/, 4];
                            if (!(this.uploadsInProgress <= parallelUploads)) return [3 /*break*/, 1];
                            fileToken = uploadQueue.shift();
                            this.saveUpload(fileToken);
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 1000); })];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [3 /*break*/, 0];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }, appendParentId: function (formData) {
            var _a;
            formData.inode.parent_id = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.id;
            return formData;
        }, saveUpload: function (token) {
            return __awaiter(this, void 0, void 0, function () {
                var upload, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.uploadsInProgress++;
                            upload = this.uploads.find(function (e) { return e.file.token === token; });
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            return [4 /*yield*/, this.createFile(this.appendParentId(upload.formData))];
                        case 2:
                            _a.sent();
                            this.savedUploads++;
                            this.$refs.mediaUploader.removeUpload(token);
                            return [3 /*break*/, 5];
                        case 3:
                            err_1 = _a.sent();
                            this.handleUploadError(err_1, token);
                            return [3 /*break*/, 5];
                        case 4:
                            this.processedUploads++;
                            this.uploadsInProgress--;
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        }, handleUploadError: function (err, token) {
            this.errorUploads++;
            if (err.response) {
                // The request was made and the server responded with a
                // status code that falls out of the range of 2xx
                try {
                    this.updateUpload({ errors: err.response.data })(token);
                }
                catch (e) {
                    this.updateUpload({ errors: ["Unerwartete Antwort des Servers."] })(token);
                }
            }
            else if (err.request) {
                // The request was made but no response was received, `err.request`
                // is an instance of XMLHttpRequest
                this.updateUpload({ errors: ["Keine Antwort vom Server."] })(token);
            }
            else {
                console.error(err);
                // Something happened in setting up the request and triggered an Error
                this.updateUpload({
                    errors: ["Fehler beim Vorbereiten der Serveranfrage."],
                })(token);
            }
        }, reset: function () {
            this.totalUploads = 0;
            this.processedUploads = 0;
            this.uploads = [];
            this.savedUploads = 0;
            this.errorUploads = 0;
        } }, mapMutations(["showUploader"])), mapActions(["createFile"])),
    watch: {
        uploaderOpen: function (open) {
            if (!open) {
                ;
                this.$refs.mediaUploader.reset();
                this.reset();
            }
        },
    },
});
