<template lang="pug">
a.ui.icon.button.pin-link(v-tooltip="'Anpinnen'" :class="{active: pin}" @click="togglePin" :title="title")
  i.ui.icon.suus-pin(:class="{blue: pin }")
  slot
</template>

<script>
export default {
  name: "Pin",
  props: {
    inode: { type: Object, required: true },
  },
  data() {
    return {
      pin: this.inode.is_pinned,
    }
  },
  watch: {
    inode() {
      this.pin = this.inode.is_pinned
    },
  },
  computed: {
    title() {
      if (this.pin) {
        return this.$t("drive.pinButton.pin")
      } else {
        return this.$t("drive.pinButton.pin")
      }
    },
  },
  methods: {
    async togglePin() {
      try {
        this.pin = !this.pin // optimistically set state
        const method = this.pin ? this.$http.post : this.$http.delete
        await method(this.inode.pin_url)
      } catch (err) {
        console.log(err)
        this.pin = !this.pin // reset state
      }
    },
  },
}
</script>

<style>
i.ui.icon.suus-pin {
  background-color: transparent!important;
}
</style>
