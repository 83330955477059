<template lang="pug">
v-row.mb-8
  v-col.mt-8(cols="12")
    v-alert(color="textColor", outlined, :style="alertStyles")
      .d-flex.pa-4
        v-switch.mt-0(
          v-model="inputRemindVal",
          :disabled="!!remindMailedAt",
          inset,
          @change="handleInputRemind"
        ) 
        .pl-4(@click="inputRemindVal.value = !!remindMailedAt || !inputRemindVal")
          Subtitle(
            :text="$t('components.topic_form.urgent_message')",
            :fontSize="1.1",
            :fontWeight="900"
          )
          TextBody(
            :text="$t('components.topic_form.send_urgent_message_text')"
          )
          Subtitle(:text="alertTimeText", v-if="alertTimeText")
</template>

<script>
import Header from "../UI/Header.vue"
import TextBody from "../UI/TextBody.vue"
import Subtitle from "../UI/Subtitle.vue"

import { ref } from "vue"
import { formatDateTime } from "helpers/dateTime"

export default {
  name: "TopicFormPanelReminder",
  components: { Header, TextBody, Subtitle },
  props: {
    inputRemind: { type: Boolean, default: false },
    remindMailedAt: { type: String },
    remindMailedBy: { type: Object },
  },
  setup(props, context) {
    let inputRemindVal = ref(props?.inputRemind || !!props.remindMailedAt)
    function handleInputRemind(val) {
      context.emit("update-field", { key: "inputRemind", val })
    }
    return { inputRemindVal, handleInputRemind }
  },
  computed: {
    alertTimeText() {
      if (!this.remindMailedAt) return ""
      return this.$t("components.topic_form.urgent_message_sent_at", {
        time: formatDateTime(this.remindMailedAt),
      })
    },
    alertStyles() {
      return {
        borderColor: `${this.$vuetify.theme.themes.light.mediumGrey} !important`,
        fontFamily: "system-ui !important",
        fontSize: "1rem",
        color: `${this.$vuetify.theme.themes.light.textColor} !important`,
        marginBottom: "0px",
        width: "100%",
      }
    },
  },
}
</script>
