import { __awaiter, __generator, __spreadArray } from "tslib";
import { computed, ref } from "vue";
import "features/media/media.types";
import "suus-api";
import "features/gallery/gallery.types";
import { addGalleryMediaToCreatedPost, addGalleryMediaToUpdatedPost, } from "features/posts/form/gallery/gallery.functions";
import "features/posts/form/post-form.types";
import { getGalleryMedia } from "features/posts/gallery/gallery.functions";
export var useGalleryAddon = function (postRef, deletePost) {
    var _a, _b;
    var galleryUploads = ref([]);
    var setGalleryUploads = function (newGalleryUploads) {
        galleryUploads.value = newGalleryUploads;
    };
    var resetFunction = function () {
        galleryUploads.value = [];
    };
    var galleryCount = computed(function () {
        return existingGalleryMediaCount.value + galleryUploads.value.length;
    });
    var existingGalleryMedia = ref([]);
    var existingGalleryMediaCount = ref((_b = (_a = postRef.value) === null || _a === void 0 ? void 0 : _a.event_media_count) !== null && _b !== void 0 ? _b : 0);
    var existingGalleryMediaLoading = ref(false);
    var loadAllExistingGalleryMedia = function () { return __awaiter(void 0, void 0, void 0, function () {
        var notYetLoadedMediaCount, offset, loadedMedia;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    existingGalleryMediaLoading.value = true;
                    notYetLoadedMediaCount = existingGalleryMediaCount.value;
                    _a.label = 1;
                case 1:
                    if (!(notYetLoadedMediaCount > 0)) return [3 /*break*/, 3];
                    offset = existingGalleryMediaCount.value - notYetLoadedMediaCount;
                    return [4 /*yield*/, getGalleryMedia(postRef.value.id, offset, 20)];
                case 2:
                    loadedMedia = _a.sent();
                    existingGalleryMedia.value = __spreadArray(__spreadArray([], existingGalleryMedia.value, true), loadedMedia, true);
                    notYetLoadedMediaCount -= loadedMedia.length;
                    return [3 /*break*/, 1];
                case 3:
                    existingGalleryMediaLoading.value = false;
                    return [2 /*return*/];
            }
        });
    }); };
    var createGalleryMedia = function (createdPost) {
        return addGalleryMediaToCreatedPost(createdPost, deletePost, galleryUploads.value);
    };
    var updateGalleryMedia = function (updatedPost) {
        return addGalleryMediaToUpdatedPost(updatedPost, galleryUploads.value, existingGalleryMedia.value);
    };
    var removeExistingGalleryMedia = function (mediumId) {
        existingGalleryMedia.value = existingGalleryMedia.value.filter(function (medium) { return medium.id !== mediumId; });
        existingGalleryMediaCount.value = existingGalleryMedia.value.length;
    };
    return {
        setGalleryUploads: setGalleryUploads,
        galleryCount: galleryCount,
        existingGalleryMedia: existingGalleryMedia,
        resetFunction: resetFunction,
        createGalleryMedia: createGalleryMedia,
        updateGalleryMedia: updateGalleryMedia,
        removeExistingGalleryMedia: removeExistingGalleryMedia,
        existingGalleryMediaLoading: existingGalleryMediaLoading,
        loadAllExistingGalleryMedia: loadAllExistingGalleryMedia,
    };
};
