import { defineComponent } from "vue";
import Button from "components/UI/Button.vue";
import Subtitle from "components/UI/Subtitle.vue";
import TextBody from "components/UI/TextBody.vue";
import InstallPWAMobileView from "features/push/InstallPWAMobileView.vue";
import AndroidStep1 from "./assets/install-screens/AndroidStep1.png";
import AndroidStep2 from "./assets/install-screens/AndroidStep2.png";
import AndroidStep3 from "./assets/install-screens/AndroidStep3.png";
import AndroidStep4 from "./assets/install-screens/AndroidStep4.png";
import AndroidStep5 from "./assets/install-screens/AndroidStep5.png";
import AndroidStep6 from "./assets/install-screens/AndroidStep6.png";
import IOSStep1 from "./assets/install-screens/IOSStep1.png";
import IOSStep2 from "./assets/install-screens/IOSStep2.png";
import IOSStep3 from "./assets/install-screens/IOSStep3.png";
import IOSStep4 from "./assets/install-screens/IOSStep4.png";
import IOSStep5 from "./assets/install-screens/IOSStep5.png";
import IOSStep6 from "./assets/install-screens/IOSStep6.png";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "InstallPWAMobileWizard",
    components: { InstallPWAMobileView: InstallPWAMobileView, TextBody: TextBody, Subtitle: Subtitle, Button: Button },
    props: {
        os: {
            type: String,
            required: true,
        },
    },
    computed: {
        AndroidStep1: function () {
            return AndroidStep1;
        },
        AndroidStep2: function () {
            return AndroidStep2;
        },
        AndroidStep3: function () {
            return AndroidStep3;
        },
        AndroidStep4: function () {
            return AndroidStep4;
        },
        AndroidStep5: function () {
            return AndroidStep5;
        },
        AndroidStep6: function () {
            return AndroidStep6;
        },
        IOSStep1: function () {
            return IOSStep1;
        },
        IOSStep2: function () {
            return IOSStep2;
        },
        IOSStep3: function () {
            return IOSStep3;
        },
        IOSStep4: function () {
            return IOSStep4;
        },
        IOSStep5: function () {
            return IOSStep5;
        },
        IOSStep6: function () {
            return IOSStep6;
        },
    },
    methods: {
        navigateForwards: function () {
            this.viewkey += 1;
            this.step = 1;
        },
        navigateBackwards: function () {
            this.viewkey += 1;
            this.step = 0;
        },
    },
    data: function () {
        return {
            step: 0,
            viewkey: 0,
        };
    },
    setup: function () {
        var t$ = useTranslate("components.push_wizard.install_pwa_wizard.mobile").t$;
        return { t$: t$ };
    },
});
