import { defineComponent } from "vue";
import { mdiClose } from "@mdi/js";
export default defineComponent({
    name: "CloseButton",
    setup: function () {
        return {
            mdiClose: mdiClose,
        };
    },
});
