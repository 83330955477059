import { defineComponent, provide } from "vue";
import ConfirmationDialog from "features/common/confirmation-dialog/ConfirmationDialog.vue";
import { ConfirmationService } from "features/common/confirmation-dialog/confirmation.service";
export default defineComponent({
    name: "ConfirmationDialogProvider",
    components: { ConfirmationDialog: ConfirmationDialog },
    setup: function () {
        var _confirmationService = new ConfirmationService();
        provide("ConfirmationService", _confirmationService);
        return {
            confirmationService: _confirmationService,
        };
    },
});
