import { debounce } from "lodash";
export default function asyncDebounce(func, wait) {
    var debounced = debounce(function (resolve, reject, args) {
        func.apply(void 0, args).then(resolve)
            .catch(reject);
    }, wait);
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return new Promise(function (resolve, reject) {
            debounced(resolve, reject, args);
        });
    };
}
