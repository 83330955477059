import "vue-i18n";
import { i18n } from "packs/i18n";
export default function useTranslate(component) {
    /**
     * @deprecated The method should not be used. It's too verbose. Use the "t$" method instead.
     */
    function t(key, values) {
        return i18n.t("".concat(component, ".").concat(key), values).toString();
    }
    /**
     * @deprecated The method should not be used. It's too verbose. Use the "tc$" method instead.
     */
    function tc(key, choice, values) {
        return i18n.tc("".concat(component, ".").concat(key), choice, values);
    }
    // with new syntax: leading "." in key indicates a relative path; else it's absolute
    function t$(key, values) {
        if (key.startsWith(".")) {
            return i18n.t("".concat(component).concat(key), values).toString();
        }
        else {
            return i18n.t(key, values).toString();
        }
    }
    // with new syntax: leading "." in key indicates a relative path; else it's absolute
    function tc$(key, choice, values) {
        if (key.startsWith(".")) {
            return i18n.tc("".concat(component).concat(key), choice, values);
        }
        else {
            return i18n.tc(key, choice, values);
        }
    }
    function getComponent(key) {
        return useTranslate("".concat(component, ".").concat(key));
    }
    return { t: t, tc: tc, t$: t$, tc$: tc$, getComponent: getComponent };
}
