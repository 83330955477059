import { defineComponent } from "vue";
import "components/Wysiwyg/extensions/attachment/attachment-item/AttachmentItemNodeView.vue";
import "features/gallery/lightbox/lightbox.service";
export default defineComponent({
    name: "RenderedImage",
    props: {
        src: String,
        size: {
            type: String,
            required: false,
        },
        lightboxWidth: {
            type: Number,
            required: false,
            default: 0,
        },
        lightboxHeight: {
            type: Number,
            required: false,
            default: 0,
        },
        displayMode: {
            type: String,
            required: true,
        },
        lightboxService: {
            type: Object,
            required: true,
        },
        fileName: {
            type: String,
            required: true,
        },
    },
    setup: function (props) {
        var openLightbox = function () {
            props.lightboxService.openPhotoLightbox(0, 1, function (index) {
                return Promise.resolve({
                    src: props.src,
                    width: props.lightboxWidth,
                    height: props.lightboxHeight,
                });
            });
        };
        return { openLightbox: openLightbox };
    },
});
