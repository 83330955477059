<template lang="pug">
v-row.suus__topic-block
  v-col.d-flex.justify-center(cols="4", sm="2")
    v-skeleton-loader(:loading="true", type="avatar", :max-width="100")
  v-col(cols="8", sm="10")
    v-skeleton-loader.mb-4(:loading="true", type="heading")
    v-skeleton-loader(:loading="true", type="text@5")
    .d-flex.justify-start.mt-4
      v-skeleton-loader.pr-4(:loading="true", type="button")
      v-skeleton-loader(:loading="true", type="button")
</template>

<script>
export default {
  name: "TopicSkeleton",
}
</script>
