import { __assign } from "tslib";
import { computed, defineComponent } from "vue";
import THEME from "constants/theme";
export default defineComponent({
    name: "PostFormButton",
    props: {
        inline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        var buttonDisabledLabelColor = computed(function () {
            return props.inline ? "white" : THEME.colors.light.mediumGrey;
        });
        var buttonLabelStyles = computed(function () {
            return __assign({ color: props.disabled
                    ? buttonDisabledLabelColor.value
                    : THEME.colors.light.primary }, THEME.typography.button);
        });
        var buttonContainerStyles = {
            backgroundColor: THEME.colors.light.lightGrey,
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            border: "1px solid ".concat(THEME.colors.light.mediumGrey),
            borderTop: "none",
        };
        return {
            buttonLabelStyles: buttonLabelStyles,
            buttonContainerStyles: buttonContainerStyles,
        };
    },
});
