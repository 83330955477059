import { __assign } from "tslib";
import Vue from "vue";
import UnionIcon from "../icons/UnionIcon.vue";
export default Vue.extend({
    name: "FlagTag",
    props: {
        text: { type: String, required: true },
        height: { type: Number, default: 24 },
        color: { type: String, default: "primary" },
    },
    components: {
        UnionIcon: UnionIcon,
    },
    computed: {
        setColor: function () {
            var _a, _b;
            return this.color === "primary"
                ? (_a = this.$vuetify.theme.themes.light.primary) === null || _a === void 0 ? void 0 : _a.toString()
                : (_b = this.$vuetify.theme.themes.light.textColor) === null || _b === void 0 ? void 0 : _b.toString();
        },
        labelStyles: function () {
            return __assign({ maxWidth: this.$vuetify.breakpoint.name === "xs" ? "200px" : "250px", color: this.setColor, fontSize: "0.8rem !important", lineHeight: "0.8rem !important", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }, this.fontStyles);
        },
        containerStyles: function () {
            return __assign({ height: this.height, minWidth: "120px", borderLeft: "2px solid ".concat(this.setColor), borderTop: "2px solid ".concat(this.setColor), borderBottom: "2px solid ".concat(this.setColor), marginRight: "-8px", borderRadius: "2px 0 0 2px" }, this.fontStyles);
        },
        fontStyles: function () {
            return {
                fontFamily: "system-ui !important",
                color: "".concat(this.setColor, " !important"),
                fontWeight: "900 !important",
            };
        },
    },
});
