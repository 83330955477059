import { defineComponent } from "vue";
import vuetify from "initializers/vuetify";
import { mdiCalendarRange } from "@mdi/js";
import Header from "components/UI/Header.vue";
import TextButton from "components/UI/TextButton.vue";
import Button from "components/UI/Button.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import ScrollTo from "components/UI/ScrollTo.vue";
import FormAlert from "features/common/forms/FormAlert.vue";
import TextBody from "components/UI/TextBody.vue";
import FormActions from "features/common/forms/FormActions.vue";
export default defineComponent({
    name: "FormTopSection",
    components: {
        FormActions: FormActions,
        TextBody: TextBody,
        FormAlert: FormAlert,
        ScrollTo: ScrollTo,
        Button: Button,
        TextButton: TextButton,
        Header: Header,
    },
    props: {
        formTitle: { type: String, required: true },
        disableSaveButton: { type: Boolean, required: false, default: false },
        formError: { type: String, required: false },
        fieldErrors: { type: Array, required: false },
        defaultMessage: { type: String, required: true },
    },
    computed: {
        headerIconStyles: function () {
            return {
                border: "2px solid ".concat(vuetify.framework.theme.themes.light.primary),
                borderRadius: "4px",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            };
        },
        obligatoryFieldsText: function () {
            return this.t$(".obligatory_fields");
        },
    },
    methods: {
        emitCancel: function () {
            this.$emit("cancel");
        },
        emitSave: function () {
            this.$emit("save");
        },
    },
    setup: function () {
        var t$ = useTranslate("common").t$;
        return { mdiCalendarRange: mdiCalendarRange, t$: t$ };
    },
});
