import "features/gallery/service/gallery.service";
import "features/gallery/gallery.types";
import "features/gallery/thumbnail/GalleryThumbnail.vue";
import { ref, watch } from "vue";
import "suus-api";
/**
 * This service is responsible for managing the state of the thumbnails.
 * Pass a "BuildThumbnailRepresentationFunction" which is responsible for building the thumbnail representation.
 * A thumbnail representation determines which actions are displayed on the thumbnail. see: [ThumbnailRepresentation]
 * The build function is called in the following cases:
 * - the thumbnail is registered
 * - the thumbnail is added to highlights
 * - the thumbnail is removed from highlights
 * - the user's edit rights change
 */
var ThumbnailService = /** @class */ (function () {
    function ThumbnailService(galleryService, buildThumbnailRepresentation) {
        this.galleryService = galleryService;
        this.buildThumbnailRepresentation = buildThumbnailRepresentation;
        this._thumbnails = new Map();
        galleryService.registerAddListener(this._onAdd.bind(this));
        galleryService.registerRemoveListener(this._onRemove.bind(this));
        watch(function () { return galleryService.canEdit.value; }, this._onCanEditChange.bind(this), { immediate: true });
    }
    /**
     * Register a thumbnail for a gallery medium. This function should be called, when a GalleryMedium is reloaded from the server.
     * This way the state stays consistent.
     * @param galleryMedium
     */
    ThumbnailService.prototype.registerThumbnail = function (galleryMedium) {
        var _a;
        if (this._thumbnails.has(galleryMedium.id)) {
            var existingThumbnail = this._thumbnails.get(galleryMedium.id);
            existingThumbnail.isHighlight.value = !!((_a = galleryMedium.links) === null || _a === void 0 ? void 0 : _a.highlight_url);
            return this._buildThumbnailRepresentation(existingThumbnail, null);
        }
        var thumbnailRepresentation = this._buildThumbnailRepresentation(new ThumbnailRepresentation(galleryMedium), null);
        this._thumbnails.set(galleryMedium.id, thumbnailRepresentation);
        return thumbnailRepresentation;
    };
    ThumbnailService.prototype._buildThumbnailRepresentation = function (thumbnailRepresentation, canEdit) {
        var updatedThumbnailRepresentation = this.buildThumbnailRepresentation(thumbnailRepresentation, canEdit !== null && canEdit !== void 0 ? canEdit : this.galleryService.canEdit.value);
        thumbnailRepresentation.update(updatedThumbnailRepresentation);
        return thumbnailRepresentation;
    };
    ThumbnailService.prototype._onAdd = function (highlight) {
        var addedGalleryMedium = highlight.event_medium;
        var thumbnailRepresentation = this._thumbnails.get(addedGalleryMedium.id);
        if (thumbnailRepresentation) {
            thumbnailRepresentation.isHighlight.value = true;
            this._buildThumbnailRepresentation(thumbnailRepresentation, null);
        }
    };
    ThumbnailService.prototype._onRemove = function (galleryId) {
        var thumbnailRepresentation = this._thumbnails.get(galleryId);
        if (thumbnailRepresentation) {
            thumbnailRepresentation.isHighlight.value = false;
            this._buildThumbnailRepresentation(thumbnailRepresentation, null);
        }
    };
    ThumbnailService.prototype._onCanEditChange = function (canEdit) {
        var _this = this;
        this._thumbnails.forEach(function (thumbnailRepresentation) {
            _this._buildThumbnailRepresentation(thumbnailRepresentation, canEdit);
        });
    };
    return ThumbnailService;
}());
export { ThumbnailService };
var ThumbnailRepresentation = /** @class */ (function () {
    function ThumbnailRepresentation(galleryMedium) {
        var _a;
        this.isHighlight = ref(false);
        this.galleryMedium = galleryMedium;
        this.topLeftAction = ref(null);
        this.topRightAction = ref(null);
        this.isHighlight.value = !!((_a = galleryMedium.links) === null || _a === void 0 ? void 0 : _a.highlight_url);
    }
    ThumbnailRepresentation.prototype.update = function (other) {
        this.topLeftAction.value = other.topLeftAction.value;
        this.topRightAction.value = other.topRightAction.value;
        this.isHighlight.value = other.isHighlight.value;
    };
    return ThumbnailRepresentation;
}());
export { ThumbnailRepresentation };
