import Vue from "vue"
import moment from "moment"

Vue.filter("formatDate", function (datetime) {
  return moment(datetime).utc().format("dd. DD.MM.YYYY").toString()
})

Vue.filter("formatTime", function (datetime) {
  return ` ${moment(datetime).utc().format("HH:mm").toString()} Uhr`
})
