import { defineComponent, provide } from "vue";
import "suus-api";
import "features/gallery/lightbox/lightbox.service";
import AttachmentListItem from "components/AttachmentListItem.vue";
export default defineComponent({
    name: "TextHTMLAttachmentListItem",
    components: { AttachmentListItem: AttachmentListItem },
    props: {
        attachment: {
            type: Object,
            required: true,
        },
        lightboxService: {
            type: Object,
            required: true,
        },
    },
    setup: function (props) {
        provide("lightboxService", props.lightboxService);
        return {};
    },
});
