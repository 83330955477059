/* eslint-disable no-undef */
import "fomantic-ui-less/definitions/behaviors/api.js"
import moment from "moment"
import "moment/locale/de"
moment.locale("de")
window.initSUI = function (scope) {
  // Configure Plugins
  for (let el of document.querySelectorAll("time.timeago")) {
    const time = el.attributes.datetime.value
    el.innerHTML = moment(time).fromNow()
  }

  const apiEndpoint = "/api/v1"
  $.fn.api.settings.api = {
    "search person": apiEndpoint + "/personal_records/search/{query}",
  }

  // Init basic design components
  const $scope = $(scope)
  $scope.findIncludingSelf(".ui.accordion:not(.icon-trigger)").accordion()
  $scope
    .findIncludingSelf(".ui.accordion.icon-trigger")
    .accordion({ selector: { trigger: ".title .icon", exclusive: false } })
  $scope.findIncludingSelf(".ui.checkbox").checkbox()
  $scope.findIncludingSelf(".ui.has-popup").popup({
    delay: { show: 500, hide: 0 },
    hoverable: true,
    position: "top center",
  })
  $scope.findIncludingSelf(".ui.member.search").search({
    minCharacters: 3,
    showNoResults: false,
    apiSettings: { action: "search member" },
  })
  $scope.findIncludingSelf(".tabular.menu .item, .tabs.menu .item").tab()
  $scope.findIncludingSelf(".progress.indicating").progress()
  $scope.findIncludingSelf(".ui.sticky").sticky({ offset: 75 })
  $scope.findIncludingSelf(".shape").shape("fade right", { duration: "1ms" })
  $scope.findIncludingSelf(".icon.hint").popup({ inline: true })

  // Dropdowns
  $scope
    .findIncludingSelf(".simple_form .ui.dropdown, .simple_form.ui.dropdown")
    .not(".combo, .link, .allow-additions")
    .dropdown({ fullTextSearch: true })
  $scope
    .findIncludingSelf(
      ".simple_form .ui.combo.dropdown, .simple_form.ui.combo.dropdown"
    )
    .dropdown({ action: "combo" })
  $scope
    .findIncludingSelf(
      ".simple_form .ui.hover.dropdown, .simple_form.ui.hover.dropdown"
    )
    .dropdown({ on: "hover" })
  $scope
    .findIncludingSelf(
      ".simple_form .ui.dropdown.allow-additions, .simple_form.ui.dropdown.allow-additions"
    )
    .dropdown({ allowAdditions: true })
  $scope
    .findIncludingSelf(
      ".simple_form .ui.dropdown.allow-additions, .simple_form.ui.dropdown.allow-additions"
    )
    .keydown(function (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        return false
      }
    })
  $scope.findIncludingSelf(".simple_form.ui.link.dropdown").dropdown({
    action: "hide",
    forceSelection: false,
    placeholder: "Zum Suchen tippen...",
    onHide() {
      const href = $(this).find(".item.active.selected").attr("href")
      if (href !== undefined) {
        return (location.href = href)
      }
    },
  })

  // Cloaseable messages
  $scope.findIncludingSelf("[data-dims]").each(function (_, el) {
    $(el).unbind("click")
    return $(el).click(() =>
      $($(el).data("dims"))
        .dimmer({
          closable: false,
        })
        .dimmer("toggle")
    )
  })

  $(document).ready(function () {
    $scope.findIncludingSelf(".message.info.transistion").transition({
      animation: "fade down",
      onComplete() {
        return setTimeout(function () {
          console.log("ready")
          return $scope
            .findIncludingSelf(".message.info.transistion")
            .transition({
              animation: "fade down",
              onComplete() {
                return $(this).remove()
              },
            })
        }, 2500)
      },
    })

    return $scope
      .findIncludingSelf(".message.transistion:not(.info)")
      .transition({ animation: "fade down" })
  })

  $scope.findIncludingSelf(".message .close").on("click", function () {
    return $(this)
      .closest(".message")
      .transition({
        animation: "fade down",
        onComplete() {
          return $(this).remove()
        },
      })
  })

  const defaultCalendarConfig = {
    type: "date",
    text: {
      days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      months: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
      monthsShort: [
        "Jan",
        "Feb",
        "Mär",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ],
      today: "Heute",
      now: "Jetzt",
      am: "AM",
      pm: "PM",
    },
    formatter: {
      datetime(date) {
        if (date) {
          return moment(date).format("dd., DD.MM.YYYY")
        } else {
          return null
        }
      },
    },
    parser: {
      date(text) {
        return moment(text, "DD.MM.YYYY").toDate()
      },
    },
  }

  const defaultTimepickerConfig = {
    type: "time",
    ampm: false,
    formatter: {
      time(time) {
        if (time) {
          return moment(time).format("HH:mm")
        } else {
          return null
        }
      },
    },
    parser: {
      date(text) {
        console.log(text)
        return moment(text, "HH:mm").toDate()
      },
    },
  }
  /*
  defaultDatetimepickerConfig = {
    type: 'datetime',
    ampm: false,
    text: {
      days: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
      today: 'Heute',
      now: 'Jetzt',
      am: 'AM',
      pm: 'PM'
    },
    formatter:
      datetime: (dt) ->
        if dt
          return moment(dt).format('dd., DD.MM.YYYY HH:mm Uhr')
        else
          return null;
    parser:
      datetime: (text) ->
        return moment(text, 'DD.MM.YYYY HH:mm Uhr').toDatetime();
  }
  */

  const addCalSettings = (object2) =>
    $.extend({}, defaultCalendarConfig, object2)

  // Calendars
  $scope
    .findIncludingSelf(".ui.calendar")
    .not(".start, .end, .year-first, .inline")
    .calendar(defaultCalendarConfig)
  $scope
    .findIncludingSelf(".ui.calendar.year-first")
    .calendar(addCalSettings({ startMode: "year" }))

  // Timepickers
  $scope.findIncludingSelf(".ui.timepicker").calendar(defaultTimepickerConfig)

  // Datetimepickers
  // $scope.findIncludingSelf('.ui.datetimepicker').calendar(defaultDatetimepickerConfig);

  $scope.findIncludingSelf(".calendar-range").each(function (_, el) {
    const startCal = $(el).find(".calendar.start")
    const endCal = $(el).find(".calendar.end")
    $(startCal).calendar(addCalSettings({ endCalendar: endCal }))
    return $(endCal).calendar(addCalSettings({ startCalendar: startCal }))
  })

  $scope
    .findIncludingSelf(".ui.calendar.inline")
    .calendar(addCalSettings({ inline: true }))

  // Custom components
  initSelectableLists(scope)
  initLazyComponentLoad(scope)
  return initTogglers(scope)
}
