<template lang="pug">
LoaderWrapper(:requires="[currentInode]")
  Header
    template(#buttons)
      Pin(:inode="currentInode" v-if="currentInode.pin_url")

      sui-dropdown.icon.dots(icon='suus-menu-dots' button direction="downward" v-if="currentInode.id && currentInode.destroy_url")
        sui-dropdown-menu
          sui-dropdown-item(@click="destroy" v-if="currentInode.id && currentInode.destroy_url") Datei löschen
  DriveFileForm(:inode="currentInode")
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")
import LoaderWrapper from "../LoaderWrapper.vue"
import Header from "../Header.vue"
import DriveFileForm from "../DriveFileForm.vue"
import Pin from "../Pin.vue"
export default {
  name: "EditDriveFile",
  data() {
    return {
      loaded: false,
    }
  },
  components: { Pin, DriveFileForm, Header, LoaderWrapper },
  async created() {
    await this.setCurrentInode(this.$route.params.inode_id)
  },
  computed: {
    ...mapState(["path"]),
    ...mapGetters(["currentInode", "parentFolder"]),
  },
  methods: {
    destroy() {
      if (!confirm("Datei wirklich löschen?")) return
      try {
        const parent_id = this.currentInode.parent_id
        this.destroyDriveFile(this.currentInode)
        if (parent_id) {
          this.$router.replace({
            name: "drive/folder",
            params: { inode_id: parent_id },
          })
        } else {
          this.$router.replace({ name: "drive/latest" })
        }
        this.$store.commit("drive/setSuccess", "Datei gelöscht.")
      } catch (e) {
        console.log(e)
        this.setError(e)
      }
    },
    ...mapActions(["setCurrentInode", "destroyDriveFile"]),
  },
}
</script>

<style scoped></style>
