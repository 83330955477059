import TextBody from "../UI/TextBody.vue";
import Subtitle from "../UI/Subtitle.vue";
import { PanelIdentifier } from "features/events/form/event-form.types";
import { computed, defineComponent, inject, watch, } from "vue";
import "features/events/form/event-form.service";
import "components/EventForm/EventForm.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import { usePermissionCheck } from "features/common/permissions/usePermissionCheck";
import { ClientAbility } from "suus-api";
var PublishingFields = defineComponent({
    name: "PublishingFields",
    components: { TextBody: TextBody, Subtitle: Subtitle },
    computed: {
        infoText: function () {
            return this.t$("components.event_form.semester_program_info_text");
        },
    },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        eventFormService.registerProperty({
            name: "is_public",
        });
        var isPublic = computed({
            get: function () {
                return !!eventFormService.getFormValue("is_public").value;
            },
            set: function (value) {
                eventFormService.setFormValue("is_public", !!value);
            },
        });
        var isHidden = computed(function () { return eventFormService.getFormValue("nonmember_access").value === "hidden"; });
        watch(isHidden, function (value) {
            if (value) {
                isPublic.value = false;
            }
        });
        var hasClientPermission = usePermissionCheck().hasClientPermission;
        var canPublish = computed(function () {
            return !isHidden.value &&
                hasClientPermission(ClientAbility.ManageOpenEvents).value;
        });
        return {
            isPublic: isPublic,
            t$: t$,
            canPublish: canPublish,
        };
    },
});
export default PublishingFields;
export var PublishingFieldsDefinition = {
    key: "publish",
    component: PublishingFields,
    id: PanelIdentifier.PUBLISHING,
};
