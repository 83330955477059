var initPagePartials, legacyInit

import "cookieconsent"
import "./fomantic.js"
import ujs from "@rails/ujs"
import "./shared/shared.js"
import "./pages/pages.js"
import "./initSUI.js"

ujs.start()

initPagePartials = function (scope) {
  return $(scope)
    .findIncludingSelf("[data-init]")
    .map(function (_, el) {
      var initss
      initss = $(el).data("init").split(", ")
      return $(initss).each(function () {
        var ex
        //console.log("Found partial init script: " + this)
        try {
          window[this](el)
          return $(el).removeAttr("data-init")
        } catch (error) {
          ex = error
          //return console.log("init-script not found or error: " + this)
        }
      })
    })
}

legacyInit = function (scope) {
  var action, classes, controller, ex, initActionFN, initControllerFN
  classes = $("body").attr("class").split(" ")
  controller = classes[0].replace("_", " ")
  action = classes[1].replace("_", " ")
  initControllerFN = ("init " + controller).camelize()
  initActionFN = ("init " + controller + " " + action).camelize()
  try {
    if (window[initControllerFN] && !window[initActionFN]) {
      //console.log("Calling: " + initControllerFN)
      return window[initControllerFN](scope)
    } else {
      //console.log("Calling: " + initActionFN)
      return window[initActionFN](scope)
    }
  } catch (error) {
    ex = error
    //return console.log(ex.message, initControllerFN, initActionFN)
  }
}

window.initPage = function (scope) {
  initPagePartials(scope)
  return legacyInit(scope)
}

jQuery(document).ready(function () {
  var currScrollPos, handleScrollPosition, helpWidget, isMobile, ticking
  initSUI($("body"))
  initPageLayout()
  initPage($("body"))
  isMobile = window.matchMedia("only screen and (max-width: 760px)").matches
  // Set z-index of helpWidget
  helpWidget = document.getElementById("jsd-widget")
  if (isMobile && helpWidget) {
    helpWidget.style.zIndex = "10"
  }
  handleScrollPosition = function (currScrollPos, prevScrollPos) {
    var bottomOfPage, mobileScrollDown, pageHeight, visible
    visible = document.documentElement.clientHeight
    pageHeight = document.documentElement.scrollHeight
    bottomOfPage = visible + currScrollPos >= pageHeight
    if (bottomOfPage) {
      //$("#jsd-widget").fadeOut()
    } else {
      $("#jsd-widget").fadeIn()
    }
  }
  // throttle scroll event for performance https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
  currScrollPos = 0
  ticking = false
  return document.addEventListener("scroll", function (e) {
    var prevScrollPos
    prevScrollPos = currScrollPos
    currScrollPos = window.scrollY
    if (!ticking) {
      window.requestAnimationFrame(function () {
        handleScrollPosition(currScrollPos, prevScrollPos)
        ticking = false
      })
      ticking = true
    }
  })
})

$.ajaxSetup({
  cache: false,
})
