window.initTogglers = (scope) ->
  $(scope).find('[data-toggle]').each ->
    toggle = $(this)
    target = $(''+toggle.data('toggle'))

    toggle.click ->
      target.toggle()
      newText = toggle.data('toggle-text')
      if newText != undefined
        oldText = toggle.html()
        toggle.html(newText)
        toggle.data('toggle-text', oldText)