import { __extends } from "tslib";
import "vuex";
import { computed } from "vue";
import store from "store";
import FormService from "features/common/forms/form.service";
var EventFormService = /** @class */ (function (_super) {
    __extends(EventFormService, _super);
    function EventFormService(store) {
        var _this = _super.call(this) || this;
        _this.store = store;
        _this._form = computed(function () { var _a; return (_a = _this.store.state.event) === null || _a === void 0 ? void 0 : _a.eventForm; });
        return _this;
    }
    Object.defineProperty(EventFormService.prototype, "currentId", {
        get: function () {
            return this._form.value.id;
        },
        enumerable: false,
        configurable: true
    });
    EventFormService.prototype._updateForm = function (key, value) {
        var _a;
        store.commit("event/updateEventForm", (_a = {}, _a[key] = value, _a));
    };
    return EventFormService;
}(FormService));
export default EventFormService;
