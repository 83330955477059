import { defineComponent } from "vue";
import SadGuy from "./assets/SadGuy.svg";
import TextBody from "components/UI/TextBody.vue";
import Button from "components/UI/Button.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "PushUnsupportedWizard",
    components: {
        SadGuy: SadGuy,
        TextBody: TextBody,
        Button: Button,
    },
    props: {
        pushBlocked: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props, context) {
        var t$ = useTranslate("components.push_wizard.unsupported_wizard").t$;
        return {
            t$: t$,
        };
    },
});
