<template lang="pug">
div
  sui-message(success
    v-if="success"
    :content="success"
    dismissable
    @dismiss="setSuccess(false)"
  )
  sui-message(error
  v-if="error"
    :content="error"
    dismissable
    @dismiss="setError(false)"
  )
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"

export default {
  name: "Flash",
  props: {
    success: { type: [String, Boolean] },
    error: { type: [String, Boolean] },
  },
  methods: {
    ...mapMutations("drive", ["setSuccess", "setError"]),
  },
}
</script>

<style scoped></style>
