import { Node } from "@tiptap/core";
import AttachmentItemNodeView from "components/Wysiwyg/extensions/attachment/attachment-item/AttachmentItemNodeView.vue";
import { mergeAttributes, VueNodeViewRenderer } from "@tiptap/vue-2";
export default Node.create({
    name: "attachmentItem",
    group: "block",
    draggable: false,
    addAttributes: function () {
        return {
            "data-attachment-id": {
                default: null,
            },
            "data-frontend-id": {
                default: null,
            },
            "data-stored-file": {
                default: null,
            },
            "data-file-name": {
                default: null,
            },
            "data-image-mode": {
                default: null,
            },
            "data-image-size": {
                default: null,
            },
        };
    },
    addNodeView: function () {
        return VueNodeViewRenderer(AttachmentItemNodeView); // FIXME maybe composition api does not play nice here with @tiptap/vue-2
    },
    parseHTML: function () {
        return [
            {
                tag: "attachment-item",
            },
        ];
    },
    renderHTML: function (_a) {
        var HTMLAttributes = _a.HTMLAttributes;
        return ["attachment-item", mergeAttributes(HTMLAttributes)];
    },
});
