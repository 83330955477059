import { render, staticRenderFns } from "./GalleryDashboardCardUI.vue?vue&type=template&id=e598c69e&scoped=true&"
import script from "./GalleryDashboardCardUI.vue?vue&type=script&lang=ts&"
export * from "./GalleryDashboardCardUI.vue?vue&type=script&lang=ts&"
import style0 from "./GalleryDashboardCardUI.vue?vue&type=style&index=0&id=e598c69e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e598c69e",
  null
  
)

export default component.exports