import axios from "../../initializers/axios"
import { groupApi } from "store/api"

export default {
  async getGroup({ commit }, group_id) {
    try {
      const { data } = await groupApi.getGroup(group_id)
      commit("SET_GROUP_DATA", data)
    } catch (err) {
      switch (err.response.status) {
        case 404:
          console.log(err)
          break
        case 500:
          console.log(err)
          break
        default:
          console.error(err.response.status, err)
      }
    }
  },
  async createGroup(_, formData) {
    try {
      const { data: group } = await groupApi.getGroups(formData)
      return group
    } catch (err) {
      console.log(err)
    }
  },
  async updateGroup(_, { group_id, formData }) {
    const { data } = await axios.put(`/api/v1/groups/${group_id}`, formData)
    return data
  },

  async destroy(_, group_id) {
    try {
      await axios.delete(`/api/v1/groups/${group_id}`)
    } catch (err) {
      console.log(err)
    }
  },
}
