import { defineComponent } from "vue";
import Header from "components/UI/Header.vue";
import { mdiCommentPlusOutline } from "@mdi/js";
export default defineComponent({
    name: "PostFormHeader",
    components: { Header: Header },
    setup: function () {
        return {
            newPostIcon: mdiCommentPlusOutline,
        };
    },
});
