import { __awaiter, __generator } from "tslib";
import { defineComponent, inject, provide } from "vue";
import PostList from "features/posts/PostList.vue";
import "concerns/useContextName";
import { eventsApi } from "store/api";
import "interfaces/error.interface";
import "features/posts/PostOmissionLoader.vue";
import "features/gallery/service/gallery.service";
import { PostThumbnailService } from "features/posts/gallery/post-thumbnail.service";
export default defineComponent({
    name: "EventPostList",
    components: { PostList: PostList },
    props: {
        totalPostsCount: { type: Number, required: true },
        eventId: { type: Number, required: true },
        canCreatePosts: { type: Boolean, default: false },
        canEditHighlights: { type: Boolean, default: false },
        viewContext: { type: Object },
    },
    setup: function (props) {
        var _this = this;
        var performCreatePost = function (eventId, postText) { return __awaiter(_this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.createEventPost(eventId.toString(), {
                                text: postText,
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                    case 2:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var performUpdatePost = function (eventId, postId, postText) { return __awaiter(_this, void 0, void 0, function () {
            var data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.updateEventPost(eventId.toString(), postId.toString(), {
                                text: postText,
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                    case 2:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var performDeletePost = function (eventId, postId) { return __awaiter(_this, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, eventsApi.deleteEventPost(eventId.toString(), postId.toString())];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 2:
                        error_3 = _a.sent();
                        console.error(error_3);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var loadEventPosts = function (offset, limit) {
            return eventsApi
                .getEventPosts(props.eventId.toString(), offset, limit, "desc")
                .then(function (response) { return response.data; });
        };
        var galleryService = inject("GalleryService");
        if (galleryService) {
            var thumbnailService = new PostThumbnailService(galleryService);
            provide("ThumbnailService", thumbnailService);
        }
        return {
            performCreatePost: performCreatePost,
            performUpdatePost: performUpdatePost,
            performDeletePost: performDeletePost,
            loadEventPosts: loadEventPosts,
        };
    },
});
