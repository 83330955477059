import { currentMemberApi } from "../api"

export default {
  async getCurrentMember({ state, commit }) {
    try {
      const host = window.location.host
      if (
        host.startsWith("auth.") ||
        host.startsWith("admin.") ||
        host.startsWith("docs.") ||
        window.location.pathname.startsWith("/digest/")
      ) {
        console.log("Not on a client, skipping retrieval of current member.")
        return
      }
      commit("SET_CURRENT_MEMBER_ERROR", false)
      commit("SET_CURRENT_MEMBER_LOADING", true)
      const { data } = await currentMemberApi.getCurrentMember()
      commit("SET_CURRENT_MEMBER_DATA", data)
    } catch (error) {
      commit("SET_CURRENT_MEMBER_ERROR", error)
      if (error.response) {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      } else if (error.request) {
        console.log(error.request)
      } else {
        console.log("Error", error.message)
      }
    } finally {
      commit("SET_CURRENT_MEMBER_LOADING", false)
    }
  },
}
