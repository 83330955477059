window.createEvent = (scope) ->

  $('#topic_link_type, #topic_link_id').change ->
    linked = $('#topic_link_type').is(':checked')
    $('.field.topic_link_id').toggleClass('hide', !linked)
    if $('#topic_link_id').val() == ''
      $('.wizard .step.create-event.breadcrumb').removeClass('hide')
      $('#topic_next_button').removeClass 'hide'
      $('#topic_save_button').addClass 'hide'
      $('.field.event_topic').addClass 'hide'
      $('.field.event_group_id').addClass 'hide'

    if !linked || $('#topic_link_id').val() != ''
      $('.wizard .step.create-event.breadcrumb').addClass 'hide'
      $('#topic_next_button').addClass 'hide'
      $('#topic_save_button').removeClass 'hide'
      $('.field.event_topic').removeClass 'hide'
      $('.field.event_group_id').removeClass 'hide'

  $('#event_all_day').change ->
    allDay = $('#event_all_day').is(':checked')
    $('.row.event-times').toggleClass 'hide', allDay

  $('#topic_link_type, #topic_link_id, #event_all_day').trigger 'change'