import "suus-api";
import { getContextName } from "concerns/useContextName";
var MemberReaction = /** @class */ (function () {
    function MemberReaction(id, name, link, reaction, avatar_url) {
        this.id = id;
        this.name = name;
        this.link = link;
        this.reaction = reaction;
        this.avatar_url = avatar_url;
    }
    MemberReaction.fromReaction = function (reaction, viewContext) {
        return new MemberReaction(reaction.creator.id, getContextName(viewContext, reaction.creator), reaction.creator.url, reaction.emoji, reaction.creator.avatar_url);
    };
    return MemberReaction;
}());
export { MemberReaction };
