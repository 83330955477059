import { ref, watch } from "vue";
import { mdiAccountGroupOutline, mdiAccountLockOutline, mdiEyeOffOutline, mdiEyeOutline, } from "@mdi/js";
import { GroupAccess } from "components/TopicForm/general-fields/group-access";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "suus-api";
import "components/UI/ContentfulSelect/index.vue";
var getContentfulOptions = function () {
    var _a;
    var t$ = useTranslate("components.topic_form").t$;
    var OPEN = t$(".open");
    var INTERNAL = t$(".internal");
    var CONFIDENTIAL = t$(".confidential");
    var VISIBLE_FROM_OUTSIDE_GROUP = t$(".visible_from_outside_group");
    var NOT_VISIBLE_FROM_OUTSIDE_GROUP = t$(".not_visible_from_outside_group");
    var PARTICIPATE_FROM_OUTSIDE_GROUP = t$(".participate_from_outside_group");
    var PARTICIPATE_WITHIN_GROUP_ONLY = t$(".participate_within_group_only");
    return _a = {},
        _a[GroupAccess.OPEN] = {
            title: OPEN,
            type: GroupAccess.OPEN,
            contentItems: [
                {
                    icon: mdiEyeOutline,
                    text: VISIBLE_FROM_OUTSIDE_GROUP,
                },
                {
                    icon: mdiAccountGroupOutline,
                    text: PARTICIPATE_FROM_OUTSIDE_GROUP,
                },
            ],
        },
        _a[GroupAccess.INTERNAL] = {
            title: INTERNAL,
            type: GroupAccess.INTERNAL,
            contentItems: [
                {
                    icon: mdiEyeOutline,
                    text: VISIBLE_FROM_OUTSIDE_GROUP,
                },
                {
                    icon: mdiAccountLockOutline,
                    text: PARTICIPATE_WITHIN_GROUP_ONLY,
                },
            ],
        },
        _a[GroupAccess.CONFIDENTIAL] = {
            title: CONFIDENTIAL,
            type: GroupAccess.CONFIDENTIAL,
            contentItems: [
                {
                    icon: mdiEyeOffOutline,
                    text: NOT_VISIBLE_FROM_OUTSIDE_GROUP,
                },
                {
                    icon: mdiAccountLockOutline,
                    text: PARTICIPATE_WITHIN_GROUP_ONLY,
                },
            ],
        },
        _a;
};
var isInAccessOptions = function (accessOptions, accessValue) { return accessOptions.some(function (option) { return option.type === accessValue; }); };
var _isOwnGroup = function (group, memberships) { var _a; return (_a = (!!(group === null || group === void 0 ? void 0 : group.id) && memberships.includes(group.id))) !== null && _a !== void 0 ? _a : false; };
var getDefaultValue = function (isInternalGroup, accessOptions) {
    return isInternalGroup ? GroupAccess.INTERNAL : accessOptions[0].type;
};
export function useAccessOptions(group, memberships, selectedAccess, updateSelectedAccess, initialAccessValue) {
    var contentfulOptions = getContentfulOptions();
    var accessOptions = ref([
        contentfulOptions[GroupAccess.OPEN],
    ]);
    watch(group, function (newGroup) {
        var _a;
        if (!newGroup)
            return;
        var isOwnGroup = _isOwnGroup(newGroup, memberships.value);
        var isClosedGroup = (_a = newGroup.closed) !== null && _a !== void 0 ? _a : false;
        var isInternalGroup = isOwnGroup && !isClosedGroup;
        var enableOpen = !isClosedGroup || initialAccessValue == GroupAccess.OPEN;
        var enableInternal = isInternalGroup || initialAccessValue == GroupAccess.INTERNAL;
        var enableConfidential = isOwnGroup ||
            isClosedGroup ||
            initialAccessValue == GroupAccess.CONFIDENTIAL;
        accessOptions.value = [
            enableOpen && contentfulOptions[GroupAccess.OPEN],
            enableInternal && contentfulOptions[GroupAccess.INTERNAL],
            enableConfidential && contentfulOptions[GroupAccess.CONFIDENTIAL],
        ].filter(Boolean);
        // Update
        var needsUpdate = !selectedAccess.value ||
            !isInAccessOptions(accessOptions.value, selectedAccess.value);
        if (needsUpdate)
            updateSelectedAccess(getDefaultValue(isInternalGroup, accessOptions.value));
    }, { immediate: true });
    return accessOptions;
}
