<template lang="pug">
span.breadcrumbs
  template(v-if="path.length > maxItems")
    slot(name="omission" v-bind:omittedItems="omittedItems") …

  template(v-if="path.length > 0" v-for="(item, i) in path.slice(-maxItems)")
    template(v-if="i > 0 || path.length > maxItems")
      slot(name="delimiter")
        i.icon.chevron.right
    slot(name="item" v-bind:item="item")
      | {{ item.name }}
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapGetters, mapState, mapMutations, mapActions } =
  createNamespacedHelpers("drive")

export default {
  name: "Breadcrumbs",
  props: {
    maxItems: { type: Number, default: 3 },
    path: { type: Array, required: true },
  },
  computed: {
    omittedItems() {
      return this.path.slice(0, -this.maxItems)
    },
  },
}
</script>

<style scoped></style>
