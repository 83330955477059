import { defineComponent } from "vue";
import THEME from "../../constants/theme";
export default defineComponent({
    name: "DividerZigZag",
    props: {
        backgroundColor: {
            type: String,
            default: "white",
        },
        lineColor: {
            type: String,
            default: THEME.colors.light.mediumGrey,
        },
    },
    setup: function (props) {
        return {
            dividerStyles: {
                "--backgroundColor": props.backgroundColor,
                "--lineColor": props.lineColor,
            },
        };
    },
});
