import { __assign } from "tslib";
import Vue from "vue";
import axios from "axios";
import "axios";
var subdomain = window.location.host.split(".")[0];
var hostWithCurrentSubdomain = process.env.NODE_ENV === "production"
    ? "https://".concat(window.location.host)
    : process.env.API_URL_TEMPLATE.toString().replace("{subdomain}", subdomain);
var instance = axios.create({
    baseURL: hostWithCurrentSubdomain,
    withCredentials: true,
    headers: { credentials: "same-origin" },
});
window.addEventListener("DOMContentLoaded", function () {
    var _a;
    var csrfToken = (_a = document
        .querySelector("meta[name=csrf-token]")) === null || _a === void 0 ? void 0 : _a.getAttribute("content");
    if (csrfToken) {
        Vue.prototype.$http.defaults.headers.common["X-CSRF-Token"] = csrfToken;
        Vue.prototype.$http.defaults.headers.common["Accept"] = "application/json";
    }
});
var cancelledDueToBrowserDownload = "Download is being handled manually";
// Intercept requests that request a zip file as blob via GET and trigger a download in the browser instead.
// The original download is cancelled and the cancelled requests is intercepted and resolved with an empty response.
// Pass the following options to the api call to download it in the browser, e.g.
//   highlightsApi.downloadHighlights("42", { responseType: "blob", headers: { Accept: "application/zip" } })
instance.interceptors.request.use(function (config) {
    // Check if the request should trigger a download
    if ((config === null || config === void 0 ? void 0 : config.method) === "get" && (config === null || config === void 0 ? void 0 : config.responseType) === "blob") {
        triggerDownload(config.url);
        var source = axios.CancelToken.source();
        source.cancel(cancelledDueToBrowserDownload);
        return Promise.resolve(__assign(__assign({}, config), { cancelToken: source.token }));
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// Function to trigger download in the browser
var triggerDownload = function (url) {
    var link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
// Intercept responses that are cancelled due to a browser download and resolve them with an empty response
instance.interceptors.response.use(function (response) {
    // If the response is successful, just pass it through
    return response;
}, function (error) {
    // If the error message is "Download is being handled manually", return a successful empty response
    if (axios.isCancel(error) &&
        error.message === cancelledDueToBrowserDownload) {
        return {};
    }
    // If it's another error, just throw it
    return Promise.reject(error);
});
Vue.prototype.$http = instance;
export default instance;
