<template lang="pug">
list-item.inode
  template(#image)
    inode-icon(:inode="inode")

  template(#title)

    i.ui.icon.suus-connect(v-if="inode.level" v-tooltip="$t('drive.inode.level_tooltip')")
    i.ui.icon.suus-lock-circle(v-if="inode.restrict_to" v-tooltip="inode_visibility_note")
    span
      span.mobile.hidden(v-if="show.path")

        Breadcrumbs(:max-items="1" :path="inode.path.slice(1)")
          template(#item="{item: folder}")
            router-link.sub.title(:to="{path: `/drive/folders/${folder.id}`, params: { inode_id: folder.id} }")
              | {{ folder.name | truncate(35)}}
          template(#delimiter)
            span.sub.title {{" / "}}

        span.sub.title(v-if="inode.path.length > 1") {{"&nbsp;/ "}}

      router-link.title(:to="inodeLink")
        slot(name="filename")
          template(v-if="inode.name") {{inode.name}}
          template(v-else)
            i.secondary (Ohne Titel)
    i.ui.icon.suus-pin.blue(v-if="inode.is_pinned")

  template(#meta-columns v-if="show.category || show.physically_created_at || show.inodes_count || show.latest_drive_file || show.created_at")
    .column(v-if="show.category")
      i.ui.icon.suus-drawer
      router-link.text(:to="{path: `/drive/folders/${inode.category.id}`, params: { inode_id: inode.category.id} }")  {{ inode.category.name }}

    .column(v-if="show.physically_created_at && inode.physically_created_at_formatted")
      i.ui.icon.suus-time-3(:title="$t('drive.inode.physically_created_at.tooltip')")
      .text {{$t('drive.inode.physically_created_at.text', {physically_created_at: inode.physically_created_at_formatted})}}

    .column(v-if="show.inodes_count && inode.inodes_count")
      i.ui.icon.suus-single-copy-04(:title="$tc('drive.inode.inodes_count.tooltip', inode.inodes_count)")
      .text {{$tc('drive.inode.inodes_count.text', inode.inodes_count)}}

    .column(v-if="show.latest_drive_file && inode.latest_drive_file")
      template(v-if="inode.latest_drive_file")
        i.ui.icon.suus-time-3(:title="$t('drive.inode.latest_drive_file.tooltip')")
        .text {{$t('drive.inode.latest_drive_file.text', {date: inode.latest_drive_file.physically_created_at_formatted})}}

    .column(v-if="show.created_at")
      i.ui.icon.suus-time-3(:title="$t('drive.inode.created_at.tooltip')")
      .text {{$t('drive.inode.created_at.text', {created_at: $moment(inode.created_at).fromNow()})}}

  template(#actions v-if="show.actions")
    slot(name="actions")
      a.ui.icon.button(:href="inode.download_url" v-if="inode.download_url")
        i.ui.icon.suus-hit-down
        span.mobile.hidden {{$t('drive.downloadButton.text')}}
</template>

<script>
import ListItem from "../../ListItem.vue"
import InodeIcon from "../InodeIcon.vue"
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs.vue"
export default {
  name: "Inode",
  props: {
    inode: { type: Object, required: true },
    inodeLink: { type: Object, required: true },
    show: {
      type: Object,
      default() {
        return {
          image: true,
          path: false,
          category: false,
          physically_created_at: false,
          inodes_count: true,
          latest_drive_file: true,
          actions: true,
          created_at: false,
        }
      },
    },
  },
  components: { Breadcrumbs, ListItem, InodeIcon },
  computed: {
    inode_visibility_note() {
      return this.$t("drive.inode.visibility.tooltip", {
        group: this.inode.restrict_to.name,
      })
    },
    ...mapState("drive", ["inodes"]),
  },
}
</script>

<style scoped></style>
