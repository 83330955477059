import { defineComponent } from "vue";
export default defineComponent({
    name: "ImageModeMenuItem",
    props: {
        selected: {
            type: Boolean,
            required: true,
        },
        tooltip: {
            type: String,
            required: true,
        },
    },
});
