import Vue from "vue";
import TextBody from "./TextBody.vue";
import Header from "./Header.vue";
import Button from "./Button.vue";
export default Vue.extend({
    name: "ErrorHero",
    components: { Header: Header, TextBody: TextBody, Button: Button },
    props: {
        clickHandler: Function,
        titleText: String,
        line1: String,
        line2: String,
        btnLabel: String,
    },
});
