import { __awaiter, __generator } from "tslib";
import "features/gallery/lightbox/lightbox.service";
import { getHighlights } from "features/events/highlights/highlights.functions";
import "features/gallery/service/gallery.service";
import { getContextName } from "concerns/useContextName";
import "suus-api";
export var useEventHighlightPhotoswipe = function (eventId, galleryService, lightboxService, viewContext) {
    var loadHighlight = function (index) { return __awaiter(void 0, void 0, Promise, function () {
        var highlights, originalFile, author, contextAuthorName;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getHighlights(eventId, index, 1)];
                case 1:
                    highlights = _c.sent();
                    originalFile = (_a = highlights[0]) === null || _a === void 0 ? void 0 : _a.event_medium.uploaded_file.original;
                    author = (_b = highlights[0]) === null || _b === void 0 ? void 0 : _b.event_medium.uploaded_file.uploaded_by;
                    contextAuthorName = getContextName(viewContext, author);
                    return [2 /*return*/, {
                            src: originalFile.url,
                            width: originalFile.width,
                            height: originalFile.height,
                            authorName: contextAuthorName,
                        }];
            }
        });
    }); };
    var highlightsCount = galleryService.highlightsCount;
    var openLightbox = function (index) {
        lightboxService.openPhotoLightbox(index, highlightsCount.value, loadHighlight);
    };
    return {
        openLightbox: openLightbox,
    };
};
