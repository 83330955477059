import { computed, defineComponent, inject, } from "vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/events/form/event-form.service";
import ToggleableTextField from "features/common/forms/form-fields/ToggleableTextField.vue";
import "components/EventForm/EventForm.vue";
export default defineComponent({
    name: "OnlineConferenceField",
    components: { ToggleableTextField: ToggleableTextField },
    computed: {
        callUrlLabel: function () {
            return this.t$(".enter_custom_call_url");
        },
    },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        var eventFormService = inject("EventFormService");
        eventFormService.registerProperty({
            name: "online",
        });
        var isOnline = eventFormService.getFormValue("online");
        var setOnline = function (value) {
            return eventFormService.setFormValue("online", value);
        };
        eventFormService.registerProperty({
            name: "custom_call_url",
        });
        var callUrl = computed({
            get: function () {
                return eventFormService.getFormValue("custom_call_url").value;
            },
            set: function (value) {
                if (value !== eventFormService.getFormValue("custom_call_url").value)
                    eventFormService.setFormValue("custom_call_url", value);
            },
        });
        return {
            t$: t$,
            eventFormService: eventFormService,
            isOnline: isOnline,
            setOnline: setOnline,
            callUrl: callUrl,
        };
    },
});
