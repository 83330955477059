import { defineComponent } from "vue";
import TextButton from "components/UI/TextButton.vue";
import Button from "components/UI/Button.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import vuetify from "initializers/vuetify";
export default defineComponent({
    name: "FormActions",
    components: { Button: Button, TextButton: TextButton },
    props: {
        disableSaveButton: { type: Boolean, required: false, default: false },
    },
    computed: {
        formActionsStyles: function () {
            return {
                backgroundColor: "".concat(vuetify.framework.theme.themes.light.lightGrey, " !important"),
                borderRadius: "4px",
                height: "100%",
            };
        },
    },
    methods: {
        emitCancel: function () {
            this.$emit("cancel");
        },
        emitSave: function () {
            this.$emit("save");
        },
    },
    setup: function () {
        var t$ = useTranslate("common").t$;
        return {
            t$: t$,
        };
    },
});
