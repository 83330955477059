import { computed, defineComponent } from "vue";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
export default defineComponent({
    name: "CarouselArrow",
    props: {
        direction: {
            type: String,
            required: true,
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    setup: function (props) {
        var arrowIcon = props.direction === "left" ? mdiChevronLeft : mdiChevronRight;
        var classes = computed(function () { return [
            {
                hide: props.hide,
            },
            [props.direction],
            "arrow",
            "animate-fade",
        ]; });
        return {
            arrowIcon: arrowIcon,
            classes: classes,
        };
    },
});
