import { __awaiter, __generator, __spreadArray } from "tslib";
import "features/media/media.types";
import "features/gallery/gallery.types";
import { eventMediaApi } from "store/api";
import "suus-api";
import "features/posts/form/post-form.types";
export function addGalleryMediaToPost(postId, galleryUploads) {
    return __awaiter(this, void 0, Promise, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (galleryUploads.length === 0)
                        return [2 /*return*/, []];
                    return [4 /*yield*/, eventMediaApi.addEventMedia(postId.toString(), galleryUploads.map(function (galleryUpload) {
                            return convertMediaUploadToDTO(galleryUpload);
                        }))];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    });
}
export function addGalleryMediaToCreatedPost(createdPost, deletePost, galleryMedia) {
    return __awaiter(this, void 0, Promise, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, addGalleryMediaToPost(createdPost.id, galleryMedia)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [2 /*return*/, []];
                case 3: return [2 /*return*/];
            }
        });
    });
}
function convertMediaUploadToDTO(upload) {
    var _a, _b, _c, _d;
    return {
        file_name: (_a = upload.file) === null || _a === void 0 ? void 0 : _a.name,
        stored_file: upload.s3Key,
        content_type: (_b = upload.file) === null || _b === void 0 ? void 0 : _b.type,
        width: (_c = upload.file) === null || _c === void 0 ? void 0 : _c.width,
        height: (_d = upload.file) === null || _d === void 0 ? void 0 : _d.height,
    };
}
export function addGalleryMediaToUpdatedPost(updatedPost, galleryMedia, existingGalleryMedia) {
    if (existingGalleryMedia === void 0) { existingGalleryMedia = []; }
    return __awaiter(this, void 0, Promise, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = [[]];
                    return [4 /*yield*/, addGalleryMediaToPost(updatedPost.id, galleryMedia)];
                case 1: return [2 /*return*/, __spreadArray.apply(void 0, [__spreadArray.apply(void 0, _a.concat([(_b.sent()), true])), existingGalleryMedia, true])];
            }
        });
    });
}
export function deleteGalleryMedium(mediumId) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, eventMediaApi.deleteEventMedium(mediumId.toString())];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
