import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, ref } from "vue";
import PostListDivider from "./PostListDivider.vue";
import DividerZigZag from "components/UI/DividerZigZag.vue";
import TextButton from "components/UI/TextButton.vue";
import TextBody from "components/UI/TextBody.vue";
import PostListSkeleton from "features/posts/PostListSkeleton.vue";
import "suus-api";
import THEME from "constants/theme";
import useTranslate from "features/common/translate/concerns/useTranslate";
export default defineComponent({
    name: "PostOmissionLoader",
    props: {
        initialLeadingCount: {
            type: Number,
            required: true,
        },
        initialTrailingCount: {
            type: Number,
            required: true,
        },
        pageSize: {
            type: Number,
            required: true,
        },
        totalPostCount: {
            type: Number,
            required: true,
        },
        loadMorePosts: {
            type: Function,
            required: true,
        },
    },
    components: {
        PostListDivider: PostListDivider,
        TextButton: TextButton,
        TextBody: TextBody,
        PostListSkeleton: PostListSkeleton,
        DividerZigZag: DividerZigZag,
    },
    setup: function (props, context) {
        var _this = this;
        var leadingOffset = ref(0);
        var trailingOffset = ref(props.totalPostCount);
        var leadingLoading = ref(false);
        var trailingLoading = ref(false);
        var nextPageSize = computed(function () {
            return Math.min(props.pageSize, remainingPostsCount.value);
        });
        var loadLeading = function (pageSize) {
            if (pageSize === void 0) { pageSize = nextPageSize.value; }
            return __awaiter(_this, void 0, void 0, function () {
                var newLeadingPosts;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            leadingLoading.value = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, , 3, 4]);
                            return [4 /*yield*/, props.loadMorePosts(leadingOffset.value, pageSize)];
                        case 2:
                            newLeadingPosts = _a.sent();
                            leadingLoading.value = false; // so the loading is removed first, before new post is shown.
                            context.emit("leadingLoaded", newLeadingPosts);
                            leadingOffset.value += newLeadingPosts.length;
                            return [3 /*break*/, 4];
                        case 3:
                            leadingLoading.value = false;
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        var loadTrailing = function (pageSize) {
            if (pageSize === void 0) { pageSize = props.pageSize; }
            return __awaiter(_this, void 0, void 0, function () {
                var newTrailingPosts;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (pageSize == 0)
                                return [2 /*return*/];
                            trailingLoading.value = true;
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, , 3, 4]);
                            return [4 /*yield*/, props.loadMorePosts(trailingOffset.value - pageSize, pageSize)];
                        case 2:
                            newTrailingPosts = _a.sent();
                            context.emit("trailingLoaded", newTrailingPosts);
                            trailingOffset.value -= pageSize;
                            return [3 /*break*/, 4];
                        case 3:
                            trailingLoading.value = false;
                            return [7 /*endfinally*/];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        };
        var isInitialLoadingOverlapping = props.initialLeadingCount + props.initialTrailingCount >
            props.totalPostCount;
        if (props.totalPostCount > 0) {
            if (isInitialLoadingOverlapping) {
                loadLeading(props.totalPostCount);
            }
            else {
                loadLeading(props.initialLeadingCount);
                loadTrailing(props.initialTrailingCount);
            }
        }
        var remainingPostsCount = computed(function () {
            return trailingOffset.value - leadingOffset.value;
        });
        var isLastPage = computed(function () {
            return remainingPostsCount.value <= props.pageSize;
        });
        var allLoaded = computed(function () {
            return remainingPostsCount.value <= 0;
        });
        var onlyLeading = computed(function () {
            return props.initialLeadingCount > 0 && props.initialTrailingCount == 0;
        });
        var t$ = useTranslate("components.post_omission_loader").t$;
        return {
            isLastPage: isLastPage,
            loadLeading: loadLeading,
            loadTrailing: loadTrailing,
            allLoaded: allLoaded,
            leadingLoading: leadingLoading,
            trailingLoading: trailingLoading,
            remainingPostsCount: remainingPostsCount,
            dividerBackgroundColor: THEME.colors.light.surface,
            onlyLeading: onlyLeading,
            t$: t$,
            nextPageSize: nextPageSize,
        };
    },
});
