import { __awaiter, __generator } from "tslib";
/**
 * Register the service worker and take care of the lifecycle.
 *
 * @see https://web.dev/service-worker-lifecycle/
 * @see https://developer.chrome.com/docs/workbox/modules/workbox-window/
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Service_Worker_API/Using_Service_Workers
 */
import { Workbox } from "workbox-window";
import { initRegistrationRefresh } from "initializers/firebase";
import log from "features/common/logging/logging";
if ("serviceWorker" in navigator) {
    var wb_1 = new Workbox("/packs/actual-service-worker.js", { scope: "/" });
    // Not used yet: Since we do not yet use caches, we can activate the new SW immediately.
    var showSkipWaitingPrompt = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var doUpdate;
        return __generator(this, function (_a) {
            // Assuming the user accepted the update, set up a listener
            // that will reload the page as soon as the previously waiting
            // service worker has taken control.
            wb_1.addEventListener("controlling", function () {
                // At this point, reloading will ensure that the current
                // tab is loaded under the control of the new service worker.
                // Depending on your web app, you may want to auto-save or
                // persist transient state before triggering the reload.
                window.location.reload();
            });
            doUpdate = confirm("Es ist ein neues Update verfügbar. Um dieses zu aktivieren, müssen alle Tabs neu geladen werden. Ungespeicherte Eingaben gehen dabei verloren. Jetzt neu laden?");
            if (doUpdate) {
                wb_1.messageSkipWaiting();
            }
            return [2 /*return*/];
        });
    }); };
    var sendCsrfTokenToSW_1 = function () { return __awaiter(void 0, void 0, void 0, function () {
        var csrfToken;
        var _a;
        return __generator(this, function (_b) {
            csrfToken = (_a = document
                .querySelector("meta[name=csrf-token]")) === null || _a === void 0 ? void 0 : _a.getAttribute("content");
            if (csrfToken) {
                wb_1.messageSW({
                    type: "SET_CSRF_TOKEN",
                    payload: { csrfToken: csrfToken },
                })
                    .then(function (csrfToken) {
                    log("CSRF Token send to Service Worker: " + csrfToken);
                })
                    .catch(function (e) { return log("CSRF Token could not be send to SW", e); });
            }
            else {
                console.warn("CSRF Token not found in client!");
            }
            return [2 /*return*/];
        });
    }); };
    // A service worker has installed but it's stuck in the waiting phase.
    wb_1.addEventListener("waiting", function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            log("A new service worker has installed, but it can't activate" +
                "until all tabs running the current version have fully unloaded " +
                "(not refreshed!) or waiting is skipped forcefully.");
            wb_1.messageSkipWaiting();
            return [2 /*return*/];
        });
    }); });
    // A new service worker has installed (for the first time)
    wb_1.addEventListener("installed", function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!event.isUpdate) return [3 /*break*/, 2];
                    log("Service Worker was updated!");
                    // Tell the new SW directly the new CSRF token.
                    // Otherwise only on next registration the CSRF is shared.
                    return [4 /*yield*/, sendCsrfTokenToSW_1()];
                case 1:
                    // Tell the new SW directly the new CSRF token.
                    // Otherwise only on next registration the CSRF is shared.
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    log("Service worker installed for the first time!");
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); });
    // The service worker has finished its `activate` callback
    wb_1.addEventListener("activated", function (event) {
        // `event.isUpdate` will be true if another version of the service
        // worker was controlling the page when this version was registered.
        if (!event.isUpdate) {
            log("Service worker activated for the first time!");
            // If your service worker is configured to precache assets, those
            // assets should all be available now.
        }
        else {
            log("Service worker activated!");
        }
    });
    // Send the CSRF token to the service worker, when requested (e.g. after a terminated service worker was restarted)
    wb_1.addEventListener("message", function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.data.action === "request-csrf-token")) return [3 /*break*/, 2];
                    return [4 /*yield*/, sendCsrfTokenToSW_1()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); });
    // The service worker has started controlling the page.
    // This happens only on second visit of the page: First visit the SW is discovered and installed,
    // but not controlling this client yet.
    wb_1.addEventListener("controlling", function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            log("The service worker is now controlling the page!");
            return [2 /*return*/];
        });
    }); });
    // Register the service worker after event listeners have been added.
    wb_1.register()
        .then(function (swRegistration) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    log("SW registered!");
                    return [4 /*yield*/, initRegistrationRefresh(swRegistration.active)
                        // TODO in case of error refresh service worker
                        //swRegistration.update()
                    ];
                case 1:
                    _a.sent();
                    // TODO in case of error refresh service worker
                    //swRegistration.update()
                    return [4 /*yield*/, sendCsrfTokenToSW_1()
                        // Check the version of the controlling service worker.
                    ];
                case 2:
                    // TODO in case of error refresh service worker
                    //swRegistration.update()
                    _a.sent();
                    // Check the version of the controlling service worker.
                    wb_1.messageSW({ type: "GET_VERSION" }).then(function (swVersion) {
                        log("Checking service Worker version: " + swVersion);
                    });
                    return [2 /*return*/];
            }
        });
    }); })
        .catch(function (e) { return log("SW could not be registered", e); });
}
