<template>
  <sui-progress
      :percent="totalProgress"
      success
      state="success"
      progress
      size="tiny"
      attached
      bottom
      content=""
      :indicating="saving"
  ></sui-progress>
</template>

<script>
export default {
  name: 'UploadProgress',
  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    totalProgress: {
      type: Number,
      required: true
    },
  },
}
</script>
