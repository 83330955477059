import { __awaiter, __generator } from "tslib";
/**
 * Responsible for loading the Highlights-Preview.
 */
import "suus-api";
import { ref } from "vue";
import { getHighlights } from "features/events/highlights/highlights.functions";
export var useFetchPreviewHighlights = function (eventId) {
    var isLoading = ref(false);
    var error = ref(null);
    var highlights = ref([]);
    var loadHighlights = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    isLoading.value = true;
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, 4, 5]);
                    _a = highlights;
                    return [4 /*yield*/, getHighlights(eventId, 0, 10)];
                case 2:
                    _a.value = _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    error.value = e_1;
                    return [3 /*break*/, 5];
                case 4:
                    isLoading.value = false;
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return {
        isLoading: isLoading,
        error: error,
        highlights: highlights,
        loadHighlights: loadHighlights,
    };
};
