import Vue from "vue";
import AvatarPlaceholder from "../UI/AvatarPlaceholder.vue";
import { mdiEmailSendOutline, mdiPencil } from "@mdi/js";
import { mdiPhoneOutgoingOutline } from "@mdi/js";
import { mdiArrowTopRight } from "@mdi/js";
import Button from "components/UI/Button.vue";
export default Vue.extend({
    name: "AvatarCard",
    components: { Button: Button, AvatarPlaceholder: AvatarPlaceholder },
    props: {
        email: String,
        phone: String,
        name: String,
        avatarUrl: String,
        isEditable: { type: Boolean, default: false },
        url: String,
        maxWidth: { type: String, default: "350" },
        subtitle: { type: [String, Boolean], default: false },
        avatarType: {
            type: String,
            default: "GroupMember",
            validator: function (val) { return ["GroupMember", "Group"].includes(val); },
        },
    },
    computed: {
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        },
        contactData: function () {
            if ((this.email && this.phone && this.isMobile) ||
                (!this.email && this.phone)) {
                return {
                    href: "tel:".concat(this.phone),
                    icon: mdiPhoneOutgoingOutline,
                    label: "Tel",
                };
            }
            if (!this.isMobile && this.email) {
                return {
                    href: "mailto:".concat(this.email),
                    icon: mdiEmailSendOutline,
                    label: "E-Mail",
                };
            }
            return {
                href: this.url,
                icon: mdiArrowTopRight,
                label: "Profil",
            };
        },
    },
    methods: {
        goToUrl: function (event) {
            if (this.url)
                window.location.href = "".concat(this.url);
        },
        emitEdit: function () {
            this.$emit("edit");
        },
    },
    data: function () {
        return {
            editIcon: mdiPencil
        };
    }
});
