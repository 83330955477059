export default {
  currentInode(state) {
    if (state.inodes !== undefined) {
      return state.inodes[state.currentInodeId]
    } else {
      return undefined
    }
  },
  currentContents(state, getters) {
    if (getters.currentInode) {
      return Object.values(state.inodes).filter(
        (e) => e.parent_id == getters.currentInode.id
      )
    } else {
      return []
    }
  },
  currentCategory(state, getters) {
    if (getters.path.length === 0) {
      return null
    }
    return getters.path[0]
  },
  parentFolder(state, getters) {
    if (getters.path.length < 2) {
      return {}
    }
    return getters.path.slice(-2, -1)[0] // penultimate element
  },
  currentFolder(state, getters) {
    if (!getters.currentInode) return null

    if (
      ["Container", "Folder", "Gallery"].includes(getters.currentInode.type)
    ) {
      return getters.currentInode
    } else {
      return state.inodes[getters.currentInode.parent_id]
    }
  },
  path(state, getters) {
    let path = []
    if (!getters.currentInode) {
      return []
    }

    let node = getters.currentInode
    while (node) {
      path = [node].concat(path)
      node = state.inodes[node.parent_id]
    }
    return path
  },
  error(state) {
    return state.error
  },
}
