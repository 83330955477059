import axios from "../initializers/axios";
import { CommentsApi, Configuration, CurrentMemberApi, DigestApi, EventsApi, GroupApi, GroupMemberApi, MembersApi, PostsApi, TagsApi, TopicsApi, FoldersApi, DriveFilesApi, RsvpsApi, DriveApi, PushDeviceApi, EventMediaApi, HighlightsApi, CustomAttributeDefinitionsApi, ClientApi, LevelApi, } from "suus-api";
var configuration = new Configuration({ basePath: axios.defaults.baseURL });
export var commentsApi = new CommentsApi(configuration, undefined, axios);
export var currentMemberApi = new CurrentMemberApi(configuration, undefined, axios);
export var digestApi = new DigestApi(configuration, undefined, axios);
export var eventsApi = new EventsApi(configuration, undefined, axios);
export var groupApi = new GroupApi(configuration, undefined, axios);
export var groupMemberApi = new GroupMemberApi(configuration, undefined, axios);
export var membersApi = new MembersApi(configuration, undefined, axios);
export var postsApi = new PostsApi(configuration, undefined, axios);
export var tagsApi = new TagsApi(configuration, undefined, axios);
export var topicsApi = new TopicsApi(configuration, undefined, axios);
export var foldersApi = new FoldersApi(configuration, undefined, axios);
export var driveApi = new DriveApi(configuration, undefined, axios);
export var driveFilesApi = new DriveFilesApi(configuration, undefined, axios);
export var rsvpApi = new RsvpsApi(configuration, undefined, axios);
export var pushDeviceApi = new PushDeviceApi(configuration, undefined, axios);
export var eventMediaApi = new EventMediaApi(configuration, undefined, axios);
export var highlightsApi = new HighlightsApi(configuration, undefined, axios);
export var clientApi = new ClientApi(configuration, undefined, axios);
export var levelApi = new LevelApi(configuration, undefined, axios);
export var customAttributeDefinitionsApi = new CustomAttributeDefinitionsApi(configuration, undefined, axios);
