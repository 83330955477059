import { defineComponent } from 'vue';
export default defineComponent({
    name: 'DriveUploaderSubmit',
    props: {
        saving: {
            type: Boolean,
            default: false,
        },
        saved: {
            type: Boolean,
            default: false,
        },
        processedUploads: {
            type: Number,
            default: 0,
        },
        totalUploads: {
            type: Number,
            default: 0,
        },
        uploadsCount: {
            type: Number,
            default: 0,
        },
        savedUploads: {
            type: Number,
            default: 0,
        },
        failedUploads: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },
});
