import { __awaiter, __generator } from "tslib";
import { computed, defineComponent, } from "vue";
import "suus-api";
import "../../interfaces/reactionCounts.interface";
import useReaction from "../../concerns/useReaction";
import ReactionCountFull from "../ReactionCount/ReactionCountFull.vue";
import ReactionComboButton from "../ReactionComboButton/ReactionComboButton.vue";
import SamePageLink from "../UI/SamePageLink.vue";
import Button from "../UI/Button.vue";
import ScrollTo from "../UI/ScrollTo.vue";
import { mdiCommentAccountOutline } from "@mdi/js";
import { mdiMessagePlusOutline } from "@mdi/js";
import "concerns/useContextName";
import { MemberReaction } from "components/ReactionCount/reaction-count.types";
export default defineComponent({
    name: "TopicFooter",
    components: {
        ReactionCountFull: ReactionCountFull,
        ReactionComboButton: ReactionComboButton,
        SamePageLink: SamePageLink,
        Button: Button,
        ScrollTo: ScrollTo,
    },
    props: {
        topic: { type: Object, required: true },
        postsCount: { type: Number, default: 0 },
        canReact: { type: Boolean, default: true },
        canPost: { type: Boolean, default: true },
        viewContext: { type: Object },
    },
    setup: function (props, context) {
        var initialReactionCounts = computed(function () {
            return {
                likesCount: props.topic.likes_count,
                ideasCount: props.topic.ideas_count,
                cheersCount: props.topic.cheers_count,
            };
        });
        var intitialUserReaction = computed(function () { var _a; return (_a = props.topic.own_reaction) === null || _a === void 0 ? void 0 : _a.emoji; });
        var _a = useReaction({ id: props.topic.id, type: "Topic" }, initialReactionCounts, intitialUserReaction), createReaction = _a.createReaction, deleteReaction = _a.deleteReaction, updateReaction = _a.updateReaction, likesCount = _a.likesCount, ideasCount = _a.ideasCount, cheersCount = _a.cheersCount, userReaction = _a.userReaction, reactions = _a.reactions;
        var memberReactions = computed(function () {
            return reactions.value.map(function (reaction) { return MemberReaction.fromReaction(reaction, props.viewContext); });
        });
        function handleDeleteReaction(prevReaction) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, deleteReaction(props.topic.id.toString(), prevReaction)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function handleCreateReaction(newReaction) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, createReaction(props.topic.id.toString(), newReaction)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function handleUpdateReaction(_a) {
            var prevReaction = _a.prevReaction, newReaction = _a.newReaction;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, updateReaction(props.topic.id.toString(), newReaction, prevReaction)];
                        case 1:
                            _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
        function composePostsSummary(count) {
            var isOne = count === 1;
            var amountText = "".concat(count, " Antworten");
            if (isOne)
                amountText = "1 Antwort";
            return amountText;
        }
        var postsSummary = computed(function () {
            return props.postsCount ? composePostsSummary(props.postsCount) : "";
        });
        return {
            likesCount: likesCount,
            ideasCount: ideasCount,
            cheersCount: cheersCount,
            userReaction: userReaction,
            handleDeleteReaction: handleDeleteReaction,
            handleCreateReaction: handleCreateReaction,
            handleUpdateReaction: handleUpdateReaction,
            postsSummary: postsSummary,
            mdiCommentAccountOutline: mdiCommentAccountOutline,
            mdiMessagePlusOutline: mdiMessagePlusOutline,
            memberReactions: memberReactions,
        };
    },
});
