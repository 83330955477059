import { __awaiter, __generator } from "tslib";
import Header from "../UI/Header.vue";
import TextBody from "../UI/TextBody.vue";
import Subtitle from "../UI/Subtitle.vue";
import { EVENT_REMINDERS } from "../../constants/constants";
import moment from "moment";
import { useCanSendUrgentOnLevelOrClientFallback } from "features/common/permissions/usePermissionCheckHelper";
import { formatDateTime } from "helpers/dateTime";
import { PanelIdentifier } from "features/events/form/event-form.types";
import { computed, defineComponent, inject } from "vue";
import "features/events/form/event-form.service";
import "components/EventForm/EventForm.vue";
import store from "store";
import useTranslate from "features/common/translate/concerns/useTranslate";
import "features/member/member.service";
var ReminderFields = defineComponent({
    name: "ReminderFields",
    components: { Header: Header, TextBody: TextBody, Subtitle: Subtitle },
    data: function () {
        return {
            remindersEnabled: true,
            infoText: "Hier kannst Du konfigurieren, ob und wann du eine Benachrichtigung \u00FCber diese Veranstaltung im Digest verschicken m\u00F6chtest.",
            canSendUrgent: false,
        };
    },
    computed: {
        possibleReminders: function () {
            return Object.keys(EVENT_REMINDERS);
        },
        labelStyles: function () {
            return {
                color: this.$vuetify.theme.themes.light.primary,
                fontWeight: 400,
            };
        },
        alertStyles: function () {
            return {
                borderColor: "".concat(this.$vuetify.theme.themes.light.mediumGrey, " !important"),
                fontFamily: "system-ui !important",
                fontSize: "1rem",
                color: "".concat(this.$vuetify.theme.themes.light.textColor, " !important"),
                marginBottom: "0px",
                width: "100%",
            };
        },
    },
    methods: {
        isReminderChecked: function (key) {
            var _a;
            return ((_a = this.reminders) === null || _a === void 0 ? void 0 : _a.length) && this.reminders.includes(key);
        },
        isReminderActive: function (key) {
            if (this.isEditForm && key === "on_creation")
                return false;
            return this.isReminderInFuture(key);
        },
        getReminderLabel: function (key) {
            var label = EVENT_REMINDERS[key].label;
            if (!this.isReminderInFuture(key))
                return label + " (abgelaufen)";
            return label;
        },
        showReminder: function (key) {
            if (this.isEditForm) {
                return this.isReminderChecked(key) || this.isReminderInFuture(key);
            }
            else {
                return this.isReminderInFuture(key);
            }
        },
        toggleReminder: function (key, targetState) {
            var _this = this;
            if (targetState === void 0) { targetState = undefined; }
            targetState =
                targetState === undefined ? !this.isReminderChecked(key) : targetState;
            if (targetState) {
                // Filter on possibleReminders to get ordered reminders
                this.reminders = this.possibleReminders.filter(function (r) { return _this.isReminderChecked(r) || r === key; });
            }
            else {
                this.reminders = this.reminders.filter(function (r) { return r !== key; });
            }
            // Check if all are unselected now
            this.remindersEnabled = !!this.possibleReminders.find(function (r) {
                return _this.isReminderChecked(r);
            });
        },
        toggleFutureReminders: function (val) {
            var _this = this;
            this.possibleReminders
                .filter(function (r) { return _this.isReminderInFuture(r); })
                .map(function (r) { return _this.toggleReminder(r, val); });
        },
        formatDateTime: function (datetime) {
            return moment(datetime).format("dd. DD.MM.YYYY, HH:mm:ss");
        },
        setRemindersEnabled: function (val) {
            this.remindersEnabled = val;
            this.toggleFutureReminders(val);
        },
    },
    watch: {
        startDate: function (dateNow, datePrev) {
            var _this = this;
            if (this.remindersEnabled) {
                var pastAvailableReminders_1 = this.possibleReminders.filter(function (r) { return datePrev && _this.isReminderInFuture(r, datePrev); });
                var nowAvailableReminders_1 = this.possibleReminders.filter(function (r) {
                    return _this.isReminderInFuture(r, dateNow);
                });
                // Check reminders that become now available
                var newReminders = nowAvailableReminders_1.filter(function (r) { return !pastAvailableReminders_1.includes(r); });
                newReminders.map(function (r) { return _this.toggleReminder(r, true); });
                // Uncheck reminders that became now unavailable
                var oldReminders = pastAvailableReminders_1.filter(function (r) { return !nowAvailableReminders_1.includes(r); });
                oldReminders.map(function (r) { return _this.toggleReminder(r, false); });
            }
        },
        group: function (val) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.canSendUrgent = useCanSendUrgentOnLevelOrClientFallback(val.level_id).value;
                    return [2 /*return*/];
                });
            });
        },
    },
    mounted: function () {
        this.remindersEnabled = !this.isEditForm || this.reminders.length > 0;
        this.canSendUrgent = this.canUrgentDigestOnClient;
    },
    setup: function () {
        var t$ = useTranslate("components.event_form").t$;
        var memberService = inject("MemberService");
        var canUrgentDigestOnClient = memberService.canUrgentDigestOnClient;
        var eventFormService = inject("EventFormService");
        eventFormService.registerProperty({
            name: "urgent",
        });
        eventFormService.registerProperty({
            name: "reminders",
        });
        var urgent = computed({
            get: function () {
                return eventFormService.getFormValue("urgent").value || null;
            },
            set: function (value) {
                eventFormService.setFormValue("urgent", value);
            },
        });
        var reminders = computed({
            get: function () {
                return eventFormService.getFormValue("reminders").value || [];
            },
            set: function (value) {
                eventFormService.setFormValue("reminders", value);
            },
        });
        var startDate = eventFormService.getFormValue("start_date");
        var group = eventFormService.getFormValue("group");
        var levelId = computed(function () { var _a; return ((_a = group.value) === null || _a === void 0 ? void 0 : _a.level_id) || null; });
        var currentEvent = computed(function () { return store.getters["event/currentEvent"]; });
        var mailedAt = computed(function () { var _a; return ((_a = currentEvent.value) === null || _a === void 0 ? void 0 : _a.mailed_at) || null; });
        var isEditForm = eventFormService.isEdit;
        var getReminderDate = function (key, startDateValue) {
            var _a;
            if (startDateValue === void 0) { startDateValue = startDate.value; }
            // Set the on creation reminder to some date in the past when editing an existing event
            // in order to handle it uniformely like the others.
            if (isEditForm && key === "on_creation") {
                return moment((_a = currentEvent.value) === null || _a === void 0 ? void 0 : _a.created_at);
            }
            var number = EVENT_REMINDERS[key].intervalAmount;
            var type = EVENT_REMINDERS[key].intervalType;
            var date = startDateValue || new Date();
            return moment(date).subtract(number, type);
        };
        var isReminderInFuture = function (key, startDateValue) {
            if (startDateValue === void 0) { startDateValue = startDate.value; }
            return (startDate &&
                getReminderDate(key, startDateValue).isSameOrAfter(moment()));
        };
        var alertTimeText = computed(function () {
            if (!isEditForm.value || !mailedAt.value)
                return "";
            return t$("components.topic_form.urgent_message_sent_at", {
                time: formatDateTime(mailedAt.value),
            });
        });
        return {
            getReminderDate: getReminderDate,
            isReminderInFuture: isReminderInFuture,
            urgent: urgent,
            reminders: reminders,
            t$: t$,
            group: group,
            levelId: levelId,
            startDate: startDate,
            isEditForm: isEditForm,
            mailedAt: mailedAt,
            canUrgentDigestOnClient: canUrgentDigestOnClient,
            alertTimeText: alertTimeText,
        };
    },
});
export default ReminderFields;
export var ReminderFieldsDefinition = {
    key: "notifications",
    component: ReminderFields,
    id: PanelIdentifier.REMINDER,
};
