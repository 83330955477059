<template lang="pug">
div
  template(v-if="allLoaded")
    slot(name="default")

  template(v-else-if="errorCode === 404")
    slot(name="notFound")
      h1.ui.icon.header.centered
        span.content
          i.ui.icon.suus-ban
          | Nicht gefunden!
          .sub.header Die Adresse, über die Du hier her gekommen bist, scheint nicht korrekt zu sein, tut uns leid.
          br
          a.ui.button.primary(href='/') Zur Startseite

  template(v-else-if="errorCode === 403")
    slot(name="error")
      h1.ui.icon.header.centered
        span.content
          i.ui.icon.suus-bug
          | Fehler!
          .sub.header Zugriff verweigert!

  template(v-else-if="errorCode === 500")
    slot(name="notPermitted")
      h1.ui.icon.header.centered
        span.content
          i.ui.icon.suus-bug
          | Fehler!
          .sub.header Server Fehler

  template(v-else-if="error")
    slot(name="otherError")
      h1.ui.icon.header.centered
        span.content
          i.ui.icon.suus-bug
          | Fehler!
          .sub.header Irgendetwas ist schief gegangen.
      strong Bitte melde folgenden Fehler an den Support:
      pre {{ error }}

  template(v-else-if="loading")
    slot(name="loading")
      h1.ui.icon.header.centered
        span.content
          i.ui.icon.suus-circle.loading
          | Lade...
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"

export default {
  name: "LoaderWrapper.vue",
  props: { requires: { default: "NOTHING" } },
  computed: {
    errorCode() {
      try {
        return this.error ? this.error.request.status : null
      } catch (e) {
        return null
      }
    },
    allLoaded() {
      return this.requires === "NOTHING"
        ? true
        : this.requires.every((x) => !!x)
    },
    ...mapState("loader", ["loading", "error"]),
    ...mapGetters("drive", ["parentFolder"]),
  },
}
</script>

<style scoped></style>
