import { defineComponent } from "vue";
import "features/gallery/thumbnail/GalleryThumbnail.vue";
import Theme from "constants/theme";
export default defineComponent({
    name: "GalleryThumbnailActionBar",
    props: {
        actions: {
            type: Array,
            default: function () { return []; },
        },
    },
    setup: function () {
        return {
            primaryColor: Theme.colors.light.primary,
        };
    },
});
