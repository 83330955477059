import { defineComponent } from "vue";
import "suus-api";
import { getContextNameLong } from "concerns/useContextName";
import Subtitle from "components/UI/Subtitle.vue";
import { goToUrl } from "helpers/navigation";
import RelativeTime from "components/UI/RelativeTime.vue";
export default defineComponent({
    name: "PostHeader",
    methods: { getContextNameLong: getContextNameLong },
    components: { RelativeTime: RelativeTime, Subtitle: Subtitle },
    props: {
        post: {
            type: Object,
            required: true,
        },
        viewContext: {
            type: Object,
        },
    },
    setup: function (props) {
        var goToProfile = function () {
            if (props.post.creator.url)
                goToUrl(props.post.creator.url);
        };
        return {
            goToProfile: goToProfile,
        };
    },
});
