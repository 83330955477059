import { __awaiter, __generator } from "tslib";
import "suus-api";
import { groupMemberApi } from "store/api";
export var fetchGroupMemberByCurrentMember = function (member) { return __awaiter(void 0, void 0, Promise, function () {
    var id, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = (_a = member === null || member === void 0 ? void 0 : member.personal_record) === null || _a === void 0 ? void 0 : _a.id;
                if (!id) return [3 /*break*/, 2];
                return [4 /*yield*/, groupMemberApi.getGroupMember(id.toString())];
            case 1:
                response = _b.sent();
                return [2 /*return*/, response.data];
            case 2: return [2 /*return*/, undefined];
        }
    });
}); };
