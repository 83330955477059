import List from 'list.js'
window.initGuestsIndex = (scope) ->
  values = [
    'first_name',
    'last-name',
    'contact',
    'odds',
    'age',
    'last-contact',
    'tag-container'
    {data: ['status', 'updated-at', 'tag-id', 'last-name', 'odds-impossible']},
  ]
  options = {
    valueNames: values,
    listClass: 'searchable-list',
    searchClass: ''
  }
  window.guestlist1 = new List 'all-guestlist', options
  guestlist1.registerFilterOption '#guest_record_tags', 'tag-id', List.subsetMatch
  guestlist1.registerFilterOption '#guest_record_status', 'status', List.exactMatch
  guestlist1.registerFilterOption '#guest_record_odds_impossible', 'odds-impossible', List.booleanMatch('odds_impossible')

  window.guestlist2 = new List 'own-guestlist', options
  guestlist2.registerFilterOption '#guest_record_tags', 'tag-id', List.subsetMatch
  guestlist2.registerFilterOption '#guest_record_status', 'status', List.exactMatch
  guestlist2.registerFilterOption '#guest_record_odds_impossible', 'odds-impossible', List.booleanMatch('odds_impossible')

  guestlist1.registerSortOption '#sort-by-age', 'age'
  guestlist1.registerSortOption '#sort-by-update', 'updated-at'
  guestlist1.registerSortOption '#sort-by-last-contact', 'last-contact'
  guestlist1.registerSortOption '#sort-by-last-name', 'last-name'

  guestlist2.registerSortOption '#sort-by-age', 'age'
  guestlist2.registerSortOption '#sort-by-update', 'updated-at'
  guestlist2.registerSortOption '#sort-by-last-contact', 'last-contact'
  guestlist2.registerSortOption '#sort-by-last-name', 'last-name'

  guestlist1.registerSearchForm 'guest_record_search'
  guestlist2.registerSearchForm 'guest_record_search'
