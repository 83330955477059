import { __assign } from "tslib";
import Vue from "vue";
import ScrollTo from "components/UI/ScrollTo.vue";
import useTranslate from "features/common/translate/concerns/useTranslate";
import FormAlertTipp from "features/common/forms/FormAlertTipp.vue";
export default Vue.extend({
    name: "FormAlert",
    components: { FormAlertTipp: FormAlertTipp, ScrollTo: ScrollTo },
    props: {
        customClass: { type: String, default: "" },
        color: { type: String, required: false },
        width: { type: String, default: "100%" },
        fieldErrors: {
            type: Array,
            required: false,
            default: function () { return []; },
        },
        error: { type: String, required: false },
        tipp: { type: Object, required: false },
        defaultMessage: { type: String, required: false },
    },
    computed: {
        formAlertType: function () {
            return !!this.fieldErrors.length || !!this.error ? "error" : "default";
        },
        alertStyles: function () {
            return __assign({ fontFamily: "system-ui !important", fontSize: "1rem", width: this.width, margin: 0, textWrap: "wrap", wordBreak: "break-word", height: "100%" }, (this.formAlertType === "error"
                ? this.errorAlertStyles
                : this.defaultAlertStyles));
        },
        defaultAlertStyles: function () {
            return {
                borderColor: "".concat(this.color || this.$vuetify.theme.themes.light.mediumGrey, " !important"),
                color: "".concat(this.color || this.$vuetify.theme.themes.light.darkGrey, " !important"),
            };
        },
        errorAlertStyles: function () {
            return {
                borderColor: "".concat(this.$vuetify.theme.themes.light.error, " !important"),
                color: "".concat(this.$vuetify.theme.themes.light.error, " !important"),
            };
        },
    },
    setup: function () {
        var t$ = useTranslate("common").t$;
        return {
            t$: t$,
        };
    },
});
