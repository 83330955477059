import { ref, computed, defineComponent, watch } from "vue";
import "../../interfaces/avatar.interface";
import "suus-api";
import useDeleteComment from "../../concerns/useDeleteComment";
import TopicBlock from "./TopicBlock.vue";
import TopicFooter from "./TopicFooter.vue";
import CommentList from "../Comment/CommentList.vue";
import "concerns/useContextName";
import { MemberReaction } from "components/ReactionCount/reaction-count.types";
export default defineComponent({
    name: "Topic",
    components: { TopicBlock: TopicBlock, TopicFooter: TopicFooter, CommentList: CommentList },
    props: {
        topic: { type: Object, required: true },
        totalPostsCount: { type: Number, default: 0 },
        canCreatePosts: { type: Boolean, default: false },
        canCreateReactions: { type: Boolean, default: false },
        topicReactions: { type: Array, default: function () { return []; } },
    },
    setup: function (props, context) {
        var avatarData = computed(function () {
            return {
                name: props.topic.creator.internal_name_short,
                imageUrl: props.topic.creator.avatar_url,
                profileUrl: props.topic.creator.url,
                clients: props.topic.creator.clients,
            };
        });
        var commentList = ref(props.topic.comments);
        var commentsCount = ref(props.topic.comments_count);
        watch(function () { return props.topic; }, function (newVal) {
            commentList.value = newVal.comments;
            commentsCount.value = newVal.comments_count;
        });
        function handleDeleteComment(commentId) {
            var isSuccess = useDeleteComment(commentId.toString()).isSuccess;
            watch(isSuccess, function (isSuccessValue) {
                if (isSuccessValue) {
                    var _comments = commentList.value.filter(function (comment) { return comment.id !== commentId; });
                    commentList.value = _comments;
                    commentsCount.value--;
                    console.log(commentList.value);
                    console.log(commentsCount.value);
                }
            });
        }
        var viewContext = { group: props.topic.group };
        var memberReactions = ref([]);
        watch(function () { return props.topicReactions; }, function (newVal) {
            memberReactions.value = newVal.map(function (reaction) {
                return MemberReaction.fromReaction(reaction, viewContext);
            });
        });
        return {
            viewContext: viewContext,
            avatarData: avatarData,
            handleDeleteComment: handleDeleteComment,
            commentList: commentList,
            commentsCount: commentsCount,
            memberReactions: memberReactions,
        };
    },
});
